/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import { getOffersUniqueYears, getOffersUniqueMonths, getProgramsUniqueYears, getProgramsUniqueMonths } from './requests';
import { OFFERS } from '../../constants/routes';
import SvgOffersArchive from '../../components/Svg/SvgOffersArchive';
import SvgArrow from '../../components/Svg/SvgArrow';
import { Link } from 'react-router-dom';
import Dropdown from '../../components/Dropdown';
import './index.css';

export default function BlockArchive({ children, changeHandler, header, returnLink, type }) {
  let getUniqueMonths, getUniqueYears;
  switch (type) {
    case 'programs': 
      getUniqueMonths = getProgramsUniqueMonths;
      getUniqueYears = getProgramsUniqueYears;
      break;
    case 'offers': 
    default: 
      getUniqueMonths = getOffersUniqueMonths;
      getUniqueYears = getOffersUniqueYears;
      break;
  }


  const monthsToDigitalBinding = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
  ];


  const [openYears, setOpenYears] = useState(false);
  const [openMonths, setOpenMonths] = useState(false);
  const [activeYear, setActiveYear] = useState(null);
  const [activeMonth, setActiveMonth] = useState(null);
  const [availableYears, setAvailableYears] = useState([]);
  const [availableMonths, setAvailableMonths] = useState([]);
  const [availabeMonthsInWords, setAvailabeMonthsInWords] = useState([]);

  const processingRequest = async () => {
    // setProgram((await getFilterPrograms()).data);
    setAvailableYears((await getUniqueYears(activeYear, activeMonth ? monthsToDigitalBinding.indexOf(activeMonth) + 1 : activeMonth)).data);
    setAvailableMonths((await getUniqueMonths(activeYear, activeMonth ? monthsToDigitalBinding.indexOf(activeMonth) + 1 : activeMonth)).data);
  };

  useEffect(() => {
    const monthArr = []
    availableMonths.forEach(month => {
      monthArr.push(monthsToDigitalBinding[month - 1]);
    });
    setAvailabeMonthsInWords(monthArr);
  }, [availableMonths]);

  useEffect(() => {
    changeHandler(activeYear, monthsToDigitalBinding.indexOf(activeMonth) + 1);
    processingRequest();
  }, [activeYear,activeMonth]);


  const yearsOpenHandler = () => {
    setOpenYears(!openYears);
    setOpenMonths(false);
  };
  const monthsOpenHandler = () => {
    setOpenMonths(!openMonths);
    setOpenYears(false);
  };

  return (
    <div class="archive-offers-content">
        <Link to={returnLink} className="button-back">
              <div className="button-back__text">Вернуться в активные</div>
              <SvgArrow filled direction="left" color="purple" size={26} />
            </Link>
            <div className="archive-row">
              <div className="archive-row__title-container">
                <div className="archive-row__img-container">
                  <SvgOffersArchive />
                </div>
                <div className="archive-row__title">{header}</div>
              </div>
              <div className="archive-row__date-container">
                <Dropdown
                  title="Год"
                  items={availableYears}
                  open={openYears}
                  handler={yearsOpenHandler}
                  active={activeYear}
                  setActive={setActiveYear}
                />
                <Dropdown
                  title="Месяц"
                  items={availabeMonthsInWords}
                  open={openMonths}
                  handler={monthsOpenHandler}
                  active={activeMonth}
                  setActive={setActiveMonth}
                />
              </div>
              {activeYear || activeMonth ? (
                <div class = "drop-filter" onClick={() => {
                  changeHandler(null, null, null);
                  setActiveYear(null);
                  setActiveMonth(null);
                }}>Сбросить</div>
              ) : ""}
            </div>
            <div className="archive__scroll-bar">
              {children}
            </div>
    </div>
  );
}

BlockArchive.propTypes = {
  children: PropTypes.node,
};

BlockArchive.defaultProps = {
  children: <div />,
};
