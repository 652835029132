import fetchRequest from '../../helpers/fetchRequest';

export async function rateTrack(trackId) {
  const url = `/rate/${trackId}`;
  const options = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function getChartTimestamp() {
  const url = `/get-chart-timestamp`;
  const options = {
    method: 'GET',
  };
  return fetchRequest(url, options);
}
