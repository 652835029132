import React from 'react';

export default function SvgEmcees() {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.35249 33.25C5.35249 33.25 2.84961 33.25 2.84961 30.6909C2.84961 28.1318 5.35249 20.4544 17.8669 20.4544C30.3813 20.4544 32.8842 28.1318 32.8842 30.6909C32.8842 33.25 30.3813 33.25 30.3813 33.25H5.35249ZM17.8669 17.8952C19.8583 17.8952 21.7682 17.0864 23.1763 15.6466C24.5845 14.2068 25.3756 12.254 25.3756 10.2179C25.3756 8.18168 24.5845 6.22891 23.1763 4.78912C21.7682 3.34933 19.8583 2.54047 17.8669 2.54047C15.8755 2.54047 13.9656 3.34933 12.5575 4.78912C11.1494 6.22891 10.3583 8.18168 10.3583 10.2179C10.3583 12.254 11.1494 14.2068 12.5575 15.6466C13.9656 17.0864 15.8755 17.8952 17.8669 17.8952Z"
        fill="#7500AB"
      />
    </svg>
  );
}
