import React, {useState, useEffect, Suspense} from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
// const Client = React.lazy(() => import('./client'));
// const Admin = React.lazy(() => import('./admin'));
// const NotFound = React.lazy(() => import('./client/pages/NotFound'));
import Client from './client';
import Admin from './admin';
import NotFound from './client/pages/NotFound';
import './App.css';

function App() {
  const [isLoaded, setIsloaded] = useState(false);
  // // const dispatch = useDispatch();
  // // const processingRequest = () => {
  // //   setIsloaded(false);
  // //   setTimeout(() => {
  // //     setIsloaded(true);
  // //   }, 500);
  // // }
  const hideLoadScreen = () => {
    // setTimeout(() => {
      setIsloaded(true);
    // }, 500);
  }
  useEffect(() => {
    document.querySelector("body").style.overflowY = "hidden";
    // dispatch(setLoadedPage(true));
    window.onload = () => {
      hideLoadScreen();
      document.querySelector("body").style.overflowY = "";
    }
  }, []);
  
  const bodyStyles = {
    height: `${isLoaded ? "auto" : '100vh'}`
  };
  const childStyles = {
    display: `${isLoaded ? "none" : "flex"}`, 
    justifyContent: "center", 
    alignItems: "center", 
    width: "100vw", 
    height: '100%', 
    background: "#fff", 
    position: "absolute", 
    top: "0", 
    left: "0", 
    zIndex: "9999"
  };

  return (
    // isLoaded ? (
    // <Suspense fallback={<div>Загрузка...</div>}>
    <div style = {bodyStyles}>
      <div style = {childStyles}>
        <img src = "/uploads/images/settings/svgviewer-output.svg" />
      </div>
      <BrowserRouter>
        <Switch>
          <Route path="/admin" component={Admin} />
          <Route exact path="/not-found" component={NotFound} />
          <Route component={Client} />
        </Switch>
      </BrowserRouter>
    </div>
   // </Suspense>
    // ) : (
    //   <div style={{fontSize: "50px", fontWeight: "bold", color: "#fff"}}>Loading</div>
    // )
  );
}

export default App;
