import React from 'react';
import { useHistory } from 'react-router-dom';
import PapperBlock from '../../../components/PapperBlock';
import BannersForm from '../BannersForm';
import { createBanner } from '../requests';
import { BannerIcon } from '../../../constants/icons';
import { ADMIN_BANNERS_ROUTE } from '../../../constants/routes';

export default function CreateBanners() {
  const history = useHistory();
  const submitHandler = async (data, picture) => {
    const response = await createBanner(data, picture);
    if (response.ok) {
      history.push(ADMIN_BANNERS_ROUTE);
    }
  };
  return (
    <PapperBlock title="Добавть баннер" icon={<BannerIcon />}>
      <BannersForm submitHandler={submitHandler} />
    </PapperBlock>
  );
}
