import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import styles from '../../styles';
import {loginUser, autoLogin} from './requests';

function LoginForm(props) {

  const {
    classes,
    parentLoginHandler
  } = props;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorResponse, setErrorResponse] = useState('');

  const loginHandler = async () => {
    const response = (await loginUser(email, password, "Belarus")).data;
    console.log(response);
    if (response.token) {
      localStorage.setItem("loginToken", response.token);
      parentLoginHandler();
    } else {
      setErrorResponse(response.message);
    }
  }

  const processingRequest = async () => {
    if (localStorage.getItem("loginToken")) {
      const response = (await autoLogin(localStorage.getItem("loginToken"))).data;
      console.log(response);
      if (response.result == "approve") {
        parentLoginHandler();
      }
    }
  }

  useEffect(() => {
    processingRequest()
  }, [])

  return (
    <Paper className={classes.loginFormContainer}>
      <div className={classes.formAndLogo}>
        <img 
          src={"/uploads/images/settings/314c6f5a-0e1c-4ace-aa91-aba0ca3e2599_logo.svg"}
          className={classes.loginFormLogo}
        />
        <form className={classes.loginForm}> {/* onSubmit={handleSubmit} */}
          <TextField
            name="text"
            type="text"
            className={classes.loginField} 
            fullWidth
            placeholder="Логин"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <TextField
            name="password"
            type="password"
            className={classes.loginField}
            fullWidth
            placeholder="Пароль"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
          <div>
            <Button
              variant="contained"
              fullWidth
              color="primary"
              size="large"
              onClick={loginHandler}
            >
              Войти
            </Button>
          </div>
        </form>
        <p className={classes.errorResponseText}>{errorResponse}</p>
      </div>
    </Paper>
  );
}

// LoginForm.propTypes = {
//   classes: PropTypes.object.isRequired,
//   handleSubmit: PropTypes.func.isRequired,
// };

export default withStyles(styles)(LoginForm);
