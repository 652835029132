import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTytes from 'prop-types';
import { withStyles, Button } from '@material-ui/core';
import PapperBlock from '../../components/PapperBlock';
import DataTable from '../../components/DataTable';
import { getProgramsForTable } from './requests';
import { ProgramIcon } from '../../constants/icons';
import { ADMIN_PROGRAMS_CREATE_ROUTE } from '../../constants/routes';
import Columns from './Columns';
import styles from '../../styles';

function ProgramsPage({ classes }) {
  const [dataProgram, setDataProgram] = useState([]);
  async function processingRequest() {
    setDataProgram((await getProgramsForTable()).data);
  }
  useEffect(() => {
    processingRequest();
  }, []);

  return (
    <PapperBlock title="Программы" icon={<ProgramIcon />}>
      <Link to={ADMIN_PROGRAMS_CREATE_ROUTE} className={classes.link}>
        <Button variant="contained" color="primary" className={classes.field}>
          Добавить программу
        </Button>
      </Link>
      <DataTable data={dataProgram} title="Список программ" columns={Columns} />
    </PapperBlock>
  );
}

export default withStyles(styles)(ProgramsPage);

ProgramsPage.propTypes = {
  classes: PropTytes.object,
};

ProgramsPage.defaultProps = {
  classes: {},
};
