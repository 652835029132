import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import PapperBlock from '../../../components/PapperBlock';
import UsersForm from '../UsersForm';
import {
  getUserById,
  updateUser,
} from '../requests';
import { UsersIcon } from '../../../constants/icons';
import { ADMIN_USERS_ROUTE } from '../../../constants/routes';

export default function UpdateUser() {
  const [user, setUser] = useState({});
  const [loaded, setLoaded] = useState(false);
  const params = useParams();
  const { id } = params;
  async function processingRequest() {
    setUser((await getUserById(id)).data);
    setLoaded(true);
  }
  const history = useHistory();
  const submitHandler = async (data) => {
    if(data.oldPassword != '') {
      if((data.newPassword != data.confirmPassword) || 
      (data.newPassword != "" && data.confirmPassword == "") ||
      (data.newPassword == "" && data.confirmPassword != "")) {
        alert("Значения в полях 'Новый пароль' и 'Подтвердить пароль' не совпадают");
        return false;
      } else if (data.newPassword == "" && data.confirmPassword == "") {
        alert("Новый пароль пользователя не должен быть пустым");
        return false;
      }
    }
    const response = await updateUser(data);
    if (response.ok) {
      // console.log(response);
      history.push(ADMIN_USERS_ROUTE);
    } else {
      // console.log(response);
      alert(response.data.message)
    }
  };
  useEffect(() => {
    console.log("params", params);
    console.log("loaded", loaded);
    processingRequest();
  }, []);

  return (
    <PapperBlock title="Обновить пользователя" icon={<UsersIcon />}>
      {loaded && (
        <UsersForm
          user={user}
          submitHandler={submitHandler}
        />
      )}
    </PapperBlock>
  );
}
