import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import classNames from 'classnames';
import {
  withStyles,
  TextField,
  Button,
  Switch,
  Input,
} from '@material-ui/core';
import ImageDropzone from '../../components/Dropzone/ImageDropzone';
import styles from '../../styles';
import { DATE_FORMAT, DATE_FORMAT_TO_DB, DATE_TIME_FORMAT } from '../../constants/dateTimeFormat';
import {maxImageFileSize} from "../../constants/additional";

function SlideForm({ classes, data, submitHandler }) {
  const { id } = data;
  const [link, setLink] = useState(data.link);
  const [status, setStatus] = useState(data.status);
  const [sort, setSort] = useState(data.sort);
  const [pictureUrl, setPictureUrl] = useState(!id ? data.picture : [data.picture]);
  const [pictureFile, setPictureFile] = useState([]);
  const [pictureToDelete, setPictureToDelete] = useState([]);
  const [publishDate, setPublishDate] = useState(
    data.publish_date ? new Date(data.publish_date) : data.publish_date,
  );
  const [unpublishDate, setUnpublishDate] = useState(
    data.unpublish_date ? new Date(data.unpublish_date) : data.unpublish_date,
  );
  const createDate = new Date(data.create_date);

  async function dispatchHandler(event) {
    event.preventDefault();

    const ifOk = confirm('Вы уверены что хотите сохранить изменения?');
    if (ifOk) {
      await submitHandler(
        {
          id,
          link,
          status,
          sort,
          pictureUrl,
          pictureToDelete,
          createDate: format(createDate, DATE_FORMAT_TO_DB),
          publishDate: publishDate ? format(publishDate, DATE_FORMAT_TO_DB) : null,
          unpublishDate: unpublishDate ? format(unpublishDate, DATE_FORMAT_TO_DB) : null,

        },
        pictureFile[0],
      );
    } else {
      history.back();
    }
    
  }

  return (
    <div>
      <form onSubmit={dispatchHandler}>
        <div className={classes.field}>
          <div className={classes.title}>Ссылка:</div>
          <TextField
            type="text"
            value={link}
            onChange={(e) => {
              setLink(e.target.value);
            }}
            className={classes.field}
          />
        </div>
        <div className={classes.field}>
          <div className={classes.title}>
            Видимость на главной:
            <Switch
              color="primary"
              checked={status}
              onChange={(e) => {
                setStatus(e.target.checked);
              }}
            />
          </div>
        </div>
        <div className={classes.field}>
          <div className={classes.title}>Сортировка:</div>
          <Input
            type="number"
            value={sort}
            onChange={(e) => {
              setSort(e.target.value);
            }}
            className={classes.field}
          />
        </div>
        <div className={classes.flexOne}>
          <div className={classes.halfWidth}>
            <ImageDropzone
              files={pictureFile}
              setFiles={setPictureFile}
              urls={pictureUrl}
              setUrls={setPictureUrl}
              toDelete={pictureToDelete}
              setToDelete={setPictureToDelete}
              text="Загрузить картинку"
              filesLimit={1}
              acceptedFiles={['image/*']}
              maxSize={maxImageFileSize}
            />
          </div>
        </div>
        <div className={classNames(classes.field, classes.dateRow)}>
          <div className={classes.dateColumn}>
            <div className={classes.dateRow}>Дата создания:</div>
            <div className={classes.dateRow}>
              {format(createDate, DATE_TIME_FORMAT)}
            </div>
          </div>
        <div className={classes.dateColumn}>
          <div className={classes.dateRow}>Дата публикации:</div>
            <TextField
              className={classes.dateRow}
              type="datetime-local"
              value={
                publishDate ? format(publishDate, DATE_TIME_FORMAT) : undefined
              }
              onChange={(e) => {
                setPublishDate(Date.parse(e.target.value));
              }}
            />
          </div>
          <div className={classes.dateColumn}>
            <div className={classes.dateRow}>Дата снятия с публикации:</div>
            <TextField
              className={classes.dateRow}
              type="datetime-local"
              value={
                unpublishDate
                  ? format(unpublishDate, DATE_TIME_FORMAT)
                  : undefined
              }
              onChange={(e) => {
                setUnpublishDate(Date.parse(e.target.value));
              }}
            />
          </div>
        </div>
        <Button variant="contained" color="primary" type="submit" fullWidth>
          {id ? 'Обновить' : 'Создать'}
        </Button>
      </form>
    </div>
  );
}

SlideForm.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  submitHandler: PropTypes.func,
};

SlideForm.defaultProps = {
  classes: {},
  data: {
    link: '',
    status: false,
    sort: 0,
    create_date: Date.now(),
  },
  submitHandler: () => {},
};

export default withStyles(styles)(SlideForm);
