import React from 'react';

export default function SvgPodcasts() {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.9805 24.5653C31.0498 24.5653 33.5469 22.0682 33.5469 18.9989V10.0927C33.5469 7.02333 31.0498 4.52625 27.9805 4.52625C24.9112 4.52625 22.4141 7.02333 22.4141 10.0927V18.9989C22.4141 22.0682 24.9112 24.5653 27.9805 24.5653Z"
        fill="#7500AB"
      />
      <path
        d="M36.8867 13.4324C36.2719 13.4324 35.7734 13.9309 35.7734 14.5457V18.9988C35.7734 23.2959 32.2775 26.7918 27.9805 26.7918C23.6834 26.7918 20.1875 23.2959 20.1875 18.9988V14.5457C20.1875 13.9309 19.689 13.4324 19.0742 13.4324C18.4594 13.4324 17.9609 13.9309 17.9609 14.5457V18.9988C17.9609 24.1473 21.8644 28.4006 26.8672 28.9562V33.4715H23.5273C22.9125 33.4715 22.4141 33.9699 22.4141 34.5848C22.4141 35.1996 22.9125 35.6981 23.5273 35.6981H32.4336C33.0484 35.6981 33.5469 35.1996 33.5469 34.5848C33.5469 33.9699 33.0484 33.4715 32.4336 33.4715H29.0938V28.9562C34.0965 28.4006 38 24.1473 38 18.9988V14.5457C38 13.9308 37.5015 13.4324 36.8867 13.4324Z"
        fill="#7500AB"
      />
      <path
        d="M20.1875 10.0927C20.1875 9.47783 19.689 8.97938 19.0742 8.97938H17.4615L15.5427 5.14167C15.1143 4.28489 13.8695 4.33551 13.5133 5.22606L10.1889 13.537L6.69676 3.06088C6.35973 2.04987 4.92219 2.04757 4.58442 3.06088L2.61161 8.9793H1.11328C0.498453 8.9793 0 9.47776 0 10.0926C0 10.7074 0.498453 11.2059 1.11328 11.2059H3.41406C3.89322 11.2059 4.31864 10.8993 4.4702 10.4446L5.64062 6.93346L9.03762 17.1244C9.18539 17.5676 9.59411 17.8714 10.0611 17.8851C10.5218 17.8988 10.9517 17.625 11.1274 17.1857L14.6598 8.35475L15.7778 10.5905C15.9663 10.9677 16.3518 11.2059 16.7735 11.2059H19.0743C19.689 11.2059 20.1875 10.7075 20.1875 10.0927Z"
        fill="#7500AB"
      />
      <defs>
        <clipPath id="clip0">
          <rect width="38" height="38" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
