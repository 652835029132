import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import PapperBlock from '../../../components/PapperBlock';
import AboutBlockForm from '../AboutBlockForm';
import { getBlockById, updateBlockById } from '../requests';
import { NewsIcon } from '../../../constants/icons';
import { ADMIN_ABOUT_ROUTE } from '../../../constants/routes';

export default function UpdateAboutBlock() {
  const [dataAbout, setDataAbout] = useState({});
  const [loaded, setLoaded] = useState(false);
  const params = useParams();
  const { id } = params;
  const processingRequest = async () => {
    setDataAbout((await getBlockById(id)).data);
    setLoaded(true);
  };
  const history = useHistory();
  const submitHandler = async (data) => {
    const response = await updateBlockById(data);
    if (response.ok) {
      history.push(ADMIN_ABOUT_ROUTE);
    }
  };
  useEffect(() => {
    processingRequest();
  }, []);

  return (
    <PapperBlock title="Обновить блок" icon={<NewsIcon />}>
      {loaded && <AboutBlockForm submitHandler={submitHandler} data={dataAbout} />}
    </PapperBlock>
  );
}
