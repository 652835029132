import React from 'react';

export default function SliderDot() {
  return (
    <svg
      width="20"
      height="10"
      viewBox="0 0 20 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1"
        y="1"
        width="18"
        height="8"
        rx="4"
        stroke="white"
        strokeWidth="1.5"
      />
    </svg>
  );
}
