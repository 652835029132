import { LOAD_PAGE, SET_BLOCK, TOGGLE_SIDEBAR } from './types';

export const toggleAction = () => ({ type: TOGGLE_SIDEBAR });
export const playTransitionAction = (isLoaded) => ({
  type: LOAD_PAGE,
  payload: isLoaded,
});

export const setAboutData = (data) => ({
  type: SET_BLOCK,
  payload: data,
});
