/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation, Redirect } from 'react-router-dom';
import './index.css';

export default function BlockPreviewProgram({
  img,
  title,
  description,
  timetable,
  redirect,
  redirectHandler,
}) {
  return (
    <div className="preview-program">
      <Link to={redirect.replace(location.protocol + "//" + location.hostname, "")}>
        <div className="zoom-container ">
          <img src={img} alt={title} className="zoom-item" />
          <div className="preview-program__inner-row">
            <div className="preview-program__inner-row-content">
              <div>
                <div className="preview-program__title">{title}</div>
                <div className="preview-program__description">
                  {description}
                </div>
              </div>
              <div>
                <div className="preview-program__timetable">
                  {timetable.split('\n').map((el) => (
                    <div className="timetable-item" key={el}>
                      {el}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}

BlockPreviewProgram.propTypes = {
  img: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  timetable: PropTypes.string,
  redirect: PropTypes.string,
};

BlockPreviewProgram.defaultProps = {
  img: '',
  title: '',
  description: '',
  timetable: '',
  redirect: '',
};
