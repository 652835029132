import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { CLIENT_DATE_FORMAT } from '../../constants/dateTimeFormat';
import './index.css';

const BlockArchiveItem = forwardRef(({
  title,
  redirect,
  img,
  publishDate,
  unpublishDate,
  bg,
  ref,
  endingLabel,
}) => {
  const grey = 'background-grey';
  const white = 'background-white';
  const background = bg === 'grey' ? grey : white;

  return (
    <Link to={redirect} className="archive-item__link-container" ref={ref}>
      <div className={`${background} archive-item`}>
        <div className="archive-item__img-container">
          <img src={img} alt={title} />
        </div>
        <div className="archive-item__content">
          <div className="archive-item__info">
            <div className="archive-item__title">{title}</div>
            <div className="archive-item__dates">
              {publishDate && format(new Date(publishDate), CLIENT_DATE_FORMAT)}
              {publishDate && unpublishDate && '-'}
              {unpublishDate && format(new Date(unpublishDate), CLIENT_DATE_FORMAT)}
            </div>
          </div>
          <div className="archive-item__alert">{endingLabel}</div>
        </div>
      </div>
    </Link>
  );
})

export default BlockArchiveItem;

// BlockArchiveItem.propTypes = {
//   title: PropTypes.string,
//   redirect: PropTypes.string,
//   img: PropTypes.string,
//   publishDate: PropTypes.string,
//   unpublishDate: PropTypes.string,
//   bg: PropTypes.oneOf(['white', 'grey']),
// };

// BlockArchiveItem.defaultProps = {
//   title: '',
//   redirect: '/',
//   img: '',
//   publishDate: '',
//   unpublishDate: '',
//   bg: 'white',
// };
