import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTytes from 'prop-types';
import { Button, withStyles } from '@material-ui/core';
import PapperBlock from '../../components/PapperBlock';
import DataTable from '../../components/DataTable';
import { getPlaylistsForTable } from './requests';
import Columns from './Columns';
import styles from '../../styles';

function PlaylistsPage({ classes }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    async function processingRequest() {
      setData((await getPlaylistsForTable()).data);
    }
    processingRequest();
  }, []);

  return (
    <PapperBlock title="Плейлисты">
      <Link to="/admin/playlists/create" className={classes.link}>
        <Button variant="contained" color="primary" className={classes.field}>
          Добавить плейлист
        </Button>
      </Link>
      <DataTable title="Список плейлистов" data={data} columns={Columns} />
    </PapperBlock>
  );
}

export default withStyles(styles)(PlaylistsPage);

PlaylistsPage.propTypes = {
  classes: PropTytes.object,
};

PlaylistsPage.defaultProps = {
  classes: {},
};
