import fetchRequest from '../../helpers/fetchRequest';

const QUIZ = '/quiz';

// export async function createQuiz(data, avatar, photos) {
//   const formData = new FormData();
//   formData.append('avatar', avatar);
//   photos.map((photo) => formData.append('photos', photo));
//   formData.append('data', JSON.stringify(data));
//   const url = QUIZ;
//   const options = {
//     method: 'POST',
//     body: formData,
//   };
//   return fetchRequest(url, options);
// }

export async function createQuiz(data) {
  const url = QUIZ;
  const options = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: { 'Content-Type': 'application/json' }
  };
  return fetchRequest(url, options);
}

export async function getQuizById(id) {
  const url = `${QUIZ}/${id}`;
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  };
  return fetchRequest(url, options);
}

export async function getQuizForTable() {
  const url = QUIZ;
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}
export async function updateQuizById(data) {
  const url = `${QUIZ}/${data.id}`;
  const options = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: { 'Content-Type': 'application/json' }
  };
  return fetchRequest(url, options);
}
export async function deleteQuizById(id) {
  const url = `${QUIZ}/${id}`;
  const options = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' }
  };
  return fetchRequest(url, options);
 }
// export async function deleteEmceeById(id) {
//   const url = `${EMCEES}/${id}`;
//   const options = {
//     method: 'DELETE',
//   };
//   return fetchRequest(url, options);
// }

// export async function getEmceeById(id) {
//   const url = `${EMCEES}/${id}`;
//   const options = {
//     method: 'GET',
//     headers: { 'Content-Type': 'application/json' },
//   };
//   return fetchRequest(url, options);
// }

// export async function updateEmceeById(data, avatar, photo) {
//   const formData = new FormData();
//   formData.append('avatar', avatar);
//   // photos.map((photo) => formData.append('photos', photo));
//   formData.append('photo', photo)
//   formData.append('data', JSON.stringify(data));
//   const url = `${EMCEES}/${data.id}`;
//   const options = {
//     method: 'PUT',
//     body: formData,
//   };
//   return fetchRequest(url, options);
// }

export async function getProgramsForForm() {
  const url = PROGRAMS_FORM;
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}
