export function isImage(file) {
  if (
    file === undefined
    || file === null
    || file.name === undefined
    || file.name === null
  ) {
    return false;
  }
  const fileName = file.name;
  const suffix = fileName.substr(fileName.indexOf('.') + 1).toLowerCase();
  if (
    suffix === 'jpg'
    || suffix === 'jpeg'
    || suffix === 'bmp'
    || suffix === 'png'
    || suffix === 'gif'
    || suffix === 'svg'
    || suffix === "webp"
  ) {
    return true;
  }

  return false;
}

export function isAudio(file) {
  if (
    file === undefined
    || file === null
    || file.name === undefined
    || file.name === null
  ) {
    return false;
  }
  const fileName = file.name;
  const suffix = fileName.substr(fileName.indexOf('.') + 1).toLowerCase();
  if (suffix === 'mp3') {
    return true;
  }

  return false;
}

export function isUrl(file) {
  if (file.name !== undefined || file === undefined || file === null) {
    return false;
  }
  const suffix = file.substr(file.indexOf('.') + 1).toLowerCase();
  if (
    suffix === 'jpg'
    || suffix === 'jpeg'
    || suffix === 'bmp'
    || suffix === 'png'
    || suffix === 'gif'
    || suffix === 'svg'
    || suffix === 'webp'
  ) {
    return true;
  }

  return false;
}

export function isAudioUrl(file) {
  if (file.name !== undefined || file === undefined || file === null) {
    return false;
  }
  const suffixesList = file.split('.');
  const suffix = suffixesList[suffixesList.length - 1];
  if (suffix === 'mp3') {
    return true;
  }
  return false;
}
