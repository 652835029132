import React from 'react';
import Slider from 'react-slick';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { CLIENT_DATE_FORMAT } from '../../constants/dateTimeFormat';
import SvgArrow from '../Svg/SvgArrow';
import SvgSliderDot from '../Svg/SvgSliderDot';
import Animation from '../../helpers/animation';
import './index.css';

export default function CarouselWidget({ data, sliderTitle }) {
  const widthOnePoint = 30;
  const dotsLength = data ? data.length * widthOnePoint : null;
  function SampleNextArrow(props) {
    const { onClick } = props;
    return (
      <div
        type="button"
        className="slick-arrow slick-next"
        onClick={(e) => {
          new Animation(e).button();
          onClick();
        }}
      >
        <SvgArrow color="yellow" direction="right" size={25} />
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
      <div
        type="button"
        className="slick-arrow slick-prev"
        onClick={(e) => {
          new Animation(e).button();
          onClick();
        }}
        style={{ right:  50 + dotsLength }}
      >
        <SvgArrow color="yellow" direction="left" size={25} />
      </div>
    );
  }
  SampleNextArrow.propTypes = {
    onClick: PropTypes.func,
  };

  SampleNextArrow.defaultProps = {
    onClick: () => {},
  };

  SamplePrevArrow.propTypes = {
    onClick: PropTypes.func,
  };

  SamplePrevArrow.defaultProps = {
    onClick: () => {},
  };

  // if (data.length) {
  //   console.log();
  // }

  return (
    <div style={{ position: 'relative'}}>
      {sliderTitle && <NavLink to="/news" className="slider-title">{sliderTitle}</NavLink>}
      {data && (
        <Slider
          autoplay
          dots
          customPaging={() => (
            <button type="button">
              <SvgSliderDot />
            </button>
          )}
          infinite
          centerMode={false}
          speed={600}
          autoplaySpeed={3000}
          pauseOnHover
          slidesToShow={1}
          slidesToScroll={1}
          nextArrow={<SampleNextArrow />}
          prevArrow={<SamplePrevArrow />}
        >
          {data.map((item, index) => (
            <NavLink to={`/news/${item.alias}`} key={index.toString()}>
              <div className="slide-item">
                <div className="slide-info">
                  <div className="slick-date">{item.publish_date ? format(new Date(item.publish_date), CLIENT_DATE_FORMAT) : ""}</div>
                  <div className="slick-title">{item.title ? item.title : ""}</div>
                </div>
                <img
                  src={item.picture}
                  alt={item.title ? item.title : ""}
                  className="slider-img"
                />
              </div>
            </NavLink>
          ))}
        </Slider>
      )}
    </div>
  );
}

CarouselWidget.propTypes = {
  data: PropTypes.array,
  sliderTitle: PropTypes.string,
};

CarouselWidget.defaultProps = {
  data: [],
  sliderTitle: '',
};
