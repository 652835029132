import React, { useState, useEffect } from 'react';
import "./index.css";
function ProgramSlide({src, programIndex, index, length, programName}) {

    let position = "";
    if(programIndex === index){
        position = 'active'
    }
    if(programIndex === index - 1 || (index === 0 && programIndex === length - 1)){
        position = ''
    }

    return ( 
        <img src={ src } className={`ether-player__preview-img auto-swiper-item ${position}`} alt={ programName } />
    );
}

export default ProgramSlide;