import React from 'react';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';
import Fade from '@material-ui/core/Fade';
import { withStyles } from '@material-ui/core/styles';
import dataMenu from '../../constants/menu';
import Header from '../../components/Header/Header';
import Sidebar from '../../components/Sidebar';
import Loading from '../../components/Loading';
import styles from '../styles';

function LeftSidebarLayout({
  classes,
  children,
  toggleDrawer,
  sidebarOpen,
  loadTransition,
  pageLoaded,
}) {
  return (
    <>
      <Header toggleDrawerOpen={toggleDrawer} margin={sidebarOpen} />
      <Sidebar
        open={sidebarOpen}
        toggleDrawerOpen={toggleDrawer}
        loadTransition={loadTransition}
        dataMenu={dataMenu}
        leftSidebar
      />
      <main
        className={classNames(
          classes.content,
          !sidebarOpen && classes.contentPaddingLeft,
        )}
        id="mainContent"
      >
        <section
          className={classNames(classes.mainWrap, classes.sidebarLayout)}
        >
          {!pageLoaded && <Loading />}
          <Fade in={pageLoaded} timeout={700}>
            <div className={!pageLoaded ? classes.hideApp : ''}>{children}</div>
          </Fade>
        </section>
      </main>
    </>
  );
}

LeftSidebarLayout.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.node,
  toggleDrawer: PropTypes.func,
  sidebarOpen: PropTypes.bool,
  loadTransition: PropTypes.func,
  pageLoaded: PropTypes.bool,
};

LeftSidebarLayout.defaultProps = {
  classes: {},
  children: <div />,
  toggleDrawer: () => {},
  sidebarOpen: true,
  loadTransition: () => {},
  pageLoaded: true,
};

export default withStyles(styles)(LeftSidebarLayout);
