import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import PapperBlock from '../../../components/PapperBlock';
import ProgramsForm from '../ProgramsForm';
import {
  getProgramById,
  getPodcastsForForm,
  getEmceesForForm,
  getGuestsForForm,
  updateProgramById,
} from '../requests';
import { ProgramIcon } from '../../../constants/icons';
import { ADMIN_PROGRAMS_ROUTE } from '../../../constants/routes';

export default function UpdatePrograms() {
  const [dataProgram, setDataProgram] = useState({});
  const [podcastsList, setPodcastsList] = useState([]);
  const [emceesList, setEmceesList] = useState([]);
  const [guestsList, setGuestsList] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const params = useParams();
  const { id } = params;
  async function processingRequest() {
    setDataProgram((await getProgramById(id)).data);
    setPodcastsList((await getPodcastsForForm()).data);
    setEmceesList((await getEmceesForForm()).data);
    setGuestsList((await getGuestsForForm()).data);
    setLoaded(true);
  }
  const history = useHistory();
  const submitHandler = async (data, picture) => {
    // console.log(data);
    let isIncorrectTime = false;
    data.detailTimetable.forEach(schedule => {
      if (schedule.time_start > '23:59' || schedule.time_end > '23:59') {
        isIncorrectTime = true;
      }
    });
    if (isIncorrectTime) {
      alert ('Указан неправильный формат времени, временные рамки - 00:00 - 23:59');
      return false;
    }
    const response = await updateProgramById(data, picture);
    if (response.ok) {
      // console.log(response);
      history.push(ADMIN_PROGRAMS_ROUTE);
    } else {
      // console.log(response);
      alert(response.data.message)
    }
  };
  useEffect(() => {
    processingRequest();
  }, []);

  return (
    <PapperBlock title="Обновить программу" icon={<ProgramIcon />}>
      {loaded && (
        <ProgramsForm
          data={dataProgram}
          podcasts={podcastsList}
          emcees={emceesList}
          guests={guestsList}
          submitHandler={submitHandler}
        />
      )}
    </PapperBlock>
  );
}
