import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTytes from 'prop-types';
import { withStyles, Button } from '@material-ui/core';
import PapperBlock from '../../components/PapperBlock';
import DataTable from '../../components/DataTable';
import { getUsers } from './requests';
import { UsersIcon } from '../../constants/icons';
import { ADMIN_USERS_CREATE_ROUTE } from '../../constants/routes';
import Columns from './Columns';
import styles from '../../styles';

function UsersPage({ classes }) {
  const [users, setUsers] = useState([]);
  async function processingRequest() {
    setUsers((await getUsers()).data);
  }
  useEffect(() => {
    processingRequest();
  }, []);

  return (
    <PapperBlock title="Пользователи" icon={<UsersIcon />}>
      <Link to={ADMIN_USERS_CREATE_ROUTE} className={classes.link}>
        <Button variant="contained" color="primary" className={classes.field}>
          Добавить пользователя
        </Button>
      </Link>
      <DataTable data={users} title="Список пользователей" columns={Columns} />
    </PapperBlock>
  );
}

export default withStyles(styles)(UsersPage);

UsersPage.propTypes = {
  classes: PropTytes.object,
};

UsersPage.defaultProps = {
  classes: {},
};
