import React, {useState} from 'react';
import PropTypes from 'prop-types';
import SvgArrow from '../Svg/SvgArrow';
import './index.css';

export default function DropdownForObject({
  title,
  items,
  open,
  handler,
  active,
  setActive,
}) {

  const [activeTitle, setActiveTitle] = useState(title);

  let titleActiveArr = Array.from(items).filter((el) => { return active == el.id });
  // console.log('activeTitle',activeTitle[0] ? activeTitle[0].title : "");

  // console.log("activeTitle", activeTitle);
  // console.log("title", title);
  // console.log("activeTitle2", activeTitle2);

  return (
    <div className="dropdown-menu__container" onClick={handler}>
      <div className="dropdown-menu__title">{ titleActiveArr.length > 0 ? activeTitle : title}</div>
      <div
        className="dropdown-menu__svg-container"
        style={{
          transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
        }}
      >
        <SvgArrow color="yellow" filled size={21} />
      </div>
      <div
        style={{ display: open ? 'flex' : 'none' }}
        className="dropdown-menu__scroll-bar"
      >
        <ul className="dropdown-menu__list">
          {Array.from(items).map((el, key) => (
            <li
              key={el.id}
              onClick={() => {
                setActive(el.id);
                handler();
                setActiveTitle(el.title);
              }}
              className={`dropdown-menu__item ${active === el.id ? 'active' : ''}`}
            >
              {el.title}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

DropdownForObject.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array,
  open: PropTypes.bool,
  handler: PropTypes.func,
  active: PropTypes.object,
  setActive: PropTypes.func,
};

DropdownForObject.defaultProps = {
  title: '',
  items: [],
  open: false,
  handler: () => {},
  active: null,
  setActive: () => {},
};
