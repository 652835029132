import fetchRequest from '../../helpers/fetchRequest';

export async function getSettingsForTable() {
  const url = '/settings';
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function getSettingSiteBackground() {
  const url = '/settings/site-background';
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function updateSettingSiteBackground(data, picture) {
  const formData = new FormData();
  formData.append('picture', picture);
  formData.append('data', JSON.stringify(data));
  const url = '/settings/site-background';
  const options = {
    method: 'PUT',
    body: formData,
  };
  return fetchRequest(url, options);
}

export async function getSettingLogo() {
  const url = '/settings/logo';
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function updateSettingLogo(data, picture) {
  const formData = new FormData();
  formData.append('picture', picture);
  formData.append('data', JSON.stringify(data));
  const url = '/settings/logo';
  const options = {
    method: 'PUT',
    body: formData,
  };
  return fetchRequest(url, options);
}

export async function getLeftBannerData() {
  const url = '/settings/left-banner';
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function updateLeftBanner(data, picture) {
  const formData = new FormData();
  formData.append('picture', picture);
  formData.append('data', JSON.stringify(data));
  console.log(formData.data);
  const url = '/settings/left-banner';
  const options = {
    method: 'POST',
    body: formData,
  };
  return fetchRequest(url, options);
}

export async function getPollData() {
  const url = '/settings/poll-settings';
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function updatePollData(data) {
  const url = '/settings/poll-settings';
  const options = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function getAdvertisementLineData() {
  const url = '/settings/advertisement-settings';
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function updateAdvertisementLineData(data, picture) {
  const formData = new FormData();
  formData.append('picture', picture);
  formData.append('data', JSON.stringify(data));
  const url = '/settings/advertisement-settings';
  for (var [key, value] of formData.entries()) { 
    console.log(key, value);
  }
  const options = {
    method: 'POST',
    body: formData,
  };
  // console.log(picture);
  return fetchRequest(url, options);
}

//temp
// export async function createPoll() {
//   const url = '/settings/temp-create-poll';
//   const options = {
//     method: 'GET',
//     headers: { 'Content-Type': 'application/json' },
//   };
//   return fetchRequest(url, options);
// }
