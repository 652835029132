import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import SvgAudio from '../Svg/SvgAudio';
import SvgPlayerPause from '../Svg/SvgPlayerPause';
import Animation from '../../helpers/animation';
import './index.css';
import { getAudioStream } from './requests';
import {
  changeAudioFlowAction,
  changeFlowAction,
  pauseAudioPlaybackAction,
  playAudioPlaybackAction,
  setStreamName,
  setLastListenedStream,
  setCurrentArtist,
  setCurrentSong,
  setCurrentCover,
} from '../../redux/actions';

export default function PlayerAudio() { {/* { title, author, src } */}

  const dispatch = useDispatch();
  const { audioPlayback, flow, flowsInfo, currentArtist, currentSong, currentCover} = useSelector((state) => state);
  const [activeFlow, setActiveFlow] = useState({});
  const [live, setLive] = useState([]);
  const [playerBurger, setPlayerBurger] = useState('');
  const [playingStation, setplayingStation] = useState('sa_1');

  // console.log(flow);

  const processingRequest = async () => {
    setLive((await getAudioStream(1)).data);
  };

  useEffect(() => {
    processingRequest();
  }, []);

  const changeFlowToAir = () => {
    const currentStream = "В Эфире";
    // console.log(flowsInfo[currentStream].artist);
    if (Object.keys(flowsInfo).length > 0) {
      if (flowsInfo[currentStream]) {
        if (flowsInfo[currentStream].hasOwnProperty("artist")) {
          dispatch(setCurrentArtist(flowsInfo[currentStream].artist));
        } else {
          dispatch(setCurrentArtist(""));
        }
        
        if (flowsInfo[currentStream].hasOwnProperty("title")) {
          dispatch(setCurrentSong(flowsInfo[currentStream].title));
        } else {
          dispatch(setCurrentSong(currentStream));
        }

        if (flowsInfo[currentStream].hasOwnProperty("cover")) {
          dispatch(setCurrentCover(flowsInfo[currentStream].cover));
        } else {
          dispatch(setCurrentCover("/uploads/images/tracks/novoe_radio_placeholder.jpeg"));
        }
      } else {
        dispatch(setCurrentArtist(""));
        dispatch(setCurrentSong(currentStream));
        dispatch(setCurrentCover("/uploads/images/tracks/novoe_radio_placeholder.jpeg"));
      }
    } else {
      dispatch(setCurrentArtist(""));
      dispatch(setCurrentSong(currentStream));
      dispatch(setCurrentCover("/uploads/images/tracks/novoe_radio_placeholder.jpeg"));
    }
  }

  return (
    <div className="ether-player">
      <div className="ether-player__logo-container">
        <div className="ether-player__alert">
          Слушай эфир
          <div />
        </div>
        <div
          aria-hidden
          className="ether-player__logo"
          onClick={(event) => {
            new Animation(event).button();

            dispatch(changeAudioFlowAction(live.link));
            dispatch(changeFlowAction(live));
            dispatch(setStreamName(live.name));
            dispatch(setLastListenedStream(live.title.trim()))
            setActiveFlow(live);

            // if (audioPlayback && live.title === flow.title) {
            //   dispatch(pauseAudioPlaybackAction());
            // }
            // if (!audioPlayback) {
            //   dispatch(playAudioPlaybackAction());
            // }
            // changeFlowToAir();

            if (audioPlayback) {
              dispatch(pauseAudioPlaybackAction());
            } else {
              dispatch(playAudioPlaybackAction());
            }
          }}
        >
          {/* { audioPlayback && live.title === flow.title ? (
            <SvgPlayerPause />
          ) : (
            <SvgAudio />
          )} */}
          { audioPlayback ? (
            <SvgPlayerPause />
          ) : (
            <SvgAudio />
          )}
        </div>
      </div>
      <img src={currentCover} className="ether-player__preview-img" alt="" />
      <div className="ether-player__info">
        <span className="ether-player__info_track">{currentSong}</span>
        <span className="ether-player__info_author">{currentArtist}</span>
      </div>
    </div>
  );
}

PlayerAudio.propTypes = {
  title: PropTypes.string,
  author: PropTypes.string,
  src: PropTypes.string,
};

PlayerAudio.defaultProps = {
  title: '',
  author: '',
  src: '',
};
