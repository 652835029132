import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, TextField } from '@material-ui/core';
import styles from '../../styles';

function InputFiled({
  classes, title, type, value, setValue,
}) {
  return (
    <div className={classes.field}>
      <div className={classes.title}>{title}</div>
      <TextField
        type={type}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        className={classes.field}
      />
    </div>
  );
}

export default withStyles(styles)(InputFiled);

InputFiled.propTypes = {
  classes: PropTypes.object,
  type: PropTypes.oneOf(['text']),
  title: PropTypes.string,
  value: PropTypes.string,
  setValue: PropTypes.func,
};

InputFiled.defaultProps = {
  classes: {},
  type: 'text',
  title: '',
  value: '',
  setValue: () => {},
};
