import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import './index.css';

export default function BlockEmceesItem({
  name, img, back_img, description, active, programs,
}) {
  const [oneEmceeHeight, setoneEmceeHeight] = useState(0);
  const [oneEmceeTransformOrigin, setoneEmceeTransformOrigin] = useState(0);
  const oneEmceeElementRef = useRef(null);

  async function processingRequest() {
    const itemHeight = window.innerWidth <= 1200 ? Math.ceil(oneEmceeElementRef.current.clientWidth / 2.2) : 318
    setoneEmceeHeight(itemHeight);
    setoneEmceeTransformOrigin(itemHeight / 2);
  }

  useEffect(() => {
    processingRequest();
  }, []);
  return (
    <div className={`block-emcees ${active ? 'active' : ''}`} ref={oneEmceeElementRef} style={{height: `${oneEmceeHeight}px`}}>
      <div className="block-emcees__flipper" style={{transformOrigin: `100% ${oneEmceeTransformOrigin}px`}}>
        <div className="block-emcees__front">
          <div className="block-emcees__info-container">
            <div className="block-emcees__name">{name}</div>
          </div>
          <img src={img} alt={name} className="block-emcees__img" />
        </div>
        <div className="block-emcees__back">
          <div className="block-emcees__info-container">
            <div className="block-emcees__description">{description}</div>
            <div className="block-emcees__programs">
              <h3 className="block-emcees__programs-title">Программы:</h3>
              {programs.map((program) => (
                <p key={program} className="block-emcees__programs-item">{program}</p>
              ))}
            </div>
          </div>
          <img src={back_img} alt={name} className="block-emcees__img" />
        </div>
      </div>
    </div>
  );
}

BlockEmceesItem.propTypes = {
  name: PropTypes.string,
  img: PropTypes.string,
  back_img: PropTypes.string,
  description: PropTypes.string,
  active: PropTypes.bool,
  programs: PropTypes.array,
};

BlockEmceesItem.defaultProps = {
  name: '',
  img: '',
  back_img: '',
  description: '',
  active: false,
  programs: [],
};
