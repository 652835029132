import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTytes from 'prop-types';
import { withStyles, Button } from '@material-ui/core';
import PapperBlock from '../../components/PapperBlock';
import DataTable from '../../components/DataTable';
import { getBlocksForTable } from './requests';
import { NewsIcon } from '../../constants/icons';
import { ADMIN_ABOUT_CREATE_ROUTE } from '../../constants/routes';
import Columns from './Columns';
import styles from '../../styles';

function NewsPage({ classes }) {
  const [data, setData] = useState([]);
  const history = useHistory();

  const processingRequest = async () => {
    setData((await getBlocksForTable()).data);
  };
  useEffect(() => {
    processingRequest();
  }, []);

  const createBtnHandler = () => history.push(ADMIN_ABOUT_CREATE_ROUTE);

  return (
    <PapperBlock title="О радио" icon={<NewsIcon />}>
      <Button onClick={createBtnHandler} variant="contained" color="primary" className={classes.field}>
        Добавить Блок
      </Button>
      <DataTable columns={Columns} data={data} />
    </PapperBlock>
  );
}

export default withStyles(styles)(NewsPage);

NewsPage.propTypes = {
  classes: PropTytes.object,
};

NewsPage.defaultProps = {
  classes: {},
};
