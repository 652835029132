import React, { useState } from 'react';
import {DropzoneArea} from 'material-ui-dropzone'
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  TextField, Button, Switch, withStyles,
} from '@material-ui/core';
import ImageDropzone from '../../components/Dropzone/ImageDropzone';
import AudioDropzone from '../../components/Dropzone/AudioDropzone';
import styles from '../../styles';
import {maxImageFileSize} from "../../constants/additional";

function TracksForm({ classes, data, submitHandler }) {
  const { id, likes } = data;
  const [title, setTitle] = useState(data.title);
  const [author, setAuthor] = useState(data.author);
  const [audioFile, setAudioFile] = useState([]);
  const [audioUrl, setAudioUrl] = useState(data.audio);
  const [audioToDelete, setAudioToDelete] = useState([]);
  const [pictureFile, setPictureFile] = useState([]);
  const [pictureUrl, setPictureUrl] = useState(data.picture);
  const [pictureToDelete, setPictureToDelete] = useState([]);
  const [isNoveltyList, setIsNoveltyList] = useState(data.newTrack);
  const [isChallengerList, setIsChallengerList] = useState(data.applicant);

  async function dispatchHandler(event) {
    event.preventDefault();

    const ifOk = confirm('Вы уверены что хотите сохранить изменения?');
    if (ifOk) {
      await submitHandler(
        {
          id,
          author,
          title,
          audioUrl,
          audioToDelete,
          pictureUrl,
          pictureToDelete,
          likes,
          newTrack: isNoveltyList,
          applicant: isChallengerList,
        },
        audioFile[0],
        pictureFile[0],
      );
    } else {
      history.back();
    }
  }

  return (
    <div>
      <form onSubmit={dispatchHandler}>
        <div className={classes.inlineWrap}>
          <div className={classNames(classes.field, classes.rightPadding)}>
            <div className={classes.title}>Исполнитель:</div>
            <TextField
              required
              value={author}
              onChange={(e) => {
                setAuthor(e.target.value);
              }}
              fullWidth
            />
          </div>
          <div className={classNames(classes.field, classes.leftPadding)}>
            <div className={classes.title}>Название:</div>
            <TextField
              required
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              fullWidth
            />
          </div>
        </div>
        <div className={classes.inlineWrap}>
          <div className={classNames(classes.field, classes.rightPadding)}>
            <AudioDropzone
              files={audioFile}
              setFiles={setAudioFile}
              urls={audioUrl}
              setUrls={setAudioUrl}
              toDelete={audioToDelete}
              setToDelete={setAudioToDelete}
              text="Загрузить трек"
              filesLimit={1}
              acceptedFiles={['audio/*']}
              maxSize={30000000}
            />
          </div>
          <div className={classNames(classes.field, classes.leftPadding)}>
            <ImageDropzone
              files={pictureFile}
              setFiles={setPictureFile}
              urls={pictureUrl}
              setUrls={setPictureUrl}
              toDelete={pictureToDelete}
              setToDelete={setPictureToDelete}
              text="Загрузить картинку"
              filesLimit={1}
              acceptedFiles={['image/*']}
              maxSize={maxImageFileSize}
            />
          </div>

        </div>
        <div className={classes.field}>
          <div className={classes.title}>
            В список новинок:
            <Switch
              color="primary"
              checked={isNoveltyList}
              onChange={() => setIsNoveltyList(!isNoveltyList)}
            />
          </div>
          <div className={classes.title}>
            В список претендентов:
            <Switch
              color="primary"
              checked={isChallengerList}
              onChange={() => setIsChallengerList(!isChallengerList)}
            />
          </div>
        </div>
        <Button variant="contained" color="primary" type="submit" fullWidth>
          {id ? 'Обновить' : 'Создать'}
        </Button>
      </form>
    </div>
  );
}

export default withStyles(styles)(TracksForm);

TracksForm.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  submitHandler: PropTypes.func,
};

TracksForm.defaultProps = {
  classes: {},
  data: {
    id: undefined,
    title: '',
    author: '',
    audio: [],
    picture: [],
    likes: 0,
    newTrack: false,
    applicant: false,
  },
  submitHandler: () => {},
};
