import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ChartPosition from '../../../client/components/ChartPosition';

export default function columns(
  weeksInputHandler, weeksValue, moveTrack, tracks, deleteHandler, placeValue, placeInputHandler, changeChartDifference
) {
  return [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: 'currentPosition',
      label: 'Место',
      options: {
        filter: false,
        sort: false,
        display: true,
        customBodyRender: (value, tableMeta) => (
          <div className="admin-chart" style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ fontWeight: 'bold', fontSize: '18px' }}>{tableMeta.rowIndex + 1}</span>
            <ChartPosition
              currentPosition={tracks[tableMeta.rowIndex].currentPosition}
              lastPosition={tracks[tableMeta.rowIndex].currentPosition}
            />
          </div>
          // <div className="admin-chart" style={{ display: 'flex', alignItems: 'center', maxWidth: "60px" }}>
          //   <span style={{ fontWeight: 'bold', fontSize: '18px' }}>{tableMeta.rowIndex + 1}</span>
          //   <ChartPosition
          //     currentPosition={tracks[tableMeta.rowIndex].currentPosition}
          //     lastPosition={tracks[tableMeta.rowIndex].lastPosition}
          //     chartDifference={tracks[tableMeta.rowIndex].chartDifference}
          //   />
          //   <input
          //     style={{ width: '100%' }}
          //     value={placeValue[tableMeta.rowIndex]}
          //     type="number"
          //     onChange={(e) => {
          //       // if (Number.parseInt(e.target.value) != NaN) {
          //         console.log(e.target.value, tableMeta);
          //         placeInputHandler(tableMeta.rowIndex, e.target.value);
          //       // }
          //     }}
          //     onBlur={(e) => {
          //       changeChartDifference(tableMeta.rowIndex, e.target.value);
          //     }}
          //   />
          // </div>
        ),
      },
    },
    {
      name: 'picture',
      label: 'Картинка',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => <img src={value ? value : '/empty.jpg' } alt="img" style={{ maxWidth: '50px' }} />,
      },
    },
    {
      name: 'audio',
      label: 'Трек',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => <audio controls src={value} className="audio" />,
      },
    },
    {
      name: 'author',
      label: 'Исполнитель',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'title',
      label: 'Название',
      options: {
        filter: true,
        sort: false,
      },
    },
    // {
    //   name: 'rate',
    //   label: 'Рейтинг',
    //   options: {
    //     filter: false,
    //     sort: false,
    //   },
    // },
    // {
    //   name: 'weeks',
    //   label: 'Недели',
    //   options: {
    //     filter: false,
    //     sort: false,
    //     customBodyRender: (value, tableMeta) => (
    //       <div style={{
    //         maxWidth: '60px', display: 'flex', alignItems: 'center',
    //       }}
    //       >
    //         <input
    //           style={{ width: '100%' }}
    //           value={weeksValue[tableMeta.rowIndex]}
    //           type="number"
    //           onChange={(e) => {
    //             if (e.target.value >= 0) {
    //               weeksInputHandler(tableMeta.rowIndex, e.target.value);
    //             }
    //           }}
    //         />
    //       </div>
    //     ),
    //   },
    // },
    {
      name: '',
      label: 'Управление',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => (
          <div style={{ display: 'flex', alignItems: 'center', transition: 'all 0.3s' }}>
            <IconButton
              onClick={() => {
                // const id = tableMeta.rowData[0];
                if(window.confirm('Вы уверены, что хотите удалить?')){
                  const { rowIndex } = tableMeta;
                  deleteHandler(rowIndex);
                  window.location.reload();
                }
              }}
            >
              <DeleteIcon />
            </IconButton>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              { tableMeta.rowIndex !== 0 && (
                <IconButton
                  onClick={() => moveTrack(tableMeta.rowIndex, 'up')}
                >
                  <ArrowUpward />
                </IconButton>
              )}
              {(tableMeta.tableData.length !== (tableMeta.rowIndex + 1)) && (
                <IconButton
                  onClick={() => moveTrack(tableMeta.rowIndex, false)}
                >
                  <ArrowDownward />
                </IconButton>
              )}
            </div>
          </div>
        ),
      },
    },
  ];
}
