import React, { useState, useEffect } from 'react';
import PropTytes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles, Button } from '@material-ui/core';
import DataTable from '../../components/DataTable';
import PapperBlock from '../../components/PapperBlock';
import { getTracksForTable } from './requests';
import { ADMIN_TRACKS_CREATE_ROUTE } from '../../constants/routes';
import Columns from './Columns';
import styles from '../../styles';

function TracksPage({ classes }) {
  const [data, setData] = useState([]);
  const processingRequest = async () => {
    setData((await getTracksForTable()).data);
  };

  const options = {
    rowsPerPageOptions: [20,40,80],
  };

  useEffect(() => {
    processingRequest();
  }, []);

  return (
    <PapperBlock title="Треки">
      <Link to={ADMIN_TRACKS_CREATE_ROUTE} className={classes.link}>
        <Button variant="contained" color="primary" className={classes.field}>
          Добавить трек
        </Button>
      </Link>
      <DataTable columns={Columns} data={data} title="Список треков" options={options}/>
    </PapperBlock>
  );
}

export default withStyles(styles)(TracksPage);

TracksPage.propTypes = {
  classes: PropTytes.object,
};

TracksPage.defaultProps = {
  classes: {},
};
