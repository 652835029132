import fetchRequest from '../../helpers/fetchRequest';

export async function getCurrentOffer() {
  const url = '/current-offer';
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function getCurrentBanner() {
  const url = '/current-banner';
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function getLatestOffersArchive(year, month, limit, offset) {
  const url = '/offers/archive-latest';
  const options = {
    method: 'POST',
    body: JSON.stringify({
      year: year,
      month: month,
      limit: limit,
      offset: offset,
    }),
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function getOffersAmount(year, month) {
  const url = `/get-offers-amount`;
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      year: year,
      month: month,
    }),
  };
  return fetchRequest(url, options);
}
