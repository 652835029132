/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import MetaTags from 'react-meta-tags';
import { format } from 'date-fns';
import BlockContent from '../../components/BlockContent';
import BlockNews from '../../components/BlockNews';
import BlockNewsItem from '../../components/BlockNewsItem';
import BlockChart from '../../components/BlockChart';
import ItemChart from '../../containers/ItemChart';
import PlaylistContainer from '../../components/PlaylistContainer';
import BannerWidget from '../../components/BannerWidget';
import ShowMoreBtn from '../../components/ShowMoreBtn';
import {
  getPlaylistChart,
  getNoveltyChart,
  getCurrentBanner,
  getNewsByPagination,
  getChartTimestamp,
  getNewsAmount,
  getLatestNews,
} from './requests';
import { CLIENT_DATE_FORMAT } from '../../constants/dateTimeFormat';
import './index.css';
import setLocalStorageForLikes from '../../helpers/setLocalStorageForLikes';
import {
  PLAYLIST, 
  NOVELTY, 
  NOVELTY_ICON, 
  PLAYLIST_ICON, 
} from '../../constants/routes';
import {useSelector} from 'react-redux';

export default function News() {

  const months = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
  ];

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  const defaultYear = params.year ? params.year : null;
  const defaultMonth = params.month ? params.month : null;

  const [newsList, setNewsList] = useState([]);
  const [paginationNews, setPaginationNews] = useState(10);
  const [playlistChartData, setPlaylistChartData] = useState({});
  const [noveltyChartData, setNoveltyChartData] = useState({});
  const [ratedTracks, setRatedTracks] = useState([]);
  const [likedTracks, setLikedTracks] = useState([]);
  const [bannerData, setBannerData] = useState(null);

  // NEW
  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [newsAmount, setNewsAmount] = useState(0);
  const [chosenYear, setChosenYear] = useState(null);
  const [chosenMonth, setChosenMonth] = useState(null);
  const {playlistLastSong} = useSelector((state) => state);
  // const endOfList = useRef();

  const setLocalStorage = async () => {
    const timestamp = ((await getChartTimestamp()).data);
    const [actualRatedTracks, actualLikedTracks] = setLocalStorageForLikes(timestamp.timestamp);
    setRatedTracks([...actualRatedTracks]);
    setLikedTracks([...actualLikedTracks]);
  }

  const loadNews = async (firstLoad = true, year, month, limit, offset) => {
    const downloaded = await getLatestNews(year, month, limit, offset);
    if (firstLoad) {
      setNewsList(downloaded.data);
    } else {
      setNewsList([...newsList, ...downloaded.data]);
    }
  }

  const processingRequest = async () => {

    setPlaylistChartData((await getPlaylistChart()).data);
    setNoveltyChartData((await getNoveltyChart()).data);
    await setLocalStorage();
    setBannerData((await getCurrentBanner()).data);

    setNewsAmount((await getNewsAmount(chosenYear, chosenMonth)).data);
    await loadNews(true, chosenYear, chosenMonth, pageSize, pageIndex * 10);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    processingRequest();
  }, []);

  const loadNewsWrapper = async (bool, ind) => {
    await loadNews(bool, chosenYear, chosenMonth, pageSize, ind);
  }

  const setNewsAmountWrapper = async () => {
    setNewsAmount((await getNewsAmount(chosenYear, chosenMonth)).data);
  }

  useEffect(() => {
    loadNewsWrapper(false, pageIndex * 10);
    // setPageIndex(1);
  }, [pageIndex])

  useEffect(() => {
    loadNewsWrapper(true, 0);
    setNewsAmountWrapper();
  }, [chosenYear, chosenMonth]);


  // const rerenderPlaylist = async () => {
  //   setPlaylistChartData((await getPlaylistChart()).data);
  // }

  useEffect(() => {
      if (playlistLastSong.id && playlistChartData.id) {
        setPlaylistChartData(
          {
            ...playlistChartData, 
            track_list: [
              {
                ...playlistLastSong, 
                play_date: new Date().toISOString(),
                play_time: new Date().toISOString(),
              }, 
              ...playlistChartData.track_list
            ]
          }
        );
      }
  }, [playlistLastSong]);

  // useEffect(() => {

  //   const handleObserver = async (entities, observer) => {
  //     if(entities[0].isIntersecting && newsList.length < newsAmount){
  //       await loadNews(pageSize, pageIndex * 10);
  //     }
  //   };

  //   const observer = new IntersectionObserver(handleObserver);
  //   observer.observe(endOfList.current);
  // }, [pageIndex]);

  return (
    <div className="page-news">
      <BlockContent>
        <div className="big-column">
          <BlockNews changeHandler ={ async (year, month) => {
            setChosenYear(year);
            setChosenMonth(month);
            setPageIndex(0);
          }}>
            {newsList.length > 0 && newsList.map((el, index) => {
              return <BlockNewsItem
                key={index.toString()}
                title={el.title}
                img={el.picture}
                date={format(new Date(el.publish_date), CLIENT_DATE_FORMAT)}
                alias={el.alias}
              />
            })}
            {newsList.length < newsAmount && (
              <ShowMoreBtn text="Больше" clickHandler={() => {
                setPageIndex(pageIndex + 1);
                }} />
            )}
          </BlockNews>
          <MetaTags>
                <title>Что нового? - Новое Радио</title>
                <meta name="description" content="Что нового? - Новое Радио" />
          </MetaTags>
        </div>
        <div className="small-column__chart ">
          {playlistChartData && (
            <PlaylistContainer
                chartData={playlistChartData.track_list}
                title={playlistChartData.title}
                link={PLAYLIST}
                icon={PLAYLIST_ICON}
                countViewTracks={4}
                additionalClass = {"playlist"}
                children={playlistChartData.track_list}
                type={"playlistChart"}
                color={null}
              />
          )}
          {noveltyChartData.length > 0 && (
            <PlaylistContainer
                chartData={noveltyChartData}
                title={"Новинки"}
                link={NOVELTY}
                icon={NOVELTY_ICON}
                countViewTracks={4}
                additionalClass = {"new_songs"}
                children={noveltyChartData}
                type={"noveltyChart"}
                color="yellow"
              />
          )}
          {bannerData && bannerData.map((elem) => (
            <BannerWidget img={elem.picture} redirect={elem.link}/>
          ))}
        </div>
      </BlockContent>
    </div>
  );
}
