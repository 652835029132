import fetchRequest from '../../helpers/fetchRequest';

const BANNERS = '/banners';

export async function createBanner(data, picture) {
  const formData = new FormData();
  formData.append('picture', picture);
  formData.append('data', JSON.stringify(data));
  const url = BANNERS;
  const options = {
    method: 'POST',
    body: formData,
  };
  return fetchRequest(url, options);
}

export async function getBannersForTable() {
  const url = '/banners';
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function getBannerById(id) {
  const url = `/banners/${id}`;
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function updateBannerById(data, picture) {
  const formData = new FormData();
  formData.append('picture', picture);
  formData.append('data', JSON.stringify(data));
  const url = `${BANNERS}/${data.id}`;
  const options = {
    method: 'PUT',
    body: formData,
  };
  return fetchRequest(url, options);
}

export async function deleteBannerById(id) {
  const url = `/banners/${id}`;
  const options = {
    method: 'DELETE',
  };
  return fetchRequest(url, options);
}
