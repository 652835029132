const styles = (theme) => ({
  title: {
    marginBottom: '10px',
  },
  videoField: { padding: '6px 0' },
  root: {
    flexGrow: 1,
    padding: 30,
  },
  field: {
    width: '100%',
    marginBottom: 20,
  },
  fieldBasic: {
    width: '100%',
    marginBottom: 20,
    marginTop: 10,
  },
  inlineWrap: {
    display: 'flex',
    flexDirection: 'row',
  },
  buttonInit: {
    margin: theme.spacing(4),
    textAlign: 'center',
  },
  dateRow: {
    display: 'flex',
    justifyContent: 'center',
  },
  dateColumn: {
    marginRight: '1rem',
  },
  link: {
    textDecoration: 'none',
  },
  columnImg: {
    maxWidth: '50px',
  },
  trackListItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    borderRadius: '8px 8px 0 0',
    margin: '10px 0 0 0',
    backgroundColor: 'rgb(241, 242, 249)',
    padding: '0 24px ',
    // '&:hover': {
    //   borderBottom: '2px solid rgba(0, 0, 0, 0.87)',
    // },
  },
  scheduleContainer: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "flex-end",
    borderBottom: "1px solid #000",
    paddingBottom: "30px",
    marginBottom: "30px",
  },
  scheduleElement: {
    width: "calc(33% - 20px)",
    padding: "0 10px",
  },
  rightPadding: { paddingRight: '10px' },
  leftPadding: { paddingLeft: '10px' },
  flexOne: { display: 'flex', flex: 1 },
  fullWidth: { width: '100%' },
  halfWidth: { width: '50%' },
  addButton: {
    margin: "0 16px",
    color: "#fff",
    backgroundColor: "#3f51b5",
    boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    padding: "6px 16px",
    fontSize: "0.875rem",
    minWidth: "64px",
    boxSizing: "border-box",
    transition: "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    fontFamily: "Roboto, sans-serif",
    fontWeight: 500,
    lineHeight: 1.75,
    borderRadius: "4px",
    letterSpacing: "0.02857em",
    textTransform: "uppercase",
    width: "max-content",
    marginBottom: "40px",
    cursor: "pointer",
  },
  deleteButton: {
    margin: "0 16px",
    color: "#fff",
    backgroundColor: "#d21f37",
    boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    padding: "6px 16px",
    fontSize: "0.875rem",
    minWidth: "64px",
    boxSizing: "border-box",
    transition: "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    fontFamily: "Roboto, sans-serif",
    fontWeight: 500,
    lineHeight: 1.75,
    borderRadius: "4px",
    letterSpacing: "0.02857em",
    textTransform: "uppercase",
    width: "max-content",
    marginBottom: "40px",
    cursor: "pointer",
  },
  loginFormContainer: {
    position: "relative",
    width: "100vw",
    height: "100vh",
    background: "linear-gradient(135deg, rgba(117,0,170,1) 0%, rgba(70,195,31,1) 100%)",
    borderRadius: "unset",
  },
  formAndLogo: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    padding: "20px",
    background: "#fff",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  loginFormLogo: {
    marginBottom: "30px",
  },
  loginForm: {
    
  },
  loginField: {
    marginBottom: "5px",
  },
  errorResponseText: {
    margin: "unset",
  },
  quizRow: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  quizInactiveLine: {
    fontSize: "1rem",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    lineHeight: "1.1875em",
  },
  quizResultsRow: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  votesSumm: {
    width: "200px",
    marginTop: "unset",
    marginBottom: "20px",
    height: "32px",
    display: "flex",
    alignItems: "flex-end",
    borderBottom: "1px solid rgba(0, 0, 0, 0.87)",
    justifyContent: "flex-end",
    paddingRight: "15px",
  },
  votesPercent: {
    marginTop: "unset",
    marginBottom: "20px",
    height: "32px",
    display: "flex",
    alignItems: "flex-end",
    borderBottom: "1px solid rgba(0, 0, 0, 0.87)",
    justifyContent: "flex-end",
  },
  buttonWidthOneHundreed: {
    width: "100%",
    textAlign: "center",
  }

});

export default styles;
