import React from 'react';
import { useHistory } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import { ADMIN_SETTING_ROUTE } from '../../constants/routes';
import { format } from 'date-fns';
import { DATE_TIME_FORMAT_FOR_COLUMNS} from '../../constants/dateTimeFormat';

export default function Columns() {
  const history = useHistory();
  return [
    {
      name: 'title',
      label: 'Настройка',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'publish_date',
      label: 'Дата/Время публикации',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          if (value) return format(new Date(value), DATE_TIME_FORMAT_FOR_COLUMNS)
          return "Не установлено"
        }
      },
    },
    {
      name: 'unpublish_date',
      label: 'Дата/Время окончания публикации',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          if (value) return format(new Date(value), DATE_TIME_FORMAT_FOR_COLUMNS)
          return "Не установлено"
        }
      },
    },
    {
      name: 'redirect',
      label: 'Управление',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => (
          <>
            <IconButton
              onClick={() => {
                const url = ADMIN_SETTING_ROUTE + value;
                history.replace(url);
              }}
            >
              <EditIcon />
            </IconButton>
          </>
        ),
      },
    },
  ];
}
