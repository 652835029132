import fetchRequest from '../../helpers/fetchRequest';

export async function getLogo() {
    const url = '/site-logo';
    const options = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    };
    return fetchRequest(url, options);
}

export async function getSocialLinks() {
  const url = '/active-slides/socials';
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}