export const ADMIN_MAIN_ROUTE = '/admin';
export const ADMIN_NEWS_ROUTE = '/admin/news';
export const ADMIN_NEWS_CREATE_ROUTE = '/admin/news/create';
export const ADMIN_NEWS_UPDATE_ROUTE = '/admin/news/update/:id';
export const ADMIN_OFFERS_ROUTE = '/admin/offers';
export const ADMIN_OFFERS_CREATE_ROUTE = '/admin/offers/create';
export const ADMIN_OFFERS_UPDATE_ROUTE = '/admin/offers/update/:id';
export const ADMIN_TRACKS_ROUTE = '/admin/tracks';
export const ADMIN_TRACKS_CREATE_ROUTE = '/admin/tracks/create';
export const ADMIN_TRACKS_UPDATE_ROUTE = '/admin/tracks/update/:id';
export const ADMIN_PLAYLISTS_ROUTE = '/admin/playlists';
export const ADMIN_PLAYLISTS_CREATE_ROUTE = '/admin/playlists/create';
export const ADMIN_PLAYLISTS_UPDATE_ROUTE = '/admin/playlists/update/:id';
export const ADMIN_PODCASTS_ROUTE = '/admin/podcasts';
export const ADMIN_PODCASTS_CREATE_ROUTE = '/admin/podcasts/create';
export const ADMIN_PODCASTS_UPDATE_ROUTE = '/admin/podcasts/update/:id';
export const ADMIN_PROGRAMS_ROUTE = '/admin/programs';
export const ADMIN_PROGRAMS_CREATE_ROUTE = '/admin/programs/create';
export const ADMIN_PROGRAMS_UPDATE_ROUTE = '/admin/programs/update/:id';
export const ADMIN_EMCEES_ROUTE = '/admin/emcees';
export const ADMIN_EMCEES_CREATE_ROUTE = '/admin/emcees/create';
export const ADMIN_EMCEES_UPDATE_ROUTE = '/admin/emcees/update/:id';
export const ADMIN_QUIZ_ROUTE = '/admin/quiz';
export const ADMIN_QUIZ_CREATE_ROUTE = '/admin/quiz/create';
export const ADMIN_QUIZ_UPDATE_ROUTE = '/admin/quiz/update/:id';
export const ADMIN_GUESTS_ROUTE = '/admin/guests';
export const ADMIN_GUESTS_CREATE_ROUTE = '/admin/guests/create';
export const ADMIN_GUESTS_UPDATE_ROUTE = '/admin/guests/update/:id';

export const ADMIN_STREAMS_A_ROUTE = '/admin/streams/audio';
export const ADMIN_STREAMS_A_CREATE_ROUTE = '/admin/streams/audio/create';
export const ADMIN_STREAMS_A_UPDATE_ROUTE = '/admin/streams/audio/update/:id';

export const ADMIN_STREAMS_V_ROUTE = '/admin/streams/video';
export const ADMIN_STREAMS_V_CREATE_ROUTE = '/admin/streams/video/create';
export const ADMIN_STREAMS_V_UPDATE_ROUTE = '/admin/streams/video/update/:id';

export const ADMIN_BANNERS_ROUTE = '/admin/banners';
export const ADMIN_BANNERS_CREATE_ROUTE = '/admin/banners/create';
export const ADMIN_BANNERS_UPDATE_ROUTE = '/admin/banners/update/:id';
export const ADMIN_SEO_ROUTE = '/admin/seo';
export const ADMIN_SEO_NEWS_UPDATE_ROUTE = '/admin/seo/news/update/:id';
export const ADMIN_SEO_OFFERS_UPDATE_ROUTE = '/admin/seo/offers/update/:id';
export const ADMIN_SEO_PROGRAMS_UPDATE_ROUTE = '/admin/seo/programs/update/:id';
export const ADMIN_SEO_EMCEES_UPDATE_ROUTE = '/admin/seo/emcees/update/:id';
export const ADMIN_SETTING_ROUTE = '/admin/settings';
export const ADMIN_SETTING_SITE_BACKGROUND_ROUTE = '/admin/settings/site-background';
export const ADMIN_SETTING_LOGO_ROUTE = '/admin/settings/logo';
export const ADMIN_SETTING_SOCIALS_ROUTE = '/admin/settings/socials';
export const ADMIN_SETTING_SOCIALS_CREATE_ROUTE = '/admin/settings/socials-create';
export const ADMIN_SETTING_SOCIALS_UPDATE_ROUTE = '/admin/settings/socials-update-:id';
export const ADMIN_SETTING_LEFT_BANNER_ROUTE = '/admin/settings/left-banner';
export const ADMIN_SETTING_POOL_SETTINGS_ROUTE = '/admin/settings/poll'
export const ADMIN_ABOUT_ROUTE = '/admin/about';
export const ADMIN_ABOUT_CREATE_ROUTE = '/admin/about/create';
export const ADMIN_ABOUT_UPDATE_ROUTE = '/admin/about/update/:id';
export const ADMIN_SLIDES_ROUTE = '/admin/slides';
export const ADMIN_SLIDES_CREATE_ROUTE = '/admin/slides/create';
export const ADMIN_SLIDES_UPDATE_ROUTE = '/admin/slides/update/:id';
export const ADMIN_CHART_ROUTE = '/admin/chart';
export const ADMIN_GO_TO_WEBSITE = "/";
export const ADMIN_USERS_ROUTE = '/admin/users';
export const ADMIN_USERS_CREATE_ROUTE = '/admin/users/create';
export const ADMIN_USERS_UPDATE_ROUTE = '/admin/users/update/:id';
export const ADMIN_NOVELTY_ROUTE = '/admin/novelty';
export const ADMIN_APPLICANT_ROUTE = '/admin/applicant';
export const ADMIN_SETTING_ADVERTISEMENT_LINE_ROUTE = '/admin/settings/advertisement-line';
