import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PapperBlock from '../../../components/PapperBlock';
import SEOForm from '../SEOForm';
import { getSeoNewsById, updateSeoNewsById } from '../requests';
import { SEOIcon } from '../../../constants/icons';

export default function UpdateSeoNews() {
  const [data, setData] = useState({});
  const [loaded, setLoaded] = useState(false);
  const params = useParams();
  const { id } = params;
  async function processingRequest() {
    setData((await getSeoNewsById(id)).data);
    setLoaded(true);
  }
  useEffect(() => {
    processingRequest();
  }, [loaded]);

  return (
    <PapperBlock title="Обновить SEO новости" icon={<SEOIcon />}>
      {loaded && (
        <SEOForm submitHandler={updateSeoNewsById} data={data} submitButton />
      )}
    </PapperBlock>
  );
}
