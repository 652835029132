import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import SidebarContent from './SidebarContent';
import styles from './sidebar-jss';

function Sidebar(props) {
  const {
    classes,
    open,
    toggleDrawerOpen,
    loadTransition,
    leftSidebar,
    dataMenu,
  } = props;

  return (
    <>
      <Hidden lgUp>
        <SwipeableDrawer
          onClose={toggleDrawerOpen}
          onOpen={toggleDrawerOpen}
          open={!open}
          anchor={leftSidebar ? 'left' : 'right'}
        >
          <div className={classes.swipeDrawerPaper}>
            <SidebarContent
              drawerPaper
              leftSidebar={leftSidebar}
              toggleDrawerOpen={toggleDrawerOpen}
              loadTransition={loadTransition}
              dataMenu={dataMenu}
            />
          </div>
        </SwipeableDrawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          variant="permanent"
          onClose={toggleDrawerOpen}
          className={open ? classes.drawer : ''}
          open={open}
          anchor={leftSidebar ? 'left' : 'right'}
        >
          <SidebarContent
            drawerPaper={open}
            leftSidebar={leftSidebar}
            loadTransition={loadTransition}
            dataMenu={dataMenu}
          />
        </Drawer>
      </Hidden>
    </>
  );
}

Sidebar.propTypes = {
  classes: PropTypes.object,
  toggleDrawerOpen: PropTypes.func,
  loadTransition: PropTypes.func,
  open: PropTypes.bool,
  leftSidebar: PropTypes.bool,
  dataMenu: PropTypes.array,
};

Sidebar.defaultProps = {
  classes: {},
  toggleDrawerOpen: () => {},
  loadTransition: () => {},
  open: true,
  leftSidebar: true,
  dataMenu: [],
};

export default withStyles(styles)(Sidebar);
