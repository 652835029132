import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import PapperBlock from '../../../components/PapperBlock';
import PodcastsForm from '../PodcastsForm';
import {
  getPodcastById,
  getProgramsForForm,
  updatePodcastById,
} from '../requests';
import { PodcastIcon } from '../../../constants/icons';
import { ADMIN_PODCASTS_ROUTE } from '../../../constants/routes';

export default function UpdatePodcasts() {
  const [dataPodcast, setDataPodcast] = useState({});
  const [programsList, setprogramsList] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const params = useParams();
  const { id } = params;
  async function processingRequest() {
    setDataPodcast((await getPodcastById(id)).data);
    setprogramsList((await getProgramsForForm()).data);
    setLoaded(true);
  }
  const history = useHistory();
  const submitHandler = async (data, audio, picture) => {
    const response = await updatePodcastById(data, audio, picture);
    if (response.ok) {
      history.push(ADMIN_PODCASTS_ROUTE);
    }
  };
  useEffect(() => {
    processingRequest();
  }, []);

  return (
    <PapperBlock title="Обновить подкаст" icon={<PodcastIcon />}>
      {loaded && (
        <PodcastsForm
          data={dataPodcast}
          programs={programsList}
          submitHandler={submitHandler}
        />
      )}
    </PapperBlock>
  );
}
