import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTytes from 'prop-types';
import { withStyles, Button } from '@material-ui/core';
import PapperBlock from '../../components/PapperBlock';
import { OfferIcon } from '../../constants/icons';
import { ADMIN_OFFERS_CREATE_ROUTE } from '../../constants/routes';
import styles from '../../styles';
import { getOffersForTable } from './requests';
import DataTable from '../../components/DataTable';
import columns from './columns';

function OffersPage({ classes }) {
  const [data, setData] = useState([]);
  const processingRequest = async () => {
    setData((await getOffersForTable()).data);
  };
  useEffect(() => {
    processingRequest();
  }, []);

  return (
    <PapperBlock title="Акции" icon={<OfferIcon />}>
      <Link to={ADMIN_OFFERS_CREATE_ROUTE} className={classes.link}>
        <Button variant="contained" color="primary" className={classes.field}>
          Добавить акцию
        </Button>
      </Link>
      <DataTable columns={columns} data={data} />
    </PapperBlock>
  );
}

export default withStyles(styles)(OffersPage);

OffersPage.propTypes = {
  classes: PropTytes.object,
};

OffersPage.defaultProps = {
  classes: {},
};
