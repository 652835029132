import React from 'react';

export default function SvgPlayerPause() {
  return (
    <svg width="68" height="68" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="24.5" cy="24.5" r="24.5" fill="#E40A3B" fill-opacity="0.1"/>
      <ellipse cx="24.4987" cy="24.4997" rx="20.527" ry="20.527" fill="#E40A3B"/>
      <path d="M24.4988 43.2607C14.1541 43.2607 5.73828 34.8448 5.73828 24.5002C5.73828 14.1557 14.1541 5.73975 24.4988 5.73975C31.1913 5.73975 37.4254 9.34651 40.768 15.1526C41.1508 15.8176 40.9221 16.6671 40.2572 17.05C39.5923 17.4329 38.7427 17.2041 38.3598 16.5392C35.5116 11.592 30.2003 8.51858 24.4988 8.51858C15.6864 8.51858 8.51712 15.688 8.51712 24.5002C8.51712 33.3125 15.6865 40.4819 24.4988 40.4819C33.311 40.4819 40.4804 33.3125 40.4804 24.5002C40.4804 23.7329 41.1025 23.1108 41.8699 23.1108C42.6372 23.1108 43.2593 23.7329 43.2593 24.5002C43.2593 34.8449 34.8432 43.2607 24.4988 43.2607Z" fill="#E40A3B"/>
      <rect x="20" y="17" width="3" height="15" rx="1.5" fill="white"/>
      <rect x="26" y="17" width="3" height="15" rx="1.5" fill="white"/>
    </svg>
  );
}