import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTytes from 'prop-types';
import { withStyles, Button } from '@material-ui/core';
import PapperBlock from '../../components/PapperBlock';
import DataTable from '../../components/DataTable';
import { getAllSlides } from './requests';
import { SlideIcon } from '../../constants/icons';
import { ADMIN_SLIDES_CREATE_ROUTE } from '../../constants/routes';
import Columns from './Columns';
import styles from '../../styles';

function SlidesPage({ classes }) {
  const [data, setData] = useState([]);
  // const processingRequest = async () => {
  //   setData((await getAllSlides()).data);
  // };

  const getData = async () => {
    const response = await getAllSlides();
    setData(response.data);
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <PapperBlock title="Слайды" icon={<SlideIcon />}>
      <Link to={ADMIN_SLIDES_CREATE_ROUTE} className={classes.link}>
        <Button variant="contained" color="primary" className={classes.field}>
          Добавить Слайд
        </Button>
      </Link>
      <DataTable columns={Columns} data={data} title="Список слайдов" />
    </PapperBlock>
  );
}

export default withStyles(styles)(SlidesPage);

SlidesPage.propTypes = {
  classes: PropTytes.object,
};

SlidesPage.defaultProps = {
  classes: {},
};
