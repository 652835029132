import fetchRequest from '../../helpers/fetchRequest';

export async function getUniqueYears(year = null, month = null) {
    const url = '/filter-news/unique-years';
    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        year: year,
        month: month,
      })
    };
    return fetchRequest(url, options);
  }
  
  export async function getUniqueMonths(year = null, month = null) {
    const url = '/filter-news/unique-months';
    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        year: year,
        month: month,
      })
    };
    return fetchRequest(url, options);
  }