import fetchRequest from '../../helpers/fetchRequest';

export async function addNewPicture(picture) {
  const formData = new FormData();
  formData.append('picture', picture);
  const url = '/content-pictures';
  const options = {
    method: 'POST',
    body: formData,
  };
  return fetchRequest(url, options);
}

export async function getLatestPictures() {
  const url = '/content-pictures';
  const options = {
    method: 'GET',
  };
  return fetchRequest(url, options);
}
