import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import PapperBlock from '../../../components/PapperBlock';
import OffersForm from '../OffersForm';
import { getOffersById, updateOffersById } from '../requests';
import { OfferIcon } from '../../../constants/icons';
import { ADMIN_OFFERS_ROUTE } from '../../../constants/routes';

export default function UpdateOffers() {
  const [dataOffer, setDataOffer] = useState({});
  const [loaded, setLoaded] = useState(false);
  const params = useParams();
  const { id } = params;
  async function processingRequest() {
    setDataOffer((await getOffersById(id)).data);
    setLoaded(true);
  }
  const history = useHistory();
  const submitHandler = async (data, picture) => {
    const response = await updateOffersById(data, picture);
    if (response.ok) {
      history.push(ADMIN_OFFERS_ROUTE);
    }
  };
  useEffect(() => {
    processingRequest();
  }, []);

  return (
    <PapperBlock title="Обновить акцию" icon={<OfferIcon />}>
      {loaded && <OffersForm submitHandler={submitHandler} data={dataOffer} />}
    </PapperBlock>
  );
}
