import React, { useState, useEffect } from 'react';
import BlockContent from '../../components/BlockContent';
import BannerWidget from '../../components/BannerWidget';
import BlockArchive from '../../components/BlockArchive';
import BlockArchiveItem from '../../components/BlockArchiveItem';
import MetaTags from 'react-meta-tags';
import { getCurrentBanner, getCurrentOffer, getLatestOffersArchive, getOffersAmount } from './requests';
import './index.css';
import { OFFERS_BY_ALIAS_ROUTE } from '../../constants/redirect';

export default function OffersArchive() {

  const [currentBanner, setCurrentBanner] = useState('');
  const [currentOffer, setCurrentOffer] = useState('');
  
  const [offers, setOffers] = useState([]);

  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [offersAmount, setOffersAmount] = useState(0);
  const [chosenYear, setChosenYear] = useState(null);
  const [chosenMonth, setChosenMonth] = useState(null);
  const processingRequest = async () => {
    setCurrentOffer((await getCurrentOffer()).data);
    setCurrentBanner((await getCurrentBanner()).data);

    setOffersAmount((await getOffersAmount(chosenYear, chosenMonth)).data);
    await loadOffers(true, chosenYear, chosenMonth, pageSize, pageIndex * 10);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    processingRequest();
  }, []);

  const loadOffers = async (firstLoad = true, year, month, limit, offset) => {
    const downloaded = await getLatestOffersArchive(year, month, limit, offset);
    if (firstLoad) {
      setOffers(downloaded.data);
    } else {
      setOffers([...offers, ...downloaded.data]);
    }
  }

  const loadOffersWrapper = async (bool, ind) => {
    await loadOffers(bool, chosenYear, chosenMonth, pageSize, ind);
  }

  const setOffersAmountWrapper = async () => {
    setOffersAmount((await getOffersAmount(chosenYear, chosenMonth)).data);
  }

  useEffect(() => {
    loadOffersWrapper(false, pageIndex * 10);
    // setPageIndex(1);
  }, [pageIndex])

  useEffect(() => {
    loadOffersWrapper(true, 0);
    setOffersAmountWrapper();
  }, [chosenYear, chosenMonth]);
  return (
    <div className="page-offers-archive">
      <BlockContent>
      <MetaTags>
        <title>{"Архив акций - Новое Радио"}</title>
        <meta name="description" content={"Архив акций - Новое Радио"} />
      </MetaTags>
        <div className="big-column">
          <BlockArchive 
            changeHandler ={ async (year, month) => {
              setChosenYear(year);
              setChosenMonth(month);
              setPageIndex(0);
            }}
            header={"Архив акций"}
            returnLink={OFFERS_BY_ALIAS_ROUTE}
            type={"offers"}
          >
            {offers.length > 0 && offers.map((el, index) => {
            return <BlockArchiveItem
              key={el.title}
              img={el.picture}
              redirect={OFFERS_BY_ALIAS_ROUTE + el.alias}
              title={el.title}
              publishDate={el.publish_date}
              unpublishDate={el.unpublish_date}
              bg={index % 2 !== 0 ? 'grey' : 'white'}
              endingLabel={"Акция завершена"}
            />
        })}
            
          </BlockArchive>
        </div>
        <div className="small-column">
          {currentOffer[0] && Object.values(currentOffer).map((elem) => (
            <BannerWidget
            local
            img={elem.picture.replace("static", "")}
            title={elem.title}
            redirect={elem.alias}
          />
          ))}
          {currentBanner.length > 0 && currentBanner.map((elem) => (
            <BannerWidget
            img={elem.picture}
            redirect={elem.link}
          />
          ))}
          
        </div>
      </BlockContent>
    </div>
  );
}
