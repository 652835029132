/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  changeFlowAction,
  changeAudioFlowAction,
  changeAudioPlaybackAction,
  playAudioPlaybackAction,
  pauseAudioPlaybackAction,
} from '../../redux/actions';
import SvgLike from '../../components/Svg/SvgLike';
import SvgPosition from '../../components/Svg/SvgPosition';
import SvgPause from '../../components/Svg/SvgPause';
import Animation from '../../helpers/animation';
import { likeTrack } from './requests';
import './index.css';

function ItemChart({
  playlistId,
  trackId,
  chart,
  playTime,
  number,
  index,
  img,
  audio,
  title,
  author,
  info,
  timeInfo,
  like,
  liked,
  position,
  color,
  flow,
  changeFlow,
  changeAudioFlow,
  playAudioPlayback,
  pauseAudioPlayback,
  audioPlayback,
  likedTracks,
  isTrackLiked,
  date,
  onClickHandler,
  name
}) {
  const [isLike, setIsLike] = useState(isTrackLiked);
  useEffect(() => {
    setIsLike(isTrackLiked);
  }, [isTrackLiked])
  const bgColor = index % 2 !== 0 ? 'grey' : 'white';
  const isActive = audioPlayback
    && JSON.stringify(flow) === JSON.stringify({ chart, title, author });

  const renderPosition = () => (position && position.action ? (
    <div className="chart-item__position">
      <div className="chart-item__position-change">
        <SvgPosition direction={position.action} />
      </div>
      <div className="chart-item__position-value">{position.value}</div>
    </div>
  ) : (
    <div />
  ));

  const onLikeTrack = async () => {
    let storage = localStorage.getItem('likedTracks');
    const date = new Date().toISOString();
    if (!storage.length ) {
      storage = [{date, trackId, title, author}]
    } else if (typeof storage != "array") {
      storage = JSON.parse(localStorage.getItem('likedTracks'));
      storage.push({
        date, trackId, title, author,
      });
    } else {
    storage.push({
      date, trackId, title, author,
    });
  }
    localStorage.setItem('likedTracks', JSON.stringify(storage));
    await likeTrack(trackId);
  };

  const playTrack = (event) => {
    document.getElementsByTagName("audio")[0].onended=function(){ 
      pauseAudioPlayback();
      onClickHandler(event);
    };
    changeAudioFlow(audio);
    if (isActive) {
      return pauseAudioPlayback();
    }
    playAudioPlayback();
    return changeFlow({
      chart, title, author,
    });
  };

  const returnPlayTime = (str) => {
    const date = new Date(str);
    const hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
    const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
    return `${hours}:${minutes}`
  }

  return (
    <div className={`chart_container ${bgColor} ${
      isActive ? 'active track-animation' : null
    } ${color}` }
    name={name}
    >
    <div
      className={`chart-item ${ !audio ? 'no-audio' : ''} `}
    >
      <div
        // style={{ paddingRight: like ? '42px' : '0' }}
        className={`chart-item__content ${bgColor} `}
        onClick={(e) => playTrack(e)}
      >
        {number ? (
          <div className="chart-item__number">
            {number}
            {renderPosition()}
          </div>
        ) : (
          <div className={playTime && 'chart-item__number'}>
            {playTime}
          </div>
        )}

        <div className="chart-item__preview-container">
          {isActive && (
            <div className="chart-item__control">
              <SvgPause />
            </div>
          )}
          <img
            className={
              number ? 'chart-item__preview_img-number' : 'chart-item__preview'
            }
            src={img ? img : '/uploads/images/tracks/novoe_radio_placeholder.jpeg'}
            alt=""
          />
        </div>
        <div className="chart-item__info">
          <div className="track-info">
            <span className="track-info__name">{title}</span>
            <span className="track-info__author">{author}</span>
          </div>
          <div className="chart-item__other-info">
            {renderPosition()}
            {info && <span className="chart-item__date">{info}</span>}
            {timeInfo && <span className="chart-item__time">{timeInfo}</span>}
            {date && (<span className='chart-item__playtime'>{returnPlayTime(date)}</span>)}
          </div>
        </div>
      </div>
    </div>
    {like && (
        <div
          className={`chart-item__like ${isLike ? 'liked' : ''}`}
          aria-hidden
          onClick={(event) => {
            if (!isLike) {
              setTimeout(() => {
                setIsLike(true);
              }, 250);
              onLikeTrack();
              new Animation(event).button();
            }
          }}
        >
          <SvgLike />
        </div>
      )}
    </div>
  );
}

ItemChart.propTypes = {
  chart: PropTypes.string,
  number: PropTypes.number,
  playTime: PropTypes.string,
  index: PropTypes.number,
  img: PropTypes.string,
  audio: PropTypes.string,
  author: PropTypes.string,
  title: PropTypes.string,
  info: PropTypes.string,
  like: PropTypes.bool,
  liked: PropTypes.bool,
  position: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
  color: PropTypes.oneOf(['green', 'yellow', 'purple']),
  flow: PropTypes.objectOf(PropTypes.string).isRequired,
  changeFlow: PropTypes.func,
  changeAudioFlow: PropTypes.func,
  playAudioPlayback: PropTypes.func.isRequired,
  pauseAudioPlayback: PropTypes.func.isRequired,
  audioPlayback: PropTypes.bool.isRequired,
  date: PropTypes.string,
};

ItemChart.defaultProps = {
  chart: '',
  number: 0,
  playTime: '',
  index: 0,
  img: '',
  audio: '',
  author: '',
  title: '',
  info: '',
  like: false,
  liked: false,
  position: {},
  color: 'green',
  changeFlow: () => {},
  changeAudioFlow: () => {},
  date: '',
};

const mapStateToProps = (state) => ({
  flow: state.flow,
  playback: state.playback,
  audioFlow: state.audioFlow,
  audioPlayback: state.audioPlayback,
  likedTracks: state.likedTracks,
});

const mapDispatchToProps = {
  changeFlow: changeFlowAction,
  changeAudioFlow: changeAudioFlowAction,
  changeAudioPlayback: changeAudioPlaybackAction,
  playAudioPlayback: playAudioPlaybackAction,
  pauseAudioPlayback: pauseAudioPlaybackAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemChart);
