import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTytes from 'prop-types';
import { withStyles, Button } from '@material-ui/core';
import PapperBlock from '../../components/PapperBlock';
import DataTable from '../../components/DataTable';
import { getQuizForTable } from './requests';
import { QuizIcon } from '../../constants/icons';
import { ADMIN_QUIZ_CREATE_ROUTE } from '../../constants/routes';
import Columns from './Columns';
import styles from '../../styles';

function QuizPage({ classes }) {
  const [dataQuiz, setDataQuiz] = useState([]);
  async function processingRequest() {
    setDataQuiz((await getQuizForTable()).data);
  }
  useEffect(() => {
    processingRequest();
  }, []);

  return (
    <PapperBlock title="Опросники" icon={<QuizIcon />}>
      <Link to={ADMIN_QUIZ_CREATE_ROUTE} className={classes.link}>
        <Button variant="contained" color="primary" className={classes.field}>
          Добавить опросник
        </Button>
      </Link>
      <DataTable title="Список ведущих" data={dataQuiz} columns={Columns} />
    </PapperBlock>
  );
}

export default withStyles(styles)(QuizPage);

QuizPage.propTypes = {
  classes: PropTytes.object,
};

QuizPage.defaultProps = {
  classes: {},
};
