import { fade } from '@material-ui/core/styles/colorManipulator';

const styles = (theme) => ({
  appBar: {
    background: '#ffffff',
    boxShadow: '0 10px 15px -5px rgb(62 57 107 / 7%)',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin', 'background'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    '& $menuButton': {
      color:
        theme.palette.type === 'dark'
          ? theme.palette.primary.light
          : theme.palette.primary.main,
      zIndex: 10,
    },
  },
  floatingBar: {
    position: 'fixed',
  },
  appBarShift: {
    transition: theme.transitions.create(['width', 'margin', 'background'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  brandWrap: {
    display: 'flex',
    paddingLeft: theme.spacing(1.5),
    '& svg': {
      fill:
        theme.palette.type === 'dark'
          ? fade(theme.palette.primary.light, 0.64)
          : fade(theme.palette.primary.dark, 0.64),
    },
  },
  dense: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(4),
    },
    '& $brand': {
      [theme.breakpoints.up('lg')]: {
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(1),
      },
    },
  },
  menuButton: {},
  brand: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
    fontSize: 16,
    margin: 0,
    fontWeight: 500,
    textDecoration: 'none',
    color: theme.palette.text.primary,
    '& img': {
      marginRight: 10,
      height: 40,
      borderRadius: '8px',
    },
  },
  brandBar: {},
  headerProperties: {
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    zIndex: 1,
  },
  userToolbar: {
    marginRight: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginRight: theme.spacing(0.5),
    },
    '& $buttonTop': {
      [theme.breakpoints.down('xs')]: {
        fontSize: 0,
        '& svg': {
          margin: 0,
        },
      },
    },
  },
  buttonTop: {
    margin: `0 ${theme.spacing(2)}px`,
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
});

export default styles;
