import React from 'react';
import { useHistory } from 'react-router-dom';
import PapperBlock from '../../../../components/PapperBlock';
import SocialsForm from '../SocialsForm';
import { ADMIN_SETTING_ROUTE } from '../../../../constants/routes';
import { createSocial } from '../requests';

const CreateSocial = () => {
  const location = useHistory();
  const submitHandler = async (data, picture) => {
    const response = await createSocial(data, picture);
    if (response.ok) {
      location.push(ADMIN_SETTING_ROUTE);
    }
  };

  return (
    <PapperBlock>
      <SocialsForm submitHandler={submitHandler} />
    </PapperBlock>
  );
};

export default CreateSocial;
