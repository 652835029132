import React from 'react';
import { useHistory } from 'react-router-dom';
import PapperBlock from '../../../components/PapperBlock';
import NewsForm from '../NewsForm';
import { createNews } from '../requests';
import { NewsIcon } from '../../../constants/icons';
import { ADMIN_NEWS_ROUTE } from '../../../constants/routes';

export default function CreateNews() {
  const history = useHistory();
  const submitHandler = async (data, picture) => {
    const response = await createNews(data, picture);
    if (response.ok) {
      history.push(ADMIN_NEWS_ROUTE);
    }
  };
  return (
    <PapperBlock title="Добавить новость" icon={<NewsIcon />}>
      <NewsForm submitHandler={submitHandler} />
    </PapperBlock>
  );
}
