import React, {useState, useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './index.css';

export default function BlockNewsItem({
  date, title, img, alias,
}) {
  const [oneNewsHeight, setoneNewsHeight] = useState(0);
  const oneNewsElementRef = useRef(null);

  async function processingRequest() {
    const itemHeight = window.innerWidth <= 1200 ? Math.ceil(oneNewsElementRef.current.clientWidth / 1.87) : Math.ceil(oneNewsElementRef.current.clientWidth) - 30
    setoneNewsHeight(itemHeight);
  }

  useEffect(() => {
    processingRequest();
  }, []);

  return (
    <Link 
      to={`/news/${alias}`} 
      ref={oneNewsElementRef}
      style={{height: `${oneNewsHeight}px`}}>
      <div className="item-news banner">
        <img
          src={img}
          className="item-news__wrapper banner-zoom"
          alt=""
        />
        <div className="item-news__row">
          <div className="item-news__row-content">
            <div className="item-news__row-date">{date}</div>
            <div className="item-news__row-title">{title}</div>
          </div>
        </div>
      </div>
    </Link>
  );
}

BlockNewsItem.propTypes = {
  date: PropTypes.string,
  title: PropTypes.string,
  img: PropTypes.string,
  alias: PropTypes.string,
};

BlockNewsItem.defaultProps = {
  date: '',
  title: '',
  img: '',
  alias: '',
};
