import React, { useState, useEffect } from 'react';
import MetaTags from 'react-meta-tags';
import BlockContent from '../../components/BlockContent';
import BlockChart from '../../components/BlockChart';
import ItemChart from '../../containers/ItemChart';
import PlaylistContainer from '../../components/PlaylistContainer';
import BannerWidget from '../../components/BannerWidget';
import {
  getPlaylistChart,
  getCurrentBanner,
  getChartTracks,
  getNoveltyChart, 
  getApplicantTracks,
  getChartTimestamp,
} from './requests';
import './index.css';
import Chart from '../../containers/Chart';
import setLocalStorageForLikes from '../../helpers/setLocalStorageForLikes';
import {
  MUSIC, 
  PLAYLIST, 
  NOVELTY, 
  NOVELTY_ICON, 
  PLAYLIST_ICON, 
  MUSIC_ICON, 
  APPLICANTS, 
  APPLICANTS_ICON 
} from '../../constants/routes';
import {useSelector} from 'react-redux';

export default function Novelty() {
  // reasdew;
  const [chartData, setChartData] = useState([]);
  const [playlistChartData, setPlaylistChartData] = useState([]);
  const [noveltyChartData, setNoveltyChartData] = useState([]);
  const [applicantChartData, setApplicantChartData] = useState([]);
  const [bannerData, setBannerData] = useState(null);
  const [ratedTracks, setRatedTracks] = useState([]);
  const [likedTracks, setLikedTracks] = useState([]);
  const {playlistLastSong} = useSelector((state) => state);

  const setLocalStorage = async () => {
    const timestamp = ((await getChartTimestamp()).data);
    const [actualRatedTracks, actualLikedTracks] = setLocalStorageForLikes(timestamp.timestamp);
    setRatedTracks([...actualRatedTracks]);
    setLikedTracks([...actualLikedTracks]);
  }

  async function processingRequest() {
    setChartData((await getChartTracks()).data);
    setPlaylistChartData((await getPlaylistChart()).data);
    setNoveltyChartData((await getNoveltyChart()).data);
    setBannerData((await getCurrentBanner()).data);
    setApplicantChartData((await getApplicantTracks()).data);
    await setLocalStorage();
  }
  useEffect(async () => {
    window.scrollTo(0, 0);
    await processingRequest();
  }, []);

  // const asyncGetPlaylistChartData = async () => {
  //   setPlaylistChartData((await getPlaylistChart()).data);
  // }

  useEffect(() => {
      if (playlistLastSong.id && playlistChartData.id) {
        setPlaylistChartData(
          {
            ...playlistChartData, 
            track_list: [
              {
                ...playlistLastSong, 
                play_date: new Date().toISOString(),
                play_time: new Date().toISOString(),
              }, 
              ...playlistChartData.track_list
            ]
          }
        );
      }
  }, [playlistLastSong]);

  return (
    <div className="page-novelty">
      <BlockContent left>
      <MetaTags>
          <title>Новинки - Новое Радио</title>
          <meta name="description" content="Новинки - Новое Радио" />
        </MetaTags>
        <div className="big-column__chart">
          <PlaylistContainer
            chartData={noveltyChartData}
            title={"Новинки"}
            link={NOVELTY}
            icon={NOVELTY_ICON}
            countViewTracks={10}
            additionalClass = {"new_songs"}
            children={noveltyChartData}
            type={"noveltyChart"}
            color="yellow"
            isLinkable={false}
          />
        </div>
        <div className="small-column__chart">
          {chartData && (
            <PlaylistContainer
              chartData={chartData}
              title={"Улетный чарт"}
              link={MUSIC}
              icon={MUSIC_ICON}
              countViewTracks={4}
              additionalClass = {"flyaway-chart"}
              children={chartData.track_list}
              type={"chart"}
              color={null}
            />
          )}
          {playlistChartData && (
            <PlaylistContainer
                chartData={playlistChartData.track_list}
                title={playlistChartData.title}
                link={PLAYLIST}
                icon={PLAYLIST_ICON}
                countViewTracks={4}
                additionalClass = {"playlist"}
                children={playlistChartData.track_list}
                type={"playlistChart"}
                color={null}
              />
          )}
          {applicantChartData && (
            <PlaylistContainer
                chartData={applicantChartData}
                title={"Претенденты"}
                link={APPLICANTS}
                icon={APPLICANTS_ICON}
                countViewTracks={6}
                additionalClass = {"new_songs"}
                children={applicantChartData}
                type={"applicantChart"}
                color="yellow"
                isLinkable={false}
              />
          )}
          {bannerData && bannerData.map((elem) => (
            <BannerWidget img={elem.picture} redirect={elem.link} />
          ))}
        </div>
      </BlockContent>
    </div>
  );
}
