import fetchRequest from '../../helpers/fetchRequest';

export async function getChartTracks() {
  const url = '/chart';
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function getNotChartTracks() {
  const url = '/not-chart';
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function addChartTrackById(id) {
  const url = `/chart/${id}`;
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function updateChartTracks(data) {
  const url = '/chart-update';
  const options = {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function deleteChartTrack(data) {
  const url = '/chart-delete';
  const options = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function resetChartRating() {
  const url = '/chart-reset-rating';
  const options = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function writeChartTimestamp(data) {
  const url = '/chart-write-timestamp';
  // const data = {"timestamp": timestamp};
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };
  return fetchRequest(url, options);
}

export async function deleteTrackFromChartById(id) {
  const url = `/chart/${id}`;
  const options = {
    method: 'DELETE',
  };
  return fetchRequest(url, options);
}
