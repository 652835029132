import fetchRequest from '../../helpers/fetchRequest';
  
  export async function setVotes(data) {
    const url = '/quiz';
    const options = {
      method: 'POST',
      body: JSON.stringify(data),
      headers: { 'Content-Type': 'application/json' },
    };
    return fetchRequest(url, options);
  }