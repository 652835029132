import React from 'react';
import PropTypes from 'prop-types';
import SvgArrow from '../Svg/SvgArrow';
import './index.css';

export default function Dropdown({
  title,
  items,
  open,
  handler,
  active,
  setActive,
}) {
  return (
    <div className="dropdown-menu__container" onClick={handler}>
      <div className="dropdown-menu__title">{active || title}</div>
      <div
        className="dropdown-menu__svg-container"
        style={{
          transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
        }}
      >
        <SvgArrow color="yellow" filled size={21} />
      </div>
      <div
        style={{ display: open ? 'flex' : 'none' }}
        className="dropdown-menu__scroll-bar"
      >
        <ul className="dropdown-menu__list">
          {items.map((el) => (
            <li
              key={el}
              onClick={() => {
                setActive(el);
                handler();
              }}
              className={`dropdown-menu__item ${active === el ? 'active' : ''}`}
            >
              {el}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

Dropdown.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array,
  open: PropTypes.bool,
  handler: PropTypes.func,
  active: PropTypes.object,
  setActive: PropTypes.func,
};

Dropdown.defaultProps = {
  title: '',
  items: [],
  open: false,
  handler: () => {},
  active: null,
  setActive: () => {},
};
