const styles = (theme) => ({
  dropItem: {
    textAlign: 'center',
  },
  uploadIconSize: {
    display: 'inline-block',
    '& svg': {
      width: 72,
      height: 72,
      fill: theme.palette.secondary.main,
    },
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      fill: theme.palette.common.white,
    },
  },
  button: {
    marginTop: 20,
  },
});

export default styles;
