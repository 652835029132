/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import {
  withStyles, Button, IconButton, Snackbar,
} from '@material-ui/core';
import ActionDelete from '@material-ui/icons/Delete';
import CloudUpload from '@material-ui/icons/CloudUpload';
import { isAudio, isAudioUrl } from '../../helpers/dropzone';
import styles from './styles';

function AudioDropzone(props) {
  const {
    files,
    setFiles,
    urls,
    setUrls,
    toDelete,
    setToDelete,
    text,
    classes,
    acceptedFiles,
    filesLimit,
    maxSize,
  } = props;

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  function onDrop(filesVal) {
    let oldFiles = urls.concat(files);
    oldFiles = oldFiles.concat(filesVal);
    if (oldFiles.length > filesLimit) {
      setOpenSnackBar(true);
      setErrorMessage(`Максимальное количество элементов: ${filesLimit}`);
    } else {
      setFiles((prev) => prev.concat(filesVal));
    }
  }

  function onDropRejected() {
    setOpenSnackBar(true);
    setErrorMessage(
      `Файл слишком большой, максимальный размер: ${maxSize / 1000000} MB`,
    );
  }

  function handleRequestCloseSnackBar() {
    setOpenSnackBar(false);
  }

  function handleRemove(file, listIndex) {
    if (isAudio(file)) {
      const thisFiles = files;
      thisFiles.splice(listIndex, 1);
      setFiles([].concat(thisFiles));
    }
    if (isAudioUrl(file)) {
      setToDelete((prev) => prev.concat(file));
      const thisUrls = urls;
      thisUrls.splice(listIndex, 1);
      setUrls([].concat(thisUrls));
    }
  }

  const deleteBtn = (file, index) => (
    <div className="middle">
      <IconButton onClick={() => handleRemove(file, index)}>
        <ActionDelete className="removeBtn" />
      </IconButton>
    </div>
  );

  const previewUrls = urls.map((item, index) => (
    <div key={index.toString()} className="audioConteiner">
      <audio controls src={item} className="audio" />
      {deleteBtn(item, index)}
    </div>
  ));

  const previewFiles = files.map((item, index) => {
    const src = URL.createObjectURL(item);
    return (
      <div key={index.toString()} className="audioConteiner">
        <audio controls src={src} className="audio" />
        {deleteBtn(item, index)}
      </div>
    );
  });

  let dropzoneRef;

  return (
    <div>
      <Dropzone
        accept={acceptedFiles}
        onDrop={onDrop}
        onDropRejected={onDropRejected}
        acceptClassName="stripes"
        rejectClassName="rejectStripes"
        maxSize={maxSize}
        ref={(node) => {
          dropzoneRef = node;
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <section className="dropzone-wrap">
              <div {...getRootProps({className: 'dropzone'})}>
                <div className="dropzoneTextStyle">
                  <input {...getInputProps()} />
                </div>
                <div className="dropzone-placeholder">
                  <svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z"></path></svg>
                  <p>Перетащите сюда файл или нажмите по области</p>
                  <p>Поддерживаемые форматы: {acceptedFiles}</p>
                  <p>Максимальное количество элементов: {filesLimit}</p>
                  <p>Максимальный размер: {maxSize / 1000000} Мб</p>
                </div>
              </div>
          </section>
        )}
      </Dropzone>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        onClick={() => {
          dropzoneRef.open();
        }}
      >
        {text}
        <span className={classes.rightIcon}>
          <CloudUpload />
        </span>
      </Button>
      <div className="row preview">
        {previewUrls}
        {previewFiles}
      </div>

      <Snackbar
        open={openSnackBar}
        message={errorMessage}
        autoHideDuration={4000}
        onClose={handleRequestCloseSnackBar}
      />
    </div>
  );
}

AudioDropzone.propTypes = {
  files: PropTypes.array,
  setFiles: PropTypes.func,
  urls: PropTypes.array,
  setUrls: PropTypes.func,
  toDelete: PropTypes.array,
  setToDelete: PropTypes.func,
  text: PropTypes.string,
  acceptedFiles: PropTypes.array,
  maxSize: PropTypes.number,
  filesLimit: PropTypes.number,
  classes: PropTypes.object,
  multiple: PropTypes.bool,
};

AudioDropzone.defaultProps = {
  files: [],
  setFiles: () => {},
  urls: [],
  setUrls: () => {},
  toDelete: [],
  setToDelete: () => {},
  text: undefined,
  acceptedFiles: ['audio/*'],
  maxSize: 10000000,
  filesLimit: 1,
  classes: {},
  multiple: true,
};

export default withStyles(styles)(AudioDropzone);
