import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Switch, withStyles, TextField } from '@material-ui/core';
import PapperBlock from '../../../components/PapperBlock';
import { getAdvertisementLineData, updateAdvertisementLineData } from '../requests';
import { SettingIcon } from '../../../constants/icons';
import InputField from '../../../components/InputField';
import ImageDropzone from '../../../components/Dropzone/ImageDropzone';
import { ADMIN_SETTING_ROUTE } from '../../../constants/routes';
import {maxImageFileSize} from "../../../constants/additional";
import { DATE_FORMAT, DATE_FORMAT_TO_DB, DATE_TIME_FORMAT } from '../../../constants/dateTimeFormat';
import classNames from 'classnames';
import styles from '../../../styles';
import { format } from 'date-fns';

const AdvertisementLineSettings = ({classes}) => {

  const [advertisementLineData, setAdvertisementLineData] = useState({});
  const [link, setLink] = useState('');
  const [pictureFile, setPictureFile] = useState([]);
  const [pictureUrl, setPictureUrl] = useState([]);
  const [pictureToDelete, setPictureToDelete] = useState([]);
  const [active, setActive] = useState(0);
  const [publishDate, setPublishDate] = useState(null);
  const [unpublishDate, setUnpublishDate] = useState(null);
  const history = useHistory();

  const submitHandler = async (event) => {
    event.preventDefault();
    const response = await updateAdvertisementLineData({
      link,
      pictureUrl,
      pictureToDelete,
      active,
      publishDate: publishDate ? format(publishDate, DATE_FORMAT_TO_DB) : null,
      unpublishDate: unpublishDate ? format(unpublishDate, DATE_FORMAT_TO_DB) : null,
    },
    pictureFile[0]);
    if (response.ok) {
      history.push(ADMIN_SETTING_ROUTE);
    }
  };

  async function processingRequest() {
    setAdvertisementLineData((await getAdvertisementLineData()).data);
  }

  useEffect(() => {
    processingRequest();
  }, []);

  useEffect(() => {
    setPictureUrl(advertisementLineData.picture);
    setLink(advertisementLineData.link);
    setActive(advertisementLineData.active);
    setPublishDate(advertisementLineData.publish_date ? new Date(advertisementLineData.publish_date) : advertisementLineData.publish_date);
    setUnpublishDate(advertisementLineData.unpublish_date ? new Date(advertisementLineData.unpublish_date) : advertisementLineData.unpublish_date);
  }, [advertisementLineData]);
  return (
    <PapperBlock title="Обновить рекламную полосу" icon={<SettingIcon />}>
      <form onSubmit={submitHandler}>
        <InputField title="Ссылка:" value={link} setValue={setLink} />

        <ImageDropzone
          files={pictureFile}
          setFiles={setPictureFile}
          urls={pictureUrl}
          setUrls={setPictureUrl}
          toDelete={pictureToDelete}
          setToDelete={setPictureToDelete}
          text="Загрузить картинку"
          filesLimit={1}
          acceptedFiles={['image/*']}
          maxSize={maxImageFileSize}
        />
        Активность рекламной полосы
        <Switch
          color="primary"
          checked={active}
          onChange={(e) => {
            setActive(e.target.checked);
          }}
        />
        <div className={classNames(classes.field, classes.dateRow)}>
          <div className={classes.dateColumn}>
            <div className={classes.dateRow}>Дата публикации:</div>
            <TextField
              className={classes.dateRow}
              type="datetime-local"
              value={
                publishDate ? format(publishDate, DATE_TIME_FORMAT) : undefined
              }
              onChange={(e) => {
                setPublishDate(Date.parse(e.target.value));
              }}
            />
          </div>
          <div className={classes.dateColumn}>
            <div className={classes.dateRow}>Дата снятия с публикации:</div>
            <TextField
              className={classes.dateRow}
              type="datetime-local"
              value={
                unpublishDate
                ? format(unpublishDate, DATE_TIME_FORMAT)
                : undefined
              }
              onChange={(e) => {
                setUnpublishDate(Date.parse(e.target.value));
              }}
            />
          </div>
        </div>

        <Button variant="contained" color="primary" type="submit" fullWidth>
          Сохранить изменения
        </Button>
      </form>

    </PapperBlock>
  )
}

export default withStyles(styles)(AdvertisementLineSettings);

AdvertisementLineSettings.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
};

AdvertisementLineSettings.defaultProps = {
  classes: {},
};