import React, { useState, useEffect } from 'react';
import BlockContent from '../../components/BlockContent';
import BannerWidget from '../../components/BannerWidget';
import BlockArchive from '../../components/BlockArchive';
import BlockArchiveItem from '../../components/BlockArchiveItem';
import MetaTags from 'react-meta-tags';
import { getCurrentBanner, getCurrentProgram, getLatestProgramsArchive, getProgramsAmount } from './requests';
import './index.css';
import { PROGRAMS_BY_ALIAS_ROUTE } from '../../constants/redirect';

export default function ProgramsArchive() {

  const [currentBanner, setCurrentBanner] = useState('');
  // const [currentProgram, setCurrentProgram] = useState('');
  
  const [programs, setPrograms] = useState([]);

  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [programsAmount, setProgramsAmount] = useState(0);
  const [chosenYear, setChosenYear] = useState(null);
  const [chosenMonth, setChosenMonth] = useState(null);
  const processingRequest = async () => {
    // setCurrentProgram((await getCurrentProgram()).data);
    setCurrentBanner((await getCurrentBanner()).data);

    setProgramsAmount((await getProgramsAmount(chosenYear, chosenMonth)).data);
    await loadPrograms(true, chosenYear, chosenMonth, pageSize, pageIndex * 10);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    processingRequest();
  }, []);

  const loadPrograms = async (firstLoad = true, year, month, limit, offset) => {
    const downloaded = await getLatestProgramsArchive(year, month, limit, offset);
    if (firstLoad) {
      setPrograms(downloaded.data);
    } else {
      setPrograms([...programs, ...downloaded.data]);
    }
  }

  const loadProgramsWrapper = async (bool, ind) => {
    await loadPrograms(bool, chosenYear, chosenMonth, pageSize, ind);
  }

  const setProgramsAmountWrapper = async () => {
    setProgramsAmount((await getProgramsAmount(chosenYear, chosenMonth)).data);
  }

  useEffect(() => {
    loadProgramsWrapper(false, pageIndex * 10);
    // setPageIndex(1);
  }, [pageIndex])

  useEffect(() => {
    loadProgramsWrapper(true, 0);
    setProgramsAmountWrapper();
  }, [chosenYear, chosenMonth]);
  return (
    <div className="page-offers-archive">
      <BlockContent>
      <MetaTags>
        <title>{"Архив программ - Новое Радио"}</title>
        <meta name="description" content={"Архив программ - Новое Радио"} />
      </MetaTags>
        <div className="big-column">
          <BlockArchive 
            changeHandler ={ async (year, month) => {
              setChosenYear(year);
              setChosenMonth(month);
              setPageIndex(0);
            }}
            header={"Архив программ"}
            returnLink={PROGRAMS_BY_ALIAS_ROUTE}
            type={"programs"}
          >
            {programs.length > 0 && programs.map((el, index) => {
            return <BlockArchiveItem
              key={el.title}
              img={el.picture}
              redirect={PROGRAMS_BY_ALIAS_ROUTE + el.alias}
              title={el.title}
              publishDate={el.publish_date}
              unpublishDate={el.unpublish_date}
              bg={index % 2 !== 0 ? 'grey' : 'white'}
              endingLabel={"Программа завершена"}
            />
        })}
            
          </BlockArchive>
        </div>
        <div className="small-column">
          {currentBanner.length > 0 && currentBanner.map((elem) => (
            <BannerWidget
            img={elem.picture}
            redirect={elem.link}
          />
          ))}
          
        </div>
      </BlockContent>
    </div>
  );
}
