import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import SvgPrograms from '../Svg/SvgPrograms';
import SvgArrow from '../Svg/SvgArrow';
import { PROGRAMS } from '../../constants/routes';
import './index.css';

export default function BlockPreviewProgramContainer({ children }) {
  return (
    <div className="preview-program-container">
      <div className="preview-program-container__row-title">
        <div className="preview-program-container__icon">
          <SvgPrograms />
        </div>
        <div className="preview-program-container__title">Программы</div>
      </div>
      <div className="preview-program-container__elements">{children}</div>
      <Link to={PROGRAMS} className="button-back">
        <div className="button-back__text">Больше</div>
        <SvgArrow filled direction="right" color="purple" size={26} />
      </Link>
    </div>
  );
}

BlockPreviewProgramContainer.propTypes = {
  children: PropTypes.node,
};

BlockPreviewProgramContainer.defaultProps = {
  children: <div />,
};
