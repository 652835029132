export const CHANGE_FLOW = 'CHANGE_FLOW';
export const PLAY = 'PLAY';
export const PAUSE = 'PAUSE';
export const PLAYBACK = 'PLAYBACK';
export const LIKE_TRACK = 'LIKE_TRACK';
export const CHANGE_AUDIO_FLOW = 'CHANGE_AUDIO_FLOW';
export const CHANGE_AUDIO_PLAYBACK = 'CHANGE_AUDIO_PLAYBACK';
export const PLAY_AUDIO_PLAYBACK = 'PLAY_AUDIO_PLAYBACK';
export const PAUSE_AUDIO_PLAYBACK = 'PAUSE_AUDIO_PLAYBACK';
export const CHANGE_VOLUME = 'CHANGE_VOLUME';
export const AUDIO_CURRENT_TIME_PERCENT = 'AUDIO_CURRENT_TIME_PERCENT';
export const FILTER_PODCASTS = 'FILTERED_PODCASTS';

export const SET_DURATION = 'SET_DURATION';
export const UPDATE_CURRENT_TIME = 'UPDATE_CURRENT_TIME';
export const SET_FULL_DURATION = 'SET_FULL_DURATION';
export const SET_FULL_CURRENT_TIME = 'SET_FULL_CURRENT_TIME';
export const SET_PLAYING_PODCAST_INDEX = 'SET_PLAYING_PODCAST_INDEX';

export const SET_STREAM_NAME = 'SET_STREAM_NAME';

export const IS_PAGE_LOADED = "IS_PAGE_LOADED";

export const IS_VIDEOSTREAM_OPENED = "IS_VIDEOSTREAM_OPENED";
export const IS_VIDEOSTREAM_PLAYING = "IS_VIDEOSTREAM_PLAYING";
export const SET_LIKED_TRACKS = "SET_LIKED_TRACKS";
export const SET_RATED_TRACKS = "SET_RATED_TRACKS";


export const SET_CURRENT_ARTIST = "SET_CURRENT_ARTIST";
export const SET_CURRENT_SONG = "SET_CURRENT_SONG";
export const SET_CURRENT_COVER = "SET_CURRENT_COVER";

export const SET_FLOWS_INFO = "SET_FLOW_INFO";
export const SET_PROGRAM_SLIDE_INDEX = "SET_PROGRAM_SLIDE_INDEX";
export const SET_LAST_LISTENED_STREAM = "SET_LAST_LISTENED_STREAM";

export const SET_MENU_OPEN = "SET_MENU_OPEN";
export const SET_PLAYLIST_LAST_SONG = "SET_PLAYLIST_LAST_SONG";