import React from 'react';

export default function SvgAudio() {
  return (
    <svg
      width="68"
      height="68"
      viewBox="0 0 68 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="34" cy="34" r="34" fill="#E40A3B" fillOpacity="0.1" />
      <circle cx="34.0002" cy="34" r="28.4865" fill="#E40A3B" />
      <path
        d="M25.9625 46C25.6194 46 25.2766 45.9116 24.9705 45.7351C24.3693 45.3887 24 44.7545 24 44.0694V21.9306C24 21.2452 24.3693 20.6112 24.9705 20.2649C25.5717 19.9185 26.3145 19.9114 26.9222 20.2465L46.9971 31.3159C47.6165 31.6574 48 32.3014 48 33C48 33.6986 47.6164 34.3426 46.9971 34.6841L26.922 45.7535C26.6237 45.918 26.293 46 25.9625 46ZM27.9251 25.2261V40.7739L42.0235 33L27.9251 25.2261Z"
        fill="white"
      />
      <path d="M46 33.5L25.75 45.1914L25.75 21.8087L46 33.5Z" fill="white" />
      <path
        d="M33.9998 60.035C19.644 60.035 7.96484 48.3557 7.96484 34C7.96484 19.6443 19.644 7.96506 33.9998 7.96506C43.2874 7.96506 51.9388 12.9704 56.5776 21.0278C57.1088 21.9506 56.7914 23.1296 55.8686 23.661C54.946 24.1924 53.7668 23.8748 53.2355 22.952C49.283 16.0865 41.9121 11.8214 33.9998 11.8214C21.7704 11.8214 11.8212 21.7708 11.8212 34C11.8212 46.2293 21.7706 56.1787 33.9998 56.1787C46.2291 56.1787 56.1785 46.2293 56.1785 34C56.1785 32.9351 57.0417 32.0719 58.1066 32.0719C59.1715 32.0719 60.0348 32.9351 60.0348 34C60.0348 48.3559 48.3554 60.035 33.9998 60.035Z"
        fill="#E40A3B"
      />
    </svg>
  );
}
