import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import SvgArrow from '../Svg/SvgArrow';
import Animation from '../../helpers/animation';
import './index.css';
import {getChartTimestamp} from './requests';
import {useDispatch} from 'react-redux';
import {
  setRatedTracks
} from '../../redux/actions';
import ShowMoreBtn from '../../components/ShowMoreBtn';

export default function BlockChart({
  icon,
  title,
  children,
  style,
  link,
  countViewTracks,
  enableFilter,
  additionalClass,
  isLinkable = true,
  moreBtn
}) {
  const dispatch = useDispatch();
  const [scrollPosition, setScrollPosition] = useState(0);
  const [childIndex, setChildIndex] = useState(children.length - countViewTracks);
  const [showAllTracks, setShowAllTracks] = useState(false);
  const childrenCount = children.length;
  const topMargin = 12;
  const filterHeight = 290;
  const trackItemHeight =  80 ;
  const scrollBias = 80;
  const minPosition = 0;
  // const height = countViewTracks < 20
  //   ? trackItemHeight * countViewTracks - topMargin
  //   : trackItemHeight * 20 - topMargin;
  const height = children.length < countViewTracks
    ? trackItemHeight * children.length - topMargin
    : trackItemHeight * countViewTracks - topMargin;
  const maxPosition = trackItemHeight * children.length - topMargin;
  const fromPositiveToNegative = (number) => number * -1;
  const disable = {
    up: childrenCount <= countViewTracks ? true : scrollPosition <= minPosition,
    down:
      childrenCount <= countViewTracks ? true : scrollPosition >= maxPosition,
  };

  

  // const processingRequest = async () => {
  //   const timestamp = ((await getChartTimestamp()).data);
  //   let ratedTracksInStorage = [];
  //   if( typeof localStorage.getItem("ratedTracks") == "array") {
  //     ratedTracksInStorage = localStorage.getItem("ratedTracks");
  //   } else if (localStorage.getItem("ratedTracks").length) {
  //     ratedTracksInStorage = JSON.parse(localStorage.getItem("ratedTracks"));
  //   }

    
  //   const actualRatedTracks = ratedTracksInStorage.filter(track => track.date >= timestamp.timestamp);
  //   localStorage.setItem('ratedTracks', JSON.stringify(actualRatedTracks));
  //   dispatch(setRatedTracks(JSON.stringify(actualRatedTracks)));
  //   window.dispatchEvent(new Event('storage'));
  // }
  // // processingRequest();

  // useEffect(() => {
  //   processingRequest();
  // }, [])

  return (
    <div className={`chart-block ${additionalClass != "" ? additionalClass : ""}`} style={style}>
      <div className="chart-info__row">
        <div className="chart-info">
          <img
            src={icon}
            className="chart-info__icon"
            alt=""
          />
          <span className="chart-info__title">
            <Link className={`chart-info__title_link ${!isLinkable ? "not-link" : ""}`} to={link || ''}>
              {title}
            </Link>
          </span>
        </div>
        <div className="chart-scroll">
          <div
            aria-hidden
            className="chart-scroll__up"
            onClick={(event) => {
              if (!disable.up && !showAllTracks ) {
                new Animation(event).button();
                setScrollPosition((prev) => {
                  if (prev > minPosition) {
                    return prev - scrollBias;
                  }
                  return prev;
                });
                setChildIndex(childIndex + 1);
              }
            }}
          >
            <SvgArrow color="green" direction="up" disable={disable.up || showAllTracks} />
          </div>
          <div
            aria-hidden
            className="chart-scroll__down"
            onClick={(event) => {
              if (!(childIndex <= 0) && !showAllTracks) {
                new Animation(event).button();
                setScrollPosition((prev) => {
                  if (prev < maxPosition) {
                    return prev + scrollBias;
                  }
                  return prev;
                });
                setChildIndex(childIndex - 1);
              }
            }}
          >
            <SvgArrow color="green" disable={childIndex <= 0 || showAllTracks} />
          </div>
        </div>
      </div>

      <div className="chart-playlist" style={{ height: `${enableFilter && !showAllTracks ? height + "px" : ""}` }}>
        <div
          className="chart-playlist__scroll-bar"
          style={{
            transform: `translateY(${ !showAllTracks ? fromPositiveToNegative(
              scrollPosition,
            ) : 0}px)`,
            transition: 'all 0.2s',
          }}
        >
          {children}
        </div>
      </div>
      {moreBtn ? (
        <ShowMoreBtn text={!showAllTracks ? "Больше" : "Меньше"} clickHandler={() => {
          setShowAllTracks(!showAllTracks);
        }} />
      ) : ""}
    </div>
  );
}

BlockChart.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.array,
  style: PropTypes.object,
  link: PropTypes.string,
  countViewTracks: PropTypes.number,
  enableFilter: PropTypes.bool,
  additionalClass: PropTypes.string,
};

BlockChart.defaultProps = {
  icon: '',
  title: '',
  children: [],
  style: {},
  link: '',
  countViewTracks: 0,
  enableFilter: false,
  additionalClass: '',
};
