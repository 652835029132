import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import BlockContent from '../../components/BlockContent';
import BannerWidget from '../../components/BannerWidget';
import FormMailing from '../../components/FormMailing';
import MetaTags from 'react-meta-tags';
import {
  getCurrentBanner,
  getOffersArchive,
  getOffersByAlias,
  getAllCurrentOffers,
  getAllCurrentActiveOffers,
  getOneCurrentOffer,
} from './requests';
import './index.css';
import { OFFERS } from '../../constants/routes';
import { OFFERS_BY_ALIAS_ROUTE, OFFERS_ARCHIVE_ROUTE } from '../../constants/redirect';
import SvgArrow from '../../components/Svg/SvgArrow';

export default function OffersDetail() {
  const [offerById, setOfferById] = useState({});
  const [prewiewArchive, setPrewiewArchiwe] = useState({});
  const [banner, setBanner] = useState({});
  const [bannerData, setBannerData] = useState(null);
  const [activeOffers, setActiveOffers] = useState({})
  const { alias } = useParams();
  const processingRequest = async () => {
    const currentOffer = (await getOffersByAlias(alias)).data;
    const activeOffersList = ((await getAllCurrentActiveOffers()).data.filter(elem => elem.id != currentOffer.id));
    activeOffersList.forEach(elem => {
    })
    // if (currentOffer.is_main) {
    //   setActiveOffers((await getOneCurrentOffer(currentOffer.id)).data);
    // } else {
      setActiveOffers((await getAllCurrentActiveOffers()).data.filter(elem => elem.id != currentOffer.id));
    // }
    setOfferById((await getOffersByAlias(alias)).data);
    setBanner((await getCurrentBanner()).data);
    setBannerData((await getCurrentBanner()).data);
    setPrewiewArchiwe((await getOffersArchive()).data);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    processingRequest();
    return () => {
      setOfferById({});
      setBanner({});
      setPrewiewArchiwe({});
      setActiveOffers({})
    };
  }, [alias]);
  // const formarr = offerById.google_form.replace("\\", "").split(" ");
  console.log("offerById", offerById);
  return (
    <div className="page-offers-detail">
      <BlockContent>
        <MetaTags>
          <title>{offerById.title}</title>
          <meta name="description" content={offerById.title} />
        </MetaTags>
        <div className="big-column">
          {offerById && (
            <div className="current-offer__container">
              <div class = "current-offer__dates">
                {`${new Date(offerById.publish_date).toLocaleDateString('ru-RU')} - ${new Date(offerById.unpublish_date).toLocaleDateString('ru-RU')}`}
              </div>
              <div className="current-offer__title">{offerById.title}</div>
              <div className="current-offer__image">
                <img src={offerById.picture ? offerById.picture.replace("static", "") : ""} alt={offerById.title} />
              </div>
              <div
                className="content-editor"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: offerById.content}}
              />
              <div
                className="content-editor"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: offerById.google_form ? offerById.google_form : ""}}
                />
            </div>
          )}
          {/* <FormMailing /> */}
          <Link to={OFFERS} className="button-back">
              <div className="button-back__text">Вернуться назад</div>
              <SvgArrow filled direction="left" color="purple" size={26} />
          </Link>
        </div>
        <div className="small-column">
          {activeOffers.length > 0 && activeOffers.map((elem) => (
            <BannerWidget
              local
              title={elem.title}
              img={elem.picture.replace("static", "")}
              redirect={OFFERS_BY_ALIAS_ROUTE + elem.alias}
          />
          ))}
          {banner.id && (
            <BannerWidget
              local
              title={banner.title}
              img={banner.picture}
              redirect={OFFERS_BY_ALIAS_ROUTE$ + banner.link}
            />
          )}
          {prewiewArchive && (
            <BannerWidget
              local
              title="Архив"
              img={prewiewArchive.hasOwnProperty("picture") ? prewiewArchive.picture.replace("static", "") : ""}
              redirect={OFFERS_ARCHIVE_ROUTE}
            />
          )}
          {bannerData && bannerData.map((elem) => (
            <BannerWidget img={elem.picture} redirect={elem.link} />
          ))}
        </div>
      </BlockContent>
    </div>
  );
}
