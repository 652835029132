import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import classNames from 'classnames';
import {
  Select,
  withStyles,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
  Button,
  Input,
  Tabs,
  Tab,
} from '@material-ui/core';
import ImageDropzone from '../../components/Dropzone/ImageDropzone';
import { DATE_FORMAT, DATE_FORMAT_TO_DB, DATE_TIME_FORMAT } from '../../constants/dateTimeFormat';
import translit from '../../helpers/translit';
import WysiwygWidget from '../../components/WysiwygWidget';
import styles from '../../styles';
import {maxImageFileSize} from "../../constants/additional";

function NewsForm({ classes, data, submitHandler }) {
  const { id } = data;
  const [alias, setAlias] = useState(data.alias);
  const [title, setTitle] = useState(data.title);
  const [headline, setHeadline] = useState(data.headline);
  const [content, setContent] = useState(data.content);
  const [status, setStatus] = useState(data.status);
  const [pictureUrl, setPictureUrl] = useState(data.picture);
  const [pictureFile, setPictureFile] = useState([]);
  const [pictureToDelete, setPictureToDelete] = useState([]);
  const [publishDate, setPublishDate] = useState(
    data.publish_date ? new Date(data.publish_date) : data.publish_date,
  );
  const [publishDateFormatted, setPublishDateFormatted] = useState(null);
  const [unpublishDate, setUnpublishDate] = useState(
    data.unpublish_date ? new Date(data.unpublish_date) : data.unpublish_date,
  );
  const [navigationPage, setNavigationPage] = useState(0);
  const [seoTitle, setSeoTitle] = useState(data.seo_title);
  const [seoDescription, setSeoDescription] = useState(data.seo_description);
  const [seoKeyWords, setSeoKeyWords] = useState(data.seo_key_words);
  const createDate = new Date(data.create_date);
  const updateDate = data.updateDate ? new Date(data.updateDate) : new Date();

  async function dispatchHandler(event) {
    // const date = new Date();
    // const pubDate = new Date(publishDate);
    // pubDate.setHours(date.getHours());
    // pubDate.setMinutes(date.getMinutes());
    // pubDate.setSeconds(date.getSeconds());
    // console.log(pubDate);
    event.preventDefault();

    const ifOk = confirm('Вы уверены что хотите сохранить изменения?');
    if (ifOk) {
      await submitHandler(
        {
          id,
          alias,
          title,
          headline,
          content,
          status,
          createDate: format(createDate, DATE_FORMAT_TO_DB),
          publishDate: publishDate
            ? format(publishDate, DATE_FORMAT_TO_DB)
            : null,
          unpublishDate: unpublishDate
            ? format(unpublishDate, DATE_FORMAT_TO_DB)
            : null,
          updateDate: format(updateDate, DATE_FORMAT_TO_DB),
          pictureUrl,
          pictureToDelete,
          seoTitle,
          seoDescription,
          seoKeyWords,
        },
        pictureFile[0],
      );
    } else {
      history.back();
    }
  }

  async function formatBeforeSave() {
    setPublishDateFormatted(pubDate);
  }

  return (
    <div>
      <form onSubmit={dispatchHandler}>
        <div className={classes.field}>
          <Tabs
            indicatorColor="primary"
            textColor="primary"
            aria-label="disabled tabs example"
            value={navigationPage}
            onChange={(event, value) => {
              setNavigationPage(value);
            }}
          >
            <Tab label="Форма Новости" />
            <Tab label="Форма SEO" />
          </Tabs>
        </div>
        {navigationPage === 0 && (
          <div>
            <div className={classes.field}>
              <div className={classes.title}>Алиас домена:</div>
              <TextField
                disabled
                required
                value={alias}
                className={classes.field}
              />
            </div>
            <div className={classes.field}>
              <div className={classes.title}>Заголовок:</div>
              <TextField
                type="text"
                required
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                  setAlias(translit(e.target.value));
                }}
                className={classes.field}
              />
            </div>
            <div className={classes.field}>
              <div className={classes.title}>Абзац:</div>
              <TextField
                type="text"
                required
                value={headline}
                onChange={(e) => {
                  setHeadline(e.target.value);
                }}
                className={classes.field}
              />
            </div>
            <div className={classes.field}>
              <div className={classes.title}>Содержание:</div>
              <WysiwygWidget state={content} setState={setContent} />
            </div>
            <div className={classes.field}>
              <div className={classes.title}>
                Статус видимости (Отображать на главной):
              </div>
              <FormControl className={classes.field}>
                <InputLabel id="seletc-status" />
                <Select
                  required
                  labelId="seletc-status"
                  value={status}
                  onChange={(e) => {
                    setStatus(e.target.value);
                  }}
                >
                  <MenuItem value={1}>Видимый</MenuItem>
                  <MenuItem value={0}>Невидимый</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className={classes.flexOne}>
              <div className={classes.halfWidth}>
                <ImageDropzone
                  files={pictureFile}
                  setFiles={setPictureFile}
                  urls={pictureUrl}
                  setUrls={setPictureUrl}
                  toDelete={pictureToDelete}
                  setToDelete={setPictureToDelete}
                  text="Загрузить картинку"
                  filesLimit={1}
                  acceptedFiles={['image/*']}
                  maxSize={maxImageFileSize}
                />
              </div>
            </div>
            <div className={classNames(classes.field, classes.dateRow)}>
              {data.updateDate && (
              <div className={classes.dateColumn}>
                <div className={classes.dateRow}>Дата обновления:</div>
                <div className={classes.dateRow}>
                  {format(updateDate, DATE_TIME_FORMAT)}
                </div>
              </div>
              )}
              <div className={classes.dateColumn}>
                <div className={classes.dateRow}>Дата создания:</div>
                <div className={classes.dateRow}>
                  {format(createDate, DATE_TIME_FORMAT)}
                </div>
              </div>
              <div className={classes.dateColumn}>
                <div className={classes.dateRow}>Дата публикации:</div>
                <TextField
                  className={classes.dateRow}
                  type="datetime-local"
                  value={
                    publishDate ? format(publishDate, DATE_TIME_FORMAT) : undefined
                  }
                  onChange={(e) => {
                    console.log(e.target.value);
                    setPublishDate(Date.parse(e.target.value));
                  }}
                />
              </div>
              <div className={classes.dateColumn}>
                <div className={classes.dateRow}>Дата снятия с публикации:</div>
                <TextField
                  className={classes.dateRow}
                  type="datetime-local"
                  value={
                    unpublishDate
                      ? format(unpublishDate, DATE_TIME_FORMAT)
                      : undefined
                  }
                  onChange={(e) => {
                    setUnpublishDate(Date.parse(e.target.value));
                  }}
                />
              </div>
            </div>
            <Button variant="contained" color="primary" type="submit" fullWidth>
              {id ? 'Обновить' : 'Создать'}
            </Button>
          </div>
        )}
        {navigationPage === 1 && (
          <div>
            <div className={classes.field}>
              <div className={classes.title}>Заголовок:</div>
              <TextField
                value={seoTitle}
                onChange={(event) => {
                  setSeoTitle(event.target.value);
                }}
                className={classes.field}
              />
            </div>
            <div className={classes.field}>
              <div className={classes.title}>Описание:</div>
              <TextField
                value={seoDescription}
                onChange={(e) => {
                  setSeoDescription(e.target.value);
                }}
                className={classes.field}
              />
            </div>
            <div className={classes.field}>
              <div className={classes.title}>Ключевые слова:</div>
              <TextField
                value={seoKeyWords}
                onChange={(e) => {
                  setSeoKeyWords(e.target.value);
                }}
                className={classes.field}
              />
            </div>
          </div>
        )}
        <div />
      </form>
    </div>
  );
}

NewsForm.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  submitHandler: PropTypes.func,
};

NewsForm.defaultProps = {
  classes: {},
  data: {
    alias: '',
    title: '',
    headline: '',
    content: '',
    status: 1,
    picture: [],
    create_date: Date.now(),
    publish_date: null,
    unpublish_date: null,
    seo_title: '',
    seo_description: '',
    seo_key_words: '',
  },
  submitHandler: () => {},
};

export default withStyles(styles)(NewsForm);
