import fetchRequest from '../../helpers/fetchRequest';

export async function createTrack(data, audio, picture) {
  const formData = new FormData();
  formData.append('audio', audio);
  formData.append('picture', picture);
  formData.append('data', JSON.stringify(data));
  const url = '/tracks';
  const options = {
    method: 'POST',
    body: formData,
  };
  return fetchRequest(url, options);
}

export async function getTracksForTable() {
  const url = '/tracks';
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function getTrackById(id) {
  const url = `/tracks/${id}`;
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function updateTrackById(data, audio, picture) {
  const formData = new FormData();
  formData.append('audio', audio);
  formData.append('picture', picture);
  formData.append('data', JSON.stringify(data));
  const url = `/tracks/${data.id}`;
  const options = {
    method: 'PUT',
    body: formData,
  };
  return fetchRequest(url, options);
}

export async function deleteTrackById(id) {
  const url = `/tracks/${id}`;
  const options = {
    method: 'DELETE',
  };
  return fetchRequest(url, options);
}
