/* eslint-disable no-unused-vars */
import {
  NewsIcon,
  OfferIcon,
  TrackIcon,
  PlaylistIcon,
  PodcastIcon,
  ProgramIcon,
  EmceeIcon,
  GuestIcon,
  BannerIcon,
  SEOIcon,
  SlideIcon,
  SettingIcon,
  AboutIcon,
  StreamsIcon,
  BackIcon,
  UsersIcon,
  QuizIcon
} from './icons';
import {
  ADMIN_STREAMS_A_ROUTE,
  ADMIN_STREAMS_V_ROUTE,
  ADMIN_BANNERS_ROUTE,
  ADMIN_EMCEES_ROUTE,
  ADMIN_GUESTS_ROUTE,
  ADMIN_NEWS_ROUTE,
  ADMIN_OFFERS_ROUTE,
  ADMIN_PLAYLISTS_ROUTE,
  ADMIN_PODCASTS_ROUTE,
  ADMIN_PROGRAMS_ROUTE,
  ADMIN_SEO_ROUTE,
  ADMIN_SETTING_ROUTE,
  ADMIN_ABOUT_ROUTE,
  ADMIN_SLIDES_ROUTE,
  ADMIN_TRACKS_ROUTE, 
  ADMIN_CHART_ROUTE,
  ADMIN_GO_TO_WEBSITE,
  ADMIN_USERS_ROUTE,
  ADMIN_NOVELTY_ROUTE,
  ADMIN_APPLICANT_ROUTE,
  ADMIN_QUIZ_ROUTE
} from './routes';

const menu = [
  {
    key: 'tracks',
    name: 'Треки',
    icon: TrackIcon(),
    link: ADMIN_TRACKS_ROUTE,
    newPage: false,
  },
  {
    key: 'chart',
    name: 'Чарт',
    icon: TrackIcon(),
    link: ADMIN_CHART_ROUTE,
    newPage: false,
  },
  {
    key: 'novelty',
    name: 'Новинки',
    icon: TrackIcon(),
    link: ADMIN_NOVELTY_ROUTE,
    newPage: false,
  },
  {
    key: 'applicant',
    name: 'Претенденты',
    icon: TrackIcon(),
    link: ADMIN_APPLICANT_ROUTE,
    newPage: false,
  },
  {
    key: 'offers',
    name: 'Акции',
    icon: OfferIcon(),
    link: ADMIN_OFFERS_ROUTE,
    newPage: false,
  },
  {
    key: 'podcasts',
    name: 'Подкасты',
    icon: PodcastIcon(),
    link: ADMIN_PODCASTS_ROUTE,
    newPage: false,
  },
  {
    key: 'news',
    name: 'Новости',
    icon: NewsIcon(),
    link: ADMIN_NEWS_ROUTE,
    newPage: false,
  },
  {
    key: 'programs',
    name: 'Программы',
    icon: ProgramIcon(),
    link: ADMIN_PROGRAMS_ROUTE,
    newPage: false,
  },
  {
    key: 'emcees',
    name: 'Ведущие',
    icon: EmceeIcon(),
    link: ADMIN_EMCEES_ROUTE,
    newPage: false,
  },
  {
    key: 'quiz',
    name: 'Опросники',
    icon: QuizIcon(),
    link: ADMIN_QUIZ_ROUTE,
    newPage: false,
  },
  // {
  //   key: 'playlists',
  //   name: 'Плейлисты',
  //   icon: PlaylistIcon(),
  //   link: ADMIN_PLAYLISTS_ROUTE,
  // },
  {
    key: 'settings',
    name: 'Настройки',
    icon: SettingIcon(),
    link: ADMIN_SETTING_ROUTE,
    newPage: false,
  },
  {
    key: 'about',
    name: 'О радио',
    icon: AboutIcon(),
    link: ADMIN_ABOUT_ROUTE,
    newPage: false,
  },
  // {
  //   key: 'guests',
  //   name: 'Гости',
  //   icon: GuestIcon(),
  //   link: ADMIN_GUESTS_ROUTE,
  // },
  {
    key: 'stream',
    name: 'Аудио-стримы',
    icon: StreamsIcon(),
    link: ADMIN_STREAMS_A_ROUTE,
    newPage: false,
  },
  {
    key: 'stream',
    name: 'Видео-стримы',
    icon: StreamsIcon(),
    link: ADMIN_STREAMS_V_ROUTE,
    newPage: false,
  },
  {
    key: 'banners',
    name: 'Баннеры',
    icon: BannerIcon(),
    link: ADMIN_BANNERS_ROUTE,
    newPage: false,
  },
  // {
  //   key: 'seo',
  //   name: 'SEO данные',
  //   icon: SEOIcon(),
  //   link: ADMIN_SEO_ROUTE,
  // },
  {
    key: 'slides',
    name: 'Слайды',
    icon: SlideIcon(),
    link: ADMIN_SLIDES_ROUTE,
    newPage: false,
  },
  {
    key: 'users',
    name: 'Список пользователей',
    icon: UsersIcon(),
    link: ADMIN_USERS_ROUTE,
    newPage: false,
  },
  {
    key: 'exit',
    name: 'Перейти на сайт',
    icon: BackIcon(),
    link: ADMIN_GO_TO_WEBSITE,
    newPage: true,
  },
];

export default menu;
