import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import PapperBlock from '../../../components/PapperBlock';
import BannersForm from '../BannersForm';
import { getBannerById, updateBannerById } from '../requests';
import { BannerIcon } from '../../../constants/icons';
import { ADMIN_BANNERS_ROUTE } from '../../../constants/routes';

export default function UpdateBanners() {
  const [dataBanner, setDataBanner] = useState({});
  const [loaded, setLoaded] = useState(false);
  const params = useParams();
  const { id } = params;
  const history = useHistory();

  async function processingRequest() {
    setDataBanner((await getBannerById(id)).data);
    setLoaded(true);
  }

  useEffect(() => {
    processingRequest();
  }, []);

  const submitHandler = async (data, picture) => {
    const response = await updateBannerById(data, picture);
    if (response.ok) {
      history.push(ADMIN_BANNERS_ROUTE);
    }
  };

  return (
    <PapperBlock title="" icon={<BannerIcon />}>
      {loaded && (
        <BannersForm data={dataBanner} submitHandler={submitHandler} />
      )}
    </PapperBlock>
  );
}
