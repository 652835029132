import React, { useState, useEffect } from 'react';
import MetaTags from 'react-meta-tags';
import BlockContent from '../../components/BlockContent';
import BlockEmceesItem from '../../components/BlockEmceesItem';
import BlockChart from '../../components/BlockChart';
import ItemChart from '../../containers/ItemChart';
import PlaylistContainer from '../../components/PlaylistContainer';
import BannerWidget from '../../components/BannerWidget';
import SvgEmcees from '../../components/Svg/SvgEmcees';
import {
  getAllEmcees,
  getPlaylistChart,
  getNoveltyChart,
  getCurrentBanner,
  getChartTimestamp,
} from './requests';
import './index.css';
import setLocalStorageForLikes from '../../helpers/setLocalStorageForLikes';
import {PLAYLIST, NOVELTY, NOVELTY_ICON, PLAYLIST_ICON } from '../../constants/routes';
import {useSelector} from 'react-redux';

export default function Emcees() {
  const [emcees, setEmcees] = useState([]);
  const [bannerData, setBannerData] = useState(null);
  const [playlistChartData, setPlaylistChartData] = useState({});
  const [noveltyChartData, setNoveltyChartData] = useState({});
  const [activeIndex, setActiveIndex] = useState(null);
  const [ratedTracks, setRatedTracks] = useState([]);
  const [likedTracks, setLikedTracks] = useState([]);
  const {playlistLastSong} = useSelector((state) => state);

  const setLocalStorage = async () => {
    const timestamp = ((await getChartTimestamp()).data);
    const [actualRatedTracks, actualLikedTracks] = setLocalStorageForLikes(timestamp.timestamp);
    setRatedTracks([...actualRatedTracks]);
    setLikedTracks([...actualLikedTracks]);
  }

  const processingRequest = async () => {
    setEmcees((await getAllEmcees()).data);
    setPlaylistChartData((await getPlaylistChart()).data);
    setNoveltyChartData((await getNoveltyChart()).data);
    setBannerData((await getCurrentBanner()).data);
    await setLocalStorage();
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    processingRequest();
  }, []);

  // const rerenderPlaylist = async () => {
  //   setPlaylistChartData((await getPlaylistChart()).data);
  // }

  useEffect(() => {
      if (playlistLastSong.id && playlistChartData.id) {
        setPlaylistChartData(
          {
            ...playlistChartData, 
            track_list: [
              {
                ...playlistLastSong, 
                play_date: new Date().toISOString(),
                play_time: new Date().toISOString(),
              }, 
              ...playlistChartData.track_list
            ]
          }
        );
      }
  }, [playlistLastSong]);
  return (
    <div className="page-emcees">
      <BlockContent>
        <MetaTags>
          <title>Ведущие - Новое Радио</title>
          <meta name="description" content="Ведущие - Новое Радио" />
        </MetaTags>
        <div className="big-column">
          <div className="emcees-row">
            <div className="emcees-row__icon">
              <SvgEmcees />
            </div>
            <div className="emcees-row__title">Ведущие</div>
          </div>
          <div class = "emcees-content">
          {emcees.map((el, index) => (
            <div
              key={el.name}
              onClick={() => {
                if (activeIndex === index) {
                  return setActiveIndex(null);
                }
                return setActiveIndex(index);
              }}
              className="emcee-item"
            >
              <BlockEmceesItem
                active={activeIndex === index}
                name={el.name}
                img={el.avatar}
                back_img={el.back_img}
                description={el.description}
                programs={el.programs}
              />
            </div>
          ))}
          </div>
        </div>
        <div className="small-column__chart ">
          {playlistChartData && (
            <PlaylistContainer
                chartData={playlistChartData.track_list}
                title={playlistChartData.title}
                link={PLAYLIST}
                icon={PLAYLIST_ICON}
                countViewTracks={4}
                additionalClass = {"playlist"}
                children={playlistChartData.track_list}
                type={"playlistChart"}
                color={null}
              />
          )}
          {noveltyChartData.length > 0 && (
            <PlaylistContainer
                chartData={noveltyChartData}
                title={"Новинки"}
                link={NOVELTY}
                icon={NOVELTY_ICON}
                countViewTracks={4}
                additionalClass = {"new_songs"}
                children={noveltyChartData}
                type={"noveltyChart"}
                color="yellow"
              />
          )}
          {bannerData && bannerData.map((elem) => (
            <BannerWidget img={elem.picture} redirect={elem.link}/>
          ))}
        </div>
      </BlockContent>
    </div>
  );
}
