/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useCallback, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import {
  changeAudioFlowAction,
  changeAudioPlaybackAction,
  changeFlowAction,
  pauseAudioPlaybackAction,
  playAudioPlaybackAction,
} from '../../redux/actions';
import SvgLike from '../../components/Svg/SvgLike';
import SvgPause from '../../components/Svg/SvgPause';
import Animation from '../../helpers/animation';
import { rateTrack, getChartTimestamp } from './requests';
import './index.css';
import ChartPosition from '../../components/ChartPosition';

const numToWeek = {
  0: 'недель',
  1: 'неделя',
  2: 'недели',
  3: 'недели',
  4: 'недели',
  5: 'недель',
  6: 'недель',
  7: 'недель',
  8: 'недель',
  9: 'недель',
};

const setWeekLabel = (num) => {
  if (num === 0) {
    return 'Новинка';
  }
  if (num > 9 && num < 20) {
    return 'недель';
  }
  const getLastNum = num % 10;
  return numToWeek[getLastNum];
};

function Chart({
  trackId,
  playTime,
  number,
  index,
  img,
  audio,
  title,
  author,
  info,
  weeks,
  rate,
  position,
  color,
  flow,
  changeFlow,
  changeAudioFlow,
  playAudioPlayback,
  pauseAudioPlayback,
  audioPlayback,
  // ratedTracks,
  isTrackRated,
  currentPosition,
  lastPosition,
  chartDifference,
  onClickHandler,
  name
}) {
  const [isRate, setIsRate] = useState(isTrackRated);
  useEffect(() => {
    setIsRate(isTrackRated);
  }, [isTrackRated])
  const bgColor = index % 2 !== 0 ? 'grey' : 'white';
  const isActive = audioPlayback
    && JSON.stringify(flow) === JSON.stringify({ title, author });

  const today = Date.now();
  const week = 7 * 24 * 60 * 60 * 1000;
  const calcWeeks = () => {
    const currentWeeksAmount = Math.trunc((today - weeks) / week);
    return currentWeeksAmount ? `${currentWeeksAmount} ${setWeekLabel(currentWeeksAmount)} в чарте` : 0;
  };
  const onRateTrack = async () => {
    let storage = localStorage.getItem('ratedTracks');
    const date = new Date().toISOString();
    if (!storage.length ) {
      storage = [{date, trackId, title, author}]
    } else if (typeof storage != "array") {
      storage = JSON.parse(localStorage.getItem('ratedTracks'));
      storage.push({
        date, trackId, title, author,
      });
    } else {
    storage.push({
      date, trackId, title, author,
    });
  }
    localStorage.setItem('ratedTracks', JSON.stringify(storage));
    await rateTrack(trackId);
  };
  return (
    <div className={`chart_container ${bgColor} ${
      isActive ? 'active track-animation' : null
    } ${color}` }
    name={name}
    >
    <div
      className={`chart-item ${ !audio ? 'no-audio' : ''} `}
    >
      <div
        // style={{ paddingRight: rate ? '42px' : '0' }}
        className={`chart-item__content`}
        onClick={(event) => {
          document.getElementsByTagName("audio")[0].onended=function(){
            pauseAudioPlayback();
            onClickHandler(event);
          };
          changeAudioFlow(audio);
          if (isActive) {
            return pauseAudioPlayback();
          }
          playAudioPlayback();
          return changeFlow({
            title, author,
          });
        }}
      >
        {number ? (
          <div className="chart-item__number">
            {number}
          </div>
        ) : (
          <div className={playTime && 'chart-item__number'}>
            {playTime}
          </div>
        )}

        <div className="chart-item__preview-container">
          {isActive && (
            <div className="chart-item__control">
              <SvgPause />
            </div>
          )}
          <img
            className={
              number ? 'chart-item__preview_img-number' : 'chart-item__preview'
            }
            src={img ? img : '/uploads/images/tracks/novoe_radio_placeholder.jpeg'}
            alt=""
          />
        </div>
        <div className="chart-item__info">
          <div className="track-info">
            <span className="track-info__name" title={title}>{title}</span>
            <span className="track-info__author" title={author}>{author}</span>
            {/* {weeks ? <span className="chart-item__week mobile">{calcWeeks() || 'Новинка'}</span> : <span className="chart-item__week mobile" />} */}
          </div>
          <div className="chart-item__other-info">
          {weeks ? <span className="chart-item__week desktop">{calcWeeks() || 'Новинка'}</span> : <span className="chart-item__week desktop" />}
            <div className="chart-item__chart-position">
              <ChartPosition currentPosition={currentPosition} lastPosition={lastPosition} chartDifference={chartDifference}/>
            </div>
          </div>
        </div>
      </div>
    </div>
    {rate && (
          <div
            className={`chart-item__like ${isRate ? 'liked' : ''}`}
            aria-hidden
            onClick={(event) => {
              if (!isRate) {
                setTimeout(() => {
                  setIsRate(true);
                }, 250);
                onRateTrack();
                new Animation(event).button();
              }
            }}
          >
            <SvgLike />
          </div>
        )}
    </div>
  );
}

Chart.propTypes = {
  number: PropTypes.number,
  playTime: PropTypes.string,
  index: PropTypes.number,
  img: PropTypes.string,
  audio: PropTypes.string,
  author: PropTypes.string,
  title: PropTypes.string,
  info: PropTypes.string,
  rate: PropTypes.bool,
  position: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
  color: PropTypes.oneOf(['green', 'yellow', 'purple']),
  flow: PropTypes.objectOf(PropTypes.string).isRequired,
  changeFlow: PropTypes.func,
  changeAudioFlow: PropTypes.func,
  playAudioPlayback: PropTypes.func.isRequired,
  pauseAudioPlayback: PropTypes.func.isRequired,
  audioPlayback: PropTypes.bool.isRequired,
};

Chart.defaultProps = {
  number: 0,
  playTime: '',
  index: 0,
  img: '',
  audio: '',
  author: '',
  title: '',
  info: '',
  rate: false,
  position: {},
  color: 'green',
  changeFlow: () => {},
  changeAudioFlow: () => {},
};

const mapStateToProps = (state) => ({
  flow: state.flow,
  playback: state.playback,
  audioFlow: state.audioFlow,
  audioPlayback: state.audioPlayback,
  // ratedTracks: state.ratedTracks,
});

const mapDispatchToProps = {
  changeFlow: changeFlowAction,
  changeAudioFlow: changeAudioFlowAction,
  changeAudioPlayback: changeAudioPlaybackAction,
  playAudioPlayback: playAudioPlaybackAction,
  pauseAudioPlayback: pauseAudioPlaybackAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Chart);
