import React, { useEffect, useState } from 'react';
import PapperBlock from '../../components/PapperBlock';
import DataTable from '../../components/DataTable';
import { getSeoForTable } from './requests';
import { SEOIcon } from '../../constants/icons';
import Columns from './Columns';

function SEOPage() {
  const [data, setData] = useState([]);
  async function processingRequest() {
    setData((await getSeoForTable()).data);
  }
  useEffect(() => {
    processingRequest();
  }, []);

  return (
    <PapperBlock title="SEO" icon={<SEOIcon />}>
      <DataTable data={data} columns={Columns} />
    </PapperBlock>
  );
}

export default SEOPage;
