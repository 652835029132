'use strict'

import React, { useEffect, useState } from 'react';
import PropTytes from 'prop-types';
import { Button, TextField, withStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DataTable from '../../components/DataTable';
import PapperBlock from '../../components/PapperBlock';
import {
  addChartTrackById, getChartTracks, getNotChartTracks, resetChartRating, updateChartTracks, writeChartTimestamp, deleteChartTrack
} from './requests';
import Columns from './Columns';
import styles from '../../styles';
import {maxFileSize} from "../../constants/additional";

function TracksPage({ classes }) {
  const [tracks, setTracks] = useState([]);
  const [notInChartTracks, setNotInChartTracks] = useState([]);
  const [selectValue, setSelectValue] = useState({ author: '', title: '' });
  const [weeksValue, setWeeksValue] = useState({});
  const [placeValue, setPlaceValue] = useState({});

  const today = Date.now();
  const week = 7 * 24 * 60 * 60 * 1000;

  const options = {
    rowsPerPageOptions: [20,40,80],
  };

  const initialWeeksValues = () => {
    let weeksForTable = {};
    let weekInChart = 0;
    tracks.forEach((track, index) => {
      weekInChart = track.weeks > 0 ? Math.trunc((today - track.weeks) / week) : 0; //(new Date()).getTime()
      weeksForTable = { ...weeksForTable, [index]: weekInChart };
    });
    return weeksForTable;
  };

  const initialPlacesValues = () => {
    let placesForTable = {};
    tracks.forEach((track, index) => {
      placesForTable = { ...placesForTable, [index]: track.chartDifference ? track.chartDifference : 0 };
    });
    return placesForTable;
  };

  const processingRequest = async () => {
    setTracks((await getChartTracks()).data);
    setNotInChartTracks((await getNotChartTracks()).data);
  };

  useEffect(async () => {
    await processingRequest();
  }, []);

  useEffect(() => {
    const weeksValues = initialWeeksValues();
    setWeeksValue(weeksValues);
  }, [tracks]);

  useEffect(() => {
    const placesValues = initialPlacesValues();
    setPlaceValue(placesValues);
  }, [tracks]);

  const addTrackToChart = async () => {
    await addChartTrackById(selectValue.id);
    setTracks((await getChartTracks()).data);
    setSelectValue({ author: '', title: '' });
    setNotInChartTracks((await getNotChartTracks()).data);
  };
  const weeksInputHandler = (rowIndex, value) => {
    setWeeksValue((prevState) => ({ ...prevState, [rowIndex]: value }));
  };

  const placeInputHandler = (rowIndex, value) => {
    setPlaceValue((prevState) => ({ ...prevState, [rowIndex]: value }));
    // tracks = [...tracks, {tracks[rowIndex]}]
    // console.log(tracks[rowIndex])
    // setWeeksValue((prevState) => ({ ...prevState, [rowIndex]: value }));
  };

  const moveTrack = (index, direction) => {
    const movedTracks = [...tracks];
    if (direction) {
      console.log('direction', direction);
      const upwardTrack = movedTracks[index - 1];
      console.log('upwardTrack', upwardTrack);
      movedTracks[index - 1] = movedTracks[index];
      movedTracks[index - 1].currentPosition = index;
      movedTracks[index - 1].chartDifference = movedTracks[index - 1].lastPosition - movedTracks[index - 1].currentPosition;
      movedTracks[index] = upwardTrack;
      movedTracks[index].currentPosition = index + 1;
      movedTracks[index].chartDifference = movedTracks[index].lastPosition - movedTracks[index].currentPosition;
    } else {
      console.log('direction', direction);
      const downwardTrack = movedTracks[index + 1];
      console.log('downwardTrack', downwardTrack);
      movedTracks[index + 1] = movedTracks[index];
      movedTracks[index + 1].currentPosition = index + 2;
      movedTracks[index + 1].chartDifference = movedTracks[index + 1].lastPosition - movedTracks[index + 1].currentPosition;
      movedTracks[index] = downwardTrack;
      movedTracks[index].currentPosition = index + 1;
      movedTracks[index].chartDifference = movedTracks[index].lastPosition - movedTracks[index].currentPosition;
    }
    setTracks(movedTracks);
  };

  const changeChartDifference = (elemIndex, value) => {
    const valueNum = Number.parseInt(value)
    const chart = [...tracks];
    if (valueNum % 1 != 0) {
      alert("Значение не может быть дробным");
      return;
    }
    chart[elemIndex].chartDifference = valueNum;
    setTracks(chart);
    // const valueNum = Number.parseInt(value)
    // // const movedTracks = [...tracks];
    // let tracksCopy = [...tracks];
    // let movedTracks = [...tracks];
    // console.log(elemIndex, valueNum);
    // if (valueNum % 1 != 0) {
    //   alert("Значение не может быть дробным");
    //   return;
    // }

    // if (valueNum == 0) {
    //   alert("А смысл сдвигать трек на 0 позиций?");
    //   return;
    // }

    // if (valueNum < 0) {
    //   if(elemIndex + 1 + Math.abs(valueNum) > tracksCopy.length) {
    //     alert("Вышли за нижнюю границу списка");
    //     return;
    //   }
    //   let trackCounter = elemIndex;
    //   while (trackCounter < elemIndex + Math.abs(valueNum)) {
    //     const modifiedTrack = movedTracks[trackCounter + 1];
    //     movedTracks[trackCounter + 1] = movedTracks[trackCounter];
    //     movedTracks[trackCounter + 1].currentPosition = trackCounter + 2;
    //     movedTracks[trackCounter] = modifiedTrack;
    //     movedTracks[trackCounter].currentPosition = trackCounter + 1;
    //     trackCounter++;
    //   }

    // } else {
    //   if (elemIndex + 1 - Math.abs(valueNum) < 0) {
    //     alert("Вышли за верхнюю границу списка");
    //     return;
    //   }

    //   let trackCounter = elemIndex;
    //   while (trackCounter > elemIndex - Math.abs(valueNum)) {
    //     const modifiedTrack = movedTracks[trackCounter - 1];
    //     movedTracks[trackCounter - 1] = movedTracks[trackCounter];
    //     movedTracks[trackCounter - 1].currentPosition = trackCounter;
    //     movedTracks[trackCounter] = modifiedTrack;
    //     movedTracks[trackCounter].currentPosition = trackCounter + 1;
    //     trackCounter--;
    //   }
    // }

    // setTracks(movedTracks);



    // movedTracks[elemIndex].lastPosition = Number.parseInt(movedTracks[elemIndex].currentPosition);
    // movedTracks[elemIndex].currentPosition = Number.parseInt(value);
    // let trackCounter = 0;
    // while (trackCounter < movedTracks.length) {
    //   trackCounter++;
    // } 
  }

  const deleteButtonHandler = async (index) => {
    if(window.confirm('Вы уверены, что хотите удалить трек из чарта?')){
      const changedTracks = tracks.map((track, i) => {
        if (i === index) {
          return ({
            ...track,
            currentPosition: 0,
            lastPosition: 0,
            userLastPosition: 0,
            weeks: today,
            applicant: false,
          });
        }
        if (i > index) {
          return ({
            ...track,
            currentPosition: track.currentPosition - 1,
            lastPosition: track.currentPosition - 1,
            userLastPosition: track.currentPosition - 1,
          });
        }
        return track;
      });
      const tracksToDisplay = changedTracks.filter((item, i) => i !== index);
      const trackToRemove = changedTracks.filter((item, i) => i === index)
      setTracks(tracksToDisplay);
      console.log("trackToRemove", trackToRemove);
      console.log("tracksToDisplay", tracksToDisplay);
      await deleteChartTrack(trackToRemove[0]);
    }
  };

  const resetButtonHandler = async () => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm('Сбросить голоса чарта?')) {
      await resetChartRating();
      const date = {"timestamp": new Date(Date.now()).toISOString()};
      console.log(date);
      await writeChartTimestamp(date);
      setTracks((await getChartTracks()).data);
    }
  };

  const saveChangesHandler = async () => {
    const updatedTracks = tracks.map((track, index) => ({
      ...track,
      weeks: (today - +weeksValue[index] * week).toString(),
      userLastPosition: track.lastPosition,
      lastPosition: track.currentPosition,
    }));
    const result = (await updateChartTracks(updatedTracks)).data;
    alert(result.message);
    await processingRequest();
      // .then(async () => setTracks((await getChartTracks()).data));

  };

  return (
    <PapperBlock title="Чарт">
      <div className={classes.field}>
        <div className={classes.title}>Треки:</div>
        <Autocomplete
          id="checkboxes-tags-demo"
          options={notInChartTracks}
          getOptionLabel={(option) => `${option.author} ${option.author && '-'} ${option.title}`}
          value={selectValue}
          style={{ width: '100%', marginBottom: '5px' }}
          onChange={(event, value) => {
            setSelectValue(value);
          }}
          renderInput={(arg) => {
            const params = arg;
            params.InputProps.startAdornment = undefined;
            return (
              <TextField
                fullWidth
                InputLabelProps={params.InputLabelProps}
                InputProps={params.InputProps}
                id={params.id}
                inputProps={params.inputProps}
              />
            );
          }}
        />
        <Button onClick={() => addTrackToChart()} variant="contained" color="primary" className={classes.field}>Добавить трек</Button>
      </div>
      <DataTable
        columns={Columns}
        data={tracks}
        weeksInputHandler={weeksInputHandler}
        moveTrack={moveTrack}
        weeksValue={weeksValue}
        deleteHandler={deleteButtonHandler}
        placeValue={placeValue}
        placeInputHandler={placeInputHandler}
        changeChartDifference={changeChartDifference}
        options={options}
      />
      <div style={{ display: 'flex' }}>
        <Button variant="contained" color="default" className={classes.field} onClick={resetButtonHandler}>Сбросить голоса</Button>
        <Button variant="contained" color="primary" className={classes.field} onClick={saveChangesHandler}>Сохранить</Button>
      </div>
    </PapperBlock>
  );
}

export default withStyles(styles)(TracksPage);

TracksPage.propTypes = {
  classes: PropTytes.object,
};

TracksPage.defaultProps = {
  classes: {},
};
