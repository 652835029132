import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextField, Button, withStyles } from '@material-ui/core';
import styles from '../../styles';

function SEOForm({ classes, data, submitHandler }) {
  const [title, setTitle] = useState(data.title);
  const [description, setDescription] = useState(data.description);
  const [keyWords, setKeyWords] = useState(data.key_words);
  const dispatchHandler = (event) => {
    event.preventDefault();

    const ifOk = confirm('Вы уверены что хотите сохранить изменения?');
    if (ifOk) {
      // eslint-disable-next-line no-return-await
      submitHandler(data.id, { title, description, keyWords });
    } else {
      history.back();
    }
  };

  return (
    <form onSubmit={dispatchHandler}>
      <div>
        <div className={classes.title}>Заголовок:</div>
        <TextField
          value={title}
          onChange={(event) => {
            setTitle(event.target.value);
          }}
          className={classes.field}
        />
      </div>
      <div className={classes.field}>
        <div className={classes.title}>Описание:</div>
        <TextField
          value={description}
          onChange={(e) => {
            setDescription(e.target.value);
          }}
          className={classes.field}
        />
      </div>
      <div className={classes.field}>
        <div className={classes.title}>Ключевые слова:</div>
        <TextField
          value={keyWords}
          onChange={(e) => {
            setKeyWords(e.target.value);
          }}
          className={classes.field}
        />
      </div>
      <Button variant="contained" color="primary" type="submit" fullWidth>
        Обновить
      </Button>
    </form>
  );
}

export default withStyles(styles)(SEOForm);

SEOForm.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  submitHandler: PropTypes.func,
};

SEOForm.defaultProps = {
  classes: {},
  data: {
    id: undefined,
    title: '',
    description: '',
    key_words: '',
  },
  submitHandler: PropTypes.func,
};
