/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef, lazy, Suspense } from 'react';
import MetaTags from 'react-meta-tags';
import BlockContent from '../../components/BlockContent';
import BlockChart from '../../components/BlockChart';
import QuizWidget from '../../components/QuizWidget';
import ItemChart from '../../containers/ItemChart';
// import PlaylistContainer from '../../components/PlaylistContainer';
import {
  getPlaylistChart,
  getCurrentBanner,
  getLatestNews,
  getChartTracks,
  getNoveltyChart,
  getChartTimestamp,
  getPollData,
  getAllActiveQuizes
} from './requests';
// import CarouselWidget from '../../components/CarouselWidget';
// import BannerWidget from '../../components/BannerWidget';
import './index.css';
import Chart from '../../containers/Chart';
import setLocalStorageForLikes from '../../helpers/setLocalStorageForLikes';
const CarouselWidget = lazy(() => import('../../components/CarouselWidget'));
const BannerWidget = lazy(() => import('../../components/BannerWidget'));
const PlaylistContainer = lazy(() => import('../../components/PlaylistContainer'));
import {useSelector} from 'react-redux';
import {MUSIC, PLAYLIST, NOVELTY, NOVELTY_ICON, PLAYLIST_ICON, MUSIC_ICON, QUIZ_ICON } from '../../constants/routes';

export default function Main() {
  const [playlistChartData, setPlaylistChartData] = useState({});
  const [noveltyChartData, setNoveltyChartData] = useState([]);
  const [bannerData, setBannerData] = useState(null);
  const [sliderNewsData, setSliderNewsData] = useState(null);
  const [chartData, setChartData] = useState([]);
  const [ratedTracks, setRatedTracks] = useState([]);
  const [likedTracks, setLikedTracks] = useState([]);
  const [poll, setPoll] = useState([]);
  const [quizes, setQuizes] = useState([])
  const {playlistLastSong} = useSelector((state) => state);

  const setLocalStorage = async () => {
    const timestamp = ((await getChartTimestamp()).data);
    const [actualRatedTracks, actualLikedTracks] = setLocalStorageForLikes(timestamp.timestamp);
    setRatedTracks([...actualRatedTracks]);
    setLikedTracks([...actualLikedTracks]);
  }

  async function processingRequest() {
    setChartData((await getChartTracks()).data);
    setPlaylistChartData((await getPlaylistChart()).data);
    setNoveltyChartData((await getNoveltyChart()).data);
    setBannerData((await getCurrentBanner()).data);
    setSliderNewsData((await getLatestNews()).data);
    setPoll((await getPollData()).data);
    setQuizes((await getAllActiveQuizes()).data);
    await setLocalStorage();
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    processingRequest(); 
  }, []);

  // const asyncGetPlaylistChartData = async () => {
  //   setPlaylistChartData((await getPlaylistChart()).data);
  // }

  // const changePlayList = (last, tracks) => {
    
  //   tracks.pop();
  //   tracks.unshift(last);
  //   console.log("tracks", tracks)
  //   return tracks;
  // }

  useEffect(() => {
      if (playlistLastSong.id && playlistChartData.id) {
        setPlaylistChartData(
          {
            ...playlistChartData, 
            track_list: [
              {
                ...playlistLastSong, 
                play_date: new Date().toISOString(),
                play_time: new Date().toISOString(),
              }, 
              ...playlistChartData.track_list
            ]
          }
        );
      }
  }, [playlistLastSong]);

  useEffect(() => {
    if (!document.querySelector(`body script[src="${poll?.content}"]`) && poll.active) {
      const script = document.createElement("script");

      script.src = poll?.content;
      script.async = true;

      document.querySelector("body").appendChild(script);
    }
  }, [poll]);

  return (
    <div className="page-main">
      <MetaTags>
        <title>Новое Радио</title>
        <meta name="description" content="Главная - Новое Радио" />
      </MetaTags>
      <BlockContent left>
        <div className="big-column__chart">
          <Suspense fallback={<p>Loading</p>}>
            <PlaylistContainer
              chartData={chartData}
              title={"Улетный чарт"}
              link={MUSIC}
              icon={MUSIC_ICON}
              countViewTracks={10}
              additionalClass = {"flyaway-chart"}
              children={chartData.track_list}
              type={"chart"}
              color={null}
              moreBtn={true}
            />
          </Suspense>
        </div>
        <div className="small-column__chart">
        {playlistChartData && (
          <Suspense fallback={<p>Loading</p>}>
            <PlaylistContainer
                chartData={playlistChartData.track_list}
                title={playlistChartData.title}
                link={PLAYLIST}
                icon={PLAYLIST_ICON}
                countViewTracks={4}
                additionalClass = {"playlist"}
                children={playlistChartData.track_list}
                type={"playlistChart"}
                color={null}
              />
          </Suspense>
        )}
          {noveltyChartData && (
            <Suspense fallback={<p>Loading</p>}>
              <PlaylistContainer
                  chartData={noveltyChartData}
                  title={"Новинки"}
                  link={NOVELTY}
                  icon={NOVELTY_ICON}
                  countViewTracks={4}
                  additionalClass = {"new_songs"}
                  children={noveltyChartData}
                  type={"noveltyChart"}
                  color="yellow"
                />
            </Suspense>
          )}
        </div>
      </BlockContent>

      {quizes.length > 0 && (
        <BlockContent>
          <div className="quiz-container">
            <div class="chart-info">
              <img src={QUIZ_ICON} class="chart-info__icon" alt="" />
              <span class="chart-info__title">Опрос</span>
            </div>
            {/* {console.log("quizes", quizes)} */}
            {quizes.map((quiz) => {
              return (
                <QuizWidget elem={quiz}/> //buttonClickHandler={async () => {setQuizes((await getAllActiveQuizes()).data)}}
              )
            })}
          </div>
        </BlockContent>
      )}
      
      <BlockContent className="footer-block">
        <div className="big-column">
          <div class = "slider-padding">
          <Suspense fallback={<p>Loading</p>}>
            <CarouselWidget sliderTitle="Что нового" data={sliderNewsData}/>
          </Suspense>
          </div>
        </div>
        <div className="small-column">
          {bannerData && bannerData.map((elem) => (
          <Suspense fallback={<p>Loading</p>}>
            <BannerWidget img={elem.picture} redirect={elem.link}/>
          </Suspense>
          ))}
        </div>
      </BlockContent>
      {/* {poll && poll.active && 
      <script async src={poll.content}></script>} */}
    </div>
  );
}
