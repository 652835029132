import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import PapperBlock from '../../../components/PapperBlock';
import PlaylistsForm from '../PlaylistsForm';
import {
  getPlaylistById,
  getTracksForForm,
  updatePlaylistById,
} from '../requests';
import { PlaylistIcon } from '../../../constants/icons';
import { ADMIN_PLAYLISTS_ROUTE } from '../../../constants/routes';

export default function UpdatePlaylists() {
  const [tracksList, setTracksList] = useState([]);
  const [playlistData, setPlaylistData] = useState({});
  const [loaded, setLoaded] = useState(false);
  const params = useParams();
  const { id } = params;
  async function processingRequest() {
    setPlaylistData((await getPlaylistById(id)).data);
    setTracksList((await getTracksForForm()).data);
    setLoaded(true);
  }
  const history = useHistory();
  const submitHandler = async (data, icon) => {
    const response = await updatePlaylistById(data, icon);
    if (response.ok) {
      history.push(ADMIN_PLAYLISTS_ROUTE);
    }
  };
  useEffect(() => {
    processingRequest();
  }, []);

  return (
    <PapperBlock title="Обновить плейлист" icon={<PlaylistIcon />}>
      {loaded && (
        <PlaylistsForm
          data={playlistData}
          tracks={tracksList}
          submitHandler={submitHandler}
        />
      )}
    </PapperBlock>
  );
}
