import fetchRequest from '../../helpers/fetchRequest';

const PODCASTS = '/podcasts';
const PROGRAMS_FOR_FORM = '/programs-form';

export async function createPodcast(data, audio, picture) {
  const formData = new FormData();
  formData.append('audio', audio);
  formData.append('picture', picture);
  formData.append('data', JSON.stringify(data));
  const url = PODCASTS;
  const options = {
    method: 'POST',
    body: formData,
  };
  return fetchRequest(url, options);
}

export async function getPodcastsForTable() {
  const url = PODCASTS;
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function getPodcastById(id) {
  const url = `${PODCASTS}/${id}`;
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function updatePodcastById(data, audio, picture) {
  const formData = new FormData();
  formData.append('audio', audio);
  formData.append('picture', picture);
  formData.append('data', JSON.stringify(data));
  const url = `${PODCASTS}/${data.id}`;
  const options = {
    method: 'PUT',
    body: formData,
  };
  return fetchRequest(url, options);
}

export async function deletePodcastById(id) {
  const url = `${PODCASTS}/${id}`;
  const options = {
    method: 'DELETE',
  };
  return fetchRequest(url, options);
}

export async function getProgramsForForm() {
  const url = PROGRAMS_FOR_FORM;
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}
