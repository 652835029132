const location = window.location.origin;

export const getDomain = () => {
  if (process.env.NODE_ENV === 'production') {
    return location;
  }
  return 'http://localhost:3002';
};

const api = `${getDomain()}/api/v2/client`;

export default async function fetchRequest(url, options) {
  try {
    const response = await fetch(`${api}${url}`, options);
    const responseData = await response.json();
    return {
      status: response.status,
      data: await responseData,
      ok: response.ok,
    };
  } catch (error) {
    return { status: 500, ok: false, data: null };
  }
}
