import React from 'react';
import PropTypes from 'prop-types';
import './index.css';

export default function BlockContent({
  children, className, left, right,
}) {
  const isPadding = window.innerWidth > 1200;
  return (
    <div
      className={`content-block ${className}`}
      // style={{
      //   paddingLeft: left && isPadding ? '25px' : 'auto',
      //   paddingRight: right && isPadding ? '25px' : 'auto',
      // }}
    >
      {children}
    </div>
  );
}

BlockContent.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  left: PropTypes.bool,
  right: PropTypes.bool,
};

BlockContent.defaultProps = {
  children: <div />,
  className: '',
  left: false,
  right: false,
};
