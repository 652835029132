export const MAIN = '/';
export const MUSIC = '/music';
export const PLAYLIST = '/music-playlist';
export const OFFERS = '/offers';
export const OFFERS_DETAIL = '/offers/:alias';
export const OFFERS_ARCHIVE = '/offers/archive';
export const NEWS = '/news';
export const NEWS_DETAIL = '/news/:alias';
export const PODCASTS = '/podcasts';
export const PROGRAMS = '/programs';
export const PROGRAMS_DETAIL = '/programs/:alias';
export const EMCEES = '/emcees';
export const ABOUT = '/about';
export const PROGRAMS_ARCHIVE = '/programs/archive';
export const NOVELTY = '/novelty';
export const APPLICANTS = "#";

export const PLAYLIST_ICON = "/uploads/images/playlists/d22ba3c1-ab52-4ed2-a2d7-263085086212_playlist (1).png";
export const NOVELTY_ICON = "/uploads/images/playlists/6f8e0cda-ca1d-4517-9b48-f6afff74bf64_sound-waves.png";
export const MUSIC_ICON = "/uploads/images/playlists/5bdc0e2b-2d31-4211-8a65-8cd540e13afa_flyaway.svg";
export const APPLICANTS_ICON = "/uploads/images/playlists/e8599af7-0da9-4d37-9e76-c2c7dcf6cbf9_Group.png";
export const QUIZ_ICON = "/uploads/images/quizes/quiz_icon.png";
