import React, { useState, useEffect } from 'react';
import MetaTags from 'react-meta-tags';
import BlockContent from '../../components/BlockContent';
import BannerWidget from '../../components/BannerWidget';
import { OFFERS_BY_ALIAS_ROUTE, OFFERS_ARCHIVE_ROUTE } from '../../constants/redirect';
import { Link } from 'react-router-dom';
// import FormMailing from '../../components/FormMailing';
import {
  getCurrentOffer,
  getCurrentBanner,
  getOffersArchive,
  getAllCurrentOffers,
  getPictureFromLastUnactiveOffer
} from './requests';
import './index.css';

export default function Offers() {
  const [currentOffer, setCurrentOffer] = useState({});
  const [currentOffers, setCurrentOffers] = useState([]);
  const [prewiewArchive, setPrewiewArchiwe] = useState([]);
  const [bannerData, setBannerData] = useState(null);
  const [archivePic, setArchivePic] = useState("");
  const processingRequest = async () => {
    setCurrentOffer((await getCurrentOffer()).data);
    setBannerData((await getCurrentBanner()).data);
    setPrewiewArchiwe((await getOffersArchive()).data);
    setCurrentOffers((await getAllCurrentOffers()).data);
    setArchivePic((await getPictureFromLastUnactiveOffer()).data);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    processingRequest();
  }, []);
  return (
    console.log(currentOffer),
    <div className="page-offers">
      
      <BlockContent>
        <div className="big-column">
          <MetaTags>
            <title>{currentOffer[0] && currentOffer[0].alias && currentOffer[0].alias.length > 0 ? currentOffer[0].title : "Акции - Новое Радио"}</title>
            <meta name="description" content={currentOffer[0] && currentOffer[0].description && currentOffer[0].description.length > 0 ? currentOffer[0].description : "Акции - Новое Радио"} />
          </MetaTags>
          {currentOffer.length > 1 ? currentOffer.map((item, index) => {
            return (
              <Link to={OFFERS_BY_ALIAS_ROUTE + item.alias} className={`current-offer__container ${index > 0 && "margin-top"}`}>
                <div className="current-offer__image">
                  <img src={item.picture.replace("static", "")} alt={item.title} />
                </div>
            </Link>
            )
          }) : currentOffer[0] && (
            <div className="current-offer__container">
              <div class = "current-offer__dates">
                {`${new Date(currentOffer[0].publish_date).toLocaleDateString('ru-RU')} - ${new Date(currentOffer[0].unpublish_date).toLocaleDateString('ru-RU')}`}
              </div>
              <div className="current-offer__title">{currentOffer[0].title}</div>
              <div className="current-offer__image">
                <img src={currentOffer[0].picture.replace("static", "")} alt={currentOffer[0].title} />
              </div>
              <div
                className="content-editor"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: currentOffer[0].content }}
              />
              <div
                className="content-editor"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: currentOffer[0].google_form ? currentOffer[0].google_form : ""}}
              />
            </div>
          )}
          {/* {currentOffer[0] && (
            <div className="current-offer__container">
              <div class = "current-offer__dates">
                {`${new Date(currentOffer[0].publish_date).toLocaleDateString('ru-RU')} - ${new Date(currentOffer[0].unpublish_date).toLocaleDateString('ru-RU')}`}
              </div>
              <div className="current-offer__title">{currentOffer[0].title}</div>
              <div className="current-offer__image">
                <img src={currentOffer[0].picture.replace("static", "")} alt={currentOffer[0].title} />
              </div>
              <div
                className="content-editor"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: currentOffer[0].content }}
              />
              <div
                className="content-editor"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: currentOffer[0].google_form ? currentOffer[0].google_form : ""}}
              />
            </div>
          )} */}
          {/* <FormMailing /> */}
        </div>
        <div className="small-column__chart">
          {/* {currentOffer.length > 1  && currentOffer.map((elem, index) => {
            // console.log(elem),
            if (index > 0) return (
              <BannerWidget
                local
                img={elem.picture.replace('static', '')}
                redirect={OFFERS_BY_ALIAS_ROUTE + elem.alias}
                title={elem.title ? elem.title : "Активные акции"}
              />
            )
          })} */}
          {/* {currentOffers[currentOffers.length - 1] && (
            <BannerWidget
              img={currentOffers[currentOffers.length - 1].picture.replace('static', '')}
              redirect={currentOffers[currentOffers.length - 1].link}
              title={currentOffers[currentOffers.length - 1].title}
            />
          )} */}
          {/* {prewiewArchive[0] && ( */}
            <BannerWidget
              local
              title="Архив акций"
              img={archivePic ? archivePic.picture.replace('static', '') : ""}
              redirect={OFFERS_ARCHIVE_ROUTE}
            />
          {/* )} */}
          {bannerData && bannerData.map((elem) => (
            <BannerWidget img={elem.picture} redirect={elem.link} />
          ))}
        </div>
      </BlockContent>
    </div>
  );
}
