import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SvgPodcast from '../Svg/SvgPodcasts';
import Dropdown from '../Dropdown';
import DropdownForObject from '../DropdownForObject';
import data from './data';
import './index.css';
import { getFilterPrograms, getUniqueYears, getUniqueMonths, getUniquePrograms } from './requests';

export default function BlockPodcasts({ children, changeHandler }) {

  const material = data();

  const monthsToDigitalBinding = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
  ];


  const processingRequest = async () => {
    // setProgram((await getFilterPrograms()).data);
    setAvailableYears((await getUniqueYears(activeYear, activeMonth ? monthsToDigitalBinding.indexOf(activeMonth) + 1 : activeMonth, activeProgram)).data);
    setAvailableMonths((await getUniqueMonths(activeYear, activeMonth ? monthsToDigitalBinding.indexOf(activeMonth) + 1 : activeMonth, activeProgram)).data);
    setAvailablePrograms((await getUniquePrograms(activeYear, activeMonth ? monthsToDigitalBinding.indexOf(activeMonth) + 1 : activeMonth, activeProgram)).data);
  };


  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  const [openYears, setOpenYears] = useState(false);
  const [openMonths, setOpenMonths] = useState(false);
  const [openPrograms, setOpenPrograms] = useState(false);
  const [programs, setProgram] = useState([]);
  const defaultYear = params.year ? params.year : null;
  const defaultMonth = params.month ? material.months[params.month-1]: null;
  const defaultProgram = params.program ? params.program : null;
  const [activeYear, setActiveYear] = useState(defaultYear);
  const [activeMonth, setActiveMonth] = useState(defaultMonth);
  const [activeProgram, setActiveProgram] = useState(defaultProgram);
  const [availableYears, setAvailableYears] = useState([]);
  const [availableMonths, setAvailableMonths] = useState([]);
  const [availabeMonthsInWords, setAvailabeMonthsInWords] = useState([]);
  const [availablePrograms, setAvailablePrograms] = useState([]);

  useEffect(() => {
    processingRequest();
  }, []);

  useEffect(() => {
    const monthArr = []
    availableMonths.forEach(month => {
      monthArr.push(monthsToDigitalBinding[month - 1]);
    });
    setAvailabeMonthsInWords(monthArr);
  }, [availableMonths]);


  useEffect(() => {
    changeHandler(activeYear, monthsToDigitalBinding.indexOf(activeMonth) + 1, activeProgram);
    processingRequest();
  }, [activeYear,activeMonth,activeProgram]);

  const yearsHandler = () => {
    setOpenYears(!openYears);
    setOpenMonths(false);
    setOpenPrograms(false);
  };

  const monthsHandler = () => {
    setOpenYears(false);
    setOpenMonths(!openMonths);
    setOpenPrograms(false);
  };

  const programsHandler = () => {
    setOpenYears(false);
    setOpenMonths(false);
    setOpenPrograms(!openPrograms);
  };


  return (
    <div className="block-podcasts">
      <div className="block-podcasts__row mob-column">
        <div className="inline-row">
          <SvgPodcast />
          <div className="block-podcasts__title">Подкасты</div>
        </div>
        <div className="block-podcasts__dropdown-container">
          <Dropdown
            title="Год"
            items={availableYears}
            open={openYears}
            handler={yearsHandler}
            active={activeYear}
            setActive={setActiveYear}
          />
          <Dropdown
            title="Месяц"
            items={availabeMonthsInWords}
            open={openMonths}
            handler={monthsHandler}
            active={activeMonth}
            setActive={setActiveMonth}
          />
          <DropdownForObject
            title="Все программы"
            items={availablePrograms}
            open={openPrograms}
            handler={programsHandler}
            active={activeProgram}
            setActive={setActiveProgram}
          />
        </div>
        {activeYear || activeMonth || activeProgram ? (
        <div class = "drop-filter" onClick={() => {
          changeHandler(null, null, null);
          setActiveYear(null);
          setActiveMonth(null);
          setActiveProgram(null);
        }}>Сбросить</div>
      ) : ""}
      </div>
      <div className="block-podcasts__scroll-bar">{children}</div>
    </div>
  );
}

BlockPodcasts.propTypes = {
  children: PropTypes.node,
};

BlockPodcasts.defaultProps = {
  children: <div />,
};
