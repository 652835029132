import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  TextField,
  Button,
  withStyles,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  IconButton,
} from '@material-ui/core';
import Delete from '@material-ui/icons/Delete';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ImageDropzone from '../../components/Dropzone/ImageDropzone';
import styles from '../../styles';
import {maxImageFileSize} from "../../constants/additional";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function PlaylistsForm({
  classes, data, submitHandler, tracks,
}) {
  const { id } = data;
  const [title, setTitle] = useState(data.title);
  const [status, setStatus] = useState(data.status);
  const [link, setLink] = useState(data.link);
  const [iconUrl, setIconUrl] = useState(data.icon);
  const [iconFile, setIconFile] = useState([]);
  const [iconToDelete, setIconToDelete] = useState([]);
  const [selectedTracks, setSelectedTracks] = useState(data.selected_tracks);
  const defArr = [];
  const deleteBtn = (item) => (
    <div>
      <IconButton
        onClick={() => {
          setSelectedTracks(selectedTracks.filter((el) => el !== item));
        }}
      >
        <Delete />
      </IconButton>
    </div>
  );

  if (selectedTracks.length) {
    for (let i = 0; i < selectedTracks.length; i += 1) {
      const result = tracks.findIndex((el) => el.id === selectedTracks[i].id);
      defArr.push(tracks[result]);
    }
  }

  async function dispatchHandler(event) {
    event.preventDefault();
    
    const ifOk = confirm('Вы уверены что хотите сохранить изменения?');
    if (ifOk) {
    // eslint-disable-next-line no-return-await
    await submitHandler(
      {
        id,
        title,
        status,
        link,
        selectedTracks,
        iconUrl,
        iconToDelete,
      },
      iconFile[0],
    );
    } else {
      history.back();
    }
  }

  const trackList = selectedTracks.map((item, index) => (
    <div key={index.toString()} className={classes.trackListItem}>
      <div className={classes.flexOne}>
        <div>{item.author}</div>
        <div>-</div>
        <div>{item.title}</div>
      </div>
      <audio controls src={item.audio} className={classes.flexOne} />
      {deleteBtn(item)}
    </div>
  ));

  return (
    <div>
      <form onSubmit={dispatchHandler}>
        <div className={classes.field}>
          <div className={classes.title}>Заголовок:</div>
          <TextField
            fullWidth
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
          />
        </div>
        <div className={classes.field}>
          <div className={classes.title}>Ссылка:</div>
          <TextField
            fullWidth
            value={link}
            onChange={(e) => {
              setLink(e.target.value);
            }}
          />
        </div>
        <div>
          <ImageDropzone
            urls={iconUrl}
            setUrls={setIconUrl}
            files={iconFile}
            setFiles={setIconFile}
            toDelete={iconToDelete}
            setToDelete={setIconToDelete}
            text="Загрузить иконку"
            acceptedFiles={['image/*']}
            maxSize={maxImageFileSize}
            filesLimit={1}
          />
        </div>
        <div className={classes.field}>
          <div className={classes.title}>Треки:</div>
          <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            options={tracks}
            disableCloseOnSelect
            getOptionLabel={(option) => `${option.author} - ${option.title}`}
            value={[...defArr]}
            renderOption={(option, state) => (
              <>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={state.selected}
                />
                {`${option.author} - ${option.title}`}
              </>
            )}
            style={{ width: '100%' }}
            onChange={(event, value) => {
              setSelectedTracks(value);
            }}
            renderInput={(arg) => {
              const params = arg;
              params.InputProps.startAdornment = undefined;
              return (
                <TextField
                  fullWidth
                  InputLabelProps={params.InputLabelProps}
                  InputProps={params.InputProps}
                  id={params.id}
                  inputProps={params.inputProps}
                />
              );
            }}
          />
          {trackList.length > 0 && (
            <div className={classes.fieldBasic}>
              Список треков данного плейлиста:
            </div>
          )}
          {trackList.length > 0 && trackList}
        </div>
        <div className={classes.field}>
          <div className={classes.title}>
            Статус видимости (Отображать на главной):
          </div>
          <FormControl fullWidth>
            <InputLabel id="seletc-status" />
            <Select
              required
              labelId="seletc-status"
              value={status}
              onChange={(e) => {
                setStatus(e.target.value);
              }}
            >
              <MenuItem value={1}>Видимый</MenuItem>
              <MenuItem value={0}>Невидимый</MenuItem>
            </Select>
          </FormControl>
        </div>
        <Button variant="contained" color="primary" type="submit" fullWidth>
          {id ? 'Обновить' : 'Создать'}
        </Button>
      </form>
    </div>
  );
}

export default withStyles(styles)(PlaylistsForm);

PlaylistsForm.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  submitHandler: PropTypes.func,
  tracks: PropTypes.array,
};

PlaylistsForm.defaultProps = {
  classes: {},
  data: {
    title: '',
    status: 1,
    selected_tracks: [],
    icon: [],
    link: '',
  },
  submitHandler: () => {},
  tracks: [],
};
