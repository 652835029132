import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import PapperBlock from '../../../components/PapperBlock';
import TracksForm from '../TracksForm';
import { getTrackById, updateTrackById } from '../requests';
import { TrackIcon } from '../../../constants/icons';
import { ADMIN_TRACKS_ROUTE } from '../../../constants/routes';

export default function UpdateTracks() {
  const [dataTrack, setDataTrack] = useState({});
  const [loaded, setLoaded] = useState(false);
  const params = useParams();
  const { id } = params;
  async function processingRequest() {
    setDataTrack((await getTrackById(id)).data);
    setLoaded(true);
  }
  const history = useHistory();
  const submitHandler = async (data, audio, picture) => {
    const response = await updateTrackById(data, audio, picture);
    if (response.ok) {
      history.push(ADMIN_TRACKS_ROUTE);
    }
  };
  useEffect(() => {
    processingRequest();
  }, []);

  return (
    <PapperBlock title="Обновить трек" icon={<TrackIcon />}>
      {loaded && <TracksForm submitHandler={submitHandler} data={dataTrack} />}
    </PapperBlock>
  );
}
