import React from 'react';
import PropTytes from 'prop-types';
import SvgPosition from '../Svg/SvgPosition';
import './index.css';

const ChartPosition = ({ currentPosition, lastPosition, chartDifference }) => {
  const position = chartDifference != null && chartDifference != undefined ? chartDifference : lastPosition - currentPosition;
  if (!position) {
    return <></>;
  }
  return (
    <div className="chart-position">
      <SvgPosition direction={position < 0 ? 'down' : 'up'} />
      <span className="chart-position__number">{Math.abs(position)}</span>
    </div>
  );
};

export default ChartPosition;

ChartPosition.propTypes = {
  currentPosition: PropTytes.number,
  lastPosition: PropTytes.number,
};

ChartPosition.defaultProps = {
  currentPosition: null,
  lastPosition: null,
};
