import React, {useState, useEffect} from 'react';
import "./index.css";
import {setVotes} from "./requests";

const QuizWidget = ({elem, buttonClickHandler}) => { //, buttonClickHandler
    const [answers, setAnswers] = useState([]);
    const [isVoted, setIsVoted] = useState(false);
    const [allVotes, setAllVotes] = useState(0);
    const [localStorageData, setLocalStorageData] = useState({})

    const processingRequest = async () => {
        // setQuizes(elem)
        if (localStorage.hasOwnProperty(`quizId_${elem.id}`)) {
            setIsVoted(true);
            setLocalStorageData(JSON.parse(localStorage.getItem(`quizId_${elem.id}`)));
            console.log([...JSON.parse(localStorage.getItem(`quizId_${elem.id}`)).answers]);
            setAnswers([...JSON.parse(localStorage.getItem(`quizId_${elem.id}`)).answers])
        }
        
        setAllVotes(elem.answers.reduce((acc, curr) => acc + curr.votes, allVotes));
    }

    const chooseAnswer = (id) => {
        if (isVoted) return false;
        console.log("here");
        if (elem.type == "single") setAnswers([id])
        else if (elem.type == "multiple") {
            console.log("answers", answers)
            const answerIndex = answers.indexOf(id)
            console.log("answerIndex", answerIndex)
            if (answerIndex != -1) {
                console.log("delete item with index: ", answerIndex, " and ID: ", id)
                const newAnswers = answers;
                console.log("newAnswers before", newAnswers);
                newAnswers.splice(answerIndex, 1);
                console.log("newAnswers after", newAnswers);
                setAnswers([...newAnswers]);
            } else {
                console.log("add item with ID: ", id)
                setAnswers([...answers, id]);
            } 
        }
    }

    const sendVotes = async () => {
        if (isVoted) return false;
        const dataForLocalStorage = {"quizId": elem.id, "answers": [...answers]}
        localStorage.setItem(`quizId_${elem.id}`, JSON.stringify(dataForLocalStorage));
        await setVotes({"ids": [...answers]});
        answers.map((answerID) => {
            const answerIndex = elem.answers.findIndex(answer => answer.id == answerID);
            if (answerIndex > -1 ) elem.answers[answerIndex]. votes += 1;
        })
        setAllVotes((prev) => prev + answers.length);
        setIsVoted(true);
    }

    useEffect(() => {
        processingRequest();
        return () => {
            setAnswers([]);
            setAllVotes(0);
            setIsVoted(false);
            setLocalStorageData({});

        }
    }, [])

    return (
        <div className="quiz-body">
            <p className="quiz-header">{elem.title}</p>
            <div className="quiz-answers">
                {elem.answers.map((answer) => {
                    return (
                        <div className={`answer-item ${answers.indexOf(answer.id) > -1 || (Object.keys(localStorageData).length > 0 && localStorageData.answers.indexOf(answer.id) > -1) ? "active" : ""}`}  key={answer.id} onClick={() => {chooseAnswer(answer.id)}}>
                            <p className="answer-title">{answer.answer}</p>
                            <span className={`answer-percent ${isVoted ? "" : "hidden"}`} >{allVotes > 0 && answer.votes > 0 ? Math.round(answer.votes / allVotes * 100) : 0}%</span>
                        </div>
                    )
                })}
            </div>
            <button className={`button-accept ${answers.length || isVoted ? "" : "hidden"}`} onClick={() => {sendVotes()}}>{isVoted ? "Спасибо за ответ!" : "Отправить"}</button>
        </div>
    );
}

export default QuizWidget;