import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import PapperBlock from '../../../components/PapperBlock';
import SlideForm from '../SlideForm';
import { updateSlide, getSlideById } from '../requests';
import { SlideIcon } from '../../../constants/icons';
import { ADMIN_SLIDES_ROUTE } from '../../../constants/routes';

export default function UpdateSlide() {
  const history = useHistory();
  const [slideData, setSlideData] = useState({});
  const [loaded, setLoaded] = useState(false);
  const params = useParams();
  const { id } = params;

  async function processingRequest() {
    setSlideData((await getSlideById(id)).data);
    setLoaded(true);
  }

  useEffect(() => {
    processingRequest();
  }, []);

  const submitHandler = async (data, picture) => {
    const response = await updateSlide(data, picture);
    if (response.ok) {
      history.push(ADMIN_SLIDES_ROUTE);
    }
  };
  return (
    <PapperBlock title="Редактировать слайд" icon={<SlideIcon />}>
      {loaded && <SlideForm submitHandler={submitHandler} data={slideData} />}
    </PapperBlock>
  );
}
