import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import PapperBlock from '../../../components/PapperBlock';
import NewsForm from '../NewsForm';
import { getNewsById, updateNewsById } from '../requests';
import { NewsIcon } from '../../../constants/icons';
import { ADMIN_NEWS_ROUTE } from '../../../constants/routes';

export default function UpdateNews() {
  const [dataNews, setDataNews] = useState({});
  const [loaded, setLoaded] = useState(false);
  const params = useParams();
  const { id } = params;
  const processingRequest = async () => {
    setDataNews((await getNewsById(id)).data);
    setLoaded(true);
  };
  const history = useHistory();
  const submitHandler = async (data, picture) => {
    const response = await updateNewsById(data, picture);
    if (response.ok) {
      history.push(ADMIN_NEWS_ROUTE);
    }
  };
  useEffect(() => {
    processingRequest();
  }, []);

  return (
    <PapperBlock title="Обновить новость" icon={<NewsIcon />}>
      {loaded && <NewsForm submitHandler={submitHandler} data={dataNews} />}
    </PapperBlock>
  );
}
