import React from 'react';
import { useHistory } from 'react-router-dom';
import PapperBlock from '../../../components/PapperBlock';
import SlideForm from '../SlideForm';
import { createSlide } from '../requests';
import { SlideIcon } from '../../../constants/icons';
import { ADMIN_SLIDES_ROUTE } from '../../../constants/routes';

export default function CreateSlide() {
  const history = useHistory();
  const submitHandler = async (data, picture) => {
    const response = await createSlide(data, picture);
    if (response.ok) {
      history.push(ADMIN_SLIDES_ROUTE);
    }
  };

  return (
    <PapperBlock title="Добавить слайд" icon={<SlideIcon />}>
      <SlideForm submitHandler={submitHandler} />
    </PapperBlock>
  );
}
