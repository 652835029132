import fetchRequest from '../../../helpers/fetchRequest';

export async function getAllSocials() {
  const url = '/settings/socials';
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function getSocialById(id) {
  const url = `/settings/socials-${id}`;
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function createSocial(data, picture) {
  const formData = new FormData();
  formData.append('picture', picture);
  formData.append('data', JSON.stringify(data));
  const url = '/settings/socials-create';
  const options = {
    method: 'POST',
    body: formData,
  };
  return fetchRequest(url, options);
}

export async function updateSocial(data, picture) {
  const formData = new FormData();
  formData.append('picture', picture);
  formData.append('data', JSON.stringify(data));
  const url = `/settings/socials-update-${data.id}`;
  const options = {
    method: 'PUT',
    body: formData,
  };
  return fetchRequest(url, options);
}

export async function deleteSocial(id) {
  const url = `/settings/socials-${id}`;
  const options = {
    method: 'DELETE',
  };
  return fetchRequest(url, options);
}
