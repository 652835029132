import React from 'react';
import PropTypes from 'prop-types';
import SvgPrograms from '../Svg/SvgPrograms';
import './index.css';

export default function BlockPrograms({ children }) {
  return (
    <div className="block-programs">
      <div className="block-programs__row">
        <div className="block-programs__icon">
          <SvgPrograms />
        </div>
        <div className="block-programs__title">Программы</div>
      </div>
      <div className="block-programs__scroll-bar">{children}</div>
    </div>
  );
}

BlockPrograms.propTypes = {
  children: PropTypes.node,
};

BlockPrograms.defaultProps = {
  children: <div />,
};
