import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { deleteUserById } from './requests';
import styles from '../../styles';

export default function columns(handleRemove = () => {}) {
  const classes = makeStyles(styles)();
  const history = useHistory();
  return [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: 'login',
      label: 'Логин',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: '',
      label: 'Управление',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => (
          <>
            <IconButton
              onClick={() => {
                const id = tableMeta.rowData[0];
                history.replace(`/admin/users/update/${id}`);
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={async () => {
                if(window.confirm('Вы уверены, что хотите удалить?')){
                  const id = tableMeta.rowData[0];
                  const { rowIndex } = tableMeta;
                  await deleteUserById(id);
                  handleRemove(rowIndex);
                  window.location.reload();
                }
              }}
            >
              <DeleteIcon />
            </IconButton>
          </>
        ),
      },
    },
  ];
}
