import React from 'react';
import NextWeekIcon from '@material-ui/icons/NextWeek';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import AudioTrackIcon from '@material-ui/icons/Audiotrack';
import PlaylistPlayIcon from '@material-ui/icons/PlaylistPlay';
import LibraryMusicIcon from '@material-ui/icons/LibraryMusic';
import FeaturedPlayListIcon from '@material-ui/icons/FeaturedPlayList';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import PeopleIcon from '@material-ui/icons/People';
import BrandingWatermarkIcon from '@material-ui/icons/BrandingWatermark';
import DataUsageIcon from '@material-ui/icons/DataUsage';
import Slideshow from '@material-ui/icons/Slideshow';
import SettingsIcon from '@material-ui/icons/Settings';
import RadioIcon from '@material-ui/icons/Radio';
import CloudCircleIcon from '@material-ui/icons/CloudCircle';
import HelpIcon from '@material-ui/icons/Help';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import QuizImg from '@mui/icons-material/Quiz';


export const NewsIcon = () => <NextWeekIcon />;
export const OfferIcon = () => <LocalOfferIcon />;
export const TrackIcon = () => <AudioTrackIcon />;
export const PlaylistIcon = () => <PlaylistPlayIcon />;
export const PodcastIcon = () => <LibraryMusicIcon />;
export const ProgramIcon = () => <FeaturedPlayListIcon />;
export const EmceeIcon = () => <AccountBoxIcon />;
export const GuestIcon = () => <PeopleIcon />;
export const BannerIcon = () => <BrandingWatermarkIcon />;
export const SEOIcon = () => <DataUsageIcon />;
export const SlideIcon = () => <Slideshow />;
export const SettingIcon = () => <SettingsIcon />;
export const AboutIcon = () => <RadioIcon />;
export const StreamsIcon = () => <CloudCircleIcon />;
export const FaqIcon = () => <HelpIcon />;
export const BackIcon = () => <ArrowBackIcon/>;
export const UsersIcon = () => <PeopleIcon/>;
export const QuizIcon = () => <QuizImg/>;
