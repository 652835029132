/* eslint-disable import/prefer-default-export */
import fetchRequest from '../../helpers/fetchRequest';

export async function likeTrack(trackId) {
  const url = `/like/${trackId}`;
  const options = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}
