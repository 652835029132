import fetchRequest from '../../helpers/fetchRequest';

export async function getNewsByPagination(page,y,m, limit, offset) {
  const url = `/pagination-news/${page}?`+ new URLSearchParams({
      year: y,
      month: m,
  });
  const options = {
    method: 'POST',
    body: JSON.stringify({
      limit: limit,
      offset: offset 
    }),
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function getPlaylistChart() {
  const url = '/charts/playlist';
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function getNoveltyChart() {
  const url = '/charts/novelty';
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function getCurrentBanner() {
  const url = '/current-banner';
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function getChartTimestamp() {
  const url = `/get-chart-timestamp`;
  const options = {
    method: 'GET',
  };
  return fetchRequest(url, options);
}

export async function getNewsAmount(year, month) {
  const url = `/get-news-amount`;
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      year: year,
      month: month,
    }),
  };
  return fetchRequest(url, options);
}

export async function getLatestNews(year, month, limit, offset) {
  const url = '/latest-news';
  const options = {
    method: 'POST',
    body: JSON.stringify({
      year: year,
      month: month,
      limit: limit,
      offset: offset,
    }),
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}


