/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import './checkboxes-tags.css';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function CheckboxesTags({
  options,
  selected,
  schedule_id,
  setSelected,
  label,
}) {
  const [checked, setChecked] = useState(options);
  const defaultArray = [];
  console.log(options);

  for (let i = 0; i < selected.length; i += 1) {
    const result = checked.findIndex((el) => el.id === selected[i].id);
    defaultArray.push(checked[result]);
  }

  return (
    <Autocomplete
      multiple
      id="checkboxes-tags-demo"
      options={checked}
      disableCloseOnSelect
      getOptionLabel={(option) => option ? option.hasOwnProperty("title") ? option.title : option.name : ""}
      renderOption={(option, state) => (
        <>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={state.selected}
          />
          {option.title || option.name}
        </>
      )}
      defaultValue={defaultArray}
      style={{ width: '100%' }}
      onChange={(event, value) => {
        if (schedule_id) setSelected(schedule_id, "emcees", value);
        else setSelected(value);
      }}
      renderInput={(params) => (
        <TextField
          value="helo"
          fullWidth
          InputLabelProps={params.InputLabelProps}
          InputProps={params.InputProps}
          id={params.id}
          inputProps={params.inputProps}
          label={label}
        />
      )}
    />
  );
}

CheckboxesTags.propTypes = {
  options: PropTypes.array,
  selected: PropTypes.array,
  schedule_id: PropTypes.string,
  setSelected: PropTypes.func,
  label: PropTypes.string,
};

CheckboxesTags.defaultProps = {
  options: [],
  selected: [],
  schedule_id: "",
  setSelected: () => {},
  label: '',
};
