import fetchRequest from '../../helpers/fetchRequest';

export async function createBlock(data) {
  const url = '/stream/audio';
  const options = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function getDataForTable() {
  const url = '/stream/audio';
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function getBlockById(id) {
  const url = `/stream/audio/${id}`;
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function updateBlockById(data) {
  const url = `/stream/audio/${data.id}`;
  const options = {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function deleteBlockById(id) {
  const url = `/stream/audio/${id}`;
  const options = {
    method: 'DELETE',
  };
  return fetchRequest(url, options);
}
