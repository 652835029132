import React, {useRef, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './index.css';

export default function BlockProgramsItem({
  title,
  description,
  img,
  timetable,
  redirect,
}) {
  const [oneProgramHeight, setoneProgramHeight] = useState(0);
  const oneProgramElementRef = useRef(null);
  // useEffect(() => {
  //   const itemHeight = window.innerWidth <= 1200 ? Math.ceil(oneProgramElementRef.current.clientWidth / 1.87) : 318
  //   setoneProgramHeight(itemHeight);
  // }, []);
  return (
    <Link to={redirect}>
      <div className="programs-item" > {/*ref={oneProgramElementRef} style={{height: `${oneProgramHeight}px`}}*/}
        <div className="zoom-container ">
          <img src={img} alt={title} className="zoom-item" />
          <div className="programs-item__inner-row">
            <div className="programs-item__inner-row-content">
              <div>
                <div className="programs-item__title">{title}</div>
                <div className="programs-item__description">{description}</div>
              </div>
              <div>
                <div className="programs-item__timetable">
                  {timetable.split('\n').map((el) => (
                    <div className="timetable-item" key={el}>
                      {el}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}

BlockProgramsItem.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  img: PropTypes.string,
  timetable: PropTypes.string,
  redirect: PropTypes.string,
};

BlockProgramsItem.defaultProps = {
  title: '',
  description: '',
  img: '',
  timetable: '',
  redirect: '/',
};
