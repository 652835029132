import React, { useState, useEffect } from 'react';
import MetaTags from 'react-meta-tags';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import BlockContent from '../../components/BlockContent';
import BlockChart from '../../components/BlockChart';
import ItemChart from '../../containers/ItemChart';
import PlaylistContainer from '../../components/PlaylistContainer';
import BannerWidget from '../../components/BannerWidget';
import { PROGRAMS, PLAYLIST, NOVELTY, NOVELTY_ICON, PLAYLIST_ICON } from '../../constants/routes';
import {
  getPlaylistChart, getAboutData, getLatestPodcasts, getNoveltyChart, getPodcastsAmount, getCurrentBanner, getChartTimestamp
} from './requests';
import './index.css';
import aboutIcon from './img/radio.svg';
import FormMailing from '../../components/FormMailing';
import BlockPodcasts from '../../components/BlockPodcasts';
import ItemPodcast from '../../containers/ItemPodcast';
import { CLIENT_DATE_FORMAT } from '../../constants/dateTimeFormat';
import BlockProgramsItem from '../../components/BlockProgramsItem';
import BlockPreviewProgram from '../../components/BlockPreviewProgram';
import BlockPreviewProgramContainer from '../../components/BlockPreviewProgramContainer';
import { PROGRAMS_BY_ALIAS_ROUTE } from '../../constants/redirect';
import BlockPrograms from '../../components/BlockPrograms';
import { getPrograms } from '../Programs/requests';
import SvgArrow from '../../components/Svg/SvgArrow';
import ShowMoreBtn from '../../components/ShowMoreBtn';
import setLocalStorageForLikes from '../../helpers/setLocalStorageForLikes';
import {useSelector} from 'react-redux';


export default function About() {
  const [aboutData, setAboutData] = useState([]);
  const [playlistChartData, setPlaylistChartData] = useState({});
  const [podcasts, setPodcasts] = useState([]);
  const [noveltyChartData, setNoveltyChartData] = useState({});
  const [programs, setPrograms] = useState([]);

  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [podcastsAmount, setPodcastsAmount] = useState(0);
  const [chosenYear, setChosenYear] = useState(null);
  const [chosenMonth, setChosenMonth] = useState(null);
  const [chosenProgram, setChosenProgram] = useState(null);
  const [bannerData, setBannerData] = useState(null);
  const [ratedTracks, setRatedTracks] = useState([]);
  const [likedTracks, setLikedTracks] = useState([]);
  const {playlistLastSong} = useSelector((state) => state);

  const setLocalStorage = async () => {
    const timestamp = ((await getChartTimestamp()).data);
    const [actualRatedTracks, actualLikedTracks] = setLocalStorageForLikes(timestamp.timestamp);
    setRatedTracks([...actualRatedTracks]);
    setLikedTracks([...actualLikedTracks]);
  }

  const loadPodcasts = async (firstLoad = true, year, month, program, limit, offset) => {
    const downloaded = await getLatestPodcasts(year, month, program, limit, offset);
    if (firstLoad) {
      setPodcasts(downloaded.data);
    } else {
      setPodcasts([...podcasts, ...downloaded.data]);
    }
  }

  const processingRequest = async () => {
    setPlaylistChartData((await getPlaylistChart()).data);
    setPodcasts((await getLatestPodcasts()).data);
    setAboutData((await getAboutData()).data);
    setNoveltyChartData((await getNoveltyChart()).data);
    setPrograms((await getPrograms()).data);
    await loadPodcasts(true, chosenYear, chosenMonth, chosenProgram, pageSize, pageIndex * 10);
    setPodcastsAmount((await getPodcastsAmount(chosenYear, chosenMonth, chosenProgram,)).data);
    setBannerData((await getCurrentBanner()).data);
    await setLocalStorage();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    processingRequest();
  }, []);

  const loadPodcastsWrapper = async (bool, ind) => {
    await loadPodcasts(bool, chosenYear, chosenMonth, chosenProgram, pageSize, ind);
  }

  const setPodcastsAmountWrapper = async () => {
    setPodcastsAmount((await getPodcastsAmount(chosenYear, chosenMonth, chosenProgram,)).data);
  }

  useEffect(() => {
    loadPodcastsWrapper(false, pageIndex * 10);
    setPodcastsAmountWrapper();
    // setPageIndex(1);
  }, [pageIndex])

  useEffect(() => {
    loadPodcastsWrapper(true, 0);
    // setPageIndex(1);
  }, [chosenYear, chosenMonth, chosenProgram]);

  // const rerenderPlaylist = async () => {
  //   setPlaylistChartData((await getPlaylistChart()).data);
  // }

  useEffect(() => {
      if (playlistLastSong.id && playlistChartData.id) {
        setPlaylistChartData(
          {
            ...playlistChartData, 
            track_list: [
              {
                ...playlistLastSong, 
                play_date: new Date().toISOString(),
                play_time: new Date().toISOString(),
              }, 
              ...playlistChartData.track_list
            ]
          }
        );
      }
  }, [playlistLastSong]);

  const podcastsList = podcasts && podcasts.length > 0 ? podcasts.map((el, index) => (
    <ItemPodcast
      index={index}
      key={`${el.title}${el.id}`}
      img={el.picture}
      audio={el.audio}
      date={
        el.publish_date && format(new Date(el.publish_date), CLIENT_DATE_FORMAT)
      }
      download={el.download}
      title={el.title}
      description={el.description}
    />
  )) : [];

  return (
    <div className="page-about">
      <BlockContent>
        
        <MetaTags>
          <title>О радио - Новое Радио</title>
          <meta name="description" content="О радио - Новое Радио" />
        </MetaTags>
        <div className="big-column">
          <div class = "aboutpage-content">
            <div className="about-row">
              <div className="about-row__icon">
                <img src={aboutIcon} alt="about icon" />
              </div>
              <div className="about-row__title">О радио</div>
            </div>
            <div
              className="about-content"
              dangerouslySetInnerHTML={{
                __html: aboutData.map((block) => (
                  `<div class="about-content__item">${block.content}</div>`
                )).join(' '),
              }}
            />
            <div>
              {/* <FormMailing /> */}
            </div>
          </div>
        </div>

        <div className="small-column__chart ">
          <BlockPreviewProgramContainer>
            {programs.map((el) => (
              <BlockPreviewProgram
                key={el.title}
                title={el.title}
                description={el.description}
                timetable={el.short_timetable}
                img={el.picture}
                redirect={PROGRAMS_BY_ALIAS_ROUTE + el.alias}
              />
            ))}
          </BlockPreviewProgramContainer>
        </div>

      </BlockContent>

      <BlockContent>
        <div className="big-column">
          <BlockPodcasts changeHandler ={ async (year, month, program) => {
            setChosenYear(year);
            setChosenMonth(month);
            setChosenProgram(program);
            setPageIndex(0);
          }}>
            {podcastsList}
            {podcasts.length < podcastsAmount && (
              <ShowMoreBtn text="Больше" clickHandler={() => {
                setPageIndex(pageIndex + 1);
                }} />
            )}
          </BlockPodcasts>
        </div>
        <div className="small-column__chart">
          {playlistChartData && (
            <PlaylistContainer
                chartData={playlistChartData.track_list}
                title={playlistChartData.title}
                link={PLAYLIST}
                icon={PLAYLIST_ICON}
                countViewTracks={4}
                additionalClass = {"playlist"}
                children={playlistChartData.track_list}
                type={"playlistChart"}
                color={null}
              />
          )}
          {noveltyChartData.length > 0 && (
            <PlaylistContainer
                chartData={noveltyChartData}
                title={"Новинки"}
                link={NOVELTY}
                icon={NOVELTY_ICON}
                countViewTracks={4}
                additionalClass = {"new_songs"}
                children={noveltyChartData}
                type={"noveltyChart"}
                color="yellow"
              />
          )}
          {bannerData && bannerData.map((elem) => (
            <BannerWidget img={elem.picture} redirect={elem.link}/>
          ))}
        </div>
      </BlockContent>
    </div>
  );
}
