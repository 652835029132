import React from 'react';
import PropTypes from 'prop-types';
import './index.css';

export default function ShowMoreBtn({text, clickHandler}) {
  return (
    <div class="show-more-btn" onClick={() => {clickHandler()}}>
        <div class="show-more-btn-text">{text}</div>
        <svg width="26" height="26" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" class="arrow-purple__filled" style={{transform: "rotate(270deg)", cursor: "pointer", color: "#fff"}}>
            <path class="circul" d="M0.750004 14C0.750004 21.3178 6.68223 27.25 14 27.25C21.3178 27.25 27.25 21.3178 27.25 14C27.25 6.68223 21.3178 0.749999 14 0.749999C6.68223 0.749998 0.750005 6.68223 0.750004 14Z" fill="#46C31E" stroke="#46C31E" stroke-width="1.5"></path>
            <path class="arrow" d="M13.6064 17.9641L8.10481 10.9203C8.09032 10.9019 8.08131 10.8798 8.07883 10.8565C8.07634 10.8332 8.08048 10.8097 8.09076 10.7886C8.10104 10.7676 8.11704 10.7499 8.13694 10.7375C8.15684 10.7251 8.17982 10.7186 8.20325 10.7188L9.41106 10.7188C9.48762 10.7188 9.56106 10.7547 9.60793 10.8141L14.0001 16.4391L18.3923 10.8141C18.4392 10.7531 18.5126 10.7188 18.5892 10.7188L19.797 10.7188C19.9017 10.7188 19.9595 10.8391 19.8954 10.9203L14.3939 17.9641C14.3472 18.0239 14.2874 18.0724 14.2192 18.1057C14.151 18.139 14.0761 18.1563 14.0001 18.1563C13.9242 18.1563 13.8493 18.139 13.781 18.1057C13.7128 18.0724 13.6531 18.0239 13.6064 17.9641Z" fill="#FFFFFF"></path>
        </svg>
    </div>
  );
}

ShowMoreBtn.propTypes = {
  text: PropTypes.string,
  clickHandler: PropTypes.func
};

ShowMoreBtn.defaultProps = {
  text: '',
  clickHandler: () => {},
};
