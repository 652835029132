import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PapperBlock from '../../../components/PapperBlock';
import EmceesForm from '../EmceesForm';
import { getProgramsForForm, createEmcee } from '../requests';
import { EmceeIcon } from '../../../constants/icons';
import { ADMIN_EMCEES_ROUTE } from '../../../constants/routes';

export default function CreateEmcee() {
  const [programsList, setProgramsList] = useState([]);
  const [loaded, setLoaded] = useState(false);
  async function processingRequest() {
    setProgramsList((await getProgramsForForm()).data);
    setLoaded(true);
  }
  const history = useHistory();
  const submitHandler = async (data, avatar, photos) => {
    const response = await createEmcee(data, avatar, photos);
    if (response.ok) {
      history.push(ADMIN_EMCEES_ROUTE);
    }
  };
  useEffect(() => {
    processingRequest();
  }, []);

  return (
    <PapperBlock title="Добавть ведущего" icon={<EmceeIcon />}>
      {loaded && (
        <EmceesForm submitHandler={submitHandler} programs={programsList} />
      )}
    </PapperBlock>
  );
}
