import './index.css';

export default class Animation {
  constructor(event) {
    this.event = event;
  }

  button() {
    const { currentTarget } = this.event;
    currentTarget.classList.add('hvr-push');
    setTimeout(() => {
      currentTarget.classList.remove('hvr-push');
    }, 300);
  }

  like() {
    this.event.currentTarget.classList.toggle('chart-item__like-icon');
    this.event.currentTarget.classList.toggle('chart-item__active-like-icon');
  }

  track() {
    const { currentTarget } = this.event;
    currentTarget.classList.add('track-animation');
  }
}
