import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import PapperBlock from '../../../../components/PapperBlock';
import SocialsForm from '../SocialsForm';
import { ADMIN_SETTING_ROUTE } from '../../../../constants/routes';
import { updateSocial, getSocialById } from '../requests';

const CreateSocial = () => {
  const location = useHistory();
  const [socialData, setSocialData] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const { id } = useParams();

  const processingRequest = async () => {
    setSocialData((await getSocialById(id)).data);
    setLoaded(true);
  };

  useEffect(() => {
    processingRequest();
  }, []);

  const submitHandler = async (data, picture) => {
    const response = await updateSocial(data, picture);
    if (response.ok) {
      location.push(ADMIN_SETTING_ROUTE);
    }
  };

  return (
    <PapperBlock>
      {loaded && <SocialsForm submitHandler={submitHandler} data={socialData} />}
    </PapperBlock>
  );
};

export default CreateSocial;
