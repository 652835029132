import React, { useState, useEffect, memo } from 'react';
import { Link, NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import menu from '../../constants/menu';
import PlayerAudio from '../Players/PlayerAudio';
import PlayerVideo from '../Players/PlayerVideo';
import Animation from '../../helpers/animation';
import './index.css';
import {
  getLogo,
  getSocialLinks
} from './requests';
import {
  setMenuOpen,
  setCurrentArtist,
  setCurrentSong,
  setCurrentCover,
} from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';

export const Header = React.memo(function Header({ setPlayerOpen }) {
  const dispatch = useDispatch();
  const {flow, flowsInfo, lastListenedStream, isMenuOpen } = useSelector((state) => state);
  // const [currentArtist, setCurrentArtist] = useState("");
  // const [currentSong, setCurrentSong] = useState("");
  // const [currentCover, setCurrentCover] = useState("");
  const [activeBurger, setActiveBurger] = useState('');
  const [headerScroll, setHeaderScroll] = useState('');
  const [logoData, setLogoData] = useState(null);
  const [socialLinks, setSocialLinks] = useState([]);

  async function processingRequest() {
    setLogoData((await getLogo()).data);
    setSocialLinks((await getSocialLinks()).data);
  }
  
  useEffect(() => {
    processingRequest();
  }, []);

  // useEffect(() => {
  //   let currentStream;
  //   if (flow.length > 0 && flowsInfo[flow.title.trim()]) {
  //     currentStream = flow.title.trim();
  //   } else if (lastListenedStream) {
  //     currentStream = lastListenedStream.trim();
  //   } else {
  //     currentStream = "В Эфире";
  //   }
  //   if (Object.keys(flowsInfo).length > 0) {
  //     if (flowsInfo[currentStream]) {
  //       if (flowsInfo[currentStream].hasOwnProperty("artist")) {
  //         dispatch(setCurrentArtist(flowsInfo[currentStream].artist));
  //       } else {
  //         dispatch(setCurrentArtist(""));
  //       }
  //       if (flowsInfo[currentStream].hasOwnProperty("title")) {
  //         dispatch(setCurrentSong(flowsInfo[currentStream].title));
  //       } else {
  //         dispatch(setCurrentSong(currentStream));
  //       }
  //       if (flowsInfo[currentStream].hasOwnProperty("cover")) {
  //         dispatch(setCurrentCover(flowsInfo[currentStream].cover));
  //       } else {
  //         dispatch(setCurrentCover("/uploads/images/tracks/novoe_radio_placeholder.jpeg"));
  //       }
  //     } else {
  //       dispatch(setCurrentArtist(""));
  //       dispatch(setCurrentSong(""));
  //       dispatch(setCurrentCover("/uploads/images/tracks/novoe_radio_placeholder.jpeg"));
  //     }
  //   } else {
  //     dispatch(setCurrentArtist(""));
  //     dispatch(setCurrentSong(""));
  //     dispatch(setCurrentCover("/uploads/images/tracks/novoe_radio_placeholder.jpeg"));
  //   }

  //   // setCurrentArtist(Object.keys(flowsInfo).length > 0 ? flowsInfo[currentStream] ? flowsInfo[currentStream].artist : "" : "");
  //   // setCurrentSong(Object.keys(flowsInfo).length > 0 ? flowsInfo[currentStream] ? flowsInfo[currentStream].title : "" : "");
  //   // setCurrentCover(Object.keys(flowsInfo).length > 0 ? flowsInfo[currentStream] ? flowsInfo[currentStream].cover : "" : "");
  // });


  function burgerHandler() {
    dispatch(setMenuOpen(!isMenuOpen));
    setActiveBurger((prev) => {
      if (prev === 'active-burger') {
        return '';
      }
      return 'active-burger';
    });
  }

  const renderNavMenu = menu.map((el, index) => {

    const link = el.isExternal ? {pathname: el.link} : el.link;
    const target = el.isExternal ? "_blank" : "";
    return (
      <NavLink
        key={index.toString()}
        to={link}
        target={target}
        className={`nav-item ${headerScroll}`}
        activeClassName="nav-item_active"
      >
        <div
          aria-hidden
          className="nav-item_text"
          onClick={(event) => {
            dispatch(setMenuOpen(false));
            setActiveBurger('');
            new Animation(event).button();
          }}
        >
          {el.title}
        </div>
      </NavLink>
    )
});

  function handleScroll() {
    const scrollTop = window.scrollY;
    const width = window.outerWidth;
    if (width >= 1201) {
      if (scrollTop > document.querySelector('.advertisement-line-container').offsetHeight + 80) {
        setHeaderScroll('scroll-nav');
        setPlayerOpen(true);
      } else {
        setHeaderScroll('');
        setPlayerOpen(false);
      }
    } else {
      if (scrollTop > document.querySelector('.advertisement-line-container').offsetHeight) {
        setHeaderScroll('scroll-nav');
        setPlayerOpen(true);
      } else {
        setHeaderScroll('');
        setPlayerOpen(false);
      }
    }
    // if (scrollTop > document.querySelector('.advertisement-line-container').offsetHeight + 80 && width >= 1201) { //&& width >= 1400
    //   setHeaderScroll('scroll-nav');
    //   setPlayerOpen(true);
    // } else {
    //   setHeaderScroll('');
    //   setPlayerOpen(false);
    // }
  }
  window.addEventListener('scroll', handleScroll);

  useEffect(() => {
    const scrollTop = window.scrollY;
    if (scrollTop >= 1) {
      setHeaderScroll('scroll-nav');
      setPlayerOpen(true);
    }
    return () => {
      setActiveBurger(null);
      setHeaderScroll(null);
    };
  }, []);

  return (
    <div className={`header-container ${headerScroll}`}>
      <header className={`header ${headerScroll} ${activeBurger}`}>
        <div className={`header-row ${headerScroll}`}>
          <Link to="/" className={`header-logo ${headerScroll}`}>
            {logoData && (
              <img src={logoData.src} className={`header-logo ${headerScroll}`} title={logoData.title} alt={logoData.title} />
            )}
          </Link>
          <div className={`scroll-menu ${headerScroll}`}>
            <div className={`header-players ${headerScroll}`}>
              <div className="header-ether__audio">
                <PlayerAudio/> {/*title={currentSong} author={currentArtist}  src={currentCover}*/}
              </div>
              <div className="header-ether__video">
                <PlayerVideo />
              </div>
            </div>
            <div className="header-nav">
              <nav className={`header-bar ${activeBurger}`}>
                {renderNavMenu}
                <div class = "social_links_hidden">
                  {socialLinks.map((el, index) => (
                      <div
                        key={index.toString()}
                        className="banner__link-item"
                      >
                        <a
                          href={el.link}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            src={el.picture}
                            alt={el.title}
                            className="social-img"
                          />
                        </a>
                      </div>
                  ))}
                </div>
              </nav>
            </div>
          </div>
        </div>
        <div
          aria-hidden
          className={`header-burger ${activeBurger}`}
          onClick={burgerHandler}
        >
          <span />
        </div>
      </header>
      <nav className={`navigation ${headerScroll}`}>{renderNavMenu}</nav>
    </div>
  );
})

Header.propTypes = {
  setPlayerOpen: PropTypes.func,
};

Header.defaultProps = {
  setPlayerOpen: () => {},
};
