import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTytes from 'prop-types';
import { withStyles, Button } from '@material-ui/core';
import PapperBlock from '../../components/PapperBlock';
import DataTable from '../../components/DataTable';
import { getDataForTable } from './requests';
import { StreamsIcon } from '../../constants/icons';
import { ADMIN_STREAMS_V_CREATE_ROUTE } from '../../constants/routes';
import Columns from './Columns';
import styles from '../../styles';

function VideoStreamPage({ classes }) {
  const [data, setData] = useState([]);
  const history = useHistory();

  const processingRequest = async () => {
    setData((await getDataForTable()).data);
  };
  useEffect(() => {
    processingRequest();
  }, []);

  const createBtnHandler = () => history.push(ADMIN_STREAMS_V_CREATE_ROUTE);

  return (
    <PapperBlock title="Видео стримы" icon={<StreamsIcon />}>
      <Button onClick={createBtnHandler} variant="contained" color="primary" className={classes.field}>
        Добавить стрим
      </Button>
      <DataTable columns={Columns} data={data} />
    </PapperBlock>
  );
}

export default withStyles(styles)(VideoStreamPage);

VideoStreamPage.propTypes = {
  classes: PropTytes.object,
};

VideoStreamPage.defaultProps = {
  classes: {},
};
