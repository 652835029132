import React from 'react';

export default function SvgOffersArchive() {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M38 11.1792V5.58496C38 4.97013 37.5015 4.47168 36.8867 4.47168H1.11328C0.498453 4.47168 0 4.97013 0 5.58496V11.1792H38Z"
        fill="#7500AB"
      />
      <path
        d="M16.7642 20.1226H21.2358C21.8548 20.1226 22.3584 19.619 22.3584 19C22.3584 18.381 21.8548 17.8774 21.2358 17.8774H16.7642C16.1452 17.8774 15.6416 18.381 15.6416 19C15.6416 19.619 16.1452 20.1226 16.7642 20.1226Z"
        fill="#7500AB"
      />
      <path
        d="M2.23633 13.4058V32.415C2.23633 33.0299 2.73478 33.5283 3.34961 33.5283H34.6514C35.2662 33.5283 35.7646 33.0299 35.7646 32.415V13.4058H2.23633ZM16.7646 15.6509H21.2363C23.083 15.6509 24.5854 17.1533 24.5854 19C24.5854 20.8467 23.083 22.3491 21.2363 22.3491H16.7646C14.9179 22.3491 13.4155 20.8467 13.4155 19C13.4155 17.1533 14.9179 15.6509 16.7646 15.6509Z"
        fill="#7500AB"
      />
    </svg>
  );
}
