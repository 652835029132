import fetchRequest from '../../helpers/fetchRequest';

const TRACKS_FOR_FORM = '/tracks-form';

export async function getTracksForForm() {
  const api = TRACKS_FOR_FORM;
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(api, options);
}

export async function createPlaylist(data, icon) {
  const formData = new FormData();
  formData.append('icon', icon);
  formData.append('data', JSON.stringify(data));
  const api = '/playlists';
  const options = {
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
    body: JSON.stringify(data),
  };
  return fetchRequest(api, options);
}

export async function getPlaylistsForTable() {
  const api = '/playlists';
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(api, options);
}

export async function deletePlaylistById(id) {
  const api = `/playlists/${id}`;
  const options = {
    method: 'DELETE',
  };
  return fetchRequest(api, options);
}

export async function getPlaylistById(id) {
  const api = `/playlists/${id}`;
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(api, options);
}

export async function updatePlaylistById(data, icon) {
  const formData = new FormData();
  formData.append('icon', icon);
  formData.append('data', JSON.stringify(data));
  const url = `/playlists/${data.id}`;
  const options = {
    method: 'PUT',
    body: formData,
  };
  return fetchRequest(url, options);
}
