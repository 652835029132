import fetchRequest from '../../helpers/fetchRequest';

const PROGRAMS = '/programs';
const PODCASTS_FOR_FORM = '/podcasts-form';
const EMCEES_FOR_FORM = '/emcees-form';
const GUESTS_FOR_FORM = '/guests-form';

export async function createProgram(data, picture) {
  const formData = new FormData();
  formData.append('picture', picture);
  formData.append('data', JSON.stringify(data));
  console.log("data", JSON.stringify(data))
  const url = PROGRAMS;
  const options = {
    method: 'POST',
    body: formData,
  };
  return fetchRequest(url, options);
}

export async function getProgramsForTable() {
  const url = PROGRAMS;
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function deleteProgramById(id) {
  const url = `${PROGRAMS}/${id}`;
  const options = {
    method: 'DELETE',
  };
  return fetchRequest(url, options);
}

export async function getProgramById(id) {
  const url = `${PROGRAMS}/${id}`;
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function updateProgramById(data, picture) {
  const formData = new FormData();
  formData.append('picture', picture);
  formData.append('data', JSON.stringify(data));
  const url = `${PROGRAMS}/${data.id}`;
  const options = {
    method: 'PUT',
    body: formData,
  };
  return fetchRequest(url, options);
}

export async function getPodcastsForForm() {
  const url = PODCASTS_FOR_FORM;
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function getEmceesForForm() {
  const url = EMCEES_FOR_FORM;
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function getGuestsForForm() {
  const url = GUESTS_FOR_FORM;
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}
