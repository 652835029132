import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import MainMenu from './MainMenu';
import styles from './sidebar-jss';

function SidebarContent({
  classes,
  drawerPaper,
  toggleDrawerOpen,
  loadTransition,
  leftSidebar,
  dataMenu,
}) {
  const refSidebar = useRef(null);

  return (
    <div
      className={classNames(
        classes.drawerInner,
        !drawerPaper ? classes.drawerPaperClose : '',
      )}
    >
      <div
        id="sidebar"
        ref={refSidebar}
        className={classNames(
          classes.menuContainer,
          leftSidebar && classes.rounded,
          classes.withProfile,
        )}
      >
        <MainMenu
          loadTransition={loadTransition}
          dataMenu={dataMenu}
          toggleDrawerOpen={toggleDrawerOpen}
        />
      </div>
    </div>
  );
}

SidebarContent.propTypes = {
  classes: PropTypes.object,
  drawerPaper: PropTypes.bool,
  toggleDrawerOpen: PropTypes.func,
  loadTransition: PropTypes.func,
  leftSidebar: PropTypes.bool,
  dataMenu: PropTypes.array,
};

SidebarContent.defaultProps = {
  classes: {},
  drawerPaper: false,
  toggleDrawerOpen: () => {},
  loadTransition: () => {},
  leftSidebar: false,
  dataMenu: [],
};

export default withStyles(styles)(SidebarContent);
