import React from 'react';
import { format } from 'date-fns';
import { DATE_TIME_FORMAT_FOR_COLUMNS} from '../../constants/dateTimeFormat';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import styles from '../../styles';
import { deleteSlideById } from './requests';

export default function Columns(handleRemove = () => {}) {
  const classes = makeStyles(styles)();
  const history = useHistory();
  return [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: 'picture',
      label: 'Картинка',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => (
          <img src={value} alt="img" className={classes.columnImg} />
        ),
      },
    },
    {
      name: 'sort',
      label: 'Сортировка',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'status',
      label: 'Статус',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          if (value) {
            return 'Видимый';
          }
          if (!value) {
            return 'Невидимый';
          }
          return 'Неизвестно';
        },
      },
    },
    {
      name: 'publish_date',
      label: 'Дата/Время публикации',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          if (value) return format(new Date(value), DATE_TIME_FORMAT_FOR_COLUMNS)
          return "Не установлено"
        }
      },
    },
    {
      name: 'unpublish_date',
      label: 'Дата/Время окончания публикации',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          if (value) return format(new Date(value), DATE_TIME_FORMAT_FOR_COLUMNS)
          return "Не установлено"
        }
      },
    },
    {
      name: '',
      label: 'Управление',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => (
          <>
            <IconButton
              onClick={() => {
                const id = tableMeta.rowData[0];
                history.push(`/admin/slides/update/${id}`);
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={async () => {
                if(window.confirm('Вы уверены, что хотите удалить?')){
                  const id = tableMeta.rowData[0];
                  const { rowIndex } = tableMeta;
                  handleRemove(rowIndex);
                  await deleteSlideById(id);
                  window.location.reload();
                }
              }}
            >
              <DeleteIcon />
            </IconButton>
          </>
        ),
      },
    },
  ];
}
