import React, {useState, useEffect} from 'react';
import { Provider, useSelector, useDispatch } from 'react-redux';
import { createStore } from 'redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import Fade from '@material-ui/core/Fade';
import rootReduser from './redux/rootReduser';
import Layout from './Layout';
import Main from './pages/Main';
import Music from './pages/Music';
import PlaylistDetail from './pages/PlaylistDetail';
import Offers from './pages/Offers';
import OffersDetail from './pages/OffersDetail';
import OffersArchive from './pages/OffersArchive';
import News from './pages/News';
import NewsDetail from './pages/NewsDetail';
import Podcasts from './pages/Podcasts';
import Programs from './pages/Programs';
import ProgramsDetail from './pages/ProgramsDetail';
import ProgramsArchive from './pages/ProgramsArchive';
import Emcees from './pages/Emcees';
import About from './pages/About';
import Novelty from './pages/Novelty';
import {
  MAIN,
  MUSIC,
  PLAYLIST,
  OFFERS,
  OFFERS_DETAIL,
  OFFERS_ARCHIVE,
  NEWS,
  NEWS_DETAIL,
  PODCASTS,
  PROGRAMS,
  PROGRAMS_DETAIL,
  EMCEES,
  ABOUT,
  PROGRAMS_ARCHIVE,
  NOVELTY,
} from './constants/routes';
import {
  setLoadedPage,
} from './redux/actions';

const store = createStore(rootReduser);

const routes = [
  {
    path: MAIN,
    component: Main,
  },
  {
    path: MUSIC,
    component: Music,
  },
  {
    path: PLAYLIST,
    component: PlaylistDetail,
  },
  {
    path: OFFERS,
    component: Offers,
  },
  {
    path: OFFERS_ARCHIVE,
    component: OffersArchive,
  },
  {
    path: OFFERS_DETAIL,
    component: OffersDetail,
  },
  {
    path: PODCASTS,
    component: Podcasts,
  },
  {
    path: NEWS,
    component: News,
  },
  {
    path: NEWS_DETAIL,
    component: NewsDetail,
  },
  {
    path: PROGRAMS,
    component: Programs,
  },
  {
    path: PROGRAMS_ARCHIVE,
    component: ProgramsArchive,
  },
  {
    path: PROGRAMS_DETAIL,
    component: ProgramsDetail,
  },
  {
    path: EMCEES,
    component: Emcees,
  },
  {
    path: ABOUT,
    component: About,
  },
  {
    path: NOVELTY,
    component: Novelty,
  },
];

const renderRoutes = routes.map((el) => (
  <Route exact path={el.path} key={el.path}>
    <Fade in timeout={700}>
      <div>
        <el.component />
      </div>
    </Fade>
  </Route>
));
export default function App() {
  // const { isPageLoaded } = useSelector((state) => state);
  // const [isLoaded, setIsloaded] = useState(false);
  // // const dispatch = useDispatch();

  // useEffect(() => {
  //   // dispatch(setLoadedPage(true));
  //   setIsloaded(true);
  // }, []);

  return (
    // isLoaded ? (
      <Provider store={store}>
      <Layout>
        <Switch>
          {renderRoutes}
          <Redirect to="/not-found" />
        </Switch>
      </Layout>
    </Provider>
    // ) : (
    //   <div>Loading</div>
    // )
    
  );
}
