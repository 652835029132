import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PapperBlock from '../../../components/PapperBlock';
import UsersForm from '../UsersForm';
import {
  createUser,
} from '../requests';
import { UsersIcon } from '../../../constants/icons';
import { ADMIN_USERS_ROUTE } from '../../../constants/routes';

export default function createUsers() {
  const [loaded, setLoaded] = useState(false);
  async function processingRequest() {
    setLoaded(true);
  }
  const history = useHistory();
  const submitHandler = async (data) => {
    if((data.newPassword != data.confirmPassword) || 
      (data.newPassword != "" && data.confirmPassword == "") ||
      (data.newPassword == "" && data.confirmPassword != "")) {
      alert("Значения в полях 'Новый пароль' и 'Подтвердить пароль' не совпадают");
      return false;
    } else if (data.newPassword == "" && data.confirmPassword == "") {
      alert("Нельзя создать пользователя без пароля");
      return false;
    }
    const response = await createUser(data);
    if (response.ok) {
      history.push(ADMIN_USERS_ROUTE);
    } else {
      // console.log(response);
      alert(response.data.message)
    }
  };
  useEffect(() => {
    processingRequest();
  }, []);

  return (
    <PapperBlock title="Добавить пользователя" icon={<UsersIcon />}>
      {loaded && (
        <UsersForm
          user={[]}
          submitHandler={submitHandler}
        />
      )}
    </PapperBlock>
  );
}
