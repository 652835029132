import React, { useEffect, useState } from 'react';
import { Button, Switch, withStyles, TextField} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import PapperBlock from '../../../components/PapperBlock';
import { SettingIcon } from '../../../constants/icons';
import InputField from '../../../components/InputField';
import { getPollData, updatePollData } from '../requests';
import { ADMIN_SETTING_ROUTE } from '../../../constants/routes';
import { DATE_FORMAT, DATE_FORMAT_TO_DB, DATE_TIME_FORMAT } from '../../../constants/dateTimeFormat';
import classNames from 'classnames';
import styles from '../../../styles';
import { format } from 'date-fns';

const PollSettings = ({ classes }) => {
  const [pollData, setPollData] = useState({});
  const [content, setContent] = useState('');
  const [active, setActive] = useState(0);
  const [publishDate, setPublishDate] = useState(null);
  const [unpublishDate, setUnpublishDate] = useState(null);
  const history = useHistory();

  async function processingRequest() {
    setPollData((await getPollData()).data);
  }

  useEffect(() => {
    processingRequest();
  }, []);

  useEffect(() => {
    setContent(pollData.content);
    setActive(pollData.active);
    setPublishDate(pollData.publish_date ? new Date(pollData.publish_date) : pollData.publish_date);
    setUnpublishDate(pollData.unpublish_date ? new Date(pollData.unpublish_date) : pollData.unpublish_date);
  }, [pollData]);

  const submitHandler = async (event) => {
    event.preventDefault();
    console.log(content)
    const response = await updatePollData({
      content,
      active,
      publishDate: publishDate ? format(publishDate, DATE_FORMAT_TO_DB) : null,
      unpublishDate: unpublishDate ? format(unpublishDate, DATE_FORMAT_TO_DB) : null,
    });
    if (response.ok) {
      history.push(ADMIN_SETTING_ROUTE);
    }
  };

  return (
    <PapperBlock title="Обновить опросник" icon={<SettingIcon />}>
      <form onSubmit={submitHandler}>

      <InputField title="Код опросника" value={content} setValue={setContent} />

        Активность
        <Switch
          color="primary"
          checked={active}
          onChange={(e) => {
            setActive(e.target.checked);
          }}
        />

        <div className={classNames(classes.field, classes.dateRow)}>
          <div className={classes.dateColumn}>
            <div className={classes.dateRow}>Дата публикации:</div>
            <TextField
              className={classes.dateRow}
              type="datetime-local"
              value={
                publishDate ? format(publishDate, DATE_TIME_FORMAT) : undefined
              }
              onChange={(e) => {
                setPublishDate(Date.parse(e.target.value));
              }}
            />
          </div>
          <div className={classes.dateColumn}>
            <div className={classes.dateRow}>Дата снятия с публикации:</div>
            <TextField
              className={classes.dateRow}
              type="datetime-local"
              value={
                unpublishDate
                ? format(unpublishDate, DATE_TIME_FORMAT)
                : undefined
              }
              onChange={(e) => {
                setUnpublishDate(Date.parse(e.target.value));
              }}
            />
          </div>
        </div>

        <Button variant="contained" color="primary" type="submit" fullWidth>
          Сохранить изменения
        </Button>
      </form>
    </PapperBlock>
  );
};
export default withStyles(styles)(PollSettings);

PollSettings.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
};

PollSettings.defaultProps = {
  classes: {},
};
