/* eslint-disable import/prefer-default-export */
import fetchRequest from '../../helpers/fetchRequest';

export async function getLatestPodcasts() {
  const url = '/latest-podcasts';
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function getFilterPrograms() {
  const url = '/filter/programs';
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function getUniqueYears(year = null, month = null, program = null) {
  const url = '/filter-podcast/unique-years';
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      year: year,
      month: month,
      program: program
    })
  };
  return fetchRequest(url, options);
}

export async function getUniqueMonths(year = null, month = null, program = null) {
  const url = '/filter-podcast/unique-months';
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      year: year,
      month: month,
      program: program
    })
  };
  return fetchRequest(url, options);
}

export async function getUniquePrograms(year = null, month = null, program = null) {
  const url = '/filter-podcast/unique-programs';
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      year: year,
      month: month,
      program: program
    })
  };
  return fetchRequest(url, options);
}