import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TextField, Button, withStyles, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import ImageDropzone from '../../components/Dropzone/ImageDropzone';
import CheckboxesTags from '../../components/CheckboxesTags';
import styles from '../../styles';
import {maxImageFileSize} from "../../constants/additional";
import { DATE_FORMAT, DATE_FORMAT_TO_DB, DATE_TIME_FORMAT } from '../../constants/dateTimeFormat';
import { format } from 'date-fns';
import classNames from 'classnames';

function QuizForm({
  classes, data, submitHandler,
}) {
  const newData = data[0]
  // console.log("data", data.answers.length ? Array.from(JSON.parse(JSON.parse(data.answers))): "loh");
  const { id } = data;
  const [title, setTitle] = useState(data.title);
  const [status, setStatus] = useState(data.status ? data.status : 0);
  const [alias, setAlias] = useState(data.alias);
  const [publishDate, setPublishDate] = useState(
    data.publish_date ? new Date(data.publish_date) : data.publish_date,
  );
  const [unpublishDate, setUnpublishDate] = useState(
    data.unpublish_date ? new Date(data.unpublish_date) : data.unpublish_date,
  );
  const [type, setType] = useState(data.type ? data.type : "single");
  const [answers, setAnswers] = useState(data.answers.length ? data.answers : [{"id": Date.now(), "answer": "", "votes": 0}]);
  const createDate = new Date(data.create_date);
  const [overallVotes, setOverallVotes] = useState(0);
  // const [sort, setSort] = useState(data.sort ? data.sort : 500);
  // const [avatarFile, setAvatarFile] = useState([]);
  // const [avatarUrl, setAvatarUrl] = useState(data.avatar);
  // const [avatarToDelete, setAvatarToDelete] = useState([]);
  // const [photoFiles, setPhotoFiles] = useState([]);
  // const [photoUrls, setPhotoUrls] = useState(data.photos);
  // const [photoUrlsToDelete, setPhotoUrlsToDelete] = useState([]);

  const setAllVotes = () => {
    let allVotes = 0;
    answers.map(answer => allVotes += Number.parseInt(answer.votes));
    setOverallVotes(allVotes);
  }
  useEffect(() => {
    setAllVotes();
  }, [answers])
  async function handleSubmit(event) {
    event.preventDefault();

    
    const ifOk = confirm('Вы уверены что хотите сохранить изменения?');
    if (ifOk) {
    // eslint-disable-next-line no-return-await
    await submitHandler(
      {
        id,
        title,
        status,
        createDate: format(createDate, DATE_FORMAT_TO_DB),
        publishDate: publishDate ? format(publishDate, DATE_FORMAT_TO_DB) : null,
        unpublishDate: unpublishDate ? format(unpublishDate, DATE_FORMAT_TO_DB) : null,
        type,
        answers,
      }
    );
    } else {
      history.back();
    }
  }
  const changeAnswers =(value, id) => {
    const existedAnswerIndex = answers.findIndex(answer => answer.id == id)
    setAnswers(answers.map(answer => 
      answer.id == id 
      ? { ...answer, "answer": value }
      : answer        
    ))
  }
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div>
          <div className={classes.field}>
            <div className={classes.title}>Вопрос:</div>
            <TextField
              value={title}
              required
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              className={classes.field}
            />
          </div>
          <div className={classes.field}>
            <div className={classes.title}>Варианты ответов:</div>
            {answers.map(answer => {
              return (
                <div className={classes.quizRow}>
                  <TextField
                    value={answer.answer}
                    required
                    onChange={(e) => {
                      changeAnswers(e.target.value, answer.id);
                      // setAnswers(answers)
                    }}
                    className={classes.field}
                  />
                  <p className={`${classes.quizInactiveLine} ${classes.votesSumm}`}>{answer.votes} голос</p>
                  <p className={`${classes.quizInactiveLine} ${classes.votesPercent}`}>{overallVotes > 0 && answer.votes > 0 ? `${Math.round(answer.votes / overallVotes * 100)}%` : '0%'}</p>
                </div>
              )
            })}
            <div className={classes.quizResultsRow}>
              <p className={`${classes.quizInactiveLine}`}>{overallVotes} голос</p>
            </div>
          </div>
          
            <div onClick={() => setAnswers(
              [...answers,
                {
                  "id": Date.now(), 
                  "answer": "", 
                  "votes": 0
                }
              ]
              )} 
            className = {`${classes.addButton} ${classes.buttonWidthOneHundreed}`}
            >
              Добавить вариант ответа
            </div>
            
          
          <div>
            <div className={classes.title}>
              Статус видимости (Отображать на главной):
            </div>
            <FormControl className={classes.field}>
              <InputLabel id="select-status" />
              <Select
                required
                labelId="select-status"
                value={status}
                onChange={(e) => {
                  setStatus(e.target.value);
                }}
              >
                <MenuItem value={1}>Видимый</MenuItem>
                <MenuItem value={0}>Невидимый</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div>
            <div className={classes.title}>
              Тип вопроса:
            </div>
            <FormControl className={classes.field}>
              <InputLabel id="select-status" />
              <Select
                required
                labelId="select-status"
                value={type}
                onChange={(e) => {
                  setType(e.target.value);
                }}
              >
                <MenuItem value={"single"}>Один из списка</MenuItem>
                <MenuItem value={"multiple"}>Со множественным выбором</MenuItem>
              </Select>
            </FormControl>
          </div>
          <Button variant="contained" color="primary" type="submit" fullWidth>
            {id ? 'Обновить' : 'Создать'}
          </Button>
        </div>
        <div />
      </form>
    </div>
  );
}

QuizForm.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  submitHandler: PropTypes.func,
};

QuizForm.defaultProps = {
  classes: {},
  data: {
    id: undefined,
    title: '',
    status: '',
    create_date: Date.now(),
    type: '',
    answers: []
  },
  submitHandler: () => {},
};

export default withStyles(styles)(QuizForm);
