import React from 'react';
import { useHistory } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { deleteSocial } from './requests';

export default function Columns(handleRemove) {
  const history = useHistory();
  return [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: 'picture',
      label: 'Картинка',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => <img src={value} alt="img" style={{ maxWidth: '100px' }} />,
      },
    },
    {
      name: 'title',
      label: 'Название',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'redirect',
      label: 'Управление',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => (
          <>
            <IconButton
              onClick={() => {
                const id = tableMeta.rowData[0];
                history.push(`/admin/settings/socials-update-${id}`);
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={async () => {
                const id = tableMeta.rowData[0];
                const { rowIndex } = tableMeta;
                handleRemove(rowIndex);
                await deleteSocial(id);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </>
        ),
      },
    },
  ];
}
