import React from 'react';

export default function SvgNews() {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.7441 17.8867H17.8875V11.1699H12.5982C12.1056 13.2375 11.8138 15.5137 11.7441 17.8867Z"
        fill="#7500AB"
      />
      <path
        d="M20.1133 17.8867H26.2567C26.187 15.5137 25.8952 13.2375 25.4026 11.1699H20.1133V17.8867Z"
        fill="#7500AB"
      />
      <path
        d="M28.4849 17.8867H37.968C37.8329 15.5374 37.2721 13.2686 36.3222 11.1699H27.6855C28.1467 13.2663 28.4192 15.537 28.4849 17.8867Z"
        fill="#7500AB"
      />
      <path
        d="M10.3156 11.1699H1.67893C0.729152 13.2686 0.168281 15.5374 0.0332031 17.8867H9.51628C9.58196 15.537 9.85442 13.2663 10.3156 11.1699Z"
        fill="#7500AB"
      />
      <path
        d="M17.8877 2.4472C16.5048 2.99011 15.1597 4.5191 14.0786 6.82478C13.768 7.48725 13.4865 8.19619 13.2344 8.94335H17.8877V2.4472Z"
        fill="#7500AB"
      />
      <path
        d="M12.0622 5.87954C13.8399 2.08807 16.3039 0 19.0004 0C13.9253 0 9.15404 1.97637 5.56533 5.56492C4.53072 6.59961 3.63097 7.73307 2.87305 8.94336H10.897C11.23 7.8516 11.6191 6.82441 12.0622 5.87954Z"
        fill="#7500AB"
      />
      <path
        d="M25.9383 5.87954C26.3813 6.82434 26.7703 7.8516 27.1034 8.94336H35.1273C34.3694 7.73307 33.4696 6.59961 32.4349 5.56492C28.8464 1.97637 24.0751 0 19 0C21.6964 0 24.1605 2.08807 25.9383 5.87954Z"
        fill="#7500AB"
      />
      <path
        d="M20.1133 2.4472V8.94335H24.7666C24.5145 8.19619 24.2329 7.48725 23.9223 6.82478C22.8413 4.51917 21.4963 2.99019 20.1133 2.4472Z"
        fill="#7500AB"
      />
      <path
        d="M26.2227 20H20.0793V26.7168H25.3686C25.8612 24.6492 26.153 22.373 26.2227 20Z"
        fill="#7500AB"
      />
      <path
        d="M17.8535 20H11.7101C11.7798 22.373 12.0716 24.6492 12.5642 26.7168H17.8535V20Z"
        fill="#7500AB"
      />
      <path
        d="M9.48191 20H-0.00116348C0.133915 22.3493 0.694713 24.6181 1.64456 26.7168H10.2812C9.82005 24.6204 9.5476 22.3498 9.48191 20Z"
        fill="#7500AB"
      />
      <path
        d="M27.6512 26.7168H36.2879C37.2376 24.6181 37.7985 22.3493 37.9336 20H28.4505C28.3848 22.3498 28.1124 24.6204 27.6512 26.7168Z"
        fill="#7500AB"
      />
      <path
        d="M20.0791 35.4395C21.462 34.8966 22.8071 33.3676 23.8882 31.0619C24.1988 30.3995 24.4803 29.6905 24.7324 28.9434H20.0791V35.4395Z"
        fill="#7500AB"
      />
      <path
        d="M25.9046 32.0072C24.1269 35.7986 21.6629 37.8867 18.9664 37.8867C24.0415 37.8867 28.8128 35.9103 32.4015 32.3218C33.4361 31.2871 34.3358 30.1536 35.0938 28.9434H27.0698C26.7368 30.0351 26.3477 31.0623 25.9046 32.0072Z"
        fill="#7500AB"
      />
      <path
        d="M12.0285 32.0072C11.5855 31.0624 11.1965 30.0351 10.8634 28.9434H2.83951C3.59743 30.1536 4.49718 31.2871 5.53187 32.3218C9.12042 35.9103 13.8917 37.8867 18.9668 37.8867C16.2704 37.8867 13.8063 35.7986 12.0285 32.0072Z"
        fill="#7500AB"
      />
      <path
        d="M17.8535 35.4395V28.9434H13.2002C13.4523 29.6905 13.7339 30.3995 14.0445 31.0619C15.1255 33.3675 16.4705 34.8965 17.8535 35.4395Z"
        fill="#7500AB"
      />
      <defs>
        <clipPath id="clip0">
          <rect width="38" height="38" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
