import React from 'react';

export default function SvgVideo() {
  return (
    <svg
      width="68"
      height="68"
      viewBox="0 0 68 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="34" cy="34" r="34" fill="#E40A3B" fillOpacity="0.1" />
      <circle cx="34.0002" cy="34" r="28.4865" fill="#E40A3B" />
      <path
        d="M33.9998 60.035C19.644 60.035 7.96484 48.3557 7.96484 34C7.96484 19.6443 19.644 7.96506 33.9998 7.96506C43.2874 7.96506 51.9388 12.9704 56.5776 21.0278C57.1088 21.9506 56.7914 23.1296 55.8686 23.661C54.946 24.1924 53.7668 23.8748 53.2355 22.952C49.283 16.0865 41.9121 11.8214 33.9998 11.8214C21.7704 11.8214 11.8212 21.7708 11.8212 34C11.8212 46.2293 21.7706 56.1787 33.9998 56.1787C46.2291 56.1787 56.1785 46.2293 56.1785 34C56.1785 32.9351 57.0417 32.0719 58.1066 32.0719C59.1715 32.0719 60.0348 32.9351 60.0348 34C60.0348 48.3559 48.3554 60.035 33.9998 60.035Z"
        fill="#E40A3B"
      />
      <g clipPath="url(#clip0)">
        <path
          d="M36.5244 25.9394H24.0303C22.3575 25.9413 21.0019 27.2969 21 28.9697V39.0272C21.0019 40.7001 22.3575 42.0556 24.0303 42.0575H36.5244C38.1972 42.0556 39.5528 40.7001 39.5547 39.0272V28.9697C39.5528 27.2969 38.1972 25.9413 36.5244 25.9394V25.9394Z"
          fill="white"
        />
        <path
          d="M48.6942 27.5173C48.5052 27.4096 48.273 27.4112 48.0855 27.5218L41.3847 31.4782C41.2001 31.5873 41.0869 31.7857 41.0869 32.0002V35.6551C41.0869 35.8423 41.1733 36.0192 41.3213 36.134L48.0221 41.3362C48.2048 41.478 48.4525 41.5035 48.6603 41.4017C48.8682 41.2999 48.9998 41.0888 48.9998 40.8572V28.0436C48.9998 27.826 48.8831 27.6252 48.6942 27.5173Z"
          fill="white"
        />
        <rect
          x="23.8955"
          y="38.3448"
          width="7.72414"
          height="1.93104"
          rx="0.965519"
          fill="#E40A3B"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="28"
            height="28"
            fill="white"
            transform="translate(21 20)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
