import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import PapperBlock from '../../../components/PapperBlock';
import EmceesForm from '../EmceesForm';
import { getProgramsForForm, getEmceeById, updateEmceeById } from '../requests';
import { EmceeIcon } from '../../../constants/icons';
import { ADMIN_EMCEES_ROUTE } from '../../../constants/routes';

export default function UpdateEmcees() {
  const [dataEmcee, setDataEmcee] = useState({});
  const [programsList, setProgramsList] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const params = useParams();
  const { id } = params;
  async function processingRequest() {
    setDataEmcee((await getEmceeById(id)).data);
    setProgramsList((await getProgramsForForm()).data);
    setLoaded(true);
  }
  const history = useHistory();
  const submitHandler = async (data, avatar, photos) => {
    const response = await updateEmceeById(data, avatar, photos);
    if (response.ok) {
      history.push(ADMIN_EMCEES_ROUTE);
    }
  };
  useEffect(() => {
    processingRequest();
  }, []);

  return (
    <PapperBlock title="Обновить ведущего" icon={<EmceeIcon />}>
      {loaded && (
        <EmceesForm
          data={dataEmcee}
          programs={programsList}
          submitHandler={submitHandler}
        />
      )}
    </PapperBlock>
  );
}
