import React from 'react';
import PropTypes from 'prop-types';

export default function SvgPause({ color }) {
  const white = '#ffffff';
  const red = '#E40A3B';
  const isWhite = color === 'white';
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 47 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="23.5"
        cy="23.5"
        r="23.5"
        fill={isWhite ? white : red}
        fillOpacity={isWhite ? '0.5' : '0.1'}
      />
      <ellipse
        cx="23.4997"
        cy="23.5002"
        rx="19.6892"
        ry="19.6892"
        fill={isWhite ? white : red}
      />
      <path
        d="M23.4996 41.4949C13.5772 41.4949 5.50488 33.4225 5.50488 23.5001C5.50488 13.5778 13.5772 5.50537 23.4996 5.50537C29.919 5.50537 35.8987 8.96492 39.1048 14.5341C39.472 15.1719 39.2526 15.9867 38.6148 16.354C37.9771 16.7213 37.1621 16.5018 36.7949 15.864C34.063 11.1188 28.9684 8.17079 23.4996 8.17079C15.047 8.17079 8.1703 15.0476 8.1703 23.5001C8.1703 31.9527 15.0471 38.8295 23.4996 38.8295C31.9522 38.8295 38.829 31.9527 38.829 23.5001C38.829 22.7641 39.4257 22.1674 40.1617 22.1674C40.8977 22.1674 41.4944 22.7641 41.4944 23.5001C41.4944 33.4225 33.4219 41.4949 23.4996 41.4949Z"
        fill={isWhite ? white : red}
      />
      <rect
        x="19"
        y="17"
        width="3"
        height="14"
        rx="1.5"
        fill={isWhite ? red : white}
      />
      <rect
        x="25"
        y="17"
        width="3"
        height="14"
        rx="1.5"
        fill={isWhite ? red : white}
      />
    </svg>
  );
}

SvgPause.propTypes = {
  color: PropTypes.oneOf(['white', 'red']),
};

SvgPause.defaultProps = {
  color: 'white',
};
