import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { format } from 'date-fns';
import {
  Button,
  Select,
  withStyles,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
  Tabs,
  Tab,
} from '@material-ui/core';
import InputMask from 'react-input-mask';
import MaterialInput from '@material-ui/core/Input';
import ImageDropzone from '../../components/Dropzone/ImageDropzone';
import CheckboxesTags from '../../components/CheckboxesTags';
import CrudGrid from '../../components/CrudGrid';
import { DATE_FORMAT, DATE_FORMAT_TO_DB } from '../../constants/dateTimeFormat';
import translit from '../../helpers/translit';
import WysiwygWidget from '../../components/WysiwygWidget';
import styles from '../../styles';
import {maxImageFileSize} from "../../constants/additional";

function UsersForm({
  classes,
  user,
  // guests,
  submitHandler,
}) {
  // console.log("user", user);
  const { id } = user;
  const [login, setLogin] = useState(user.login);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  async function handleSubmit(event) {
    event.preventDefault();

    const ifOk = confirm('Вы уверены что хотите сохранить изменения?');
    if (ifOk) {
      console.log(login);
    await submitHandler(
      {
        id,
        login: login,
        oldPassword: oldPassword,
        newPassword: newPassword,
        confirmPassword: confirmPassword,
      }
    );
    } else {
      history.back();
    }
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
          <div>
            <div className={classes.field}>
              <div className={classes.title}>Логин</div>
              <TextField
                value={login}
                onChange={(e) => {
                  setLogin(e.target.value);
                  console.log(e.target.value);
                }}
                className={classes.field}
              />
            </div>
            {id ? (
              <div>
                <div className={classes.title}>Изменить пароль:</div>
                <div className={classes.field}>
                  <div className={classes.title}>Старый пароль:</div>
                  <TextField
                    value={oldPassword}
                    onChange={(e) => {
                      setOldPassword(e.target.value);
                    }}
                    className={classes.field}
                  />
                  <div className={classes.title}>Новый пароль:</div>
                  <TextField
                    value={newPassword}
                    onChange={(e) => {
                      setNewPassword(e.target.value);
                    }}
                    className={classes.field}
                  />
                  <div className={classes.title}>Подтвердить пароль:</div>
                    <TextField
                      value={confirmPassword}
                      onChange={(e) => {
                        setConfirmPassword(e.target.value);
                      }}
                      className={classes.field}
                    />
                </div>
              </div>
            ) : (
              <div className={classes.field}>
                <div className={classes.title}>Новый пароль:</div>
                <TextField
                  value={newPassword}
                  onChange={(e) => {
                    setNewPassword(e.target.value);
                  }}
                  className={classes.field}
                />
                <div className={classes.title}>Подтвердить пароль:</div>
                <TextField
                  value={confirmPassword}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                  }}
                  className={classes.field}
                  />
              </div>
            )}
            {/* </div> */}
              {/* <div className={classes.title}>
                Статус видимости (Отображать на главной):
              </div>
              <FormControl className={classes.field}>
                <InputLabel id="seletc-status" />
                <Select
                  required
                  labelId="seletc-status"
                  value={status}
                  onChange={(e) => {
                    setStatus(e.target.value);
                  }}
                >
                  <MenuItem value={1}>Видимый</MenuItem>
                  <MenuItem value={0}>Невидимый</MenuItem>
                </Select>
              </FormControl> */}
            {/* </div> */}
            <Button variant="contained" color="primary" type="submit" fullWidth>
              {id ? 'Обновить' : 'Создать'}
            </Button>
          </div>
      </form>
    </div>
  );
}

UsersForm.propTypes = {
  classes: PropTypes.object,
  user: PropTypes.object,
  submitHandler: PropTypes.func,
};

UsersForm.defaultProps = {
  classes: {},
  user: {
    id: undefined,
    login: '',
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  },
  submitHandler: () => {},
};

export default withStyles(styles)(UsersForm);
