import fetchRequest from '../../helpers/fetchRequest';

export async function getNoveltyChartTracks() {
  const url = '/novelty-chart';
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function getNotNoveltyChartTracks() {
  const url = '/not-novelty-chart';
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function addNoveltyChartTrackById(id) {
  const url = `/novelty-chart/${id}`;
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function updateNoveltyChartTracks(data) {
  const url = '/novelty-chart-update';
  const options = {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function deleteNoveltyChartTrack(data) {
  const url = '/novelty-chart-delete';
  const options = {
    method: 'DELETE',
    body: JSON.stringify(data),
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function resetNoveltyChartRating() {
  const url = '/novelty-chart-reset-rating';
  const options = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function writeNoveltyChartTimestamp(data) {
  const url = '/novelty-chart-write-timestamp';
  // const data = {"timestamp": timestamp};
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };
  return fetchRequest(url, options);
}

export async function deleteTrackFromNoveltyChartById(id) {
  const url = `/novelty-chart/${id}`;
  const options = {
    method: 'DELETE',
  };
  return fetchRequest(url, options);
}
