import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { format } from 'date-fns';
import {
  Button,
  Select,
  withStyles,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
  Tabs,
  Tab,
  Input,
} from '@material-ui/core';
import InputMask from 'react-input-mask';
import MaterialInput from '@material-ui/core/Input';
import ImageDropzone from '../../components/Dropzone/ImageDropzone';
import CheckboxesTags from '../../components/CheckboxesTags';
import CrudGrid from '../../components/CrudGrid';
import { DATE_FORMAT, DATE_FORMAT_TO_DB, DATE_TIME_FORMAT } from '../../constants/dateTimeFormat';
import translit from '../../helpers/translit';
import WysiwygWidget from '../../components/WysiwygWidget';
import styles from '../../styles';
import {maxImageFileSize} from "../../constants/additional";

function ProgramsForm({
  classes,
  data,
  podcasts,
  emcees,
  guests,
  submitHandler,
}) {
  const { id } = data;
  const [alias, setAlias] = useState(data.alias);
  const [title, setTitle] = useState(data.title);
  const [description, setDescription] = useState(data.description);
  const [content, setContent] = useState(data.content);
  const [status, setStatus] = useState(data.status);
  const [sort, setSort] = useState(data.sort);
  const [pictureUrl, setPictureUrl] = useState(data.picture);
  const [pictureFile, setPictureFile] = useState([]);
  const [pictureToDelete, setPictureToDelete] = useState([]);
  const [guestList, setGuestList] = useState(data.guests_list);
  const [emceesList, setEmceesList] = useState(data.emcees_list);
  const [podcastList, setPodcastList] = useState(data.podcasts_list);
  const [shortTimetable, setShorttimetable] = useState(data.short_timetable);
  const [detailTimetable, setDetailTimetable] = useState(data.detail_timetable);
  const [navigationList, setNavigationList] = useState(0);
  const [navigationPage, setNavigationPage] = useState(0);
  const [seoTitle, setSeoTitle] = useState(data.seo_title);
  const [seoDescription, setSeoDescription] = useState(data.seo_description);
  const [seoKeyWords, setSeoKeyWords] = useState(data.seo_key_words);
  const [publishDate, setPublishDate] = useState(
    data.publish_date ? new Date(data.publish_date) : null,
  );
  const [unpublishDate, setUnpublishDate] = useState(
    data.unpublish_date ? new Date(data.unpublish_date) : null,
  );
  const createDate = new Date(data.create_date);

  async function handleSubmit(event) {
    event.preventDefault();

    const ifOk = confirm('Вы уверены что хотите сохранить изменения?');
    if (ifOk) {
    // eslint-disable-next-line no-return-await
    await submitHandler(
      {
        id,
        alias,
        title,
        description,
        content,
        status,
        sort,
        createDate: format(createDate, DATE_FORMAT_TO_DB),
        pictureUrl,
        pictureToDelete,
        guestList,
        emceesList,
        podcastList,
        shortTimetable,
        detailTimetable,
        seoTitle,
        seoDescription,
        seoKeyWords,
        publishDate: publishDate ? format(publishDate, DATE_FORMAT_TO_DB) : null,
        unpublishDate: unpublishDate ? format(unpublishDate, DATE_FORMAT_TO_DB) : null,
      },
      pictureFile[0],
    );
    } else {
      history.back();
    }
  }

  const changeSchedule = (schedule_id, type, value) => {
    console.log(schedule_id, value)
    setDetailTimetable(detailTimetable.map(elem => 
      elem.program_schedule_id == schedule_id 
      ? { ...elem, [type]: value }
      : elem        
    ));
  };
  const deleteSchedule = (id) => {
    console.log(detailTimetable);
    if (detailTimetable.length == 1) {
      setDetailTimetable([]);
    } else {
      const indexOfObject = detailTimetable.findIndex(object => {
        return object.program_schedule_id === id;
      });
      setDetailTimetable(detailTimetable.splice(indexOfObject, 1));
    }
  }

  const days = [
    "Понедельник",
    "Вторник",
    "Среда",
    "Четверг",
    "Пятница",
    "Суббота",
    "Воскресенье"
  ];

  // const processingRequest = async () => {
  //   const schedules = ((await getSchedules(id)).data);
  //   setDetailTimetable(schedules);
  // };

  useEffect(() => {
    // processingRequest();
    console.log(JSON.stringify(data));
    console.log("emcees", emcees);
  }, []);






  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className={classes.field}>
          <Tabs
            indicatorColor="primary"
            textColor="primary"
            aria-label="disabled tabs example"
            value={navigationPage}
            onChange={(event, value) => {
              setNavigationPage(value);
            }}
          >
            <Tab label="Форма программы" />
            <Tab label="Форма SEO" />
          </Tabs>
        </div>

        {navigationPage === 0 && (
          <div>
            <div className={classes.field}>
              <div className={classes.title}>Alias домена:</div>
              <TextField
                disabled
                required
                value={alias}
                className={classes.field}
              />
            </div>
            <div className={classes.field}>
              <div className={classes.title}>Заголовок:</div>
              <TextField
                required
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                  setAlias(translit(e.target.value));
                }}
                className={classes.field}
              />
            </div>
            <div className={classes.field}>
              <div className={classes.title}>Описание:</div>
              <TextField
                required
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
                className={classes.field}
                multiline
              />
            </div>
            <div className={classes.field}>
              <div className={classes.title}>Сортировка:</div>
              <TextField
                value={sort}
                onChange={(e) => {
                  setSort(e.target.value);
                }}
                className={classes.field}
              />
            </div>
            <div className={classes.field}>
              <div className={classes.title}>Контент:</div>
              <WysiwygWidget setState={setContent} state={content} />
            </div>
            <div>
              <div className={classes.title}>
                Статус видимости (Отображать на главной):
              </div>
              <FormControl className={classes.field}>
                <InputLabel id="seletc-status" />
                <Select
                  required
                  labelId="seletc-status"
                  value={status}
                  onChange={(e) => {
                    setStatus(e.target.value);
                  }}
                >
                  <MenuItem value={1}>Видимый</MenuItem>
                  <MenuItem value={0}>Невидимый</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className={classes.field}>
              <div className={classes.title}>
                <Tabs
                  className={classes.title}
                  indicatorColor="primary"
                  textColor="primary"
                  aria-label="disabled tabs example"
                  value={navigationList}
                  onChange={(event, value) => {
                    setNavigationList(value);
                  }}
                >
                  <Tab label="Список ведущих" />
                  <Tab label="Список гостей" />
                  {/*<Tab label="Список подкастов" />*/}
                </Tabs>
              </div>
              {navigationList === 0 && (
                <CheckboxesTags
                  options={emcees}
                  selected={emceesList}
                  setSelected={setEmceesList}
                />
              )}
              {navigationList === 1 && (
                <CheckboxesTags
                  options={guests}
                  selected={guestList}
                  setSelected={setGuestList}
                />
              )}
            </div>
            {/* <div className={classes.field}>
              <div>Дата создания:</div>
              {format(createDate, DATE_FORMAT)}
            </div> */}
            <div className={classes.field}>
              <div className={classes.title}>Краткое расписание:</div>
              <TextField
                required
                value={shortTimetable}
                onChange={(e) => {
                  setShorttimetable(e.target.value);
                }}
                className={classes.field}
                multiline
              />
            </div>
            <div className={classes.flexOne}>
              <div
                className={classNames(classes.rightPadding, classes.fullWidth)}
              >
                <ImageDropzone
                  files={pictureFile}
                  setFiles={setPictureFile}
                  urls={pictureUrl}
                  setUrls={setPictureUrl}
                  toDelete={pictureToDelete}
                  setToDelete={setPictureToDelete}
                  text="Загрузить картинку"
                  filesLimit={1}
                  acceptedFiles={['image/*']}
                  maxSize={maxImageFileSize}
                />
              </div>
            </div>
            <div className={classes.field}>
              <div className={classes.title}>Расписание детально:</div>
              {detailTimetable.map((elem, index) => (
                <div className = {classes.scheduleContainer}>
                  
                  <FormControl className={[classes.field, classes.scheduleElement]}>
                    <InputLabel id="seletc-status" />
                    <Select
                      required
                      labelId="seletc-status"
                      value={Number.parseInt(elem.day)}
                      onChange={(e) => {
                        changeSchedule(elem.program_schedule_id, "day", e.target.value);
                      }}
                    >
                      <MenuItem value={1}>Понедельник</MenuItem>
                      <MenuItem value={2}>Вторник</MenuItem>
                      <MenuItem value={3}>Среда</MenuItem>
                      <MenuItem value={4}>Четверг</MenuItem>
                      <MenuItem value={5}>Пятница</MenuItem>
                      <MenuItem value={6}>Суббота</MenuItem>
                      <MenuItem value={0}>Воскресенье</MenuItem>
                    </Select>
                  </FormControl>
                  {/* <div className={classes.scheduleElement}>
                    <div className={classes.title}>Время начала:</div>
                    <TextField
                      required
                      value={elem.time_start}
                      onChange={(e) => {
                        changeSchedule(elem.program_schedule_id, "time_start", e.target.value);
                      }}
                      className={classes.field}
                      multiline
                    />
                  </div> */}

                  <div className={classes.scheduleElement}>
                    <div className={classes.title}>Время начала:</div>
                      <InputMask 
                        mask="99:99" 
                        value={elem.time_start} 
                        onChange={(e) => {
                          changeSchedule(elem.program_schedule_id, "time_start", e.target.value);
                        }}>
                        {(inputProps) => <TextField {...inputProps} value={elem.time_start} className={classes.field} required type="text" disableUnderline />}
                      </InputMask>
                  </div>

                  <div className={classes.scheduleElement}>
                    <div className={classes.title}>Время окончания:</div>
                    {/* <TextField
                      required
                      value={elem.time_end}
                      onChange={(e) => {
                        changeSchedule(elem.program_schedule_id, "time_end", e.target.value);
                      }}
                      className={classes.field}
                      multiline
                    /> */}
                    <InputMask 
                        mask="99:99" 
                        value={elem.time_end} 
                        onChange={(e) => {
                          changeSchedule(elem.program_schedule_id, "time_end", e.target.value);
                        }}>
                        {(inputProps) => <TextField {...inputProps} value={elem.time_end} className={classes.field} required type="text" disableUnderline />}
                    </InputMask>
                  </div>
                  
                  {/* <p>{elem.id}</p>
                  <p>{elem.program_id}</p>
                  <p>{elem.program_schedule_id}</p>
                  <p>{elem.day}</p>
                  <p>{elem.time_start}</p>
                  <p>{elem.time_end}</p> */}
                  
                  <div className = {classes.scheduleElement}>
                    <div className={classes.title}>
                      Список ведущих
                    </div>
                      <CheckboxesTags
                        options={emcees}
                        selected={typeof elem.emcees == "string" ? JSON.parse(elem.emcees) : elem.emcees}
                        schedule_id={elem.program_schedule_id}
                        setSelected={changeSchedule}
                      />
                  </div>
                  <div className = {classes.scheduleElement}>
                    <div className={classes.title}>
                      Список гостей
                    </div>
                      <CheckboxesTags
                        options={guests}
                        selected={typeof elem.guests == "string" ? JSON.parse(elem.guests) : elem.guests}
                        schedule_id={elem.program_schedule_id}
                        setSelected={changeSchedule}
                      />
                  </div>
                  <div onClick = {() => {deleteSchedule(elem.program_schedule_id)}}
                  className = {classes.deleteButton}>Удалить расписание</div>
                </div>
              ))}
            </div>
            <div onClick={() => setDetailTimetable([...detailTimetable,
               {
                day: "0",
                emcees: [],
                // id: detailTimetable.length + Date.now(),
                program_id: id,
                program_schedule_id: detailTimetable.length + Date.now(),
                time_end: "",
                time_start: ""
               }])} className = {classes.addButton}>
                Добавить расписание
            </div>
            <div className={classNames(classes.field, classes.dateRow)}>
              <div className={classes.dateColumn}>
                <div className={classes.dateRow}>Дата создания:</div>
                <div className={classes.dateRow}>
                  {format(createDate, DATE_TIME_FORMAT)}
                </div>
              </div>
              <div className={classes.dateColumn}>
                <div className={classes.dateRow}>Дата публикации:</div>
                <TextField
                  className={classes.dateRow}
                  type="datetime-local"
                  value={
                    publishDate ? format(publishDate, DATE_TIME_FORMAT) : undefined
                  }
                  onChange={(e) => {
                    setPublishDate(Date.parse(e.target.value));
                  }}
                />
              </div>
              <div className={classes.dateColumn}>
                <div className={classes.dateRow}>Дата снятия с публикации:</div>
                <TextField
                  className={classes.dateRow}
                  type="datetime-local"
                  value={
                    unpublishDate
                      ? format(unpublishDate, DATE_TIME_FORMAT)
                      : undefined
                  }
                  onChange={(e) => {
                    setUnpublishDate(Date.parse(e.target.value));
                  }}
                />
              </div>
            </div>
            <Button variant="contained" color="primary" type="submit" fullWidth>
              {id ? 'Обновить' : 'Создать'}
            </Button>
          </div>
        )}
        {navigationPage === 1 && (
          <div>
            <div className={classes.field}>
              <div className={classes.title}>Заголовок:</div>
              <TextField
                value={seoTitle}
                onChange={(event) => {
                  setSeoTitle(event.target.value);
                }}
                className={classes.field}
              />
            </div>
            <div className={classes.field}>
              <div className={classes.title}>Описание:</div>
              <TextField
                value={seoDescription}
                onChange={(e) => {
                  setSeoDescription(e.target.value);
                }}
                className={classes.field}
              />
            </div>
            <div className={classes.field}>
              <div className={classes.title}>Ключевые слова:</div>
              <TextField
                value={seoKeyWords}
                onChange={(e) => {
                  setSeoKeyWords(e.target.value);
                }}
                className={classes.field}
              />
            </div>
          </div>
        )}
      </form>
    </div>
  );
}

ProgramsForm.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  podcasts: PropTypes.array,
  emcees: PropTypes.array,
  // guests: PropTypes.array,
  submitHandler: PropTypes.func,
};

ProgramsForm.defaultProps = {
  classes: {},
  data: {
    id: undefined,
    alias: '',
    title: '',
    description: '',
    content: '',
    status: 1,
    short_timetable: '',
    detail_timetable: [],
    guests_list: [],
    emcees_list: [],
    podcasts_list: [],
    create_date: Date.now(),
    seo_title: '',
    seo_description: '',
    seo_key_words: '',
  },
  podcasts: [],
  emcees: [],
  // guests: [],
  submitHandler: () => {},
};

export default withStyles(styles)(ProgramsForm);
