import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Hidden from '@material-ui/core/Hidden';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Button from '@material-ui/core/Button';
import { NavLink, Link } from 'react-router-dom';
import styles from './header-jss';

function Header(props) {
  const {
    classes, toggleDrawerOpen, margin, dense,
  } = props;

  const [open] = useState(false);

  const logoutHandler = () => {
    localStorage.removeItem("loginToken");
  };

  return (
    <AppBar
      className={classNames(
        classes.appBar,
        classes.floatingBar,
        margin && classes.appBarShift,
      )}
    >
      <Toolbar disableGutters={!open}>
        <div className={classNames(classes.brandWrap, dense && classes.dense)}>
          <span>
            <IconButton
              className={classes.menuButton}
              aria-label="Menu"
              onClick={toggleDrawerOpen}
            >
              <MenuIcon />
            </IconButton>
          </span>
          <Hidden smDown>
            <NavLink
              to="/admin/news"
              className={classNames(classes.brand, classes.brandBar)}
            >
              <img src="" alt="" />
            </NavLink>
          </Hidden>
        </div>
        <Hidden smDown>
          <div className={classes.headerProperties} />
        </Hidden>
        <div className={classes.userToolbar}>
          <Button
            color="primary"
            className={classes.buttonTop}
            component={Link}
            to="/"
            variant="contained"
            onClick={logoutHandler}
          >
            Выйти
          </Button>
        </div>
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  classes: PropTypes.object,
  toggleDrawerOpen: PropTypes.func,
  margin: PropTypes.bool,
  dense: PropTypes.bool,
};

Header.defaultProps = {
  classes: {},
  toggleDrawerOpen: () => {},
  margin: false,
  dense: false,
};

export default withStyles(styles)(Header);
