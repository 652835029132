import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTytes from 'prop-types';
import { withStyles, Button } from '@material-ui/core';
import PapperBlock from '../../components/PapperBlock';
import DataTable from '../../components/DataTable';
import { getPodcastsForTable } from './requests';
import { PodcastIcon } from '../../constants/icons';
import { ADMIN_PODCASTS_CREATE_ROUTE } from '../../constants/routes';
import Columns from './Columns';
import styles from '../../styles';

function PodcastsPage({ classes }) {
  const [data, setData] = useState([]);

  console.log('data',data);

  useEffect(() => {
    async function processingRequest() {
      setData((await getPodcastsForTable()).data);
    }
    processingRequest();
  }, []);

  return (
    <PapperBlock title="Подкасты" icon={<PodcastIcon />}>
      <Link to={ADMIN_PODCASTS_CREATE_ROUTE} className={classes.link}>
        <Button variant="contained" color="primary" className={classes.field}>
          Добавить подкаст
        </Button>
      </Link>
      <DataTable title="Список подкастов" data={data} columns={Columns} />
    </PapperBlock>
  );
}

export default withStyles(styles)(PodcastsPage);

PodcastsPage.propTypes = {
  classes: PropTytes.object,
};

PodcastsPage.defaultProps = {
  classes: {},
};
