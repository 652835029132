import React, { useEffect, useState, useRef } from 'react';
import SvgVideo from '../Svg/SvgVideo';
import Animation from '../../helpers/animation';
import './index.css';
import { getCurrentProgram } from './requests';
import {useDispatch, useSelector} from 'react-redux';
import {
  setVideoStreamOpen,
  setProgramSlideIndex,
  setVideoStreamPlaying,
  pauseAudioPlaybackAction,
} from '../../redux/actions';
import ProgramSlide from '../ProgramSlide';

export default function PlayerVideo() {

  const dispatch = useDispatch();
  const { isVideoStreamOpened, programSlideIndex, isVideoStreamPlaying } = useSelector((state) => state);

  const [program, setProgram] = useState([]);
  const [index, setIndex] = useState(0);
  // console.log("rerender")
  // let sliderIndex, currentProgram;

  const processingRequest = async () => {
    setProgram((await getCurrentProgram()).data);
  };

  // let slideIndex = 0;
  // const [slideIndex, setSlideIndex] = useState(0);
  // function autoSwiper() {

  //   // let itemsT = document.querySelectorAll(".header .auto-swiper-item");
  //   // let activeItemT = document.querySelector(".header .auto-swiper-item.active") ? document.querySelector(".header .auto-swiper-item.active") : null;
  //   // let itemsB = document.querySelectorAll(".player-block .auto-swiper-item");
  //   // let activeItemB = document.querySelector(".player-block .auto-swiper-item.active") ? document.querySelector(".player-block .auto-swiper-item.active") : null;

  //   // if( activeItemT ) activeItemT.classList.remove('active');
  //   // if( activeItemB ) activeItemB.classList.remove('active');
    
  //   // slideIndex++;
  //   // if (slideIndex > itemsT.length) slideIndex = 1;
  //   // if ( itemsT[slideIndex-1] ) itemsT[slideIndex-1].classList.add('active');
  //   // if (slideIndex > itemsB.length) slideIndex = 1;
  //   // if ( itemsB[slideIndex-1] ) itemsB[slideIndex-1].classList.add('active');
  //   // setTimeout(autoSwiper, 3000);

  //   if( program != null) {
  //     if (program.pictures != null && (program.pictures.isArray || typeof program.pictures == 'object' )) {
  //       if (programSlideIndex < program.pictures.length - 1) { // Явный костыль с возможным выстрелом в ногу
  //         dispatch(setProgramSlideIndex(programSlideIndex + 1));  // По непонятной мне причине await dispatch() не срабатывает должным образом, даже когда нет forEach
  //       } else {
  //         dispatch(setProgramSlideIndex(0));
  //       }
  //     }
  //   }
  // }

  // useEffect(() => {
  //   processingRequest();
  // }, []);

  // useEffect(() => {
  //   // console.log("slider rerender");
  //   // const currentProgram = setInterval(() => processingRequest(), 1000 * 60 * 3);
  //   const sliderIndex = setInterval(() => {
  //     if( program != null) {
  //       if (program.pictures != null && (program.pictures.isArray || typeof program.pictures == 'object' )) {
  //         if (programSlideIndex < program.pictures.length - 1) { // Явный костыль с возможным выстрелом в ногу
  //           dispatch(setProgramSlideIndex(programSlideIndex + 1));  // По непонятной мне причине await dispatch() не срабатывает должным образом, даже когда нет forEach
  //         } else {
  //           dispatch(setProgramSlideIndex(0));
  //         }
  //       }
  //     }
  //     // console.log("rerender")
  //   }, 3000);
  //   return () => {
  //     clearInterval(sliderIndex);
  //     // clearInterval(currentProgram);
  //   }
  // });

  useEffect(() => {
    processingRequest();
    const program = setInterval(() => {
      processingRequest();
    }, 1000 * 60 * 3);

    return (() => {
      clearInterval(program);
    });
  }, []);

  useEffect(() => {
    if (program.pictures) {
      const lastIndex =  program.pictures.length - 1
      if (index < 0) {
        setIndex(lastIndex)
      }
      if (index > lastIndex){
        setIndex(0)
      }
    }
  }, [index, program.pictures])
  
  useEffect(() => {
    let slider = setInterval(() => {
      setIndex(index + 1)
     }, 3000);
      return () => clearInterval(slider)
  }, [index])

  

  return (
    <div className="ether-player"
      onClick={(event) => {
        new Animation(event).button();
        dispatch(setVideoStreamOpen(true));
        dispatch(setVideoStreamPlaying(true));
        dispatch(pauseAudioPlaybackAction());
      }}
    >
      <div className="ether-player__logo-container">
        <div className="ether-player__alert">
          Смотри эфир
          <div />
        </div>
        <div
          aria-hidden
          className="ether-player__logo"
          src=""
        >
          <SvgVideo />
        </div>
      </div>
      {program != null && (
        <div className="program-now">
          {program.pictures != null && (program.pictures.isArray || typeof program.pictures == 'object' )  && ( //onLoad={autoSwiper()} вставить в div.auto-swiper
            <div className="auto-swiper" >
            { program.pictures.map((el, programIndex) => (
              <ProgramSlide src={ el } programIndex= 
              {programIndex} index={index} programName={program.name} length={program.pictures.length}/>
            ))}
            </div>
          )}

          {program.pictures != null && !program.pictures.isArray && typeof program.pictures != 'object' && (
            <img src={ program.pictures } className="ether-player__preview-img program-preview" alt={ program.name } />
          )}
          <div className="ether-player__info">
              <span className="ether-player__info_track" title={ program.name }>{ program.name }</span>
              <span className="ether-player__info_author" title={ program.emcees_name }>{ program.emcees_name }</span>
          </div>
        </div>
      )}
    </div>
  );
}
