import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './index.css';

export default function BannerWidget({
  redirect, img, title, zoom, local, 
}) {
  const LinkContent = () => (
    <div className="banner">
      <img
        src={img}
        className={`banner__img ${zoom ? 'banner-zoom' : ''}`}
        alt={title}
      />
      {zoom && <div className="banner-filter" />}
      {title && <div className="banner__title">{title}</div>}
    </div>
  );
  if (local || redirect.indexOf(location.hostname) != -1) { // проверка, переход ли это на внутренний ресурс или на внешний
    return (
      <div className="local banner-container">
        <Link 
          to={redirect.replace(location.protocol + "//" + location.hostname, "")} 
          className="banner-link">
          <LinkContent />
        </Link>
      </div>
    );
  }
  return (
    <div className="banner-container">
      <a href={redirect} target="_blank" className="banner-link">
        <LinkContent />
      </a>
    </div>
  );
}

BannerWidget.propTypes = {
  redirect: PropTypes.string,
  img: PropTypes.string,
  title: PropTypes.string,
  zoom: PropTypes.bool,
  local: PropTypes.bool,
};

BannerWidget.defaultProps = {
  redirect: '',
  img: '',
  title: '',
  zoom: false,
  local: false,
};
