import { darken, lighten } from '@material-ui/core/styles/colorManipulator';

const appFrame = {
  display: 'flex',
  width: '100%',
  minHeight: '100%',
  zIndex: 1,
};

const styles = (theme) => ({
  appFrameInner: {
    background:
      theme.palette.type === 'dark'
        ? darken(theme.palette.primary.dark, 0.8)
        : lighten(theme.palette.primary.light, 0.9),
    color: theme.palette.text.primary,
    ...appFrame,
  },
  content: {
    width: '100%',
    padding: theme.spacing(2),
    minHeight: '100%',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  sidebarLayout: {},
  topbarLayout: {},
  mainWrap: {
    height: '100%',
    position: 'relative',
    '& > div': {
      willChange: 'inherit !important',
    },
    '&$sidebarLayout': {
      paddingTop: theme.spacing(8),
    },
    '&$topbarLayout': {
      width: '100%',
      marginTop: theme.spacing(3),
    },
  },
  contentPaddingLeft: {
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(2),
  },
  hideApp: {
    display: 'none',
  },
  circularProgress: {
    position: 'fixed',
    top: 'calc(50% - 45px)',
    left: 'calc(50% - 45px)',
  },
});

export default styles;
