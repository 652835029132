import fetchRequest from '../../helpers/fetchRequest';

export async function getPlaylistChart() {
  const url = '/charts/playlist';
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function getNoveltyChart() {
  const url = '/charts/novelty';
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function getCurrentBanner() {
  const url = '/current-banner';
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function getLatestNews() {
  const url = '/latest-news';
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function getChartTracks() {
  const url = '/chart-tracks';
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function getChartTimestamp() {
  const url = `/get-chart-timestamp`;
  const options = {
    method: 'GET',
  };
  return fetchRequest(url, options);
}

export async function getPollData() {
  const url = '/poll-settings';
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function getAllActiveQuizes() {
  const url = '/quiz';
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}
