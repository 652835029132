import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles, Button } from '@material-ui/core';
import PapperBlock from '../../components/PapperBlock';
import DataTable from '../../components/DataTable';
import { getBannersForTable } from './requests';
import { BannerIcon } from '../../constants/icons';
import { ADMIN_BANNERS_CREATE_ROUTE } from '../../constants/routes';
import Columns from './Columns';
import styles from '../../styles';

function BannerPage({ classes }) {
  const [data, setData] = useState([]);
  async function processingRequest() {
    setData((await getBannersForTable()).data);
  }
  useEffect(() => {
    processingRequest();
  }, []);

  return (
    <PapperBlock title="Баннеры" icon={<BannerIcon />}>
      <Link to={ADMIN_BANNERS_CREATE_ROUTE} className={classes.link}>
        <Button variant="contained" color="primary" className={classes.field}>
          Добавить баннер
        </Button>
      </Link>
      <DataTable title="Список баннеров" data={data} columns={Columns} />
    </PapperBlock>
  );
}

export default withStyles(styles)(BannerPage);

BannerPage.propTypes = {
  classes: PropTypes.object,
};

BannerPage.defaultProps = {
  classes: {},
};
