import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { toggleAction, playTransitionAction } from '../redux/actions';
import LeftSidebarLayout from './layouts/LeftSidebar';
import styles from './styles';

function Dashboard({
  classes,
  children,
  toggleDrawer,
  sidebarOpen,
  loadTransition,
  pageLoaded,
  history,
}) {
  const [appHeight, setAppHeight] = useState(0);

  useEffect(() => {
    setAppHeight(window.innerHeight + 112);
    loadTransition(true);

    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
      setTimeout(() => {
        loadTransition(true);
      }, 500);
    });

    return () => {
      unlisten();
    };
  }, []);

  return (
    <div
      style={{ minHeight: appHeight }}
      className={classNames(
        classes.appFrameInner,
      )}
    >
      <LeftSidebarLayout
        toggleDrawer={toggleDrawer}
        sidebarOpen={sidebarOpen}
        loadTransition={loadTransition}
        pageLoaded={pageLoaded}
      >
        {children}
      </LeftSidebarLayout>
    </div>
  );
}

Dashboard.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.node,
  history: PropTypes.object,
  toggleDrawer: PropTypes.func,
  loadTransition: PropTypes.func,
  sidebarOpen: PropTypes.bool,
  pageLoaded: PropTypes.bool,
};

Dashboard.defaultProps = {
  classes: {},
  children: <div />,
  history: {},
  toggleDrawer: () => {},
  loadTransition: () => {},
  sidebarOpen: false,
  pageLoaded: false,
};

const mapStateToProps = (state) => ({
  sidebarOpen: state.sidebarOpen,
  pageLoaded: state.pageLoaded,
  mode: state.type,
  layout: state.layout,
  isAuthenticated: false,
});

const mapDispatchToProps = {
  toggleDrawer: toggleAction,
  loadTransition: playTransitionAction,
};

const DashboardMaped = connect(mapStateToProps, mapDispatchToProps)(Dashboard);

export default withStyles(styles)(DashboardMaped);
