const menu = [
  {
    title: 'Музыка',
    link: '/music',
    isExternal: false, 
  },
  {
    title: 'Акции',
    link: '/offers',
    isExternal: false, 
  },
  {
    title: 'Подкасты',
    link: '/podcasts',
    isExternal: false, 
  },
  {
    title: 'Что нового',
    link: '/news',
    isExternal: false, 
  },
  {
    title: 'Программы',
    link: '/programs',
    isExternal: false, 
  },
  {
    title: 'Ведущие',
    link: '/emcees',
    isExternal: false, 
  },
  {
    title: 'О радио',
    link: '/about',
    isExternal: false, 
  },
  {
    title: 'Реклама',
    link: 'https://r.novoeradio.by/ ',
    isExternal: true, 
  },
];

export default menu;
