import fetchRequest from '../../helpers/fetchRequest';

export async function loginUser(login, password, location) {
  const url = `/login`;
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      "login": login,
      "password": password, 
      "location": location
    })
  };
  return fetchRequest(url, options);
}

export async function autoLogin(userToken) {
  const url = `/autologin`;
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      "token": userToken
    })
  };
  return fetchRequest(url, options);
}