import React, { useState, useEffect } from 'react';
import MetaTags from 'react-meta-tags';
import { format } from 'date-fns';
import { useParams, Link } from 'react-router-dom';
import BlockContent from '../../components/BlockContent';
import FormMailing from '../../components/FormMailing';
import BlockChart from '../../components/BlockChart';
import ItemChart from '../../containers/ItemChart';
import BlockPodcasts from '../../components/BlockPodcasts';
import ItemPodcast from '../../containers/ItemPodcast';
import BlockPreviewProgram from '../../components/BlockPreviewProgram';
import BlockPreviewProgramContainer from '../../components/BlockPreviewProgramContainer';
import BannerWidget from '../../components/BannerWidget';
import { PROGRAMS_BY_ALIAS_ROUTE } from '../../constants/redirect';
import { CLIENT_DATE_FORMAT } from '../../constants/dateTimeFormat';
import { 
  getProgramByAlias, 
  getLatestPodcasts,
  getPlaylistChart,
  getNoveltyChart,
  getCurrentBanner,
  getRelativePrograms,
  getPodcastsAmount,
} from './requests';
import './index.css';
import { PROGRAMS, PROGRAMS_ARCHIVE } from '../../constants/routes';
import SvgArrow from '../../components/Svg/SvgArrow';
import {
  PLAYLIST, 
  NOVELTY, 
  NOVELTY_ICON, 
  PLAYLIST_ICON,  
} from '../../constants/routes';
import PlaylistContainer from '../../components/PlaylistContainer';
import ShowMoreBtn from '../../components/ShowMoreBtn';
import BlockEmceesItem from '../../components/BlockEmceesItem';

export default function ProgramsDetail() {
  const { alias } = useParams();
  const [program, setProgram] = useState({});
  const [podcasts, setPodcasts] = useState([]);
  const [playlistChartData, setPlaylistChartData] = useState({});
  const [noveltyChartData, setNoveltyChartData] = useState([]);
  const [relativePrograms, setRelativePrograms] = useState([]);
  const [bannerData, setBannerData] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null);

  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [podcastsAmount, setPodcastsAmount] = useState(0);
  const [chosenYear, setChosenYear] = useState(null);
  const [chosenMonth, setChosenMonth] = useState(null);
  const [chosenProgram, setChosenProgram] = useState(null);

  const processingRequest = async () => {
    setProgram((await getProgramByAlias(alias)).data);
    // setPodcasts((await getLatestPodcasts(alias)).data);
    setPlaylistChartData((await getPlaylistChart()).data);
    setNoveltyChartData((await getNoveltyChart()).data);
    setRelativePrograms((await getRelativePrograms(alias)).data);
    setBannerData((await getCurrentBanner()).data);
    // await loadPodcasts(true, chosenYear, chosenMonth, 192, pageSize, pageIndex * pageSize);
  };

  const loadPodcasts = async (firstLoad = true, year, month, program, limit, offset) => {
    const downloaded = await getLatestPodcasts(year, month, program, limit, offset);
    if (firstLoad) {
      setPodcasts(downloaded.data);
    } else {
      setPodcasts([...podcasts, ...downloaded.data]);
    }
  }

  const loadPodcastsWrapper = async (bool, ind) => {
    await loadPodcasts(bool, chosenYear, chosenMonth, program.id, pageSize, ind);
  }
  const setPodcastsAmountWrapper = async () => {
    setPodcastsAmount((await getPodcastsAmount(chosenYear, chosenMonth, program.id)).data);
  }

  useEffect(() => {
    if (program.id) {
      loadPodcastsWrapper(false, pageIndex * pageSize);
      setPodcastsAmountWrapper();
    }
  }, [pageIndex, program])

  useEffect(() => {
    if (program.id) {
      loadPodcastsWrapper(true, 0);
    }
  }, [chosenYear, chosenMonth, chosenProgram, program]);


  const podcastsList = podcasts.map((el, index) => {
    return <ItemPodcast
      podcastList={podcasts}
      index={index}
      key={`${el.title}${el.id}`}
      img={el.picture}
      audio={el.audio}
      date={
        el.publish_date && format(new Date(el.publish_date), CLIENT_DATE_FORMAT)
      }
      download={el.download}
      title={el.title}
      description={el.description}
    />
  });


  useEffect(() => {
    window.scrollTo(0, 0);
    processingRequest();
    return () => {
      setProgram({});
      setBannerData({});
      setPlaylistChartData({});
      setNoveltyChartData([]);
      setRelativePrograms([]);
      setPodcasts([])
    };
  }, [alias]);
  console.log(program);
  return (
    <div className="page-programs-detail">
      {program.id && (
        <>
          <div className="programs-screen">
            <MetaTags>
                  <title>{program.title}</title>
                  <meta name="description" content={program.description} />
            </MetaTags>
            <div className="programs-screen__short-timetable_mobile">
              {program.short_timetable.split('\n').map((el) => (
                <div className="" key={el}>
                  {el}
                </div>
              ))}
            </div>
            <div className="programs-screen__title_mobile">{program.title}</div>
            <img
              src={program.picture}
              alt={program.title}
            />
            <div className="programs-screen__info-row">
              <div className="programs-screen__row-content">
                <div className="programs-screen__title">{program.title}</div>
                <div className="programs-screen__short-timetable">
                  {program.short_timetable.split('\n').map((el) => (
                    <div className="" key={el}>
                      {el}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        
        <BlockContent>
          <div className="big-column">
            <div class= "program-detail-content">
              {program.emcees_list.length > 0 && (
                <>
                  <p className='program-detail_emcees-header'>Ведущие</p>
                  {program.emcees_list.map((el, index) => (
                    <div
                      key={el.name}
                      onClick={() => {
                        if (activeIndex === index) {
                          return setActiveIndex(null);
                        }
                        return setActiveIndex(index);
                      }}
                      className="emcee-item"
                    >
                      <BlockEmceesItem
                        active={activeIndex === index}
                        name={el.name}
                        img={el.avatar.replace('static', '')}
                        back_img={el.img.replace('static', '')}
                        description={el.description}
                        programs={el.programs_list.map(program => program.title)}
                      />
                    </div>
                  ))}
                </>
              )}
              <div className={"programs-detail__title " + (program.emcees_list.length > 0 ? "add-margin" : "")}>{program.title}</div>
              <div
                className="content-editor"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: program.content }}
              />
              <Link to={
                new Date(program.unpublish_date).getTime() - new Date().getTime() > 0 
                ? PROGRAMS
                : PROGRAMS_ARCHIVE
                } 
                className="button-back"
                >
                <div className="button-back__text">Вернуться назад</div>
                <SvgArrow filled direction="left" color="purple" size={26} />
              </Link>
              {/* <FormMailing /> */}
            </div>
            {podcastsAmount > 0 && (
              <BlockPodcasts 
                changeHandler ={ async (year, month, program) => {
                  setChosenYear(year);
                  setChosenMonth(month);
                  setChosenProgram(program);
                  setPageIndex(0);
                }}
              >
                {podcastsList}
                {podcasts.length < podcastsAmount && (
                  <ShowMoreBtn text="Больше" clickHandler={() => {
                    setPageIndex(pageIndex + 1);
                  }} />
                )}
              </BlockPodcasts>
            )}
          </div>
          <div className="small-column__chart">
            <BlockPreviewProgramContainer>
              {relativePrograms.map((el) => (
                <BlockPreviewProgram
                  key={el.title}
                  title={el.title}
                  description={el.description}
                  timetable={el.short_timetable}
                  img={el.picture}
                  redirect={PROGRAMS_BY_ALIAS_ROUTE + el.alias}
                />
              ))}
            </BlockPreviewProgramContainer>
          </div>
        </BlockContent>
      </>
      )}
    </div>
  );
}
