import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import PapperBlock from '../../../components/PapperBlock';
import QuizForm from '../QuizForm';
import { getQuizById, updateQuizById } from '../requests';
import { QuizIcon } from '../../../constants/icons';
import { ADMIN_QUIZ_ROUTE } from '../../../constants/routes';

export default function UpdateQuiz() {
  const [dataQuiz, setDataQuiz] = useState({});
  const [loaded, setLoaded] = useState(false);
  const params = useParams();
  const { id } = params;
  async function processingRequest() {
    setDataQuiz((await getQuizById(id)).data);
    setLoaded(true);
  }
  const history = useHistory();
  const submitHandler = async (data) => {
    const response = await updateQuizById(data);
    console.log("response", response, data);
    if (response.ok) {
      history.push(ADMIN_QUIZ_ROUTE);
    }
  };
  useEffect(() => {
    processingRequest();
  }, []);

  return (
    <PapperBlock title="Обновить опросник" icon={<QuizIcon />}>
      {loaded && (
        <QuizForm
          data={dataQuiz}
          submitHandler={submitHandler}
        />
      )}
    </PapperBlock>
  );
}
