/* eslint-disable consistent-return,no-restricted-globals */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { format } from 'date-fns';
import {
  Select,
  FormControl,
  withStyles,
  MenuItem,
  Button,
  InputLabel,
  TextField,
  Switch,
  Input,
} from '@material-ui/core';
import ImageDropzone from '../../components/Dropzone/ImageDropzone';
import AudioDropzone from '../../components/Dropzone/AudioDropzone';
import WysiwygWidget from '../../components/WysiwygWidget';
import CheckboxesTagsSingle from '../../components/CheckboxesTagsSingle';
import { DATE_FORMAT, DATE_FORMAT_TO_DB, DATE_TIME_FORMAT } from '../../constants/dateTimeFormat';
import styles from '../../styles';
import {maxImageFileSize} from "../../constants/additional";

function PodcastsForm({
  classes, data, programs, submitHandler,
}) {
  console.log(data);
  const { id } = data;
  const [title, setTitle] = useState(data.title);
  const [description, setDescription] = useState(data.description);
  const [status, setStatus] = useState(data.status);
  const [audioFile, setAudioFile] = useState([]);
  const [audioUrl, setAudioUrl] = useState(data.audio);
  const [audioToDelete, setAudioToDelete] = useState([]);
  const [pictureFile, setPictureFile] = useState([]);
  const [pictureUrl, setPictureUrl] = useState(data.picture);
  const [pictureToDelete, setPictureToDelete] = useState([]);
  const [publishDate, setPublishDate] = useState(
    data.publish_date ? new Date(data.publish_date) : data.publish_date,
  );
  const [unpublishDate, setUnpublishDate] = useState(
    data.unpublish_date ? new Date(data.unpublish_date) : data.unpublish_date,
  );
  const [download, setDownload] = useState(data.download);
  const [programsList, setProgramsList] = useState(data.program_id ? data.program_id : "");
  const createDate = new Date(data.create_date);

  console.log('programsList',programsList);

  async function handleSubmit(event) {
    event.preventDefault();
    if (!programsList) {
      return alert('Подкаст должен принадлежать программе.');
    }
    if (!pictureUrl[0] && !pictureFile[0]) {
      return alert('Необходимо загрузить картинку.');
    }
    if (!audioUrl[0] && !audioFile[0]) {
      return alert('Необходимо загрузить подкаст.');
    }
    const ifOk = confirm('Вы уверены что хотите сохранить изменения?');
    if (ifOk) {
      // eslint-disable-next-line no-return-await

      return await submitHandler(
        {
          id,
          title,
          description,
          status,
          createDate: format(createDate, DATE_FORMAT_TO_DB),
          audioUrl,
          audioToDelete,
          pictureUrl,
          pictureToDelete,
          programsList,
          download,
          publishDate: publishDate
            ? format(publishDate, DATE_FORMAT_TO_DB)
            : null,
          unpublishDate: unpublishDate
            ? format(unpublishDate, DATE_FORMAT_TO_DB)
            : null,
        },
        audioFile[0],
        pictureFile[0],
      );
    } else {
      history.back();
    }
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className={classes.field}>
          <div className={classes.title}>Заголовок:</div>
          <TextField
            required
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            className={classes.field}
          />
        </div>
        <div className={classes.field}>
          <div className={classes.title}>Описание:</div>
          <WysiwygWidget state={description} setState={setDescription} />
        </div>
        {/* <div className={classes.field}>
          <div className={classes.title}>Описание:</div>
          <TextField
            required
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            className={classes.field}
            rows={3}
            multiline
          />
        </div> */}
        <div className={classes.field}>
          <div className={classes.title}>
            Статус видимости (Отображать на главной):
          </div>
          <FormControl className={classes.field}>
            <InputLabel id="select-status" />
            <Select
              required
              labelId="seletc-status"
              placeholder="Selection"
              value={status}
              onChange={(e) => {
                setStatus(e.target.value);
              }}
            >
              <MenuItem value={1}>Видимый</MenuItem>
              <MenuItem value={0}>Невидимый</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className={classes.field}>
          <div className={classes.title}>Принадлежность к программе:</div>
          <div className={classes.field}>
            <FormControl className={classes.field}>
              <InputLabel id="select-status" />
              <Select
                required
                labelId="seletc-status"
                placeholder="Selection"
                value={programsList}
                onChange={(e) => {
                  setProgramsList(e.target.value);
                }}
              >
              {programs.map((option, index) => (
                <MenuItem key={index} value={option.id}>
                  {option.title || option.name}
                </MenuItem>
              ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <div className={classes.flexOne}>
          <div className={classNames(classes.fullWidth, classes.rightPadding)}>
            <AudioDropzone
              files={audioFile}
              setFiles={setAudioFile}
              urls={audioUrl}
              setUrls={setAudioUrl}
              toDelete={audioToDelete}
              setToDelete={setAudioToDelete}
              text="Загрузить подкаст"
              filesLimit={1}
              acceptedFiles={['audio/*']}
              maxSize={1500000000}
            />
          </div>
          <div className={classNames(classes.fullWidth, classes.leftPadding)}>
            <ImageDropzone
              files={pictureFile}
              setFiles={setPictureFile}
              urls={pictureUrl}
              setUrls={setPictureUrl}
              toDelete={pictureToDelete}
              setToDelete={setPictureToDelete}
              text="Загрузить картинку"
              filesLimit={1}
              acceptedFiles={['image/*']}
              maxSize={maxImageFileSize}
            />
          </div>
        </div>
        <div className={classes.field}>
          <div className={classes.title}>
            Возможность скачать:
            <Switch
              color="primary"
              checked={download}
              onChange={(e) => {
                setDownload(e.target.checked);
              }}
            />
          </div>
        </div>
        <div className={classNames(classes.field, classes.dateRow)}>
          <div className={classes.dateColumn}>
            <div className={classes.dateRow}>Дата создания:</div>
            <div className={classes.dateRow}>
              {format(createDate, DATE_TIME_FORMAT)}
            </div>
          </div>
          <div className={classes.dateColumn}>
            <div className={classes.dateRow}>Дата публикации:</div>
              <TextField
                className={classes.dateRow}
                type="datetime-local"
                value={
                  publishDate ? format(publishDate, DATE_TIME_FORMAT) : undefined
                }
                onChange={(e) => {
                  setPublishDate(Date.parse(e.target.value));
                }}
              />
            </div>
            <div className={classes.dateColumn}>
            <div className={classes.dateRow}>Дата снятия с публикации:</div>
              <TextField
                className={classes.dateRow}
                type="datetime-local"
                value={
                  unpublishDate
                  ? format(unpublishDate, DATE_TIME_FORMAT)
                  : undefined
                }
                onChange={(e) => {
                  setUnpublishDate(Date.parse(e.target.value));
                }}
              />
            </div>
          </div>
        <Button variant="contained" color="primary" type="submit" fullWidth>
          {id ? 'Обновить' : 'Создать'}
        </Button>
        <div />
      </form>
    </div>
  );
}

export default withStyles(styles)(PodcastsForm);

PodcastsForm.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  programs: PropTypes.array,
  submitHandler: PropTypes.func,
};

PodcastsForm.defaultProps = {
  classes: {},
  data: {
    id: undefined,
    title: '',
    description: '',
    status: 1,
    picture: [],
    audio: [],
    create_date: Date.now(),
    selected_programs: [],
    download: false,
    publish_date: null,
    unpublish_date: null,
    program_id: '',
  },
  programs: [],
  submitHandler: () => {},
};
