import fetchRequest from '../../helpers/fetchRequest';

export async function getOffersUniqueYears(year = null, month = null) {
  const url = '/filter-offers/unique-years';
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      year: year,
      month: month,
    })
  };
  return fetchRequest(url, options);
}

export async function getOffersUniqueMonths(year = null, month = null) {
  const url = '/filter-offers/unique-months';
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      year: year,
      month: month,
    })
  };
  return fetchRequest(url, options);
}

export async function getProgramsUniqueYears(year = null, month = null) {
  const url = '/filter-programs/unique-years';
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      year: year,
      month: month,
    })
  };
  return fetchRequest(url, options);
}

export async function getProgramsUniqueMonths(year = null, month = null) {
  const url = '/filter-programs/unique-months';
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      year: year,
      month: month,
    })
  };
  return fetchRequest(url, options);
}
