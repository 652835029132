import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SvgNews from '../Svg/SvgNews';
import Dropdown from '../Dropdown';
import './index.css';
import {getUniqueYears, getUniqueMonths} from './requests.js';

export default function BlockNews({ children, changeHandler }) {

  const monthsToDigitalBinding = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
  ];

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  const defaultYear = params.year ? params.year : null;
  const defaultMonth = params.month ? months[params.month-1]: null;

  const [openYears, setOpenYears] = useState(false);
  const [openMonths, setOpenMonths] = useState(false);
  const [activeYear, setActiveYear] = useState(defaultYear);
  const [activeMonth, setActiveMonth] = useState(defaultMonth);
  const [availableYears, setAvailableYears] = useState([]);
  const [availableMonths, setAvailableMonths] = useState([]);
  const [availabeMonthsInWords, setAvailabeMonthsInWords] = useState([]);

  // useEffect(() => {
  //   setTimeout(() => {
  //       let url = '/news';
  //       if( activeYear != undefined && activeMonth == undefined && defaultYear != activeYear ) window.location.href = url+'?year='+activeYear;
  //       if( activeMonth != undefined && activeYear == undefined && defaultMonth != activeMonth ) window.location.href = url+'?month='+(months.indexOf(activeMonth)+1);
  //       if( activeMonth != undefined && activeYear != undefined && (defaultYear != activeYear || defaultMonth != activeMonth) ) window.location.href = url+'?year=' + activeYear + '&month=' + (months.indexOf(activeMonth)+1);
  //   }, 300);
  // }, [activeYear,activeMonth]);

  const processingRequest = async () => {
    // setProgram((await getFilterPrograms()).data);
    setAvailableYears((await getUniqueYears(activeYear, activeMonth ? monthsToDigitalBinding.indexOf(activeMonth) + 1 : activeMonth)).data);
    setAvailableMonths((await getUniqueMonths(activeYear, activeMonth ? monthsToDigitalBinding.indexOf(activeMonth) + 1 : activeMonth)).data);
  };

  useEffect(() => {
    const monthArr = []
    availableMonths.forEach(month => {
      monthArr.push(monthsToDigitalBinding[month - 1]);
    });
    setAvailabeMonthsInWords(monthArr);
  }, [availableMonths]);

  useEffect(() => {
    changeHandler(activeYear, monthsToDigitalBinding.indexOf(activeMonth) + 1);
    processingRequest();
  }, [activeYear,activeMonth]);

  const yearsHandler = () => {
    setOpenYears(!openYears);
    setOpenMonths(false);
  };

  const monthsHandler = () => {
    setOpenYears(false);
    setOpenMonths(!openMonths);
  };

  return (
    <div className="block-news">
      <div className="block-news__row mob-column">
        <div className="inline-row">
          <SvgNews />
          <div className="block-news__title">Что нового</div>
        </div>
        <div className="block-news__dropdown-container">
          <Dropdown
            title="Год"
            open={openYears}
            items={availableYears}
            handler={yearsHandler}
            active={activeYear}
            setActive={setActiveYear}
          />
          <Dropdown
            title="Месяц"
            items={availabeMonthsInWords}
            open={openMonths}
            handler={monthsHandler}
            active={activeMonth}
            setActive={setActiveMonth}
          />
        </div>
        {activeYear || activeMonth ? (
        <div class = "drop-filter" onClick={() => {
          changeHandler(null, null, null);
          setActiveYear(null);
          setActiveMonth(null);
        }}>Сбросить</div>
      ) : ""}
      </div>
      <div className="block-news__scroll-bar">{children}</div>
    </div>
  );
}

BlockNews.propTypes = {
  children: PropTypes.node,
};

BlockNews.defaultProps = {
  children: <div />,
};
