/* eslint-disable import/prefer-default-export */
import fetchRequest from '../../helpers/fetchRequest';

export async function getAllSlides() {
  const url = '/slides';
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function getSlideById(id) {
  const url = `/slides/${id}`;
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function createSlide(data, picture) {
  const formData = new FormData();
  formData.append('picture', picture);
  formData.append('data', JSON.stringify(data));
  const url = '/slides';
  const options = {
    method: 'POST',
    body: formData,
  };
  return fetchRequest(url, options);
}

export async function updateSlide(data, picture) {
  const formData = new FormData();
  formData.append('picture', picture);
  formData.append('data', JSON.stringify(data));
  const url = `/slides/${data.id}`;
  const options = {
    method: 'PUT',
    body: formData,
  };
  return fetchRequest(url, options);
}

export async function deleteSlideById(id) {
  const url = `/slides/${id}`;
  const options = {
    method: 'DELETE',
  };
  return fetchRequest(url, options);
}
