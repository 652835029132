import fetchRequest from '../../helpers/fetchRequest';

export async function getProgramByAlias(alias) {
  const url = `/programs/${alias}`;
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function getLatestPodcasts(year, month, program, limit, offset) {
  const url = '/latest-podcasts';
  const options = {
    method: 'POST',
    body: JSON.stringify({
      year: year,
      month: month,
      program: program,
      limit: limit,
      offset: offset,
    }),
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function getRelativePrograms(alias) {
  const url = `/relative-programs/${alias}`;
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function getPlaylistChart() {
  const url = '/charts/playlist';
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function getNoveltyChart() {
  const url = '/charts/novelty';
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function getCurrentBanner() {
  const url = '/current-banner';
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function getPodcastsAmount(year, month, program) {
  const url = `/get-podcasts-amount`;
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      year: year,
      month: month,
      program: program,
    }),
  };
  return fetchRequest(url, options);
}
