import fetchRequest from '../../helpers/fetchRequest';

const SEO = '/seo';
const SEO_NEWS = '/seo-news';
const SEO_PROGRAMS = '/seo-programs';
const SEO_EMCEES = '/seo-emcees';
const SEO_OFFERS = '/seo-offers';

export async function getSeoForTable() {
  const url = SEO;
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function getSeoNewsById(id) {
  const url = `${SEO_NEWS}/${id}`;
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function getSeoProgramsById(id) {
  const url = `${SEO_PROGRAMS}/${id}`;
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function getSeoEmceesById(id) {
  const url = `${SEO_EMCEES}/${id}`;
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function updateSeoNewsById(id, data) {
  const url = `${SEO_NEWS}/${id}`;
  const options = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };
  return fetchRequest(url, options);
}

export async function updateSeoProgramsById(id, data) {
  const url = `${SEO_PROGRAMS}/${id}`;
  const options = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };
  return fetchRequest(url, options);
}

export async function updateSeoEmceesById(id, data) {
  const url = `${SEO_EMCEES}/${id}`;
  const options = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };
  return fetchRequest(url, options);
}

export async function getSeoOffersById(id) {
  const url = `${SEO_OFFERS}/${id}`;
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function updateSeoOffersById(id, data) {
  const url = `${SEO_OFFERS}/${id}`;
  const options = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };
  return fetchRequest(url, options);
}
