import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextField, Button, withStyles } from '@material-ui/core';
import ImageDropzone from '../../components/Dropzone/ImageDropzone';
import CheckboxesTags from '../../components/CheckboxesTags';
import styles from '../../styles';
import {maxImageFileSize} from "../../constants/additional";

function EmceesForm({
  classes, data, programs, submitHandler,
}) {
  const { id } = data;
  const [name, setName] = useState(data.name);
  const [description, setDescription] = useState(data.description);
  const [sort, setSort] = useState(data.sort ? data.sort : 500);
  const [avatarFile, setAvatarFile] = useState([]);
  const [avatarUrl, setAvatarUrl] = useState(data.avatar);
  const [avatarToDelete, setAvatarToDelete] = useState([]);
  const [photoFiles, setPhotoFiles] = useState([]);
  const [photoUrls, setPhotoUrls] = useState(data.photos);
  const [photoUrlsToDelete, setPhotoUrlsToDelete] = useState([]);
  const [programsList, setProgramsList] = useState(data.programs_list);
  async function handleSubmit(event) {
    event.preventDefault();

    
    const ifOk = confirm('Вы уверены что хотите сохранить изменения?');
    if (ifOk) {
    // eslint-disable-next-line no-return-await
    await submitHandler(
      {
        id,
        name,
        description,
        sort,
        programsList,
        avatarUrl,
        avatarToDelete,
        photoUrls,
        photoUrlsToDelete,
      },
      avatarFile[0],
      photoFiles,
    );
    } else {
      history.back();
    }
  }
  console.log("photoUrls", photoUrls);
  // console.log("avatarUrl", avatarUrl);
  // console.log("avatarToDelete", avatarToDelete);
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div>
          <div className={classes.field}>
            <div className={classes.title}>Имя:</div>
            <TextField
              value={name}
              required
              onChange={(e) => {
                setName(e.target.value);
              }}
              className={classes.field}
            />
          </div>
          <div className={classes.field}>
            <div className={classes.title}>Описание:</div>
            <TextField
              value={description}
              required
              onChange={(e) => {
                setDescription(e.target.value);
              }}
              className={classes.field}
              rows={1}
              multiline
            />
          </div>
          <div className={classes.field}>
            <div className={classes.title}>Принадлежность к программе:</div>
            <CheckboxesTags
              options={programs}
              selected={programsList}
              setSelected={setProgramsList}
            />
          </div>
          <div className={classes.field}>
            <div className={classes.title}>Сортировка:</div>
            <TextField
              value={sort}
              required
              onChange={(e) => {
                setSort(e.target.value);
              }}
              className={classes.field}
            />
          </div>
          <div className={classes.halfWidth}>
            <ImageDropzone
              files={avatarFile}
              setFiles={setAvatarFile}
              urls={avatarUrl}
              setUrls={setAvatarUrl}
              toDelete={avatarToDelete}
              setToDelete={setAvatarToDelete}
              text="Фото ведущего"
              filesLimit={1}
              acceptedFiles={['image/*']}
              maxSize={maxImageFileSize}
            />
            <ImageDropzone
              files={photoFiles}
              setFiles={setPhotoFiles}
              urls={photoUrls}
              setUrls={setPhotoUrls}
              toDelete={photoUrlsToDelete}
              setToDelete={setPhotoUrlsToDelete}
              text="Фото для слайдера"
              filesLimit={1}
              acceptedFiles={['image/*']}
              maxSize={maxImageFileSize}
              multiple
            />
          </div>
          <Button variant="contained" color="primary" type="submit" fullWidth>
            {id ? 'Обновить' : 'Создать'}
          </Button>
        </div>
        <div />
      </form>
    </div>
  );
}

EmceesForm.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  programs: PropTypes.array,
  submitHandler: PropTypes.func,
};

EmceesForm.defaultProps = {
  classes: {},
  data: {
    id: undefined,
    alias: '',
    name: '',
    description: '',
    img_url: [],
    programs_list: [],
    avatar: [],
    photos: [],
  },
  programs: [],
  submitHandler: () => {},
};

export default withStyles(styles)(EmceesForm);
