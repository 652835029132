const location = window.location.origin;

const getDomain = () => {
  if (process.env.NODE_ENV === 'production') {
    return location;
  }
  return 'http://localhost:3002';
};

const api = `${getDomain()}/api/v2/admin`;

export default async function fetchRequest(url, options) {
  const path = `${api}${url}`;
  const response = await fetch(path, options);
  console.log("url", url, "options", options);
  const responseData = await response.json();
  return { status: response.status, data: responseData, ok: response.ok };
}
