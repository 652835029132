import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PapperBlock from '../../../components/PapperBlock';
import ProgramsForm from '../ProgramsForm';
import {
  getPodcastsForForm,
  getEmceesForForm,
  getGuestsForForm,
  createProgram,
} from '../requests';
import { ProgramIcon } from '../../../constants/icons';
import { ADMIN_PROGRAMS_ROUTE } from '../../../constants/routes';

export default function CreatePrograms() {
  const [loaded, setLoaded] = useState(false);
  const [podcastsList, setPodcastsList] = useState([]);
  const [emceesList, setEmceesList] = useState([]);
  const [guestsList, setGuestsList] = useState([]);
  async function processingRequest() {
    setPodcastsList((await getPodcastsForForm()).data);
    setEmceesList((await getEmceesForForm()).data);
    setGuestsList((await getGuestsForForm()).data);
    setLoaded(true);
  }
  const history = useHistory();
  const submitHandler = async (data, picture) => {
    let isIncorrectTime = false;
    data.detailTimetable.forEach(schedule => {
      if (schedule.time_start > '23:59' || schedule.time_end > '23:59') {
        isIncorrectTime = true;
      }
    });
    if (isIncorrectTime) {
      alert ('Указан неправильный формат времени, временные рамки - 00:00 - 23:59');
      return false;
    }
    const response = await createProgram(data, picture);
    if (response.ok) {
      history.push(ADMIN_PROGRAMS_ROUTE);
    } else {
      // console.log(response);
      alert(response.data.message)
    }
  };
  useEffect(() => {
    processingRequest();
  }, []);

  return (
    <PapperBlock title="Добавить программу" icon={<ProgramIcon />}>
      {loaded && (
        <ProgramsForm
          podcasts={podcastsList}
          emcees={emceesList}
          guests={guestsList}
          submitHandler={submitHandler}
        />
      )}
    </PapperBlock>
  );
}
