import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTytes from 'prop-types';
import { withStyles, Button } from '@material-ui/core';
import PapperBlock from '../../components/PapperBlock';
import DataTable from '../../components/DataTable';
import { getNewsForTable } from './requests';
import { NewsIcon } from '../../constants/icons';
import { ADMIN_NEWS_CREATE_ROUTE } from '../../constants/routes';
import Columns from './Columns';
import styles from '../../styles';

function NewsPage({ classes }) {
  const [data, setData] = useState([]);

  console.log('data',data);
  
  const processingRequest = async () => {
    setData((await getNewsForTable()).data);
  };
  useEffect(() => {
    processingRequest();
  }, []);

  return (
    <PapperBlock title="Новости" icon={<NewsIcon />}>
      <Link to={ADMIN_NEWS_CREATE_ROUTE} className={classes.link}>
        <Button variant="contained" color="primary" className={classes.field}>
          Добавить Новость
        </Button>
      </Link>
      <DataTable columns={Columns} data={data} />
    </PapperBlock>
  );
}

export default withStyles(styles)(NewsPage);

NewsPage.propTypes = {
  classes: PropTytes.object,
};

NewsPage.defaultProps = {
  classes: {},
};
