import fetchRequest from '../../helpers/fetchRequest';

export async function getAudioStream(id) {
  const api = `/streams/audio/${id}`;
  const options = {
    method: 'GET',
  };
  return fetchRequest(api, options);
}

export async function getCurrentProgram() {
  const api = `/current-program`;
  const options = {
    method: 'GET',
  };
  return fetchRequest(api, options);
}
