import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, withStyles } from '@material-ui/core';
import PropTytes from 'prop-types';
import PapperBlock from '../../../components/PapperBlock';
import { SettingIcon } from '../../../constants/icons';
import {
  // ADMIN_SETTING_ROUTE,
  ADMIN_SETTING_SOCIALS_CREATE_ROUTE,
} from '../../../constants/routes';
import DataTable from '../../../components/DataTable';
import styles from '../../../styles';
import Columns from './Columns';
import { getAllSocials } from './requests';

function Socials({ classes }) {
  const [socialData, setSocialData] = useState([]);
  const history = useHistory();

  async function processingRequest() {
    setSocialData((await getAllSocials()).data);
  }

  useEffect(() => {
    processingRequest();
  }, []);

  return (
    <PapperBlock title="Социальные сети" icon={<SettingIcon />}>
      <Button onClick={() => history.push(ADMIN_SETTING_SOCIALS_CREATE_ROUTE)} variant="contained" color="primary" className={classes.field}>
        Добавить соцсеть
      </Button>
      <DataTable columns={Columns} data={socialData} />
    </PapperBlock>
  );
}

export default withStyles(styles)(Socials);

Socials.propTypes = {
  classes: PropTytes.object,
};

Socials.defaultProps = {
  classes: {},
};
