import React from 'react';
import PropTypes from 'prop-types';

export default function SvgPosition({ direction }) {
  const down = direction === 'down';
  return (
    <svg
      style={{ transform: `rotate(${down ? 180 : 0}deg)` }}
      width="11"
      height="8"
      viewBox="0 0 11 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.79447 0.556485L0.298219 5.69492C-0.232405 6.30148 0.198844 7.25023 1.00416 7.25023H9.99666C10.1769 7.25039 10.3533 7.19859 10.5049 7.10104C10.6564 7.00349 10.7767 6.86433 10.8511 6.70021C10.9256 6.5361 10.9512 6.35399 10.9249 6.17569C10.8985 5.9974 10.8214 5.83048 10.7026 5.69492L6.20634 0.557422C6.11835 0.456726 6.00983 0.37602 5.88807 0.320723C5.76631 0.265425 5.63413 0.236816 5.50041 0.236816C5.36668 0.236816 5.2345 0.265425 5.11274 0.320723C4.99098 0.37602 4.88246 0.456726 4.79447 0.557422V0.556485Z"
        fill={down ? '#E6433C' : '#46C31E'}
      />
    </svg>
  );
}

SvgPosition.propTypes = {
  direction: PropTypes.oneOf(['up', 'down']),
};

SvgPosition.defaultProps = {
  direction: 'up',
};
