import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core';
import PapperBlock from '../../components/PapperBlock';
import DataTable from '../../components/DataTable';
import { getSettingsForTable } from './requests';
import { SettingIcon } from '../../constants/icons';
import Columns from './Columns';
import styles from '../../styles';

function SettingsPage() {
  const [data, setData] = useState();
  async function processingRequest() {
    setData((await getSettingsForTable()).data);
  }
  useEffect(() => {
    processingRequest();
  }, []);

  return (
    <PapperBlock title="Настройки сайта" icon={<SettingIcon />}>
      <DataTable columns={Columns} data={data} />
    </PapperBlock>
  );
}

export default withStyles(styles)(SettingsPage);
