import React from 'react';
import { useHistory } from 'react-router-dom';
import PapperBlock from '../../../components/PapperBlock';
import OffersForm from '../OffersForm';
import { createOffers } from '../requests';
import { OfferIcon } from '../../../constants/icons';
import { ADMIN_OFFERS_ROUTE } from '../../../constants/routes';

export default function CreateOffers() {
  const history = useHistory();
  const submitHandler = async (data, picture) => {
    const response = await createOffers(data, picture);
    if (response.ok) {
      history.push(ADMIN_OFFERS_ROUTE);
    }
  };
  return (
    <PapperBlock title="Добавить акцию" icon={<OfferIcon />}>
      <OffersForm submitHandler={submitHandler} />
    </PapperBlock>
  );
}
