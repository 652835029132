import React from 'react';

export default function SvgLike() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      fillOpacity="0"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.724 2.55598C17.5255 1.30048 15.586 1.97548 15.0775 3.44098C14.6575 4.64998 14.1115 6.09898 13.5805 7.16548C11.9905 10.3545 11.0635 12.168 8.50449 14.439C8.12942 14.7547 7.70265 15.0034 7.24299 15.174C5.54799 15.843 3.95799 17.598 4.37349 19.6815L4.90299 22.329C5.03951 23.012 5.36359 23.6436 5.8389 24.1528C6.3142 24.662 6.92194 25.0288 7.59399 25.212L15.994 27.5025C16.8965 27.7483 17.8402 27.8038 18.7652 27.6656C19.6903 27.5274 20.5766 27.1984 21.3678 26.6996C22.159 26.2008 22.8379 25.5429 23.3614 24.7678C23.885 23.9927 24.2417 23.1172 24.409 22.197L25.4365 16.5555C25.5545 15.9069 25.5286 15.2403 25.3605 14.6029C25.1925 13.9654 24.8864 13.3727 24.464 12.8666C24.0416 12.3604 23.5131 11.9533 22.916 11.674C22.3189 11.3946 21.6677 11.2499 21.0085 11.25H19.684L19.699 11.172C19.819 10.5585 19.963 9.71698 20.059 8.79748C20.1565 7.88248 20.209 6.86998 20.1325 5.92798C20.0575 5.00398 19.8565 4.05448 19.3765 3.33298C19.1805 3.05668 18.9622 2.79682 18.724 2.55598Z"
        fill="white"
      />
      <path
        className="line"
        d="M15.5496 3.60506L15.5497 3.60489C15.7473 3.03539 16.2272 2.61303 16.7763 2.45968C17.3127 2.30987 17.9015 2.41871 18.3621 2.90123L18.7238 2.55599L18.3683 2.90761C18.5857 3.12733 18.7849 3.36425 18.9641 3.61605C19.3711 4.23165 19.5616 5.07714 19.6339 5.96844V5.96846C19.7069 6.86748 19.6574 7.84591 19.5616 8.74451L19.5615 8.74557C19.4675 9.64609 19.3262 10.4722 19.2081 11.076L19.2078 11.0776L19.1928 11.1556L19.0785 11.75H19.6838H21.0082H21.0084C21.5943 11.7499 22.1732 11.8785 22.704 12.1269C23.2347 12.3752 23.7044 12.7371 24.0798 13.1869C24.4553 13.6368 24.7274 14.1637 24.8768 14.7303C25.0262 15.2969 25.0492 15.8895 24.9443 16.466L23.9169 22.1074V22.1076C23.762 22.9596 23.4316 23.7703 22.9468 24.4879C22.4621 25.2056 21.8335 25.8147 21.1009 26.2766C20.3683 26.7385 19.5477 27.0431 18.6912 27.1711C17.8347 27.2991 16.9609 27.2476 16.1253 27.0201C16.1253 27.0201 16.1252 27.0201 16.1252 27.02L7.72528 24.7296H7.72524C7.1428 24.5708 6.61609 24.2529 6.20416 23.8116C5.79223 23.3703 5.51136 22.823 5.39304 22.231L5.39303 22.2309L4.86358 19.5837C4.51455 17.8335 5.85016 16.2632 7.42178 15.6409C7.93345 15.4503 8.40855 15.1731 8.82626 14.8215L8.82637 14.8216L8.83612 14.8129C11.4721 12.4737 12.4342 10.5847 14.0278 7.38859L14.0279 7.38834C14.5738 6.29188 15.1283 4.81784 15.5496 3.60506Z"
        stroke="#7f7f7f"
      />
    </svg>
  );
}
