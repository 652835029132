import React, { Fragment } from 'react';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { useHistory } from 'react-router-dom';
import { deleteTrackById } from './requests';

export default function columns(handleRemove) {
  const history = useHistory();

  return [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: 'picture',
      label: 'Картинка',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => <img src={value ? value : '/empty.jpg' } alt="img" style={{ maxWidth: '50px' }} />,
      },
    },
    {
      name: 'audio',
      label: 'Трек',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => <audio controls src={value} className="audio" />,
      },
    },
    {
      name: 'author',
      label: 'Исполнитель',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'title',
      label: 'Название',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'newTrack',
      label: 'Новинка',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => (value ? <>Да</> : null),
      },
    },
    {
      name: 'applicant',
      label: 'Претендент',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => (value ? <>Да</> : null),
      },
    },
    {
      name: 'likes',
      label: 'Лайки',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: '',
      label: 'Управление',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => (
          <>
            <IconButton
              onClick={() => {
                const id = tableMeta.rowData[0];
                history.push(`/admin/tracks/update/${id}`);
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={async () => {
                if(window.confirm('Вы уверены, что хотите удалить?')){
                  const id = tableMeta.rowData[0];
                  const { rowIndex } = tableMeta;
                  await deleteTrackById(id);
                  await handleRemove(rowIndex);
                  window.location.reload();
                }
              }}
            >
              <DeleteIcon />
            </IconButton>
          </>
        ),
      },
    },
  ];
}
