import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PapperBlock from '../../../components/PapperBlock';
import QuizForm from '../QuizForm';
import { createQuiz } from '../requests';
import { QuizIcon } from '../../../constants/icons';
import { ADMIN_QUIZ_ROUTE } from '../../../constants/routes';

export default function CreateQuiz() {
  const [loaded, setLoaded] = useState(false);
  async function processingRequest() {
    setLoaded(true);
  }
  const history = useHistory();
  const submitHandler = async (data) => {
    console.log("data", data)
    const response = await createQuiz(data);
    if (response.ok) {
      history.push(ADMIN_QUIZ_ROUTE);
    }
  };
  useEffect(() => {
    processingRequest();
  }, []);

  return (
    <PapperBlock title="Добавить опросник" icon={<QuizIcon />}>
      {loaded && (
        <QuizForm submitHandler={submitHandler} />
      )}
    </PapperBlock>
  );
}
