import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import PapperBlock from '../../../components/PapperBlock';
import {
  getSettingSiteBackground,
  updateSettingSiteBackground,
} from '../requests';
import { SettingIcon } from '../../../constants/icons';
import InputField from '../../../components/InputField';
import ImageDropzone from '../../../components/Dropzone/ImageDropzone';
import { ADMIN_SETTING_ROUTE } from '../../../constants/routes';
import {maxImageFileSize} from "../../../constants/additional";

export default function updateSiteBackground() {
  const [loaded, setLoaded] = useState(false);
  const [title, setTitle] = useState('');
  const [pictureFile, setPictureFile] = useState([]);
  const [pictureUrl, setPictureUrl] = useState([]);
  const [pictureToDelete, setPictureToDelete] = useState([]);
  const history = useHistory();
  async function processingRequest() {
    const { data } = await getSettingSiteBackground();
    setTitle(data.title);
    setPictureUrl(data.picture);
    setLoaded(true);
  }
  const submitHandler = async (event) => {
    event.preventDefault();
    const data = {
      title,
      pictureUrl,
      pictureToDelete,
    };
    const response = await updateSettingSiteBackground(data, pictureFile[0]);
    if (response.ok) {
      history.push(ADMIN_SETTING_ROUTE);
    }
  };
  useEffect(() => {
    processingRequest();
  }, []);

  return (
    <PapperBlock title="Обновить фон сайта" icon={<SettingIcon />}>
      {loaded && (
        <form onSubmit={submitHandler}>
          <InputField title="Название:" value={title} setValue={setTitle} />
          <ImageDropzone
            files={pictureFile}
            setFiles={setPictureFile}
            urls={pictureUrl}
            setUrls={setPictureUrl}
            toDelete={pictureToDelete}
            setToDelete={setPictureToDelete}
            text="Загрузить картинку"
            maxSize={maxImageFileSize}
          />
          <Button variant="contained" color="primary" type="submit" fullWidth>
            Обновить фон сайта
          </Button>
        </form>
      )}
    </PapperBlock>
  );
}
