import React from 'react';
import { useHistory } from 'react-router-dom';
import PapperBlock from '../../../components/PapperBlock';
import TracksForm from '../TracksForm';
import { createTrack } from '../requests';
import { TrackIcon } from '../../../constants/icons';
import { ADMIN_TRACKS_ROUTE } from '../../../constants/routes';

export default function CreateTracks() {
  const history = useHistory();
  const submitHandler = async (data, audio, picture) => {
    const response = await createTrack(data, audio, picture);
    if (response.ok) {
      history.push(ADMIN_TRACKS_ROUTE);
    }
  };
  return (
    <PapperBlock title="Добавить трек" icon={<TrackIcon />}>
      <TracksForm submitHandler={submitHandler} />
    </PapperBlock>
  );
}
