import fetchRequest from '../../helpers/fetchRequest';

export async function getUsers() {
  const url = '/users';
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function getUserById(id) {
  const url = `/users/${id}`;
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function createUser(data) {
  const url = `/users/add`;
  const options = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function updateUser(data) {
  const url = '/users/update';
  const options = {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function deleteUserById(id) {
  const url = `/users/delete/${id}`;
  const options = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}
