import React, { useState, useEffect, useRef } from 'react';
import MetaTags from 'react-meta-tags';
import BlockContent from '../../components/BlockContent';
import BlockPrograms from '../../components/BlockPrograms';
import BlockProgramsItem from '../../components/BlockProgramsItem';
import BlockChart from '../../components/BlockChart';
import ItemChart from '../../containers/ItemChart';
import PlaylistContainer from '../../components/PlaylistContainer';
import BannerWidget from '../../components/BannerWidget';
import { PROGRAMS_BY_ALIAS_ROUTE, PROGRAMS_ARCHIVE_ROUTE } from '../../constants/redirect';
import {
  getPrograms,
  getPlaylistChart,
  getNoveltyChart,
  getCurrentBanner,
  getChartTimestamp,
  getArchivePic,
} from './requests';
import './index.css';
import setLocalStorageForLikes from '../../helpers/setLocalStorageForLikes';
import {
  PLAYLIST, 
  NOVELTY, 
  NOVELTY_ICON, 
  PLAYLIST_ICON,  
} from '../../constants/routes';
import {useSelector} from 'react-redux';

export default function Programs() {
  const [programs, setPrograms] = useState([]);
  const [noveltyChartData, setNoveltyChartData] = useState([]);
  const [playlistChartData, setPlaylistChartData] = useState([]);
  const [bannerData, setBannerData] = useState(null);
  const [ratedTracks, setRatedTracks] = useState([]);
  const [likedTracks, setLikedTracks] = useState([]);
  const [archivePic, setArchivePic] = useState("");
  const {playlistLastSong} = useSelector((state) => state);
  

  const setLocalStorage = async () => {
    const timestamp = ((await getChartTimestamp()).data);
    const [actualRatedTracks, actualLikedTracks] = setLocalStorageForLikes(timestamp.timestamp);
    setRatedTracks([...actualRatedTracks]);
    setLikedTracks([...actualLikedTracks]);
  }

  const processingRequest = async () => {
    setPrograms((await getPrograms()).data);
    setPlaylistChartData((await getPlaylistChart()).data);
    setNoveltyChartData((await getNoveltyChart()).data);
    setBannerData((await getCurrentBanner()).data);
    setArchivePic((await getArchivePic()).data);
    await setLocalStorage();
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    processingRequest();
  }, []);

  // const rerenderPlaylist = async () => {
  //   setPlaylistChartData((await getPlaylistChart()).data);
  // }

  useEffect(() => {
      if (playlistLastSong.id && playlistChartData.id) {
        setPlaylistChartData(
          {
            ...playlistChartData, 
            track_list: [
              {
                ...playlistLastSong, 
                play_date: new Date().toISOString(),
                play_time: new Date().toISOString(),
              }, 
              ...playlistChartData.track_list
            ]
          }
        );
      }
  }, [playlistLastSong]);
  
  return (
    <div className="page-programs">
      <MetaTags>
        <title>Программы - Новое Радио</title>
        <meta name="description" content="Программы - Новое Радио" />
      </MetaTags>
      <BlockContent>
        <div className="big-column">
          <BlockPrograms>
            {programs.map((el) => (
              <div className="block-programs-item" key={el.id}>
                <BlockProgramsItem
                  title={el.title}
                  description={el.description}
                  img={el.picture}
                  timetable={el.short_timetable}
                  redirect={PROGRAMS_BY_ALIAS_ROUTE + el.alias}
                />
              </div>
            ))}
          </BlockPrograms>
        </div>
        <div className="small-column__chart">
        {archivePic && (
            <BannerWidget
                local
                title="Архив программ"
                img={archivePic ? archivePic.picture.replace('static', '') : ""}
                redirect={PROGRAMS_ARCHIVE_ROUTE}
              />
          )}
          {playlistChartData && (
            <PlaylistContainer
                chartData={playlistChartData.track_list}
                title={playlistChartData.title}
                link={PLAYLIST}
                icon={PLAYLIST_ICON}
                countViewTracks={4}
                additionalClass = {"playlist"}
                children={playlistChartData.track_list}
                type={"playlistChart"}
                color={null}
              />
          )}
          {noveltyChartData.length > 0 && (
            <PlaylistContainer
                chartData={noveltyChartData}
                title={"Новинки"}
                link={NOVELTY}
                icon={NOVELTY_ICON}
                countViewTracks={4}
                additionalClass = {"new_songs"}
                children={noveltyChartData}
                type={"noveltyChart"}
                color="yellow"
              />
          )}
          {bannerData && bannerData.map((elem) => (
            <BannerWidget img={elem.picture} redirect={elem.link}/>
          ))}
        </div>
      </BlockContent>
    </div>
  );
}
