import React, { useState, useEffect, useRef } from 'react';
import MetaTags from 'react-meta-tags';
import { format } from 'date-fns';
import { useDispatch } from 'react-redux';
import BlockContent from '../../components/BlockContent';
import BlockChart from '../../components/BlockChart';
import ItemChart from '../../containers/ItemChart';
import PlaylistContainer from '../../components/PlaylistContainer';
import BlockPodcasts from '../../components/BlockPodcasts';
import ItemPodcast from '../../containers/ItemPodcast';
import CarouselWidget from '../../components/CarouselWidget';
import BannerWidget from '../../components/BannerWidget';
import ShowMoreBtn from '../../components/ShowMoreBtn';
import {
  getLatestPodcasts,
  getPlaylistChart,
  getCurrentBanner,
  getLatestNews, 
  getNoveltyChart,
  getChartTimestamp,
  getPodcastsAmount
} from './requests';
import { CLIENT_DATE_FORMAT } from '../../constants/dateTimeFormat';
import './index.css';
import { filterPodcasts } from '../../redux/actions';
import setLocalStorageForLikes from '../../helpers/setLocalStorageForLikes';
import {
  PLAYLIST, 
  NOVELTY, 
  NOVELTY_ICON, 
  PLAYLIST_ICON,  
} from '../../constants/routes';
import {useSelector} from 'react-redux';

export default function Podcasts() {
  const dispatch = useDispatch();
  const [years, setYears] = useState([]);
  const [months, setMonths] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [programsID, setProgramsID] = useState([]);
  const {playlistLastSong} = useSelector((state) => state);


  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  const [podcasts, setPodcasts] = useState([]);
  const [playlistChartData, setPlaylistChartData] = useState({});
  const [noveltyChartData, setNoveltyChartData] = useState([]);
  const [bannerData, setBannerData] = useState(null);
  const [sliderNewsData, setSliderNewsData] = useState([]);
  const [ratedTracks, setRatedTracks] = useState([]);
  const [likedTracks, setLikedTracks] = useState([]);

  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [podcastsAmount, setPodcastsAmount] = useState(0);
  const [chosenYear, setChosenYear] = useState(null);
  const [chosenMonth, setChosenMonth] = useState(null);
  const [chosenProgram, setChosenProgram] = useState(null);
  // const endOfList = useRef();

  const setLocalStorage = async () => {
    const timestamp = ((await getChartTimestamp()).data);
    const [actualRatedTracks, actualLikedTracks] = setLocalStorageForLikes(timestamp.timestamp);
    setRatedTracks([...actualRatedTracks]);
    setLikedTracks([...actualLikedTracks]);
  }

  const loadPodcasts = async (firstLoad = true, year, month, program, limit, offset) => {
    const downloaded = await getLatestPodcasts(year, month, program, limit, offset);
    if (firstLoad) {
      setPodcasts(downloaded.data);
    } else {
      setPodcasts([...podcasts, ...downloaded.data]);
    }
  }

  const processingRequest = async () => {
    setPlaylistChartData((await getPlaylistChart()).data);
    setNoveltyChartData((await getNoveltyChart()).data);
    setSliderNewsData((await getLatestNews()).data);
    setBannerData((await getCurrentBanner()).data);
    await setLocalStorage();

    setPodcastsAmount((await getPodcastsAmount(chosenYear, chosenMonth, chosenProgram,)).data);
    await loadPodcasts(true, chosenYear, chosenMonth, chosenProgram, pageSize, pageIndex * pageSize);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    processingRequest();
  }, []);

  const loadPodcastsWrapper = async (bool, ind) => {
    await loadPodcasts(bool, chosenYear, chosenMonth, chosenProgram, pageSize, ind);
  }
  const setPodcastsAmountWrapper = async () => {
    setPodcastsAmount((await getPodcastsAmount(chosenYear, chosenMonth, chosenProgram,)).data);
  }

  useEffect(() => {
    loadPodcastsWrapper(false, pageIndex * pageSize);
    setPodcastsAmountWrapper();
  }, [pageIndex])

  useEffect(() => {
    loadPodcastsWrapper(true, 0);
  }, [chosenYear, chosenMonth, chosenProgram]);

  // const rerenderPlaylist = async () => {
  //   setPlaylistChartData((await getPlaylistChart()).data);
  // }

  useEffect(() => {
      if (playlistLastSong.id && playlistChartData.id) {
        setPlaylistChartData(
          {
            ...playlistChartData, 
            track_list: [
              {
                ...playlistLastSong, 
                play_date: new Date().toISOString(),
                play_time: new Date().toISOString(),
              }, 
              ...playlistChartData.track_list
            ]
          }
        );
      }
  }, [playlistLastSong]);

  const podcastsList = podcasts.map((el, index) => {
    return <ItemPodcast
      podcastList={podcasts}
      index={index}
      key={`${el.title}${el.id}`}
      img={el.picture}
      audio={el.audio}
      date={
        el.publish_date && format(new Date(el.publish_date), CLIENT_DATE_FORMAT)
      }
      download={el.download}
      title={el.title}
      description={el.description}
    />
    });

  return (
    <div className="page-podcasts">
      <MetaTags>
        <title>Подкасты - Новое Радио</title>
        <meta name="description" content="Подкасты - Новое радио" />
      </MetaTags>
      <BlockContent>
        <div className="big-column">
          <BlockPodcasts changeHandler ={ async (year, month, program) => {
            setChosenYear(year);
            setChosenMonth(month);
            setChosenProgram(program);
            setPageIndex(0);
          }}>
            {podcastsList}
            {podcasts.length < podcastsAmount && (
              <ShowMoreBtn text="Больше" clickHandler={() => {
                setPageIndex(pageIndex + 1);
                }} />
            )}
          </BlockPodcasts>
        </div>
        <div className="small-column__chart">
          {playlistChartData && (
            <PlaylistContainer
                chartData={playlistChartData.track_list}
                title={playlistChartData.title}
                link={PLAYLIST}
                icon={PLAYLIST_ICON}
                countViewTracks={4}
                additionalClass = {"playlist"}
                children={playlistChartData.track_list}
                type={"playlistChart"}
                color={null}
              />
          )}
          {noveltyChartData && (
            <PlaylistContainer
                chartData={noveltyChartData}
                title={"Новинки"}
                link={NOVELTY}
                icon={NOVELTY_ICON}
                countViewTracks={4}
                additionalClass = {"new_songs"}
                children={noveltyChartData}
                type={"noveltyChart"}
                color="yellow"
              />
          )}
        </div>
      </BlockContent>
      <BlockContent className="footer-block">
        <div className="big-column">
          <div class = "slider-padding">
            <CarouselWidget sliderTitle="Что нового" data={sliderNewsData} />
          </div>
        </div>
        <div className="small-column">
          {bannerData && bannerData.map((elem) => (
            <BannerWidget img={elem.picture} redirect={elem.link} />
          ))}
        </div>
      </BlockContent>
    </div>
  );
}
