import fetchRequest from '../../helpers/fetchRequest';

export async function getCurrentOffer() {
  const url = '/current-offer';
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function getAllCurrentOffers() {
  const url = '/current-offers';
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function getCurrentBanner() {
  const url = '/current-banner';
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function getOffersArchive() {
  const url = '/offers/archive-latest';
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function getPictureFromLastUnactiveOffer() {
  const url = '/offers/archive-preview';
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}
