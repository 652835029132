import React from 'react';
import { useHistory } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';

export default function Columns() {
  const history = useHistory();
  const models = {
    news: 'news',
    offers: 'offers',
    emcees: 'emcees',
    programs: 'programs',
  };
  return [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: 'model_type',
      label: 'Тип',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          if (value === models.news) {
            return 'Новость';
          }
          if (value === models.programs) {
            return 'Программа';
          }
          if (value === models.emcees) {
            return 'Ведущий';
          }
          if (value === models.offers) {
            return 'Акция';
          }
          return 'Неизвестно';
        },
      },
    },
    {
      name: 'title',
      label: 'Заголовок',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: '',
      label: 'Управление',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => (
          <>
            <IconButton
              onClick={() => {
                const id = tableMeta.rowData[0];
                const rowModel = tableMeta.rowData[1];
                history.replace(`/admin/seo/${rowModel}/update/${id}`);
              }}
            >
              <EditIcon />
            </IconButton>
          </>
        ),
      },
    },
  ];
}
