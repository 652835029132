import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PapperBlock from '../../../components/PapperBlock';
import PodcastsForm from '../PodcastsForm';
import { getProgramsForForm, createPodcast } from '../requests';
import { PodcastIcon } from '../../../constants/icons';
import { ADMIN_PODCASTS_ROUTE } from '../../../constants/routes';

export default function CreatePodcasts() {
  const [loaded, setLoaded] = useState(false);
  const [programsList, setProgramsList] = useState([]);
  async function processingRequest() {
    setProgramsList((await getProgramsForForm()).data);
    setLoaded(true);
  }
  const history = useHistory();
  const submitHandler = async (data, audio, picture) => {
    const response = await createPodcast(data, audio, picture);
    if (response.ok) {
      history.push(ADMIN_PODCASTS_ROUTE);
    }
  };
  useEffect(() => {
    processingRequest();
  }, []);

  return (
    <PapperBlock title="Добавить подкаст" icon={<PodcastIcon />}>
      {loaded && (
        <PodcastsForm programs={programsList} submitHandler={submitHandler} />
      )}
    </PapperBlock>
  );
}
