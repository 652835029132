/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Slider from '@material-ui/core/Slider';
import { makeStyles } from '@material-ui/core/styles';
import SvgPause from '../../components/Svg/SvgPause';
import SvgPlay from '../../components/Svg/SvgPlay';
import SvgDownload from '../../components/Svg/SvgDownload';
import {
  changeFlowAction,
  changeAudioFlowAction,
  changeAudioPlaybackAction,
  playAudioPlaybackAction,
  pauseAudioPlaybackAction,
  changeAudioCurrentTimePercentAction, setCurrentFullTimeAction, setPlayingPodcastIndex,
} from '../../redux/actions';
import './index.css';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  colorPrimary: {
    color: '#ffffff',
  },
  thumb: {
    width: '10px',
    height: '10px',
    marginTop: '-4px',
    '&:hover, &:focus ,&:active': { boxShadow: 'none' },
  },
});

function ItemPodcast({
  img,
  audio,
  title,
  date,
  description,
  flow,
  audioPlayback,
  changeFlow,
  changeAudioFlow,
  pauseAudioPlayback,
  playAudioPlayback,
  audioFullDuration,
  download,
  index,
  duration,
  // currentTime,
  changeCurrentFullTime,
  podcastList,
  podcastIndex,
  setPodcastIndex,
}) {
  const bgColor = index % 2 !== 0 ? 'grey' : 'white';
  const isActive = audioPlayback
    && JSON.stringify(flow)
      === JSON.stringify({ chart: 'Подкасты', title, description });

  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [currentTime, setCurrentTime] = useState('');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // eslint-disable-next-line consistent-return
  // useEffect(() => {
  //   if (currentTime && currentTime === duration) {
  //     if (!podcastList[podcastIndex + 1]) {
  //       return pauseAudioPlayback();
  //     }
  //     setValue(0);
  //     setPodcastIndex(podcastIndex + 1);
  //     changeAudioFlow(podcastList[podcastIndex + 1].audio);
  //     playAudioPlayback();
  //     changeFlow({
  //       chart: 'Подкасты',
  //       title: podcastList[podcastIndex + 1].title,
  //       description: podcastList[podcastIndex + 1].description,
  //     });
  //   }
  // }, [currentTime]);

  return (
    <div
      className={`podcast-item ${bgColor} ${
        isActive ? 'podcast-item__active' : ''
      }`}
      aria-hidden="true"
      onClick={() => {
        if (podcastIndex !== index) {
          setValue(0);
        }
        changeAudioFlow(audio);
        setPodcastIndex(index);
        if (isActive) {
          return pauseAudioPlayback();
        }
        playAudioPlayback();
        document.getElementsByTagName("audio")[0].onended=function(){ 
          pauseAudioPlayback();
        };
        document.getElementsByTagName("audio")[0].ontimeupdate=function(e){ 
          const minutes = Math.floor(e.target.currentTime / 60);
          const seconds = Math.floor(e.target.currentTime - minutes * 60);
          setCurrentTime(`${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`);
          setValue(e.target.currentTime);
        };
        return changeFlow({ chart: 'Подкасты', title, description });
      }}
    >
      <div className="podcast-item__img-container">
        <img src={img} alt={title} />
      </div>

      <div
        className={`podcast-item__img-filter ${isActive ? '' : ''}`}
        style={isActive ? { background: 'rgba(70, 195, 30, 0.5)' } : {}}
      >
        <div className="podcast-item__control">
          {isActive && (
            <div className="podcast-item__control-single">
              <SvgPause color="white" />
            </div>
          )}
          {!isActive && !download && (
            <div className="podcast-item__control-single">
              <SvgPlay color="white" />
            </div>
          )}
          {!isActive && download && (
            <div className="podcast-item__control-double">
              <div className="podcast-item__control-double_item">
                <SvgPlay />
              </div>
              <div className="podcast-item__control-double_item">
                <a
                  href={audio}
                  download
                  className="podcast-control__download-link"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <SvgDownload outline />
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* {console.log("description", description)} */}
      <div className="podcast-item__info">
        <div className="podcast-info__date">{date}</div>
        <div className="podcast-info__title">{title}</div>
        <div className="podcast-info__description">{description.replace(/(<([^>]+)>)/gi, '')}</div>
        <div className="podcast-info__audio-info">
          <div
            className="podcast-info__progress-slider"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Slider
              min={0}
              max={audioFullDuration}
              classes={classes}
              color="primary"
              value={value}
              onChange={handleChange}
              onChangeCommitted={(e, newVal) => {
                changeCurrentFullTime(newVal);
              }}
            />

            <div className="podcast-info__timelines">
              <span className="podcast-info__timelines_numbers">{currentTime}</span>
              <span className="podcast-info__timelines_numbers">{duration}</span>
            </div>
          </div>
          <div className="podcast-info__control-container">
            {download && (
              <a href={audio} download className="podcast-info__download-link">
                <div
                  className="podcast-info__download"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <SvgDownload />
                </div>
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

ItemPodcast.propTypes = {
  img: PropTypes.string,
  audio: PropTypes.string,
  title: PropTypes.string,
  date: PropTypes.string,
  description: PropTypes.string,
  flow: PropTypes.object.isRequired,
  audioPlayback: PropTypes.bool.isRequired,
  changeFlow: PropTypes.func.isRequired,
  changeAudioFlow: PropTypes.func.isRequired,
  pauseAudioPlayback: PropTypes.func.isRequired,
  playAudioPlayback: PropTypes.func.isRequired,
  download: PropTypes.bool,
};

ItemPodcast.defaultProps = {
  img: '',
  audio: '',
  title: '',
  date: '',
  description: '',
  download: false,
};

const mapStateToProps = (state) => ({
  flow: state.flow,
  playback: state.playback,
  audioFlow: state.audioFlow,
  audioPlayback: state.audioPlayback,
  audioFullDuration: state.audioCurrentFullDuration,
  audioCurrentTime: state.audioCurrentTime,
  audioCurrentTimePercent: state.audioCurrentTimePercent,
  duration: state.duration,
  // currentTime: state.currentTime,
  podcastIndex: state.podcastIndex,
});

const mapDispatchToProps = {
  changeFlow: changeFlowAction,
  changeAudioFlow: changeAudioFlowAction,
  changeAudioPlayback: changeAudioPlaybackAction,
  playAudioPlayback: playAudioPlaybackAction,
  pauseAudioPlayback: pauseAudioPlaybackAction,
  changeAudioCurrentTimePercent: changeAudioCurrentTimePercentAction,
  changeCurrentFullTime: setCurrentFullTimeAction,
  setPodcastIndex: setPlayingPodcastIndex,
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemPodcast);
