import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactQuill, { Quill } from 'react-quill';
import Dialog from '@material-ui/core/Dialog';
import { Button, Input } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import EditorToolbar, { modules, formats } from './EditorToolbar';
import { getLatestPictures, addNewPicture } from './requests';
import 'react-quill/dist/quill.snow.css';
import './index.css';

export default function WysiwygWidget({ state, setState }) {
  const [open, setOpen] = useState(false);
  const [pictures, setPictures] = useState([]);
  const [video, setVideo] = useState('');
  const [videoOpen, setVideoOpen] = useState(false);
  const handleChange = (value) => setState(value);
  const handleClose = () => setOpen(false);
  const handleCloseVideo = () => setVideoOpen(false);
  const handleOpenVideo = () => setVideoOpen(true);
  const inputRef = useRef(null);
  const reactQuillRef = useRef(null);
  const handleClickOpen = async () => {
    setPictures((await getLatestPictures()).data);
    setOpen(true);
  };
  modules.toolbar.handlers.image = handleClickOpen;
  function imageChange(img) {
    const quill = reactQuillRef.current.getEditor();
    const range = quill.getSelection();
    const position = range ? range.index : 0;
    quill.insertEmbed(position, 'image', img, 'user');
  }
  const processingRequest = async () => {
    setPictures((await getLatestPictures()).data);
  };
  function getCorrectVideoUrl(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return match && match[2].length === 11
      ? `//www.youtube.com/embed/${match[2]}`
      : null;
  }
  function videoChange(videoUrl) {
    const quill = reactQuillRef.current.getEditor();
    const range = quill.getSelection(true);
    const correctVideoUrl = getCorrectVideoUrl(videoUrl);
    quill.insertText(range.index, '\n', Quill.sources.USER);
    quill.insertEmbed(
      range.index + 1,
      'video',
      correctVideoUrl,
      Quill.sources.USER,
    );
    quill.formatText(range.index + 1, 1, { height: '170', width: '400' });
    quill.setSelection(range.index + 2, Quill.sources.SILENT);
  }
  modules.toolbar.handlers.video = handleOpenVideo;

  useEffect(() => {
    processingRequest();
  }, []);

  useEffect(
    () => () => {
      setPictures([]);
      setOpen(false);
    },
    [],
  );

  return (
    <div className="text-editor">
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Вставить изображение</DialogTitle>
        <DialogContent>
          <input
            ref={inputRef}
            style={{ display: 'none' }}
            type="file"
            onChange={async (e) => {
              addNewPicture(e.target.files[0]).then(async () => {
                processingRequest();
              });
            }}
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={async () => {
              inputRef.current.click();
            }}
          >
            Выбрать файл
          </Button>
          <div className="ql__pictures-container">
            {pictures.map((el) => (
              <div
                key={el.id}
                className="ql__picture-item"
                onClick={() => {
                  imageChange(el.img);
                  setOpen(false);
                }}
              >
                <img
                  src={el.img}
                  alt={el.id}
                />
              </div>
            ))}
          </div>
        </DialogContent>
      </Dialog>
      <Dialog open={videoOpen} onClose={handleCloseVideo}>
        <DialogTitle>Вставить видео</DialogTitle>
        <DialogContent>
          <Input
            placeholder="Ссылка на видео с YouTube"
            fullWidth
            style={{ marginBottom: '20px' }}
            value={video}
            onChange={(e) => {
              setVideo(e.target.value);
            }}
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => {
              setVideo('');
              setVideoOpen(false);
              videoChange(video);
            }}
          >
            Добавить видео
          </Button>
        </DialogContent>
      </Dialog>
      <EditorToolbar setState={setState} />
      <ReactQuill
        ref={reactQuillRef}
        theme="snow"
        value={state}
        onChange={handleChange}
        modules={modules}
        formats={formats}
      />
    </div>
  );
}

WysiwygWidget.propTypes = {
  state: PropTypes.string,
  setState: PropTypes.func,
};

WysiwygWidget.defaultProps = {
  state: '',
  setState: '',
};
