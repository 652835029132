import React from 'react';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';

export default function DataTable({
  title, data, columns, weeksInputHandler, weeksValue, moveTrack, deleteHandler, placeValue, placeInputHandler, changeChartDifference
}) {
  const dataColumns = columns(
    weeksInputHandler,
    weeksValue,
    moveTrack,
    data,
    deleteHandler,
    placeValue, 
    placeInputHandler,
    changeChartDifference,
  );

  const options = {
    selectableRows: 'none',
    filterType: 'checkbox',
    search: true,
    download: false,
    print: false,
    viewColumns: true,
    filter: true,
    rowsPerPage: 20,
    rowsPerPageOptions: [20,40,80],
    textLabels: {
      body: {
        noMatch: 'Подходящих записей не найдено',
        toolTip: 'Sort',
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
      pagination: {
        next: 'Next Page',
        previous: 'Previous Page',
        rowsPerPage: 'Строк на странице:',
        displayRows: 'из',
      },
      toolbar: {
        search: 'Поиск',
        downloadCsv: 'Download CSV',
        print: 'Print',
        viewColumns: 'Просмотр столбцов',
        filterTable: 'Таблица фильтров',
      },
      filter: {
        all: 'All',
        title: 'ФИЛЬТРЫ',
        reset: 'СБРОС',
        data: PropTypes.array,
      },
      viewColumns: {
        title: 'Show Columns',
        titleAria: 'Show/Hide Table Columns',
      },
      selectedRows: {
        text: 'row(s) selected',
        delete: 'Delete',
        deleteAria: 'Delete Selected Rows',
      },
    },
  };
  return (
    <MUIDataTable
      title={title}
      data={data}
      columns={dataColumns}
      options={options}
    />
  );
}

DataTable.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  columns: PropTypes.func,
  weeksInputHandler: PropTypes.func,
  moveTrack: PropTypes.func,
  deleteHandler: PropTypes.func,
  weeksValue: PropTypes.object,
  placeValue: PropTypes.object, 
  placeInputHandler: PropTypes.func,
  changeChartDifference: PropTypes.func,
  // trackPosition: PropTypes.object,
};

DataTable.defaultProps = {
  title: '',
  data: [],
  weeksValue: {},
  placeValue: {}, 
  // trackPosition: {},
  columns: () => {},
  weeksInputHandler: () => {},
  moveTrack: () => {},
  deleteHandler: () => {},
  placeInputHandler: () => {},
  changeChartDifference: () => {},
};
