import { fade } from '@material-ui/core/styles/colorManipulator';

const drawerWidth = 240;
const styles = (theme) => ({
  drawer: {
    width: drawerWidth,
  },
  swipeDrawerPaper: {
    width: drawerWidth,
  },
  opened: {
    '& $primary, & $icon': {
      color: theme.palette.primary.main,
    },
  },
  drawerPaperClose: {
    width: theme.spacing(8),
    position: 'absolute',
    overflowX: 'hidden',
    transition: theme.transitions.create(['width', 'background-color'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    '& nav': {
      display: 'none',
    },
    '&:hover': {
      width: drawerWidth,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[6],
      '& nav': {
        display: 'block',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,0.3)',
        border: '1px solid rgba(255,255,255,0.4)',
      },
    },
    '& $menuContainer': {
      '&$menuContainer': {
        paddingTop: theme.spacing(10),
        paddingBottom: 0,
      },
    },
    '&::-webkit-scrollbar': {
      width: 8,
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 12,
      backgroundColor: 'rgba(0,0,0,0)',
    },
  },
  drawerInner: {
    // Make the items inside not wrap when transitioning:
    height: '100%',
    position: 'fixed',
    backgroundColor: '#ffffff',
  },
  primary: {
    whiteSpace: 'nowrap',
  },
  icon: {
    minWidth: theme.spacing(5),
    color: fade(theme.palette.text.hint, 0.48),
  },
  iconed: {
    '&$opened': {
      '&:before': {
        content: '""',
        position: 'absolute',
        width: 5,
        height: theme.spacing(6),
        top: 0,
        left: 0,
        background: fade(theme.palette.primary.main, 0.5),
      },
    },
  },
  head: {
    padding: `${theme.spacing(1)}px 0`,
    margin: `${theme.spacing(1)}px 0`,
    borderRadius: `0 ${theme.spacing(1)}px ${theme.spacing(1)}px 0`,
    paddingLeft: theme.spacing(3),
    '&$iconed': {
      paddingLeft: theme.spacing(3),
    },
    '& svg[class^="MuiSvgIcon"]': {
      position: 'relative',
    },
  },
  rounded: {},
  withProfile: {},
  menuContainer: {
    overflow: 'auto',
    height: 'calc(100% - 64px)',
    width: drawerWidth,
    position: 'relative',
    display: 'block',
    padding: `${theme.spacing(5)}px 0`,
    '&$withProfile': {
      paddingTop: theme.spacing(10),
    },
    '&$rounded': {
      paddingRight: theme.spacing(0),
      '& a': {
        borderRadius: theme.spacing(),
      },
      '& $opened': {
        '&:before': {
          background: theme.palette.primary.main,
        },
      },
    },
    '&::-webkit-scrollbar': {
      width: 8,
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 12,
      backgroundColor: 'rgba(0,0,0,0)',
    },
    '&:hover': {
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,0.3)',
        border: '1px solid rgba(255,255,255,0.4)',
      },
    },
  },
  badge: {
    height: 'auto',
  },
});

export default styles;
