import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Select,
  withStyles,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
  Button,
  Tabs,
  Tab,
} from '@material-ui/core';
import WysiwygWidget from '../../components/WysiwygWidget';
import styles from '../../styles';

function AboutBlockForm({ classes, data, submitHandler }) {
  console.log(data);
  const { id } = data;
  const [title, setTitle] = useState(data.title);
  const [content, setContent] = useState(data.content);
  const [status, setStatus] = useState(data.status);
  const [navigationPage, setNavigationPage] = useState(0);
  const [seoTitle, setSeoTitle] = useState(data.seo_title);
  const [seoDescription, setSeoDescription] = useState(data.seo_description);
  const [seoKeyWords, setSeoKeyWords] = useState(data.seo_key_words);

  async function dispatchHandler(event) {
    event.preventDefault();
    
    const ifOk = confirm('Вы уверены что хотите сохранить изменения?');
    if (ifOk) {
      await submitHandler(
        {
          id,
          title,
          status,
          content,
          // seoTitle,
          // seoDescription,
          // seoKeyWords,
        },
      );
    } else {
      history.back();
    }
  }

  return (
    <form onSubmit={dispatchHandler}>
      <div className={classes.field}>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          aria-label="disabled tabs example"
          value={navigationPage}
          onChange={(event, value) => {
            setNavigationPage(value);
          }}
        >
          <Tab label="Форма О радио" />
          <Tab label="Форма SEO" />
        </Tabs>
      </div>
      {navigationPage === 0 && (
      <div>
        <div className={classes.field}>
          <div className={classes.title}>Заголовок:</div>
          <TextField
            type="text"
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            className={classes.field}
          />
        </div>
        <div className={classes.field}>
          <div className={classes.title}>Содержание:</div>
          <WysiwygWidget state={content} setState={setContent} />
        </div>
        <div className={classes.field}>
          <div className={classes.title}>
            Статус видимости (Отображать на странице):
          </div>
          <FormControl className={classes.field}>
            <InputLabel id="seletc-status" />
            <Select
              required
              labelId="seletc-status"
              value={status}
              onChange={(e) => {
                setStatus(e.target.value);
              }}
            >
              <MenuItem value={1}>Видимый</MenuItem>
              <MenuItem value={0}>Невидимый</MenuItem>
            </Select>
          </FormControl>
        </div>
        <Button variant="contained" color="primary" type="submit" fullWidth>
          {id ? 'Обновить блок' : 'Создать блок'}
        </Button>
      </div>
      )}
      {navigationPage === 1 && (
      <div>
        <div className={classes.field}>
          <div className={classes.title}>Заголовок:</div>
          <TextField
            value={seoTitle}
            onChange={(event) => {
              setSeoTitle(event.target.value);
            }}
            className={classes.field}
          />
        </div>
        <div className={classes.field}>
          <div className={classes.title}>Описание:</div>
          <TextField
            value={seoDescription}
            onChange={(e) => {
              setSeoDescription(e.target.value);
            }}
            className={classes.field}
          />
        </div>
        <div className={classes.field}>
          <div className={classes.title}>Ключевые слова:</div>
          <TextField
            value={seoKeyWords}
            onChange={(e) => {
              setSeoKeyWords(e.target.value);
            }}
            className={classes.field}
          />
        </div>
      </div>
      )}
      <div />
    </form>
  );
}

AboutBlockForm.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  submitHandler: PropTypes.func,
};

AboutBlockForm.defaultProps = {
  classes: {},
  data: {
    title: '',
    content: '',
    status: 1,
    seo_title: '',
    seo_description: '',
    seo_key_words: '',
  },
  submitHandler: () => {},
};

export default withStyles(styles)(AboutBlockForm);
