/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

export default function SvgDownload({ outline, color }) {
  if (outline) {
    return (
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 35 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.9">
          <circle
            cx="17.5"
            cy="17.5"
            r="17.5"
            fill="white"
            fillOpacity="0.5"
          />
          <ellipse
            cx="17.4999"
            cy="17.5"
            rx="14.6622"
            ry="14.6622"
            fill="white"
          />
          <path
            d="M18.2241 19.6666L21.1129 16.7777M18.2241 19.6666V11V19.6666ZM18.2241 19.6666L15.3352 16.7777L18.2241 19.6666Z"
            stroke="#E40A3B"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12.8496 24.0001H23.5947"
            stroke="#E40A3B"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    );
  }
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.3351 15.3508L16.1362 11.5496M12.3351 15.3508V3.94736V15.3508ZM12.3351 15.3508L8.53394 11.5496L12.3351 15.3508Z"
        stroke="white"
        strokeOpacity="0.7"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.26318 21.0527H19.4015"
        stroke="white"
        strokeOpacity="0.7"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

SvgDownload.propTypes = {
  outline: PropTypes.bool,
  color: PropTypes.oneOf(['white', 'red']),
};

SvgDownload.defaultProps = {
  outline: false,
  color: 'white',
};
