import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Select,
  withStyles,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
  Tooltip,
  FormHelperText,
  Button,
} from '@material-ui/core';

import { FaqIcon } from '../../constants/icons';
import styles from '../../styles';

function StreamBlockForm({ classes, data, submitHandler }) {
  const { id } = data;
  const [name, setName] = useState(data.name);
  const [sort, setSort] = useState(data.sort);
  const [status, setStatus] = useState(data.status);
  const [link, setLink] = useState(data.link);
  const [playlist_link, setPlaylistLink] = useState(data.playlist_link);
  const [quality, setQuality] = useState(data.quality);
  const [navigationPage, setNavigationPage] = useState(0);

  async function dispatchHandler(event) {
    event.preventDefault();
    
    const ifOk = confirm('Вы уверены что хотите сохранить изменения?');
    if (ifOk) {
      await submitHandler(
        {
          id,
          name,
          sort,
          status,
          link,
          playlist_link,
          quality
        },
      );
    } else {
      history.back();
    }
  }

  return (
    <form onSubmit={dispatchHandler}>
      <div>
        <div className={classes.field}>
          <div className={classes.title}>Имя:</div>
          <TextField
            type="text"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            className={classes.field}
          />
        </div>
        <div className={classes.field}>
          <div className={classes.title}>Ссылка на плейлист:</div>
          <TextField
            type="text"
            value={playlist_link}
            onChange={(e) => {
              setPlaylistLink(e.target.value);
            }}
            className={classes.field}
          />
          <FormHelperText>.xspf ссылка, например http://live.novoeradio.by:8000/novoeradio-128k.xspf</FormHelperText>
        </div>
        <div className={classes.field}>
          <div className={classes.title}>Позиция:</div>
          <TextField
            type="text"
            value={sort}
            onChange={(e) => {
              setSort(e.target.value);
            }}
            className={classes.field}
          />
        </div>
        <div className={classes.field}>
          <div className={classes.title}>
            Статус видимости <Tooltip title="Отображать на сайт"><FaqIcon fontSize="small" /></Tooltip>:
          </div>
          <FormControl className={classes.field}>
            <InputLabel id="seletc-status" />
            <Select
              required
              labelId="seletc-status"
              value={status}
              onChange={(e) => {
                setStatus(e.target.value);
              }}
            >
              <MenuItem value={1}>Видимый</MenuItem>
              <MenuItem value={0}>Невидимый</MenuItem>
            </Select>
          </FormControl>
        </div>
        
        <div className={classes.field}>
          <div className={classes.title}>Качество, кб/с:</div>
          <FormControl className={classes.field}>
            <InputLabel id="select-quality" />
            <Select
              labelId="select-quality"
              value={quality}
              label="Качество, кб/с"
              onChange={(e) => {
                setQuality(e.target.value);
              }}
            >
              <MenuItem value={64}>64</MenuItem>
              <MenuItem value={128}>128</MenuItem>
              <MenuItem value={256}>256</MenuItem>
              <MenuItem value={320}>320</MenuItem>
          </Select>
          </FormControl>
        </div>
        <div className={classes.field}>
          <div className={classes.title}>Ссылка: <Tooltip title="Ссылка по которой будут работать аудио трансляции"><FaqIcon fontSize="small" /></Tooltip></div>
          <TextField
            type="text"
            value={link}
            onChange={(e) => {
              setLink(e.target.value);
            }}
            className={classes.field}
          />
        </div>
        <Button variant="contained" color="primary" type="submit" fullWidth>
          {id ? 'Обновить блок' : 'Создать блок'}
        </Button>
      </div>
      <div />
    </form>
  );
}

StreamBlockForm.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  submitHandler: PropTypes.func,
};

StreamBlockForm.defaultProps = {
  classes: {},
  data: {
    name: '',
    sort: 0,
    status: 1,
    link: '',
    playlist_link: '',
    quality: 64,
  },
  submitHandler: () => {},
};

export default withStyles(styles)(StreamBlockForm);
