import React from 'react';
import { NavLink } from 'react-router-dom';
import './index.css';

export default function NotFound() {
  return (
    <div className="page__not-found">
      <div className="not-found__control">
        <NavLink className="not-found__button" to="/">
          Вернуться на главную
        </NavLink>
        <div className="not-found__alert">Страница не найдена</div>
      </div>
    </div>
  );
}
