import { LOAD_PAGE, SET_BLOCK, TOGGLE_SIDEBAR } from './types';

const initialStore = {
  theme: 'lightBlueTheme',
  type: 'light', // light or dark
  direction: 'ltr', // ltr or rtl
  layout: 'sidebar', // sidebar, big-sidebar, top-navigation, mega-menu
  sidebarOpen: true,
  pageLoaded: false,
  subMenuOpen: [],
  blocks: [],
};

export default function rootReduser(store = initialStore, action) {
  switch (action.type) {
    case TOGGLE_SIDEBAR: {
      return { ...store, sidebarOpen: !store.sidebarOpen };
    }
    case LOAD_PAGE: {
      return { ...store, pageLoaded: action.payload };
    }
    case SET_BLOCK:
      return { ...store, blocks: action.payload };
    default:
      return store;
  }
}
