import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import classNames from 'classnames';
import {
  Select,
  withStyles,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
  Button,
  Input,
} from '@material-ui/core';
import ImageDropzone from '../../components/Dropzone/ImageDropzone';
import { DATE_FORMAT, DATE_FORMAT_TO_DB, DATE_TIME_FORMAT } from '../../constants/dateTimeFormat';
import styles from '../../styles';
import {maxImageFileSize} from "../../constants/additional";

function BannersForm({ classes, data, submitHandler }) {
  const { id } = data;
  const [title, setTitle] = useState(data.title);
  const [link, setLink] = useState(data.link);
  const [status, setStatus] = useState(data.status);
  const [pictureUrl, setPictureUrl] = useState(data.picture);
  const [pictureFile, setPictureFile] = useState([]);
  const [pictureToDelete, setPictureToDelete] = useState([]);
  const [publishDate, setPublishDate] = useState(
    data.publish_date ? new Date(data.publish_date) : data.publish_date,
  );
  const [unpublishDate, setUnpublishDate] = useState(
    data.unpublish_date ? new Date(data.unpublish_date) : data.unpublish_date,
  );
  const createDate = new Date(data.create_date);

  async function dispatchHandler(event) {
    event.preventDefault();

    const ifOk = confirm('Вы уверены что хотите сохранить изменения?');
    if (ifOk) {
      await submitHandler(
        {
          id,
          title,
          status,
          link,
          createDate: format(createDate, DATE_FORMAT_TO_DB),
          publishDate: publishDate ? format(publishDate, DATE_FORMAT_TO_DB) : null,
          unpublishDate: unpublishDate ? format(unpublishDate, DATE_FORMAT_TO_DB) : null,
          pictureUrl,
          pictureToDelete,
        },
        pictureFile[0],
      );
    } else {
      history.back();
    }
  }

  return (
    <div>
      <form onSubmit={dispatchHandler}>
        <div className={classes.field}>
          <div className={classes.title}>Название:</div>
          <TextField
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            className={classes.field}
          />
        </div>
        <div className={classes.field}>
          <div className={classes.title}>Ссылка:</div>
          <TextField
            value={link}
            onChange={(e) => {
              setLink(e.target.value);
            }}
            className={classes.field}
          />
        </div>
        <div className={classes.field}>
          <div className={classes.title}>Статус активности:</div>
          <FormControl className={classes.field}>
            <InputLabel id="seletc-status" />
            <Select
              required
              labelId="seletc-status"
              value={status}
              onChange={(e) => {
                setStatus(e.target.value);
              }}
            >
              <MenuItem value={1}>Активный</MenuItem>
              <MenuItem value={0}>Неактивный</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className={classNames(classes.field, classes.halfWidth)}>
          <ImageDropzone
            files={pictureFile}
            setFiles={setPictureFile}
            urls={pictureUrl}
            setUrls={setPictureUrl}
            toDelete={pictureToDelete}
            setToDelete={setPictureToDelete}
            text="Загрузить картинку"
            filesLimit={1}
            acceptedFiles={['image/*']}
            maxSize={maxImageFileSize}
            multiple={false}
          />
        </div>
        <div className={classNames(classes.field, classes.dateRow)}>
          <div className={classes.dateColumn}>
            <div className={classes.dateRow}>Дата создания:</div>
            <div className={classes.dateRow}>
              {format(createDate, DATE_TIME_FORMAT)}
            </div>
          </div>
          <div className={classes.dateColumn}>
            <div className={classes.dateRow}>Дата публикации:</div>
            <TextField
              className={classes.dateRow}
              type="datetime-local"
              value={publishDate ? format(publishDate, DATE_TIME_FORMAT) : undefined}
              onChange={(e) => {
                setPublishDate(Date.parse(e.target.value));
              }}
            />
          </div>
          <div className={classes.dateColumn}>
            <div className={classes.dateRow}>Дата снятия с публикации:</div>
            <TextField
              className={classes.dateRow}
              type="datetime-local"
              value={
                unpublishDate ? format(unpublishDate, DATE_TIME_FORMAT) : undefined
              }
              onChange={(e) => {
                setUnpublishDate(Date.parse(e.target.value));
              }}
            />
          </div>
        </div>
        <Button variant="contained" color="primary" type="submit" fullWidth>
          {id ? 'Обновить' : 'Создать'}
        </Button>
      </form>
    </div>
  );
}

export default withStyles(styles)(BannersForm);

BannersForm.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  submitHandler: PropTypes.func,
};

BannersForm.defaultProps = {
  classes: {},
  data: {
    id: undefined,
    title: '',
    link: '',
    status: 1,
    picture: [],
    create_date: Date.now(),
    publish_date: undefined,
    unpublishDate: undefined,
  },
  submitHandler: () => {},
};
