export default function setLocalStorageForLikes (timestamp) {
    let ratedTracksInStorage = [];
    let likedTracksInStorage = [];
    if( typeof localStorage.getItem("ratedTracks") == "array") {
      ratedTracksInStorage = localStorage.getItem("ratedTracks");
    } else if (localStorage.getItem("ratedTracks").length) {
      ratedTracksInStorage = JSON.parse(localStorage.getItem("ratedTracks"));
    }
    
    const actualRatedTracks = ratedTracksInStorage.filter(track => track.date >= timestamp);
    localStorage.setItem('ratedTracks', JSON.stringify(actualRatedTracks));

    if( typeof localStorage.getItem("likedTracks") == "array") {
      likedTracksInStorage = localStorage.getItem("likedTracks");
    } else if (localStorage.getItem("likedTracks").length) {
      likedTracksInStorage = JSON.parse(localStorage.getItem("likedTracks"));
    }
    
    const actualLikedTracks = likedTracksInStorage.filter(track => track.date >= timestamp);
    localStorage.setItem('likedTracks', JSON.stringify(actualLikedTracks));

    return [actualRatedTracks, actualLikedTracks]
}