/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

const LinkBtn = React.forwardRef((props, ref) => (
  <NavLink to={props.to} target={props.newPage ? "_blank" : ""} {...props} innerRef={ref} />
));

LinkBtn.propTypes = {
  to: PropTypes.string,
};

LinkBtn.defaultProps = {
  to: '',
};

export default LinkBtn;
