import React from 'react';
import { useHistory } from 'react-router-dom';
import PapperBlock from '../../../components/PapperBlock';
import AboutBlockForm from '../AboutBlockForm';
import { createBlock } from '../requests';
import { NewsIcon } from '../../../constants/icons';
import { ADMIN_ABOUT_ROUTE } from '../../../constants/routes';

export default function CreateAboutBlock() {
  const history = useHistory();
  const submitHandler = async (data) => {
    const response = await createBlock(data);
    if (response.ok) {
      history.push(ADMIN_ABOUT_ROUTE);
    }
  };
  return (
    <PapperBlock title="Добавить блок" icon={<NewsIcon />}>
      <AboutBlockForm submitHandler={submitHandler} />
    </PapperBlock>
  );
}
