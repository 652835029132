import React from 'react';
import { useHistory } from 'react-router-dom';
import PapperBlock from '../../../components/PapperBlock';
import StreamBlockForm from '../StreamBlockForm';
import { createBlock } from '../requests';
import { StreamsIcon } from '../../../constants/icons';
import { ADMIN_STREAMS_A_ROUTE } from '../../../constants/routes';

export default function AudioStreamCreate() {
  const history = useHistory();
  const submitHandler = async (data) => {
    const response = await createBlock(data);
    if (response.ok) {
      history.push(ADMIN_STREAMS_A_ROUTE);
    }
  };
  return (
    <PapperBlock title="Добавить стрим" icon={<StreamsIcon />}>
      <StreamBlockForm submitHandler={submitHandler} />
    </PapperBlock>
  );
}
