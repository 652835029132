import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { TextField, Button, withStyles } from '@material-ui/core';
import ImageDropzone from '../../../components/Dropzone/ImageDropzone';
import styles from '../../../styles';

function TracksForm({ classes, data, submitHandler }) {
  const { id } = data;
  const [title, setTitle] = useState(data.title);
  const [link, setLink] = useState(data.link);
  const [pictureFile, setPictureFile] = useState([]);
  const [pictureUrl, setPictureUrl] = useState(!id ? data.picture : [data.picture]);
  const [pictureToDelete, setPictureToDelete] = useState([]);

  async function dispatchHandler(event) {
    event.preventDefault();
    
    const ifOk = confirm('Вы уверены что хотите сохранить изменения?');
    if (ifOk) {
      await submitHandler(
        {
          id,
          title,
          link,
          pictureUrl,
          pictureToDelete,
        },
        pictureFile[0],
      );
    } else {
      history.back();
    }
  }

  return (
    <div>
      <form onSubmit={dispatchHandler}>
        <div className={classes.inlineWrap}>
          <div className={classNames(classes.field, classes.rightPadding)}>
            <div className={classes.title}>Название соцсети:</div>
            <TextField
              required
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              fullWidth
            />
          </div>
          <div className={classNames(classes.field, classes.leftPadding)}>
            <div className={classes.title}>Ссылка:</div>
            <TextField
              required
              value={link}
              onChange={(e) => {
                setLink(e.target.value);
              }}
              fullWidth
            />
          </div>
        </div>
        <div className={classes.inlineWrap}>
          <div className={classNames(classes.field, classes.leftPadding)}>
            <ImageDropzone
              files={pictureFile}
              setFiles={setPictureFile}
              urls={pictureUrl}
              setUrls={setPictureUrl}
              toDelete={pictureToDelete}
              setToDelete={setPictureToDelete}
              text="Загрузить картинку"
              filesLimit={1}
              acceptedFiles={['image/*']}
              maxSize={3000000}
            />
          </div>
        </div>
        <Button variant="contained" color="primary" type="submit" fullWidth>
          {id ? 'Обновить' : 'Создать'}
        </Button>
      </form>
    </div>
  );
}

export default withStyles(styles)(TracksForm);

TracksForm.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  submitHandler: PropTypes.func,
};

TracksForm.defaultProps = {
  classes: {},
  data: {
    id: undefined,
    title: '',
    picture: [],
    link: '',
  },
  submitHandler: () => {},
};
