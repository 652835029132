import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PapperBlock from '../../../components/PapperBlock';
import PlaylistsForm from '../PlaylistsForm';
import { createPlaylist, getTracksForForm } from '../requests';
import { PlaylistIcon } from '../../../constants/icons';
import { ADMIN_PLAYLISTS_ROUTE } from '../../../constants/routes';

export default function CreatePlaylists() {
  const [loaded, setLoaded] = useState(false);
  const [tracksList, setTracksList] = useState([]);
  async function processingRequest() {
    setTracksList((await getTracksForForm()).data);
    setLoaded(true);
  }
  const history = useHistory();
  const submitHandler = async (data, icon) => {
    const response = await createPlaylist(data, icon);
    if (response.ok) {
      history.push(ADMIN_PLAYLISTS_ROUTE);
    }
  };
  useEffect(() => {
    processingRequest();
  }, []);

  return (
    <PapperBlock title="Добавить плейлист" icon={<PlaylistIcon />}>
      {loaded && (
        <PlaylistsForm tracks={tracksList} submitHandler={submitHandler} />
      )}
    </PapperBlock>
  );
}
