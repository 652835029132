import fetchRequest from '../../helpers/fetchRequest';

export async function getApplicantChartTracks() {
  const url = '/applicant-chart';
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function getNotApplicantChartTracks() {
  const url = '/not-applicant-chart';
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function addApplicantChartTrackById(id) {
  const url = `/applicant-chart/${id}`;
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function updateApplicantChartTracks(data) {
  const url = '/applicant-chart-update';
  const options = {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function deleteApplicantChartTrack(data) {
  const url = '/applicant-chart-delete';
  const options = {
    method: 'DELETE',
    body: JSON.stringify(data),
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function resetApplicantChartRating() {
  const url = '/applicant-chart-reset-rating';
  const options = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function writeApplicantChartTimestamp(data) {
  const url = '/applicant-chart-write-timestamp';
  // const data = {"timestamp": timestamp};
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };
  return fetchRequest(url, options);
}

export async function deleteTrackFromApplicantChartById(id) {
  const url = `/applicant-chart/${id}`;
  const options = {
    method: 'DELETE',
  };
  return fetchRequest(url, options);
}
