import React from 'react';

export default function SvgPrograms() {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.6691 19C31.6691 13.7305 28.448 9.21797 23.8762 7.31055L22.659 10.2348C26.0879 11.6672 28.5 15.0516 28.5 19C28.5 22.9559 26.0879 26.3328 22.6516 27.7652L23.8687 30.6895C28.448 28.782 31.6691 24.2695 31.6691 19ZM25.3309 19C25.3309 16.3652 23.7203 14.109 21.4344 13.159L20.2172 16.0832C21.3602 16.5582 22.1617 17.6863 22.1617 19.0074C22.1617 20.3211 21.3602 21.4492 20.2172 21.9316L21.4344 24.8559C23.7203 23.891 25.3309 21.6348 25.3309 19ZM26.3105 1.46211L25.0859 4.38633C30.8082 6.76875 34.8309 12.4094 34.8309 19C34.8309 25.5832 30.8082 31.2312 25.0859 33.6137L26.3031 36.5379C33.1758 33.673 38 26.9043 38 19C38 11.0957 33.1758 4.32695 26.3105 1.46211ZM0 11.0809V26.9117H6.33086L17.4191 38V0L6.33086 11.0809H0Z"
        fill="#7500AB"
      />
    </svg>
  );
}
