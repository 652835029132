import React, { useEffect, useState } from 'react';
import MetaTags from 'react-meta-tags';
import { Link, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import BlockContent from '../../components/BlockContent';
import BlockChart from '../../components/BlockChart';
import ItemChart from '../../containers/ItemChart';
import PlaylistContainer from '../../components/PlaylistContainer';
import BannerWidget from '../../components/BannerWidget';
import CarouselWidget from '../../components/CarouselWidget';
import BlockNewsItem from '../../components/BlockNewsItem';
import {
  getNewsByAlias,
  getPlaylistChart,
  getNoveltyChart,
  getCurrentBanner,
  getLatestNews,
} from './requests';
import { CLIENT_DATE_FORMAT } from '../../constants/dateTimeFormat';
import './index.css';
import { NEWS } from '../../constants/routes';
import SvgArrow from '../../components/Svg/SvgArrow';
import {
  PLAYLIST, 
  NOVELTY, 
  NOVELTY_ICON, 
  PLAYLIST_ICON, 
} from '../../constants/routes';
import {useSelector} from 'react-redux';

export default function NewsDetail() {
  const [data, setData] = useState({});
  const [bannerData, setBannerData] = useState(null);
  const [playlistChartData, setPlaylistChartData] = useState({});
  const [noveltyChartData, setNoveltyChartData] = useState({});
  const [sliderNewsData, setSliderNewsData] = useState([]);
  const [itemsNews, setItemsNews] = useState([]);
  const {playlistLastSong} = useSelector((state) => state);
  const params = useParams();
  const { alias } = params;
  const processingRequest = async () => {
    setData((await getNewsByAlias(alias)).data);
    setPlaylistChartData((await getPlaylistChart()).data);
    setNoveltyChartData((await getNoveltyChart()).data);
    setBannerData((await getCurrentBanner()).data);
    // setSliderNewsData((await getLatestNews(5)).data);
    // setItemsNews((await getLatestNews(5)).data);
  //   const newsForSlider = await latestNews;
  //   const newsForPictures = await latestNews.slice(3, 5);
  //   (newsForSlider);
  //  (newsForPictures);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    processingRequest();
    return () => {
      setData({});
      setBannerData({});
      setPlaylistChartData({});
      setNoveltyChartData({});
      setBannerData(null);
    };
  }, [alias]);

  // const rerenderPlaylist = async () => {
  //   setPlaylistChartData((await getPlaylistChart()).data);
  // }

  useEffect(() => {
      if (playlistLastSong.id && playlistChartData.id) {
        setPlaylistChartData(
          {
            ...playlistChartData, 
            track_list: [
              {
                ...playlistLastSong, 
                play_date: new Date().toISOString(),
                play_time: new Date().toISOString(),
              }, 
              ...playlistChartData.track_list
            ]
          }
        );
      }
  }, [playlistLastSong]);

  return (
    <div className="page-news-detail">
      <BlockContent>
        <div className="big-column">
          <div class="news-detail-content">
            <div className="news-detail__date">
              {data.create_date && format(new Date(data.create_date), CLIENT_DATE_FORMAT)}
            </div>
            <div className="news-detail__title">{data.title}</div>
            <div className="news-detail__headline">{data.headline}</div>
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: data.content }}
              className="news-detail__content content-editor"
            />
            <Link to={NEWS} className="button-back">
              <div className="button-back__text">Вернуться назад</div>
              <SvgArrow filled direction="left" color="purple" size={26} />
            </Link>
            
            <MetaTags>
                  <title>{data.title}</title>
                  <meta name="description" content={data.description} />
            </MetaTags>
          </div>
        </div>
        <div className="small-column__chart ">
          {playlistChartData && (
            <PlaylistContainer
                chartData={playlistChartData.track_list}
                title={playlistChartData.title}
                link={PLAYLIST}
                icon={PLAYLIST_ICON}
                countViewTracks={4}
                additionalClass = {"playlist"}
                children={playlistChartData.track_list}
                type={"playlistChart"}
                color={null}
              />
          )}
          {noveltyChartData.length > 0 && (
            <PlaylistContainer
                chartData={noveltyChartData}
                title={"Новинки"}
                link={NOVELTY}
                icon={NOVELTY_ICON}
                countViewTracks={4}
                additionalClass = {"new_songs"}
                children={noveltyChartData}
                type={"noveltyChart"}
                color="yellow"
              />
          )}
          {bannerData && bannerData.map((elem) => (
            <BannerWidget img={elem.picture} redirect={elem.link}/>
          ))}
        </div>
      </BlockContent>
    </div>
  );
}
