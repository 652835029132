import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Chip from '@material-ui/core/Chip';
import Icon from '@material-ui/core/Icon';
import LinkBtn from './LinkBtn';
import styles from './sidebar-jss';

function MainMenu({
  classes, dataMenu, toggleDrawerOpen, loadTransition,
}) {
  const location = useLocation().pathname;

  const handleClick = () => {
    toggleDrawerOpen();
    loadTransition(false);
  };

  const getMenus = dataMenu.map((item, index) => {
    if (item.link === location.slice(0, item.link.length)) {
      return (
        <ListItem
          key={index.toString()}
          button
          exact
          className={classNames(
            classes.head,
            item.icon ? classes.iconed : '',
            classes.opened,
          )}
          component={LinkBtn}
          to={item.link}
          newPage={item.newPage}
          onClick={handleClick}
        >
          {item.icon && (
            <ListItemIcon className={classes.icon}>
              <Icon>{item.icon}</Icon>
            </ListItemIcon>
          )}
          <ListItemText
            classes={{
              primary: classes.primary,
            }}
            primary={item.name}
          />
          {item.badge && (
            <Chip
              color="primary"
              label={item.badge}
              className={classes.badge}
            />
          )}
        </ListItem>
      );
    }

    return (
      <ListItem
        key={index.toString()}
        button
        exact
        className={classes.head}
        component={LinkBtn}
        to={item.link}
        onClick={handleClick}
      >
        {item.icon && (
          <ListItemIcon className={classes.icon}>
            <Icon>{item.icon}</Icon>
          </ListItemIcon>
        )}
        <ListItemText
          classes={{
            primary: classes.primary,
          }}
          primary={item.name}
        />
        {item.badge && (
          <Chip color="primary" label={item.badge} className={classes.badge} />
        )}
      </ListItem>
    );
  });

  return <div>{getMenus}</div>;
}

MainMenu.propTypes = {
  classes: PropTypes.object,
  dataMenu: PropTypes.array,
  toggleDrawerOpen: PropTypes.func,
  loadTransition: PropTypes.func,
};

MainMenu.defaultProps = {
  classes: {},
  dataMenu: [],
  toggleDrawerOpen: () => {},
  loadTransition: () => {},
};

export default withStyles(styles)(MainMenu);
