import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTytes from 'prop-types';
import { withStyles, Button } from '@material-ui/core';
import PapperBlock from '../../components/PapperBlock';
import DataTable from '../../components/DataTable';
import { getEmcessForTable } from './requests';
import { EmceeIcon } from '../../constants/icons';
import { ADMIN_EMCEES_CREATE_ROUTE } from '../../constants/routes';
import Columns from './Columns';
import styles from '../../styles';

function EmceesPage({ classes }) {
  const [dataEmcee, setDataEmcee] = useState([]);
  async function processingRequest() {
    setDataEmcee((await getEmcessForTable()).data);
  }
  useEffect(() => {
    processingRequest();
  }, []);

  return (
    <PapperBlock title="Ведущие" icon={<EmceeIcon />}>
      <Link to={ADMIN_EMCEES_CREATE_ROUTE} className={classes.link}>
        <Button variant="contained" color="primary" className={classes.field}>
          Добавить ведущего
        </Button>
      </Link>
      <DataTable title="Список ведущих" data={dataEmcee} columns={Columns} />
    </PapperBlock>
  );
}

export default withStyles(styles)(EmceesPage);

EmceesPage.propTypes = {
  classes: PropTytes.object,
};

EmceesPage.defaultProps = {
  classes: {},
};
