import React, {useState, useEffect, useRef, memo} from "react";
import BlockChart from '../../components/BlockChart';
import ItemChart from '../../containers/ItemChart';
import Chart from '../../containers/Chart';
import setLocalStorageForLikes from '../../helpers/setLocalStorageForLikes';
import {
    getChartTimestamp
  } from './requests';

function PlaylistContainer ({
    chartData, 
    title, 
    link, 
    icon, 
    countViewTracks, 
    additionalClass, 
    children,  
    type,
    color,
    isLinkable = true,
    moreBtn
}) {

    const [ratedTracks, setRatedTracks] = useState([]);
    const [likedTracks, setLikedTracks] = useState([]);
    const [childrenElems, setChildrenElems] = useState({});

    const itemsRef = useRef([]); //массив ref-ов треков в отдном плейлисте, по ним и будем смотреть, какой трек включить следующим

    const setLocalStorage = async () => {
        const timestamp = ((await getChartTimestamp()).data);
        const [actualRatedTracks, actualLikedTracks] = setLocalStorageForLikes(timestamp.timestamp);
        setRatedTracks([...actualRatedTracks]);
        setLikedTracks([...actualLikedTracks]);
      }

    useEffect(() => {
        setLocalStorage();
        setChildrenComponent();

        
    }, []);

    function getHumanTime(date) {
        let rightDate = new Date(date);
        let hours = rightDate.getHours() < 10 ? "0" + rightDate.getHours() : rightDate.getHours();
        let minutes = rightDate.getMinutes() < 10 ? "0" + rightDate.getMinutes() : rightDate.getMinutes();
        return hours + ":" + minutes;
      }

    const setChildrenComponent = () => {
        switch(type) {
            case "chart":
                return chartData != undefined && chartData != null && chartData.map((el, index) => (
                    <div className={"playlist-container-item"} ref={(ref) => itemsRef.current.push(ref)}>
                        <Chart
                            trackId={el.id}
                            key={el.id}
                            number={index + 1}
                            index={index}
                            img={el.picture}
                            audio={el.audio}
                            title={el.title}
                            author={el.author}
                            rate
                            isTrackRated = {ratedTracks.find(item => item.trackId == el.id)}
                            currentPosition={el.currentPosition}
                            lastPosition={el.lastPosition}
                            chartDifference={el.chartDifference}
                            weeks={el.weeks}
                            onClickHandler={(event) => {
                                    changeTrackHandler(event);
                            }}
                            name={`code-${index}`}
                        />
                    </div>
                ));
            case "playlistChart":
                return chartData != undefined && chartData != null && chartData.map((el, index) => (
                    <div className={"playlist-container-item"} ref={(ref) => itemsRef.current.push(ref)}>
                        <ItemChart
                        key={el.id}
                        chart={el.code}
                        index={index}
                        img={el.picture}
                        audio={el.audio}
                        title={el.title}
                        author={el.author}
                        date={el.play_date}
                        onClickHandler={(event) => {
                            changeTrackHandler(event);
                        }}
                        name={`code-${index}`}
                        />
                    </div>
                  ));
            case "playlistChartDetail":
                return chartData != undefined && chartData != null && chartData.map((el, index) => (
                    <div className={"playlist-container-item"} ref={(ref) => itemsRef.current.push(ref)}>
                        <ItemChart
                        playlistId={chartData.id}
                        trackId={el.id}
                        key={el.id}
                        chart={el.code}
                        playTime={getHumanTime(el.play_date)}
                        index={index}
                        img={el.picture}
                        audio={el.audio}
                        title={el.title}
                        author={el.author}
                        // date={el.play_date}
                        onClickHandler={(event) => {
                            changeTrackHandler(event);
                        }}
                        name={`code-${index}`}
                        />
                    </div>
                  ));
            case "noveltyChart":
                return chartData != undefined && chartData != null && chartData.map((el, index) => (
                    <div className={"playlist-container-item"} ref={(ref) => itemsRef.current.push(ref)}>
                        <ItemChart
                        trackId={el.id}
                        key={el.id}
                        chart={el.code}
                        index={index}
                        img={el.picture}
                        audio={el.audio}
                        title={el.title}
                        author={el.author}
                        color={color}
                        like
                        isTrackLiked = {likedTracks.find(item => item.trackId == el.id)}
                        onClickHandler={(event) => {
                            changeTrackHandler(event);
                        }}
                        name={`code-${index}`}
                        />
                    </div>
                  ));
            case "applicantChart":
                return chartData != undefined && chartData != null && chartData.map((el, index) => (
                    <div className={"playlist-container-item"} ref={(ref) => itemsRef.current.push(ref)}>
                        <ItemChart
                        trackId={el.id}
                        key={el.id}
                        chart={el.code}
                        index={index}
                        img={el.picture}
                        audio={el.audio}
                        title={el.title}
                        author={el.author}
                        color={color}
                        like
                        isTrackLiked = {likedTracks.find(item => item.trackId == el.id)}
                        onClickHandler={(event) => {
                            changeTrackHandler(event);
                        }}
                        name={`code-${index}`}
                        />
                    </div>
                  ));

        } 
    }
    // функция, отвечающая за переключение трека на следующий в рамках одного плейлиста
    const changeTrackHandler = (event) => {
        // console.log(event.target.closest(".chart_container").getAttribute("name"));
        const [, codeFieldIndex] =event.target.closest(".chart_container").getAttribute("name").split("-");
        let fieldIntIndex = parseInt(codeFieldIndex, 10);
        if (event.target.closest(".chart_container").parentElement.nextElementSibling) {
          itemsRef.current[fieldIntIndex + 1].querySelector(".chart-item__content").click();
        }

        /* 
            блок кода, скролящий вниз список из плейлиста, 
            если следующий трек в этом плейлисте находится вне зоны видимости для пользователя
            (обычно блоки плейлиста отображают 4 элемента, но может быть так, что следующий элемент,
            который должен стать активным и проиграться, является 5,6 и т.д. и, 
            соответственно, его не видно в списке)
        */
        const childElem = itemsRef.current[fieldIntIndex + 1];
        const parentElem = childElem.closest(".playlist-container");

        const childCoords = childElem.getBoundingClientRect();
        const parentCoords = parentElem.getBoundingClientRect();

        if (parentCoords.bottom < childCoords.bottom) {
            parentElem.querySelector(".chart-scroll__down").click();
        }
    }

    return (
        <div className={"playlist-container"}>
             {chartData && chartData.length > 0 && (
                <BlockChart
                    title={title}
                    link={link}
                    icon={icon}
                    countViewTracks={countViewTracks}
                    additionalClass = {additionalClass}
                    children={children}
                    enableFilter
                    isLinkable={isLinkable}
                    moreBtn={moreBtn}
                >
                    {setChildrenComponent()}
                </BlockChart>
            )}
        </div>
    );
}

export default memo(PlaylistContainer)